import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getPurchasesSalesDeathsMetadata = ({ reportId }) => async (dispatch) => {

  const response = await api.get(endpoints.livestock.purchasesSales.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getPurchasesSalesDeathsMetadataSuccess(response?.data));
  }
  else
    return dispatch(getPurchasesSalesDeathsMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getPurchasesSalesDeathsMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_PURCHASES_SALES_DEATHS_METADATA_SUCCESS,
    payload: response
  };
};

const getPurchasesSalesDeathsMetadataFail = () => {
  return {
    type: actionTypes.GET_PURCHASES_SALES_DEATHS_METADATA_FAIL,
  };
};

export const resetPurchasesSalesDeathsMetadata = () => {
  return {
    type: actionTypes.RESET_PURCHASES_SALES_DEATHS_METADATA,
  };
};

export const getPurchasesSalesDeathsFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.purchasesSales.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    const data = response?.data?.data;
    const summeryData = response?.data?.groupSummeryData;
    dispatch(getPurchasesSalesDeathsOtherSalesDataSuccess(summeryData));
    return dispatch(getPurchasesSalesDeathsFormDataSuccess(data));
  }
  else
    return dispatch(getPurchasesSalesDeathsFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getPurchasesSalesDeathsFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_PURCHASES_SALES_DEATHS_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getPurchasesSalesDeathsFormDataFail = () => {
  return {
    type: actionTypes.GET_PURCHASES_SALES_DEATHS_FORM_DATA_FAIL,
  };
};

export const getPurchasesSalesDeathsOtherSalesData = (/*{ reportId, formId }*/) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.purchasesSales.otherSalesData);
  if (response && response.status === 200) {
    return dispatch(getPurchasesSalesDeathsOtherSalesDataSuccess(response?.data));
  }
  else
    return dispatch(getPurchasesSalesDeathsOtherSalesDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getPurchasesSalesDeathsOtherSalesDataSuccess = (response) => {
  return {
    type: actionTypes.GET_PURCHASES_SALES_DEATHS_OTHER_SALES_DATA_SUCCESS,
    payload: response
  };
};

const getPurchasesSalesDeathsOtherSalesDataFail = () => {
  return {
    type: actionTypes.GET_PURCHASES_SALES_DEATHS_OTHER_SALES_DATA_FAIL,
  };
};

export const setPurchasesSalesDeathsFormData = (response) => {
  return {
    type: actionTypes.SET_PURCHASES_SALES_DEATHS_FORM_DATA,
    payload: response
  };
};

export const setPurchasesSalesDeathsOtherSalesData = (response) => {
  return {
    type: actionTypes.SET_PURCHASES_SALES_DEATHS_OTHER_SALES_DATA,
    payload: response
  };
};

export const resetPurchasesSalesDeathsFormData = () => {
  return {
    type: actionTypes.RESET_PURCHASES_SALES_DEATHS_ALL_DATA,
  };
};

export const savePurchasesSalesDeathsData = ({ reportId }) => async (dispatch) => {
  const { currentFormData, currentOtherSalesData } = store.getState().purchasesSales
  const response = await api.post(
    endpoints.livestock.purchasesSales.saveData,
    {
      reportId: reportId,
      data: currentFormData,
      groupSummeryData: currentOtherSalesData
    });
  if (response && response.status === 200) {
    return dispatch(savePurchasesSalesDeathsDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(savePurchasesSalesDeathsDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const savePurchasesSalesDeathsDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const savePurchasesSalesDeathsDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};