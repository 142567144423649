import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const SortButton = ({ sortKey, currentSortKey, handleSorting = () => { } }) => {
    const [sortOrder, toggleSortOrder] = useState(undefined);
    const [iconVisible, setIconVisible] = useState(false);

    const handleOnClick = () => {
        toggleSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {
        if (currentSortKey && sortKey === currentSortKey) {
            toggleSortOrder('asc');
            setIconVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentSortKey && sortKey !== currentSortKey) {
            toggleSortOrder(undefined);
            setIconVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSortKey]);

    useEffect(() => {
        sortOrder && handleSorting({ sortOrder: sortOrder, sorting: sortKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder]);

    return (
        <IconButton
            size="small"
            className="activated"
            sx={{ textTransform: 'none', fontSize: '.8rem' }}
            onClick={handleOnClick}
            onMouseEnter={() => sortOrder === undefined && setIconVisible(true)}
            onMouseLeave={() => sortOrder === undefined && setIconVisible(false)}
        >
            {sortOrder === undefined || sortOrder === 'asc' ?
                < ArrowUpwardIcon fontSize='inherit' sx={{ opacity: iconVisible ? 1 : .25 }} />
                : <ArrowDownwardIcon fontSize='inherit' />}
        </IconButton >);
};

export default SortButton;

