import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getRenewableElectricityMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.renewableElectricity.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getRenewableElectricityMetadataSuccess(response?.data));
  } else
    return dispatch(getRenewableElectricityMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getRenewableElectricityMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_RENEWABLE_ELECTRICITY_METADATA_SUCCESS,
    payload: response
  };
};

const getRenewableElectricityMetadataFail = () => {
  return {
    type: actionTypes.GET_RENEWABLE_ELECTRICITY_METADATA_FAIL,
  };
};

export const resetRenewableElectricityMetaData = () => {
  return {
    type: actionTypes.RESET_RENEWABLE_ELECTRICITY_METADATA,
  };
};

export const getRenewableElectricityFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.renewableElectricity.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    const data = response?.data?.data
    dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getRenewableElectricityFormDataSuccess(data));
  } else
    return dispatch(getRenewableElectricityFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getRenewableElectricityFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_RENEWABLE_ELECTRICITY_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getRenewableElectricityFormDataFail = () => {
  return {
    type: actionTypes.GET_RENEWABLE_ELECTRICITY_FORM_DATA_FAIL,
  };
};

const getWholeFarmData = (response) => {
  return {
    type: actionTypes.GET_RENEWABLE_ELECTRICITY_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
}

export const saveRenewableElectricityData = ({ reportId }) => async (dispatch) => {
  const { currentFormData, currentWholeFarmData } = store.getState().renewableElectricity
  const response = await api.post(endpoints.energyWaste.renewableElectricity.saveData,
    {
      reportId: reportId,
      data: currentFormData,
      wholeFarmData: currentWholeFarmData
    });
  if (response && response.status === 200) {
    return dispatch(saveRenewableElectricityDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveRenewableElectricityDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveRenewableElectricityDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveRenewableElectricityDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setRenewableElectricityFormData = (response) => {
  return {
    type: actionTypes.SET_RENEWABLE_ELECTRICITY_FORM_DATA,
    payload: response
  };
};

export const setRenewableElectricityWholeFarmData = (response) => {
  return {
    type: actionTypes.SET_RENEWABLE_ELECTRICITY_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
};

export const resetRenewableElectricityFormData = () => {
  return {
    type: actionTypes.RESET_RENEWABLE_ELECTRICITY_ALL_DATA,
  };
};

export const getAllocateRenewableElectricity = ({ reportId, currentWholeFarmData }) => async (dispatch) => {
  const response = await api.post(endpoints.energyWaste.renewableElectricity.defaults
    .concat('?ReportId=', reportId)
    .concat('&ElectricityTotalUsed=', currentWholeFarmData.ElectricityTotalUsed)
  );

  if (response && response.status === 200) {
    const data = response?.data
    //dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getRenewableElectricityFormDataSuccess(data));
  } else {
    return dispatch(getAllocateRenewableElectricityFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getAllocateRenewableElectricityFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};