import * as actionTypes from './types';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';
import { setSession, deleteSession } from '../../helpers/authHelper';
import jwt_decode from "jwt-decode";
import { APP_SCOPE, APP_CLIENT_ID } from '../../config';
import { USER_INACTIVITY_MESSAGE, BACKEND_USER_INACTIVITY_MESSAGE } from '../../constants/messages'

const GRANT_TYPE = 'password';

export const signIn = ({ username, password, rememberMe }) => async (dispatch) => {
  const data = {
    grant_type: GRANT_TYPE,
    username: username,
    password: password,
    client_id: APP_CLIENT_ID,
    scope: APP_SCOPE
  }

  try {
    const response = await api.postAuth(endpoints.authentication.signIn, data);

    if (response && response.status === 200) {
      const decoded = jwt_decode(response.data.access_token);
      const user = {
        accountId: decoded.sub,
        email: decoded.email,
        role: decoded.role,
        givenName: decoded.given_name,
        username: decoded.preferred_username,
        sessionEndTime: decoded.exp,
        accessToken: response.data.access_token,

      };
      setSession(user, rememberMe);
      dispatch(userInfo(user));
      return dispatch(signInSuccess(user));
    }
    else {
      if(response?.data?.error_description === BACKEND_USER_INACTIVITY_MESSAGE){
        return dispatch(signInFail(USER_INACTIVITY_MESSAGE));
      }
      return dispatch(signInFail(response?.data?.error_description || 'Something was wrong with your login details. Please try again.'));
    }
  } catch (error) {
    return dispatch(signInFail(error?.data?.error_description || 'Something was wrong with your login details. Please try again.'));
  }
};

const signInSuccess = (user) => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    payload: user
  };
};

const signInFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const signUp = (userInfo) => async (dispatch) => {
  const data = {
    "AppName": APP_SCOPE,
    "EmailAddress": userInfo.email,
    "extraProperties": {
      "FirstName": userInfo.firstName,
      "LastName": userInfo.lastName,
      "CompanyOrOrganisation": 'N/A',
      "Country": userInfo.country,
      "PostCode": userInfo.postCode,
      "FarmName": userInfo.farmName,
      "HoldingNumber": userInfo.holdingNumber,
      "BusinessRefNumber": userInfo.refNumber,
      "IsFarmAdvisory": userInfo.advisoryService,
      "ReasonForUsing": 'N/A',
      "OptInNewsletter": userInfo.allowExtraEmails,
      "AgreedToTandS": userInfo.termsCondAgreed
    }
  }
  try {
    const response = await api.post(endpoints.authentication.signUp, data);
    if (response.status === 200) {
      return dispatch(signUpSuccess("You are almost ready to user Agrecalc. First of all you must verify your email address. An email has been sent to the email address you entered. Please visit your email to complete your registration."));
    }
    else {
      return dispatch(signUpFail(response?.data?.error?.message || 'Something was wrong. Please try again.'));
    }
  } catch (error) {
    return dispatch(signUpFail(error?.data?.error?.message || 'Something was wrong. Please try again.'));
  }
};

const signUpSuccess = (msg) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: msg
  };
};

const signUpFail = (error) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: error
  };
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    let returnUrl = `${window.location.hostname}/auth/login`;
    const response = await api.post(endpoints.authentication.forgotPassword,
      {
        email: email,
        appName: APP_SCOPE,
        returnUrl: returnUrl,
        returnUrlHash: ""

      });
    if (response.status === 204)
      return dispatch(forgotPasswordSuccess(response.data.message ||
        "An account recovery email has been sent to your e-mail address. If you do not receive the email within 15 minutes, check your spam/junk folder."));
    else
      return dispatch(forgotPasswordFail(response?.data?.error?.message || 'Something was wrong. Please try again.'));
  } catch (error) {
    return dispatch(forgotPasswordFail(error?.data?.error?.message || 'Something was wrong. Please try again.'));
  }
};

const forgotPasswordSuccess = (res) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: res
  };
};
const forgotPasswordFail = (error) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: error
  };
};

export const resetPassword = ({ userId, resetToken, password }) => async (dispatch) => {
  try {
    const response = await api.post(endpoints.authentication.resetPassword, { userId, resetToken, password });// To Do: change to post when API ready
    if (response.status === 204){
      return dispatch(resetPasswordSuccess('Your password is successfully reset.'));
    }
    else{
      if(response?.data?.error?.message === 'Invalid token.'){
        return dispatch(resetPasswordFail('The provided link has expired. To proceed, kindly reset your password and attempt the action again.'));
      }
      return dispatch(resetPasswordFail(response?.data?.error?.message || 'Something was wrong. Please try again.'));
    }
  } catch (error) {
    return dispatch(resetPasswordFail(error?.data?.error?.message || 'Something was wrong. Please try again.'));
  }
};

const resetPasswordSuccess = (res) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: res
  };
};
const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: error
  };
};


export const signOut = () => async (dispatch) => {
  deleteSession();
  return dispatch(signOutSuccess());
};

const signOutSuccess = () => {
  return {
    type: actionTypes.SIGN_OUT,
  };
};

const userInfo = (user) => {
  return {
    type: actionTypes.SET_USER,
    payload: user
  };
};

export const resetAuthResponse = (user) => {
  return {
    type: actionTypes.RESET_AUTH_RESPONSE
  };
};


export const getUserInfo = () => async (dispatch) => {
  const response = await api.get(endpoints.user.info);
  try {
    if (response && response.status === 200) {
      const { defaultFarm, officeId, organisationId } = response?.data;
      dispatch(setFarmId(defaultFarm));
      return dispatch(getUserInfoSuccess({ officeId, organisationId }));
    }
    else {
      return dispatch(getUserInfoFail(response?.data?.error?.message || 'Something was wrong with your login details. Please try again.'));
    }
  } catch (error) {
    return dispatch(getUserInfoFail(response?.data?.error?.message || 'Something was wrong with your login details. Please try again.'));
  }
}

const setFarmId = (user) => {
  return {
    type: actionTypes.SET_FARM_ID,
    payload: user
  };
}

const getUserInfoSuccess = (user) => {
  return {
    type: actionTypes.GET_USER_INFO_SUCCESS,
    payload: user
  };
}

const getUserInfoFail = (user) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: user
  };
}
