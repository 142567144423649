import * as actionTypes from './types';
import * as api from '../../api/base';
import { endpoints } from '../../api/endpoints';

export const setFarmId = (farmId) => {
  return {
    type: actionTypes.SET_FARM_ID,
    payload: farmId
  };
};

export const setReportId = (response) => {
  return {
    type: actionTypes.SET_REPORT_ID,
    payload: response
  };
};

export const setTempReportId = (response) => {
  return {
    type: actionTypes.SET_TEMP_REPORT_ID,
    payload: response
  };
};

export const resetResponseState = () => {
  return {
    type: actionTypes.RESET_RESPONSE_STATE,
  };
};

export const setLoading = () => {
  return {
    type: actionTypes.SET_LOADING,
  };
};
export const unsetLoading = () => {
  return {
    type: actionTypes.UNSET_LOADING,
  };
};

export const getAllCountries = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.common.countries);
    if (response && response.status === 200) {
      return dispatch(getAllCountriesSuccess(response?.data));
    }
    else
      return dispatch(getAllCountriesFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getAllCountriesFail(e.message));
  }
};
const getAllCountriesSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_COUNTRIES_SUCCESS,
    payload: response
  };
};
const getAllCountriesFail = (response) => {
  return {
    type: actionTypes.GET_ALL_COUNTRIES_FAIL,
    payload: response
  };
};

export const getFarmOwnersData = ({ farmId }) => async (dispatch) => {
  const response = await api.get(endpoints.farm.availableUsers.concat(farmId !== undefined ? `&includeOwnerFromFarmId=${farmId}` : ''));
  if (response && response.status === 200) {
    return dispatch(getFarmOwnersSuccess(response.data));
  }
  else {
    return dispatch(getFarmOwnersFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getFarmOwnersSuccess = (response) => {
  return {
    type: actionTypes.GET_FARM_OWNERS_DATA_SUCCESS,
    payload: response
  };
};

const getFarmOwnersFail = () => {
  return {
    type: actionTypes.GET_FARM_OWNERS_DATA_FAIL,
  };
}

export const getOfficesData = ({ organisationId }) => async (dispatch) => {
  const response = await api.get(endpoints.common.offices.replace('ORG_ID', organisationId));
  if (response && response.status === 200) {
    return dispatch(getOfficesSuccess(response.data));
  }
  else {
    return dispatch(getOfficesFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getOfficesSuccess = (response) => {
  return {
    type: actionTypes.GET_OFFICE_DATA_SUCCESS,
    payload: response
  };
};

const getOfficesFail = () => {
  return {
    type: actionTypes.GET_OFFICE_DATA_FAIL,
  };
}

export const getOrganisationData = () => async (dispatch) => {
  const response = await api.get(endpoints.common.organisations);
  if (response && response.status === 200) {
    return dispatch(getOrganisationSuccess(response.data));
  }
  else {
    return dispatch(getOrganisationFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getOrganisationSuccess = (response) => {
  return {
    type: actionTypes.GET_ORGANISATION_DATA_SUCCESS,
    payload: response
  };
};

const getOrganisationFail = () => {
  return {
    type: actionTypes.GET_ORGANISATION_DATA_FAIL,
  };
}

export const getUserRoles = () => async (dispatch) => {
  const response = await api.get(endpoints.user.roles);
  if (response && response.status === 200) {
    return dispatch(getUserRolesSuccess(response.data?.items));
  }
  else {
    return dispatch(getUserRolesFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getUserRolesSuccess = (response) => {
  return {
    type: actionTypes.GET_USER_ROLE_DATA_SUCCESS,
    payload: response
  };
};

const getUserRolesFail = (response) => {
  return {
    type: actionTypes.GET_USER_ROLE_DATA_FAIL,
    payload: response
  };
};

export const acceptInvitation = (code) => async (dispatch) => {
  try {
    const response = await api.post(endpoints.common.project.invitation,
      { invitationId: code }
    );
    if (response && response.status === 200) {
      return dispatch(acceptInvitationSuccess(response?.data?.message || "You have been added to the project successfully"));
    }
    else {
      return dispatch(acceptInvitationFail(response?.data || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
  } catch (e) {
    return dispatch(acceptInvitationFail(e?.data || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};

const acceptInvitationSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const acceptInvitationFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};
