export const GET_CROP_PRODUCTION_METADATA = 'GET_CROP_PRODUCTION_METADATA'
export const GET_CROP_PRODUCTION_METADATA_FAIL = 'GET_CROP_PRODUCTION_METADATA_FAIL'
export const GET_CROP_PRODUCTION_METADATA_SUCCESS = 'GET_CROP_PRODUCTION_METADATA_SUCCESS'
export const RESET_CROP_PRODUCTION_METADATA = 'RESET_CROP_PRODUCTION_METADATA'

export const GET_CROP_PRODUCTION_FORM_DATA = 'GET_CROP_PRODUCTION_FORM_DATA'
export const GET_CROP_PRODUCTION_FORM_DATA_SUCCESS = 'GET_CROP_PRODUCTION_FORM_DATA_SUCCESS'
export const GET_CROP_PRODUCTION_FORM_DATA_FAIL = 'GET_CROP_PRODUCTION_FORM_DATA_FAIL'

export const SET_CROP_PRODUCTION_FORM_DATA = 'SET_CROP_PRODUCTION_FORM_DATA'
export const RESET_CROP_PRODUCTION_ALL_DATA = 'RESET_CROP_PRODUCTION_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'


