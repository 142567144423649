export const LAND_AREA_CROPS_SET_COL_TOTAL = 'LAND_AREA_CROPS_SET_COL_TOTAL'
export const LAND_AREA_CROPS_RESET_COL_TOTAL = 'LAND_AREA_CROPS_RESET_COL_TOTAL'
export const LAND_AREA_CROPS_SET_TOTAL_AREA = 'LAND_AREA_CROPS_SET_TOTAL_AREA'

export const GET_LAND_AREA_CROPS_METADATA = 'GET_LAND_AREA_CROPS_METADATA'
export const GET_LAND_AREA_CROPS_METADATA_SUCCESS = 'GET_LAND_AREA_CROPS_METADATA_SUCCESS'
export const GET_LAND_AREA_CROPS_METADATA_FAIL = 'GET_LAND_AREA_CROPS_METADATA_FAIL'
export const RESET_LAND_AREA_CROPS_METADATA = 'RESET_LAND_AREA_CROPS_METADATA'

export const GET_LAND_AREA_CROPS_FORM_DATA_SUCCESS = 'GET_LAND_AREA_CROPS_FORM_DATA_SUCCESS'
export const GET_LAND_AREA_CROPS_FORM_DATA_FAIL = 'GET_LAND_AREA_CROPS_FORM_DATA_FAIL'
export const GET_LAND_AREA_CROPS_MISC_DATA_SUCCESS = 'GET_LAND_AREA_CROPS_MISC_DATA_SUCCESS'

export const SET_LAND_AREA_CROPS_FORM_DATA = 'SET_LAND_AREA_CROPS_FORM_DATA'
export const SET_LAND_AREA_CROPS_MISC_DATA = 'SET_LAND_AREA_CROPS_MISC_DATA'

export const GET_LAND_AREA_CROPS_OPTIONS_SUCCESS = 'GET_LAND_AREA_CROPS_OPTIONS_SUCCESS'
export const GET_LAND_AREA_CROPS_OPTIONS_FAIL = 'GET_LAND_AREA_CROPS_OPTIONS_FAIL'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'