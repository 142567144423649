export const GET_FERTILISER_METADATA = 'GET_FERTILISER_METADATA'
export const GET_FERTILISER_METADATA_SUCCESS = 'GET_FERTILISER_METADATA_SUCCESS'
export const GET_FERTILISER_METADATA_FAIL = 'GET_FERTILISER_METADATA_FAIL'
export const RESET_FERTILISER_METADATA = 'RESET_FERTILISER_METADATA'

export const GET_FERTILISER_FORM_DATA_SUCCESS = 'GET_FERTILISER_FORM_DATA_SUCCESS'
export const GET_FERTILISER_FORM_DATA_FAIL = 'GET_FERTILISER_FORM_DATA_FAIL'

export const SET_FERTILISER_FORM_DATA = 'SET_FERTILISER_FORM_DATA'
export const RESET_FERTILISER_ALL_DATA = 'RESET_FERTILISER_ALL_DATA'

export const GET_FERTILISER_OPTIONS_SUCCESS = 'GET_FERTILISER_OPTIONS_SUCCESS'
export const GET_FERTILISER_OPTIONS_FAIL = 'GET_FERTILISER_OPTIONS_FAIL'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'