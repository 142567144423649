import { unsetLoading } from "../appAction";

const loaderHandler = store => next => action => {
  const result = next(action);
  switch (action.type) {
    case "RESPONSE_STATE_FAIL":
    case "persist/REHYDRATE": store.dispatch(unsetLoading());
      break
    default:
      return result;
  }
  return result;
};

export default loaderHandler;
