import { Button, Grid, Paper, Skeleton, Stack, Table, TableBody, TableContainer, TableHead, List, ListSubheader } from '@mui/material';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormDescriptionTypography, FormHeaderPaper, FormTableCell, FormTableRow, FormTitleTypography, SectorTitleTypography, DataEntryTextField, CustomizedDivider } from '../../../components/CustomStyles/StyledComponents';
import CancelModal from '../../../components/Modal/CancelModal';
import {
    getWasteTransportWaterFormData,
    getWasteTransportWaterMetaData,
    resetWasteTransportWaterMetaData,
    resetResponseState,
    saveWasteTransportWaterData,
    setLoading,
    setWasteTransportWaterFormData,
    setWasteTransportWaterWholeFarmData,
    unsetLoading
} from '../../../store/appAction';
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import _ from "lodash";
import { isEqual } from "lodash";
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import produce from 'immer';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import store from '../../../store';

const CELL_WIDTH = {
    RowTitle: "28%",
    WastePlastic: "24%",
    ExternalHaulage: "24%",
    WaterUse: "24%",
    GeneralCell: "24%"
};

const DataEntryTable = ({ sector, initialFormData }) => {
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    const [sectorDataInt, setSectorDataInt] = useState(undefined);
    const [sectorDataExt, setSectorDataExt] = useState(undefined);

    useEffect(() => {
        setSectorDataInt(initialFormData);
        setSectorDataExt(initialFormData);
    }, [initialFormData]);

    useEffect(() => {
        if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
            dispatch(setWasteTransportWaterFormData({
                sectorId: sector.id,
                formData: sectorDataExt
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectorDataExt]);

    const handleInputChange = useCallback((e, rowId, colId, rule) => {
        let value = removeCommas(e.target.value);
        if (e.target.inputMode === "numeric") {
            let numericRegex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
            if (numericRegex.test(value)) {
                if (rule.max < parseFloat(value || 0)) {
                    toastr.warning(`This input only accepts values between ${addCommas(rule.min
                    )} and ${addCommas(rule.max)}.  lease enter the value again.`,
                        undefined,
                        toastrCustomOptionsLonger
                    );
                } else {
                    setSectorDataInt(produce((draft) => {
                        draft[rowId][colId] = value;
                    }));
                    setSectorDataExt(produce((draft) => {
                        draft[rowId][colId] = parseFloat(value);
                    }));
                }
            }
        } else {
            setSectorDataInt(produce((draft) => {
                draft[rowId][colId] = value;
            }));
            setSectorDataExt(produce((draft) => {
                draft[rowId][colId] = parseFloat(value);
            }));
        }
    }, []);

    const getCellContent = ({ rowId, cell, rowData }) => {
        switch (cell.dataType) {
            case "TextField":
                return (
                    <DataEntryTextField
                        size="small"
                        variant="outlined"
                        inputProps={{ inputMode: "numeric" }}
                        style={{ width: 125, textAlign: "center" }}
                        value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                rowId,
                                cell.mappingProperty,
                                cell.validations
                            )}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            {sector ?
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                    <SectorTitleTypography>{sector.title}</SectorTitleTypography>
                    <Fragment>
                        <Table>
                            <TableHead>
                                <FormTableRow>
                                    {/* The following code segment has been commented out due to the requirement of AGC-977 */}
                                    {/* <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} ></FormTableCell>
                                    {(sector.columns || {}).map((column) => (
                                        <FormTableCell sx={{ width: CELL_WIDTH[column.cssClass] }} >
                                            {column.title}
                                        </FormTableCell>
                                    ))} */}
                                </FormTableRow>
                            </TableHead>
                            <TableBody>
                                {sector.rows.map((row) => (
                                    <FormTableRow>
                                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} >
                                            {row.title}
                                        </FormTableCell>
                                        {row.cells.map((cell) => (
                                            cell.visible &&
                                            <FormTableCell sx={{ width: CELL_WIDTH[cell.cssClass] ? CELL_WIDTH[cell.cssClass] : CELL_WIDTH.GeneralCell }} >
                                                {getCellContent({
                                                    rowId: row.id,
                                                    cell: cell,
                                                    rowData: sectorDataInt && sectorDataInt[row.id]
                                                })}
                                            </FormTableCell>
                                        ))}

                                    </FormTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Fragment>
                </TableContainer>
                : <Skeleton variant="rectangular" width={'100%'} height={150} />
            }
        </>
    )
};

const WasteTransportWater = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const reportId = useSelector(state => state.common.reportId);
    const metaData = useSelector(state => state.wasteTransportWater.metaData);
    const initialWholeFarmData = useSelector(state => state.wasteTransportWater.initialWholeFarmData);
    const currentWholeFarmData = useSelector(state => state.wasteTransportWater.currentWholeFarmData);
    const initialFormData = useSelector(state => state.wasteTransportWater.initialFormData);
    const { successMsg, errorMsg } = useSelector(state => state.common);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);

    const [formData, setFormData] = useState({})
    const [modalOpen, setModalOpen] = useState(false);
    const [nextClicked, setNextClicked] = useState(false);
    const [wholeFarmDataInt, setWholeFarmDataInt] = useState(undefined);
    const [wholeFarmDataExt, setWholeFarmDataExt] = useState(undefined);

    const sectors = metaData?.form?.sectors || [];
    const wholeFarm = metaData?.form?.wholeFarm || [];

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getWasteTransportWaterMetaData({ reportId }));
        dispatch(getWasteTransportWaterFormData({ reportId }));
        dispatch(resetResponseState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(resetWasteTransportWaterMetaData());
        }
    }, []);

    useEffect(() => {
        const tempData = { ...initialFormData };
        setFormData(tempData);
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormData]);

    useEffect(() => {
        setWholeFarmDataInt({ ...initialWholeFarmData });
        setWholeFarmDataExt({ ...initialWholeFarmData });
    }, [initialWholeFarmData]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            successMsg && toastr.success(successMsg);
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
            if (successMsg && nextClicked) {
                navigate('/farm-reports/list');
            }
            setNextClicked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    const handleModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            dispatch(getWasteTransportWaterMetaData({ reportId }));
            dispatch(getWasteTransportWaterFormData({ reportId }));
        }
    }

    const isFormUpdated = () => {
        const wasteTransportWaterData = store.getState().wasteTransportWater;
        const isUpdatedWholeFarmData = !isEqual(wasteTransportWaterData?.initialWholeFarmData, wasteTransportWaterData?.currentWholeFarmData);
        const isUpdatedEnterpriseData = !isEqual(wasteTransportWaterData?.initialFormData, wasteTransportWaterData?.currentFormData);
        if(isUpdatedWholeFarmData || isUpdatedEnterpriseData){
            return true;
        }
        return false;
    }

    const handelSaveClick = (e) => {
        if(!isFormUpdated()){
            return;
        }
        dispatch(setLoading());
        dispatch(saveWasteTransportWaterData({ reportId }));
    }

    const handleNextClick = (e) => {
        setNextClicked(true);
        if (props.locked || !isFormUpdated())
            navigate('/farm-reports/list');
        else {
            dispatch(setLoading());
            dispatch(saveWasteTransportWaterData({ reportId }));
        };
    }

    const handleInputChange = useCallback((e, colId, rule) => {
        let value = removeCommas(e.target.value);
        if (e.target.inputMode === "numeric") {
            let numericRegex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
            if (numericRegex.test(value)) {
                if (rule.max < parseFloat(value || 0)) {
                    toastr.warning(`This input only accepts values between ${addCommas(rule.min
                    )} and ${addCommas(rule.max)}.  lease enter the value again.`,
                        undefined,
                        toastrCustomOptionsLonger
                    );
                } else {
                    setWholeFarmDataInt(produce((draft) => {
                        draft[colId] = value;
                    }));
                    setWholeFarmDataExt(produce((draft) => {
                        draft[colId] = parseFloat(value);
                    }));
                }
            }
        } else {
            setWholeFarmDataInt(produce((draft) => {
                draft[colId] = value;
            }));
            setWholeFarmDataExt(produce((draft) => {
                draft[colId] = parseFloat(value);
            }));
        }
    }, []);

    useEffect(() => {
        if (wholeFarmDataExt && !_.isEmpty(wholeFarmDataExt)) {
            dispatch(setWasteTransportWaterWholeFarmData({
                wholeFarmData: wholeFarmDataExt
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wholeFarmDataExt]);

    return (
        <ScrollSync>
        <Grid container spacing={2} className={props.locked && 'deactivated'}>
            <Grid item xs={12} md={12} lg={12}>
                <FormHeaderPaper sx={{ p: 2, display: "flex", flexDirection: "column" }} divider={false}>
                    <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                    <FormTitleTypography variant="h5" component="div">
                        Waste, Transport & Water
                    </FormTitleTypography>
                    <FormDescriptionTypography variant="body2" paragraph>
                        To fully represent your farm system and calculate scope 3 emissions, enter a few final pieces of information.
                    </FormDescriptionTypography>
                </FormHeaderPaper>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
                    <List sx={{ mb: 2 }}>
                        <ListSubheader sx={{ bgcolor: "background.paper" }}>
                            <ScrollSyncPane>
                                <>
                                <DataEntryButtonStack
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                    handleModalResponse={handleModalResponse}
                                    handelSaveClick={handelSaveClick}
                                    handleNextClick={handleNextClick}
                                />
                                <CustomizedDivider/>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <FormTableRow>
                                                <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} ></FormTableCell>
                                                {Object.values(wholeFarm || {}).map((column) => (
                                                    <FormTableCell sx={{ width: CELL_WIDTH[column.cssClass] }} >
                                                        {column.title}
                                                    </FormTableCell>
                                                ))}
                                            </FormTableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                                </>
                            </ScrollSyncPane>
                        </ListSubheader>
                        {(wholeFarmDataInt && wholeFarm) && <> <TableContainer component={Paper} sx={{ mb: 3 }}>
                            <SectorTitleTypography>Whole Farm</SectorTitleTypography>
                            <Table>
                                <TableBody>
                                    <FormTableRow>
                                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}>Whole Farm</FormTableCell>
                                        {Object.values(wholeFarm || {}).map((cell,index) => (
                                            <FormTableCell>
                                                <DataEntryTextField
                                                    size="small"
                                                    variant="outlined"
                                                    inputProps={{ inputMode: "numeric" }}
                                                    style={{ width: 125, textAlign: "center" }}
                                                    value={wholeFarmDataInt && addCommas(wholeFarmDataInt[cell.mappingProperty])}
                                                    onChange={(e) => handleInputChange(e, cell.mappingProperty, cell.validations)}
                                                    autoFocus={props.locked === false && index === 0}
                                                />
                                            </FormTableCell>
                                        ))}
                                    </FormTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>}
                        {formData && sectors.map((sector, index) => (
                            <><React.Fragment key={index}>
                                <DataEntryTable
                                    key={sector.id}
                                    sector={sector}
                                    initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                                />
                            </React.Fragment></>
                        ))}
                    </List>        
                </Paper>
            </Grid>
        </Grid >
        </ScrollSync>
    )
}

export default WasteTransportWater