import * as actionTypes from './types';
import { updateObject } from '../utility';

const initialState = {
  tableData: {},
  chartData: {},
  enterpriseList: [],
  compareToList: [],
};

const enterpriseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ENTERPRISE_SECTOR_LIST_SUCCESS: return setSectorListSuccess(state, action);
    case actionTypes.GET_ENTERPRISE_SECTOR_LIST_FAIL: return setSectorListFail(state, action);
    case actionTypes.GET_ENTERPRISE_COMPARE_TO_LIST_SUCCESS: return setCompareToListListSuccess(state, action);
    case actionTypes.GET_ENTERPRISE_COMPARE_TO_LIST_FAIL: return setCompareToListListFail(state, action);
    case actionTypes.GET_ENTERPRISE_TABLE_DATA_SUCCESS: return setTableDataSuccess(state, action);
    case actionTypes.GET_ENTERPRISE_TABLE_DATA_FAIL: return setTableDataFail(state, action);
    case actionTypes.GET_ENTERPRISE_CHART_DATA_SUCCESS: return setChartDataSuccess(state, action);
    case actionTypes.GET_ENTERPRISE_CHART_DATA_FAIL: return setChartDataFail(state, action);
    case actionTypes.RESET_ENTERPRISE_DATA: return resetData(state, action);
    case actionTypes.RESET_ENTERPRISE_DROP_DOWN_DATA: return resetEnterpriseDropDownValues(state, action);
    default:
      return state;
  }
}

const setSectorListSuccess = (state, action) => {
  const updatedState = {
    enterpriseList: action.payload,
  }
  return updateObject(state, updatedState);
}

const setSectorListFail = (state, action) => {
  const updatedState = {
    enterpriseList: [],
  }
  return updateObject(state, updatedState);
}

const setCompareToListListSuccess = (state, action) => {
  const updatedState = {
    compareToList: action.payload,
  }
  return updateObject(state, updatedState);
}

const setCompareToListListFail = (state, action) => {
  const updatedState = {
    compareToList: [],
  }
  return updateObject(state, updatedState);
}

const setTableDataSuccess = (state, action) => {
  const updatedState = {
    tableData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setTableDataFail = (state, action) => {
  const updatedState = {
    tableData: {},
  }
  return updateObject(state, updatedState);
}

const setChartDataSuccess = (state, action) => {
  const updatedState = {
    chartData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setChartDataFail = (state, action) => {
  const updatedState = {
    chartData: {},
  }
  return updateObject(state, updatedState);
}

const resetData = (state, action) => {
  const updatedState = {
    tableData: {},
    chartData: {},
  }
  return updateObject(state, updatedState);
}

const resetEnterpriseDropDownValues = (state, action) => {
  const updatedState = {
    enterpriseList: [],
    compareToList: [],
  }
  return updateObject(state, updatedState);
}

export default enterpriseReducer;
