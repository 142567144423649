export const GET_WASTE_TRANSPORT_WATER_METADATA = 'GET_WASTE_TRANSPORT_WATER_METADATA'
export const GET_WASTE_TRANSPORT_WATER_METADATA_SUCCESS = 'GET_WASTE_TRANSPORT_WATER_METADATA_SUCCESS'
export const GET_WASTE_TRANSPORT_WATER_METADATA_FAIL = 'GET_WASTE_TRANSPORT_WATER_METADATA_FAIL'
export const RESET_WASTE_TRANSPORT_WATER_METADATA = 'RESET_WASTE_TRANSPORT_WATER_METADATA'

export const GET_WASTE_TRANSPORT_WATER_FORM_DATA_SUCCESS = 'GET_WASTE_TRANSPORT_WATER_FORM_DATA_SUCCESS'
export const GET_WASTE_TRANSPORT_WATER_FORM_DATA_FAIL = 'GET_WASTE_TRANSPORT_WATER_FORM_DATA_FAIL'

export const SET_WASTE_TRANSPORT_WATER_FORM_DATA = 'SET_WASTE_TRANSPORT_WATER_FORM_DATA'
export const RESET_WASTE_TRANSPORT_WATER_ALL_DATA = 'RESET_WASTE_TRANSPORT_WATER_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_ALLOCATE_WASTE_TRANSPORT_WATER_SUCCESS = 'GET_ALLOCATE_WASTE_TRANSPORT_WATER_SUCCESS'
export const GET_ALLOCATE_WASTE_TRANSPORT_WATER_FAIL = 'GET_ALLOCATE_WASTE_TRANSPORT_WATER_FAIL'

export const GET_WASTE_TRANSPORT_WATER_WHOLE_FARM_DATA_SUCCESS = 'GET_WASTE_TRANSPORT_WATER_WHOLE_FARM_DATA_SUCCESS'
export const SET_WASTE_TRANSPORT_WATER_WHOLE_FARM_DATA_SUCCESS = 'SET_WASTE_TRANSPORT_WATER_WHOLE_FARM_DATA_SUCCESS'