import { Grid, Paper } from '@mui/material'
import React, { } from 'react'
import { HeaderFooterTypographyPDF, PageFooterPaper } from '../../../../components/CustomStyles/StyledComponents';

const Footer = () => {
    return (
        <footer style={{ width: 'inherit' }}>
            <Paper sx={{ height: '10px' }}></Paper>
            <PageFooterPaper
                sx={{
                    px: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                }}
            >
                <Grid container direction="row" mt={1}>
                    <Grid item xs={11} sx={{ display: 'inline-flex', }}>
                        <HeaderFooterTypographyPDF
                            paragraph
                            variant="body2"
                            component="div"
                            color='#ababab'
                            sx={{ float: 'left' }}
                        >
                            Agrecalc Carbon Footprint Report
                        </HeaderFooterTypographyPDF>
                    </Grid>
                    <Grid item xs={1} sx={{ display: 'inline-flex', float: 'right' }}>
                        <HeaderFooterTypographyPDF
                            paragraph
                            id='pageFooter'
                            variant="body2"
                            component="div"
                            color='#ababab'
                            sx={{ float: 'right' }}
                        >
                            {/*page++*/}
                        </HeaderFooterTypographyPDF>
                    </Grid>
                </Grid>
            </PageFooterPaper>
        </footer>
    )
}

export default Footer