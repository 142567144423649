import * as React from 'react';
import { Divider, Grid, Paper } from '@mui/material';
import VerticalBarChart from '../Charts/VerticalBarChart';
import ColoredPieChart from '../Charts/ColoredPieChart';
import CircularPieChart from '../Charts/CircularPieChart';
import StackedVerticalBarChart from './StackedVerticalBarChart';
import HalfCircularPieChart from './HalfCircularPieChart';

export default function Dashboard() {
    return (
        <>
            <Divider orientation='horizontal' sx={{ marginTop: '20px', width: '100%' }} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <VerticalBarChart />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <ColoredPieChart />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <CircularPieChart />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <StackedVerticalBarChart />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <HalfCircularPieChart />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <CircularPieChart />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
