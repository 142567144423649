import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getCropProductionMetaData = ({ reportId }) => async (dispatch) => {
    const response = await api.get(endpoints.landCrops.cropProduction.metaData.concat('?reportId=', reportId));
    if (response && response.status === 200) {
        return dispatch(getCropProductionMetadataSuccess(response?.data));
    } else
        return dispatch(getCropProductionMetadataFail(response?.error?.message));
}

const getCropProductionMetadataSuccess = (response) => {
    return {
        type: actionTypes.GET_CROP_PRODUCTION_METADATA_SUCCESS,
        payload: response
    };
};

const getCropProductionMetadataFail = () => {
    return {
        type: actionTypes.GET_CROP_PRODUCTION_METADATA_FAIL,
    };
};

export const resetCropProductionMetaData = () => {
    return {
      type: actionTypes.RESET_CROP_PRODUCTION_METADATA,
    };
  };

export const getCropProductionFormData = ({ reportId }) => async (dispatch) => {
    const response = await api.get(endpoints.landCrops.cropProduction.formData.concat('?reportId=', reportId));
    if (response && response.status === 200) {
        return dispatch(getCropProductionFormDataSuccess(response?.data?.data));
    } else
        return dispatch(getCropProductionFormDataFail(response?.error?.message));
}

const getCropProductionFormDataSuccess = (response) => {
    return {
        type: actionTypes.GET_CROP_PRODUCTION_FORM_DATA_SUCCESS,
        payload: response
    };
};

const getCropProductionFormDataFail = () => {
    return {
        type: actionTypes.GET_CROP_PRODUCTION_FORM_DATA_FAIL,
    };
};

export const saveCropProductionData = ({ reportId }) => async (dispatch) => {
    const { currentFormData } = store.getState().cropProduction
    const response = await api.post(endpoints.landCrops.cropProduction.saveData,
        {
            reportId: reportId,
            data: currentFormData
        });
    if (response && response.status === 200) {
        return dispatch(saveCropProductionDataSuccess("Report data has been successfully saved."));
    }
    else
        return dispatch(saveCropProductionDataFail(response?.error?.message));
};

const saveCropProductionDataSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const saveCropProductionDataFail = () => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
    };
};

export const setCropProductionFormData = (response) => {
    return {
        type: actionTypes.SET_CROP_PRODUCTION_FORM_DATA,
        payload: response
    };
};

export const resetCropProductionFormData = () => {
    return {
        type: actionTypes.RESET_CROP_PRODUCTION_ALL_DATA,
    };
};