export const authHeader = () => {
  let user = JSON.parse(getSession());// null;
  if (user && user.accessToken) {
    return {
      'Authorization': 'Bearer ' + user.accessToken,
      'Access-Control-Allow-Origin': "*"
    };
  } else {
    return { 'Access-Control-Allow-Origin': "*" };
  }
}

export const authHeaderForMultipartFormData = () => {
  let user = JSON.parse(getSession());// null;
  if (user && user.accessToken) {
    return {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + user.accessToken,
      'Access-Control-Allow-Origin': "*"
    };
  } else {
    return { 'Access-Control-Allow-Origin': "*" };
  }
}

export const setSession = (user, rememberMe) => {
  if (rememberMe)
    localStorage.setItem('authUser', JSON.stringify(user));
  else
    sessionStorage.setItem('authUser', JSON.stringify(user));
}

export const getSession = () => {
  return sessionStorage.getItem('authUser') || localStorage.getItem('authUser');
}

export const deleteSession = () => {
  console.log('deleteSession')
  localStorage.setItem('persist:root', JSON.stringify({}));
  sessionStorage.setItem('persist:root', JSON.stringify({}));
  sessionStorage.removeItem('authUser');
  localStorage.removeItem('authUser');
}