import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getRenewableHeatMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.renewableHeat.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getRenewableHeatMetadataSuccess(response?.data));
  } else
    return dispatch(getRenewableHeatMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getRenewableHeatMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_RENEWABLE_HEAT_METADATA_SUCCESS,
    payload: response
  };
};

const getRenewableHeatMetadataFail = () => {
  return {
    type: actionTypes.GET_RENEWABLE_HEAT_METADATA_FAIL,
  };
};

export const resetRenewableHeatMetaData = () => {
  return {
    type: actionTypes.RESET_RENEWABLE_HEAT_METADATA,
  };
};

export const getRenewableHeatFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.renewableHeat.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    const data = response?.data?.data
    dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getRenewableHeatFormDataSuccess(data));
  } else
    return dispatch(getRenewableHeatFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getRenewableHeatFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_RENEWABLE_HEAT_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getRenewableHeatFormDataFail = () => {
  return {
    type: actionTypes.GET_RENEWABLE_HEAT_FORM_DATA_FAIL,
  };
};

const getWholeFarmData = (response) => {
  return {
    type: actionTypes.GET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
}

export const saveRenewableHeatData = ({ reportId }) => async (dispatch) => {
  const { currentFormData, currentWholeFarmData } = store.getState().renewableHeat
  const response = await api.post(endpoints.energyWaste.renewableHeat.saveData,
    {
      reportId: reportId,
      data: currentFormData,
      wholeFarmData: currentWholeFarmData
    });
  if (response && response.status === 200) {
    return dispatch(saveRenewableHeatDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveRenewableHeatDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveRenewableHeatDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveRenewableHeatDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setRenewableHeatFormData = (response) => {
  return {
    type: actionTypes.SET_RENEWABLE_HEAT_FORM_DATA,
    payload: response
  };
};

export const setRenewableHeatWholeFarmData = (response) => {
  return {
    type: actionTypes.SET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
};

export const resetRenewableHeatFormData = () => {
  return {
    type: actionTypes.RESET_RENEWABLE_HEAT_ALL_DATA,
  };
};

export const getAllocateRenewableHeat = ({ reportId, currentWholeFarmData }) => async (dispatch) => {
  const response = await api.post(endpoints.energyWaste.renewableHeat.defaults
    .concat('?ReportId=', reportId)
    .concat('&HeatTotalUsed=', currentWholeFarmData.HeatTotalUsed)
  );

  if (response && response.status === 200) {
    const data = response?.data
    //dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getRenewableHeatFormDataSuccess(data));
  } else {
    return dispatch(getAllocateRenewableHeatFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getAllocateRenewableHeatFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};