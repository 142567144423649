import * as actionTypes from './types';
import { updateObject } from '../utility';

const initialState = {
  reports: [],
};

const farmReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_REPORTS_SUCCESS: return setAllReportsSuccess(state, action);
    case actionTypes.GET_ALL_REPORTS_FAIL: return setAllReportsFail(state, action);
    case actionTypes.CLEAR_ALL_REPORTS_DATA: return clearAllReportsData(state, action);
    default:
      return state;
  }
};

const setAllReportsSuccess = (state, action) => {
  const updatedState = {
    reports: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAllReportsFail = (state, action) => {
  const updatedState = {
    reports: [],
  }
  return updateObject(state, updatedState);
}

const clearAllReportsData = (state, action) => {
  const updatedState = {
    reports: [],
  }
  return updateObject(state, updatedState);
}

export default farmReducer;
