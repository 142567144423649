import { updateObject } from '../../utility';
import * as actionTypes from './types';

const initialState = {
  organisations: undefined,
  selectedOrganisation: {},
};

const organisationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_ADMIN_ORG: return setOrganisation(state, action);
    case actionTypes.GET_ADMIN_ORG_LIST_SUCCESS: return setOrganisationsSuccess(state, action);
    case actionTypes.GET_ADMIN_ORG_LIST_FAIL: return setOrganisationsFail(state, action);
    default:
      return state;
  }
};

const setOrganisation = (state, action) => {
  const updatedState = {
    selectedOrganisation: action.payload,
    organisationDetails: {},
  }
  return updateObject(state, updatedState);
}

const setOrganisationsSuccess = (state, action) => {
  const updatedState = {
    organisations: action.payload,
  }
  return updateObject(state, updatedState);
}

const setOrganisationsFail = (state, action) => {
  const updatedState = {
    organisations: [],
  }
  return updateObject(state, updatedState);
}


export default organisationsReducer;
