export const GET_ELECTRICITY_FUEL_CONT_METADATA = 'GET_ELECTRICITY_FUEL_CONT_METADATA'
export const GET_ELECTRICITY_FUEL_CONT_METADATA_SUCCESS = 'GET_ELECTRICITY_FUEL_CONT_METADATA_SUCCESS'
export const GET_ELECTRICITY_FUEL_CONT_METADATA_FAIL = 'GET_ELECTRICITY_FUEL_CONT_METADATA_FAIL'
export const RESET_ELECTRICITY_FUEL_CONT_METADATA = 'RESET_ELECTRICITY_FUEL_CONT_METADATA'

export const GET_ELECTRICITY_FUEL_CONT_FORM_DATA_SUCCESS = 'GET_ELECTRICITY_FUEL_CONT_FORM_DATA_SUCCESS'
export const GET_ELECTRICITY_FUEL_CONT_FORM_DATA_FAIL = 'GET_ELECTRICITY_FUEL_CONT_FORM_DATA_FAIL'

export const SET_ELECTRICITY_FUEL_CONT_FORM_DATA = 'SET_ELECTRICITY_FUEL_CONT_FORM_DATA'
export const RESET_ELECTRICITY_FUEL_CONT_ALL_DATA = 'RESET_ELECTRICITY_FUEL_CONT_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_ALLOCATE_ELECTRICITY_FUEL_CONT_SUCCESS = 'GET_ALLOCATE_ELECTRICITY_FUEL_CONT_SUCCESS'
export const GET_ALLOCATE_ELECTRICITY_FUEL_CONT_FAIL = 'GET_ALLOCATE_ELECTRICITY_FUEL_CONT_FAIL'

export const GET_WHOLE_FARM_DATA_CONT_SUCCESS = 'GET_WHOLE_FARM_DATA_CONT_SUCCESS'
export const SET_WHOLE_FARM_DATA_CONT_SUCCESS = 'SET_WHOLE_FARM_DATA_CONT_SUCCESS'