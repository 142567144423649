
import {
    Container,
    Grid,
    ThemeProvider,
    Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { FormHeaderPaper, FormTitleTypography, } from '../../components/CustomStyles/StyledComponents';
import { mainTheme } from '../../theme';
import { acceptInvitation, resetResponseState, setLoading, unsetLoading } from '../../store/appAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import toastr from "toastr";
import { toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { FARMER } from '../../constants/userRoles';

const ProjectInvitation = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { code } = useParams();
    const { user } = useSelector(state => state.auth);
    const { successMsg, errorMsg } = useSelector(state => state.common);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(acceptInvitation(code));
        return () => {
            dispatch(unsetLoading());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            successMsg && toastr.success(successMsg, undefined, toastrCustomOptionsLonger);
            errorMsg && toastr.error(errorMsg);
            dispatch(resetResponseState());

            if (user?.role === FARMER)
                navigate('/farm-reports/list');
            else
                navigate('/admin/farm/list');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    return (
        <ThemeProvider theme={mainTheme}>
            <Container maxWidth={'lg'} sx={{ margin: '0 auto', p: '50px', height: '500px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormTitleTypography variant="h5" component="div">
                                Redirecting
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                {'Please wait...'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                </Grid >
            </Container>
        </ThemeProvider >
    )
}

export default ProjectInvitation