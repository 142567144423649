import {
  Backdrop,
  Button, CircularProgress, FormControl, Grid, List,
  ListSubheader, MenuItem, Paper, Skeleton, Stack, Table,
  TableBody, TableContainer, TableHead,
  TableRow, Typography
} from '@mui/material';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import _ from 'lodash';
import produce from 'immer';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { isEqual } from "lodash";
import {
  FormHeaderPaper, FormTitleTypography, FormTableCell,
  FormTableRow, SectorTitleTypography, TableListItem, DataEntryTextField, DataEntrySelect, FormDescriptionTypography, CustomizedDivider
} from '../../../components/CustomStyles/StyledComponents';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import {
  getBiocharFormData,
  getBiocharMetaData,
  resetBiocharMetaData,
  getFeedstockTypeOptions,
  getTemperatureTypeOptions,
  resetResponseState,
  saveBiocharData,
  setBiocharFormData,
  setLoading,
  unsetLoading
} from '../../../store/appAction';
import { useNavigate } from 'react-router-dom';
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "22%",
  FeedStockType: '18%',
  ProductionTempurature: '18%',
  QuantityOverDryTonnes: '16%'
}

const LAND_AREA_TOTAL_KEY = 'TotalLandArea';
const FEEDSTOCK_TYPE_KEY = 'FeedStockType';
const PRODUCTION_TEMPERATURE_KEY = 'ProductionTempurature';
const TOTAL_AREA_X_200 = '200xTotalArea';

const DataEntryTable = ({ sector, initialFormData, temperatureTypesOptions, feedstockTypesOptions, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setBiocharFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleInputChange = useCallback((e, groupId, rowId, cell, landAreaTotal) => {
    let value = removeCommas(e.target.value);

    if (e.target.inputMode === 'numeric') {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        const rule = cell.validations;
        const max = rule.max || (TOTAL_AREA_X_200 === rule.maxValidation ?
          parseFloat(200 * (landAreaTotal || 0)) : 0);
        if (max === 0) {
          toastr.warning(`Please enter Land Area information and try again.`,
          undefined,
          toastrCustomOptionsLonger
          );
        } else if (rule.min > numericValue || max < numericValue) {
          toastr.warning(`This input only accepts values between ${addCommas(rule.min)} and ${addCommas(max)}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = value;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = parseFloat(value);
          }));
        }
      }
    }
    else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value || null;
      }));
    }
  }, []);

  const fetchRowTitle = (title, totalArea) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    return title.concat(
      ' (', addCommas(totalArea || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }

  const getCellContent = (groupId, rowId, cell, rowData, firstVisibleCellDetails, locked) => {
    switch (cell.dataType) {
      case 'Select':
        return (
          <FormControl sx={{ width: '98%', margin: 0 }}>
            <DataEntrySelect
              value={rowData && rowData[cell.mappingProperty] !== null ?
                rowData[cell.mappingProperty] : ''}
              displayEmpty
              onChange={(e) => handleInputChange(e, groupId, rowId, cell)}
              autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
            >
              <MenuItem value=""> <em>Please Select</em> </MenuItem>
              {cell.mappingProperty === FEEDSTOCK_TYPE_KEY && feedstockTypesOptions &&
                feedstockTypesOptions.map((option) => (
                  <MenuItem id={option.id} key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              {cell.mappingProperty === PRODUCTION_TEMPERATURE_KEY && temperatureTypesOptions &&
                temperatureTypesOptions.map((option) => (
                  <MenuItem style={{ whiteSpace: 'normal', width: 280 }}
                    id={option.id} key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
            </DataEntrySelect>
          </FormControl >
        );
      case 'TextField':
        return (
          <DataEntryTextField
            key={rowId + cell.mappingProperty}
            size="small"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            style={{ width: 100, textAlign: 'center' }}
            value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            onChange={(e) => handleInputChange(
              e, groupId, rowId, cell, rowData[LAND_AREA_TOTAL_KEY])}
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }  
          />
        );
      case 'Empty':
      default:
        return <></>;
    }
  }

  return (
    <>
      {sector ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
              <TableBody>
                {(sector.sectorGroups.groups || []).map((group) => (
                  <Fragment>
                    {group.rows.map((row) => (
                      <FormTableRow>
                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} >
                          {sectorDataInt &&
                            fetchRowTitle(row.title, sectorDataInt[group.id] &&
                              sectorDataInt[group.id][row.id][LAND_AREA_TOTAL_KEY])}
                        </FormTableCell>
                        {row.cells.map((cell) => (
                          <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
                            {sectorDataInt && cell.visible &&
                              getCellContent(group.id, row.id, cell,
                                sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                                firstVisibleCellDetails,
                                locked
                              )}
                          </FormTableCell>
                        ))}
                      </FormTableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />}
    </>
  );
}

const Biochar = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.biochar.metaData);
  const initialFormData = useSelector(state => state.biochar.initialFormData);
  const temperatureTypesOptions = useSelector(state => state.biochar.temperatureTypesOptions);
  const feedstockTypesOptions = useSelector(state => state.biochar.feedstockTypesOptions);
  const
    { reportDetails: { includeSoilCarbon },
      reportConfig: { landAndCropsOnly }
    } = useSelector(state => state.farmReport);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);

  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];
  const columns = metaData?.form?.columns || [];

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getFeedstockTypeOptions());
    dispatch(getTemperatureTypeOptions());
    dispatch(getBiocharMetaData({ reportId }));
    dispatch(getBiocharFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetBiocharMetaData());
    }
  }, []);

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      setFormData(tempData);
      dispatch(unsetLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        if (includeSoilCarbon)
          navigate('/data-entry/land-crops/soilCarbon');
        else if (landAndCropsOnly)
          navigate('/data-entry/energy-waste/electricity-fuel');
        else
          navigate('/data-entry/livestock/numbers-weights');

      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getBiocharMetaData({ reportId }));
      dispatch(getBiocharFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const biocharData = store.getState().biochar;
    const isUpdated = !isEqual(biocharData?.initialFormData, biocharData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveBiocharData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      if (includeSoilCarbon)
        navigate('/data-entry/land-crops/soilCarbon');
      else if (landAndCropsOnly)
        navigate('/data-entry/energy-waste/electricity-fuel');
      else
        navigate('/data-entry/livestock/numbers-weights');
    } else {
      dispatch(setLoading());
      dispatch(saveBiocharData({ reportId }));
    }
  }

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            divider={false}
          >
            <SelectedFarm  farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <FormTitleTypography variant="h5" component="div">
              Biochar
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              If biochar has been applied, enter details on the products used. This helps calculate carbon sequestration.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                  <>
                  <DataEntryButtonStack
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleModalResponse={handleModalResponse}
                    handelSaveClick={handelSaveClick}
                    handleNextClick={handleNextClick}
                  />
                  <CustomizedDivider/>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}></FormTableCell>
                          {
                            columns.map((column) =>
                              <FormTableCell align="center" sx={{ width: CELL_WIDTH[column.cssClass] }} >
                                {column.title}
                              </FormTableCell>)
                          }
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  </>
                </ScrollSyncPane>
              </ListSubheader>
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <DataEntryTable
                      key={sector.id}
                      sectorId={sector.id}
                      sector={sector}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      temperatureTypesOptions={temperatureTypesOptions}
                      feedstockTypesOptions={feedstockTypesOptions}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}
                    />
                  </TableListItem>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
}

export default Biochar;
