import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Link } from '@mui/material';
import { DataEntryTextField } from '../CustomStyles/StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { setReportId } from '../../store/appAction';
import { useNavigate } from 'react-router-dom';

const FormTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const FormTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.light,
  },
}));

function createData(name, path) {
  return { name, path };
}

const rows = [
  createData('Report 1', 159),
  createData('Report 2', 237),
  createData('Report 3', 262),
  createData('Report 4', 305),
  createData('Report 5', 356),
];

export default function CustomizedTables({ isEdit }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const reportId = useSelector(state => state.common.reportId);

  const handleEditDetails = (e) => {
    let { value } = e.target;
    dispatch(setReportId(value));
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <FormTableCell>Report Title</FormTableCell>
            <FormTableCell align="right">Edit</FormTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <FormTableRow key={row.name}>
              <FormTableCell component="th" scope="row">
                {row.name}
              </FormTableCell>
              <FormTableCell align="right">{row.path}</FormTableCell>
            </FormTableRow>
          ))}
          <FormTableRow >
            <FormTableCell component="th" scope="row">
              <DataEntryTextField
                name="averageAnnualTemperature"
                size="small"
                variant="outlined"
                inputProps={{ inputMode: "numeric" }}
                style={{ width: '80%', textAlign: "center" }}
                value={reportId || ''}
                onChange={handleEditDetails}
              />
            </FormTableCell>
            <FormTableCell align="right">
              <Button variant='text' onClick={() => navigate('/data-entry/report/edit')}>Edit</Button>
            </FormTableCell>
          </FormTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
