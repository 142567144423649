import { Grid, LinearProgress, Link, Paper, ThemeProvider } from '@mui/material'
import React, { useEffect } from 'react'
import { Link as RouterLink, } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import { AuthTypography, RegisterInfoTypography, RegisterPaper } from '../../components/CustomStyles/StyledComponents'
import Footer from '../../layout/BottomNavigation'
import { mainTheme } from '../../theme';
import RegisterForm from './RegisterForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountries, resetResponseState } from '../../store/appAction';
import { signUp } from '../../store/authentication/action';
import { toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { ukPostCodeAlt } from '../../constants/regex';
import _ from 'lodash';

const Register = () => {
  const dispatch = useDispatch();
  const { countries, successMsg, errorMsg } = useSelector(state => state.common);
  toastr.options = toastrCustomOptionsLonger;

  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    firstName: Yup.string("Enter first name").trim().required("First name is required"),
    lastName: Yup.string("Enter last name").trim().required("Last name is required"),
    country: Yup.string("").trim().required("Country is required"),
    postCode: Yup.string("").trim().required("Postcode is required")
      .when('country', {
        is: (countryId) => (_.find(countries, { id: countryId })?.countryCategory || -1) === 1,
        then: Yup.string().matches(ukPostCodeAlt, 'Enter a valid postcode'),
      }),
    farmName: Yup.string("").trim().required("Farm name is required"),
    holdingNumber: Yup.string("").trim().required("Holding number is required"),
    refNumber: Yup.string("").trim().required("Business Reference number is required"),
    // reasonForService: Yup.string("").trim().required("Reason for using this service is required"),
    termsCondAgreed: Yup.bool().oneOf([true], 'Please accept the terms and conditions')
  });
  const values = {
    email: '',
    firstName: '',
    lastName: '',
    country: '',
    postCode: '',
    farmName: '',
    holdingNumber: '',
    refNumber: '',
    advisoryService: false,
    // reasonForService: '',
    allowExtraEmails: false,
    termsCondAgreed: false
  };

  useEffect(() => {
    dispatch(getAllCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  const handleSubmit = data => {
    dispatch(signUp(data));
  };

  return (
    < ThemeProvider theme={mainTheme} >
      <RegisterPaper>
        <Grid
          container
          direction="column"
          style={{ minHeight: "100vh", margin: '0 auto', width: '75%' }}
        >
          <Grid item>
            <Grid container>
              <Grid item md={6} xs="auto" style={{ textAlign: 'left' }} pt={2}>
                <img
                  height={100}
                  src="https://app.agrecalc.com/resources/images/agrecalc_logo_strapline_white.png"
                  alt="logo"
                />
              </Grid>
              <Grid item md={6} mt={4} style={{ textAlign: 'right' }}>
                <Link
                  underline="none"
                  component={RouterLink}
                  to="/auth/login"
                >
                  <AuthTypography >Login</AuthTypography>
                </Link >
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container
              direction="row"
              spacing={2}
            >
              <Grid item md={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '250px',
                    height: 250,
                    borderRadius: 0,
                    backgroundColor: '#253764'
                  }}
                >
                  <RegisterInfoTypography variant='h5'>Register for free</RegisterInfoTypography>
                  <RegisterInfoTypography variant='body1'>Carbon Footprint</RegisterInfoTypography>
                  <RegisterInfoTypography variant='caption'>Access to Calculations for IPCC Direct Emissions, Life Cycle Analysis, Indirect Emissions</RegisterInfoTypography>
                  <RegisterInfoTypography variant='body2'>Benchmarking</RegisterInfoTypography>
                  <RegisterInfoTypography variant='caption'>Compare Sector Averages & Key Performance Indicators</RegisterInfoTypography>
                </Paper>
              </Grid>
              <Grid item md={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '250px',
                    height: 250,
                    borderRadius: 0,
                    backgroundColor: '#1e601d',
                  }}
                >
                  <RegisterInfoTypography variant='h5'>Upgrade</RegisterInfoTypography>
                  <RegisterInfoTypography variant='caption'>Get multiple results, copy, print and download your Report.</RegisterInfoTypography>
                  <RegisterInfoTypography variant='body1'>Benchmarking</RegisterInfoTypography>
                  <RegisterInfoTypography variant='caption'>Compare System, Group and Regional Averages & Key Performance Indicators</RegisterInfoTypography>
                  <RegisterInfoTypography variant='body1'>Analysis</RegisterInfoTypography>
                  <RegisterInfoTypography variant='caption'>Perform What-If? Analysis</RegisterInfoTypography>
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 0,
                    border: '1px solid',
                    borderColor: '#1e601d',
                  }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 50,
                      borderRadius: 0,
                      backgroundColor: '#1e601d',
                    }}
                  >
                    <RegisterInfoTypography variant='h4'>Register today</RegisterInfoTypography>
                  </Paper>
                  <Formik
                    render={props => <RegisterForm {...props} />}
                    initialValues={values}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginY: 10 }}>
          </Grid>
        </Grid>
      </RegisterPaper>
      <Footer fixed={true} />
    </ThemeProvider >
  )
}

export default Register