export const endpoints = {
  authentication: {
    signIn: '/connect/token',
    signUp: '/account/register',
    signOut: '/auth/logout',
    forgotPassword: '/account/send-password-reset-code',
    resetPassword: '/account/reset-password'
  },
  farm: {
    lookup: {
    },
    report: '/report',
    reportReview: '/report/submit-for-review',
    reportStatus: '/report/REPORT_ID/review-status/STATUS_ID',
    reports: '/farm/reports',
    allFarms: '/farm/list',
    allUsers: 'farm-user/all',
    availableUsers: 'farm-user/all?includeFarmOwners=false',
  },
  farmReport: {
    projects: '/project/project-selection-list',
    lookup: {
      sectors: '/enterprise-sector/sectors',
      types: '/enterprise-sector/types',
      systems: '/enterprise-sector/systems',
      sectorItems: '/enterprise-sector/sector-items'
    },
    details: '/report/report-details',
    config: '/report/report-configurations'
  },
  reportExport:{
    monthlyReport: '/report-export/monthly-report'
  },
  livestock: {
    numbersWeights: {
      metaData: '/number-and-weight/meta',
      formData: '/number-and-weight/data',
      saveData: '/number-and-weight/save-data',
      monthlyStock: 'number-and-weight/monthly-stock',
    },
    purchasesSales: {
      metaData: '/purchases-sales-deaths/meta',
      formData: '/purchases-sales-deaths/data',
      saveData: '/purchases-sales-deaths/save-data'
    },
    performance: {
      metaData: '/performance/meta',
      formData: '/performance/data',
      saveData: '/performance/save-data'
    },
    bedding: {
      metaData: '/bedding/meta',
      formData: '/bedding/data',
      saveData: '/bedding/save-data',
      lookup: {
        options: '/master-data/bedding/name',
      },
    },
    feed: {
      metaData: '/feed/meta',
      formData: '/feed/data',
      saveData: '/feed/save-data',
      lookup: {
        category: '/master-data/feed/category',
        options: '/master-data/feed/name',
      },
    },
    manureManagement: {
      metaData: '/manure-management/meta',
      formData: '/manure-management/data',
      saveData: '/manure-management/save-data'
    },
    feedRation: {
      metaData: '/feed-ration/meta',
      formData: '/feed-ration/data',
      saveData: '/feed-ration/save-data'
    },
  },
  landCrops: {
    landAreaCrops: {
      metaData: '/landarea-crops/meta',
      formData: '/landarea-crops/data',
      saveData: '/landarea-crops/save-data',
      lookup: {
        options: '/master-data/land-types',
      },
    },
    pesticides: {
      metaData: '/pesticides/meta',
      formData: '/pesticides/data',
      saveData: '/pesticides/save-data'
    },
    fertiliser: {
      metaData: '/fertiliser/meta',
      formData: '/fertiliser/data',
      saveData: '/fertiliser/save-data',
      lookup: {
        options: '/master-data/fertilisers'
      }
    },
    cropProduction: {
      metaData: '/crop-production-use/meta',
      formData: '/crop-production-use/data',
      saveData: '/crop-production-use/save-data'
    },
    soilCarbon: {
      metaData: '/soil-carbon/meta',
      formData: '/soil-carbon/data',
      saveData: '/soil-carbon/save-data',
      lookup: {
        fallowCoverCropTypeOptions: '/master-data/fallow-cover-crop-types',
        grazingSystemOptions: '/master-data/grazing-systems',
        tillagePracticeOptions: '/master-data/tillage-types'
      }
    },
    cropUse: {
      metaData: '/crop-use-livestock/meta',
      formData: '/crop-use-livestock/data',
      saveData: '/crop-use-livestock/save-data'
    },
    organicMaterialManures: {
      metaData: '/imported-organic-materials/meta',
      formData: '/imported-organic-materials/data',
      saveData: '/imported-organic-materials/save-data',
      lookup: {
        options: '/master-data/manure-types'
      }
    },
    transferredManure: {
      metaData: '/transferred-manure/meta',
      formData: '/transferred-manure/data',
      saveData: '/transferred-manure/save-data'
    },
    biochar: {
      metaData: '/biochar/meta',
      formData: '/biochar/data',
      saveData: '/biochar/save-data',
      lookup: {
        temperature: "/master-data/temperature-types",
        feedstock: "/master-data/feedstock-types"
      }
    },
  },
  energyWaste: {
    electricityFuel: {
      metaData: '/electricity-fuel/meta',
      formData: '/electricity-fuel/data',
      saveData: '/electricity-fuel/save-data',
      defaults: '/electricity-fuel/defaults'
    },
    electricityFuelCont: {
      metaData: '/electricity-fuel-continued/meta',
      formData: '/electricity-fuel-continued/data',
      saveData: '/electricity-fuel-continued/save-data',
      defaults: '/electricity-fuel-continued/defaults'
    },
    renewableElectricity: {
      metaData: '/renewable-electricity/meta',
      formData: '/renewable-electricity/data',
      saveData: '/renewable-electricity/save-data',
      defaults: '/renewable-electricity/defaults'
    },
    renewableHeat: {
      metaData: '/renewable-heat/meta',
      formData: '/renewable-heat/data',
      saveData: '/renewable-heat/save-data',
      defaults: '/renewable-heat/defaults'
    },
    wasteTransportWater: {
      metaData: '/waste-transport-water/meta',
      formData: '/waste-transport-water/data',
      saveData: '/waste-transport-water/save-data'
    }
  },
  wholeFarm: {
    tables: '/results/whole-farm/detailed',
    charts: '/results/whole-farm/charts',
    report: '/results/pdf',
    farmerFriendlyReport: '/results/pdf/farmer-friendly',
  },
  enterprise: {
    sectors: '/results/enterprise/sector-list',
    compare: '/results/enterprise/comparison-list',
    tables: '/results/enterprise/detailed',
    charts: '/results/enterprise/charts'
  },
  common: {
    config: '/config',
    countries: '/configuration/countries',
    organisations: '/organizations',
    offices: '/organizations/ORG_ID/offices',
    project: {
      invitation: '/project/accept-invite'
    }
  },
  profile: {
    account: '/userinfo',
    // account: '/app/agre-calc-identity-user',
    farm: '/farm',
    farmAdvance: '/farm-advance'
  },
  user: {
    roles: '/identity/users/assignable-roles',
    update: '',
    info: '/userinfo'
  },
  admin: {
    farm: {
      farm: '/farm',
      farms: '/farm/list',
      edit: '/farm-advance',
      pdfAndFasData:'/farm/psf-fas'
    },
    report: '/report',
    user: {
      accounts: '/identity/users',
      account: '/identity/users/',
      create: '/identity/users',
    },
    org: '/organizations',
    office: {
      office: '/offices',
      officeUsers:'offices/OFFICE_ID/users-info',
      listByOrg: '/organizations/ORG_ID/offices'
    },
    organizations:{
      organizationUsers:'organizations/ORGANIZATION_ID/users-info',
      organizationFarms:'organizations/ORGANIZATION_ID/farms-info',
      organizationOffices:'organizations/ORGANIZATION_ID/offices-info'
    },
    project: {
      project: '/project/',
      projects: '/project/list',
      validate: {
        email: '/project/validate-user-by-email'
      },
    },
    benchmarking: {}
  },
  yearOnYear: {
    yearOnYearData:'/results/year-on-year'
  }
}