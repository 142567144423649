import { Button, FormControl, Grid, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState ,useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ResultsTableCell, FormTitleTypography, FormHeaderPaper, ResultsTableRow, FarmDetailsSelect, ResultsTableTitleTypography, FormDescriptionTypography, InfoTooltip, OpportunityLevelInfoTooltip } from '../../../components/CustomStyles/StyledComponents'
import DataChecksErrors from '../../../components/Errors/DataChecksErrors';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { getEnterpriseCompareToList, getEnterpriseSectorList, getEnterpriseTable, setLoading, setReportId, unsetLoading } from '../../../store/appAction';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';
import CreatePDFButton from '../../../components/Buttons/CreatePDFButton';
import { ENTERPRISE_DETAILED_TABLES } from '../../../constants/shellTables';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';

const DataTable = ({ metaTable, tableData, emissionUnit }) => {
    const addTextColor = (cellData) => {
        switch(cellData){
            case 'Low':  return <ResultsTableCell color='#C94D49'>{cellData}</ResultsTableCell>  
            case 'Medium':  return <ResultsTableCell color='#EDA710'>{cellData}</ResultsTableCell> 
            case 'High':  return <ResultsTableCell color='#5ace5d'>{cellData}</ResultsTableCell> 
            default : return <ResultsTableCell>{cellData}</ResultsTableCell>
        }
    }
    return <>
        {metaTable &&
            <TableContainer sx={{ mb: 3 }}>
                <ResultsTableTitleTypography sx={{ mb: 2 }}>{metaTable.title}</ResultsTableTitleTypography>
                <Table>
                    <TableHead>
                        <ResultsTableRow>
                            {metaTable.columns.map((column) => (
                                <ResultsTableCell>
                                    {column?.isDynamic ? emissionUnit : column.title}
                                    {column.key === "opportunityLevel" &&
                                        <OpportunityLevelInfoTooltip placement="top-end"
                                            title={
                                                <React.Fragment>
                                                    <Typography mb={1} variant='inherit'>Indicates the potential for improvement relative to the enterprise compared to. </Typography>
                                                    'Low' - your emissions are lower than 25% below the average
                                                    <br />'Medium' - your emissions are between -25% and + 25% of the average
                                                    <br />'High' - your emissions are over 25% of the average
                                                </React.Fragment>
                                            }>
                                            <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', verticalAlign: 'bottom' }} fontSize='small' />
                                        </OpportunityLevelInfoTooltip>
                                    }
                                </ResultsTableCell>
                            ))}
                        </ResultsTableRow>
                    </TableHead>
                    <TableBody>
                        {tableData && tableData.map((row) => (
                            <ResultsTableRow>
                                {metaTable.columns.map((column) => (
                                    addTextColor(addCommas(row[column.key]) || '--')
                                ))}
                            </ResultsTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </>
}

const EnterpriseTables = () => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const tableData = useSelector(state => state.enterprise.tableData);
    const enterpriseList = useSelector(state => state.enterprise.enterpriseList);
    const compareToList = useSelector(state => state.enterprise.compareToList);
    const reportId = useSelector(state => state.common.reportId);
    const reportList = useSelector(state => state.farm.reports);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);

    const [results, setResults] = useState([]);
    const [compareTo, setCompareTo] = useState({});
    const [option, setOption] = useState({});
    const [enterpriseOptIdx, setEnterpriseOptIdx] = useState(undefined);
    const [compareToOptIdx, setCompareToOptIdx] = useState(undefined);

    useEffect(() => {
        ref.current?.firstElementChild?.scrollIntoView();
    }, []);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getEnterpriseSectorList({ reportId }));
        dispatch(getEnterpriseCompareToList({ reportId }));
        setCompareTo({});
        setOption({});
        setEnterpriseOptIdx(undefined);
        setCompareToOptIdx(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        (enterpriseList.length > 0) && handleEnterpriseSelect({ target: { value: 0 } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enterpriseList]);

    useEffect(() => {
        if (compareToOptIdx !== undefined && enterpriseOptIdx !== undefined) {
            dispatch(setLoading());
            dispatch(getEnterpriseTable({
                ReportId: reportId,
                ReportEnterpriseSectorId: option.reportEnterpriseSectorId,
                ReportEnterpriseSectorGroupId: option.reportEnterpriseSectorGroupId,
                ReportEnterpriseSectorItemId: option.reportEnterpriseSectorItemId,
                ReportComparisonType: compareTo?.reportComparisonType,
                ComparisonReferenceId: compareTo?.referenceId,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareTo]);

    useEffect(() => {
        if (!_.isEmpty(option) && enterpriseOptIdx !== undefined) {
            dispatch(setLoading());
            dispatch(getEnterpriseTable({
                ReportId: reportId,
                ReportEnterpriseSectorId: option?.reportEnterpriseSectorId,
                ReportEnterpriseSectorGroupId: option?.reportEnterpriseSectorGroupId,
                ReportEnterpriseSectorItemId: option?.reportEnterpriseSectorItemId,
                ReportComparisonType: compareTo?.reportComparisonType,
                ComparisonReferenceId: compareTo?.referenceId,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    useEffect(() => {
        const detailed = tableData || {};
        setResults(detailed);
        dispatch(unsetLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData]);

    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }

    const handleEnterpriseSelect = async e => {
        setEnterpriseOptIdx(e.target.value);
        const enterprise = enterpriseList[e.target.value];
        if (enterprise) {
            await setOption(enterprise);
        }
    };

    const handleCompareToSelect = async e => {
        setCompareToOptIdx(e.target.value);
        const compareTo = compareToList[e.target.value];
        if (compareTo) {
            await setCompareTo(compareTo);
        }
    };

    return (
        <>
            <div>
            <div id={'enterprise-results-first-ref'} ref={ref}></div>
            {results?.status === 1 ?
                <DataChecksErrors errorList={results.dataChecks} /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                            <FormTitleTypography variant="h5" component="div">
                                Enterprise Results
                            </FormTitleTypography>
                            {/* <Typography variant="body2" paragraph>
                                {'Emissions by source and gas per report and enterprise are presented below.  You can also compare your farm against another.'}
                            </Typography> */}
                            <FormDescriptionTypography variant="body2" paragraph>
                                {'To see this information at a higher level please select Enterprise - Charts in the menu above.'}
                            </FormDescriptionTypography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '99%', float: 'left' }}
                                >
                                    <b style={{ fontSize: '1.15rem' }}>Report</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={reportId || ''}
                                        onChange={handleReportSelect}
                                        autoFocus={true}
                                    >
                                        <MenuItem value=""><em>Change Report...</em></MenuItem>
                                        {reportList.map((option) => (
                                            <MenuItem
                                                id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>

                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '99%', float: 'left' }}
                                >
                                    <b style={{ fontSize: '1.15rem' }}>Enterprise</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={enterpriseOptIdx !== undefined ? enterpriseOptIdx : ''}
                                        onChange={handleEnterpriseSelect}
                                    >
                                        <MenuItem value=""><em>Select enterprise...</em></MenuItem>
                                        {enterpriseList.map((option, index) => (
                                            <MenuItem
                                                id={index}
                                                key={index}
                                                value={index}
                                            >
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '91%', float: 'left' }}
                                >
                                    <b style={{ fontSize: '1.15rem' }}>Compare To</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={compareToOptIdx !== undefined ? compareToOptIdx : ''}
                                        onChange={handleCompareToSelect}
                                    >
                                        <MenuItem value=""><em>Select report...</em></MenuItem>
                                        {compareToList.map((option, index) => (
                                            <MenuItem
                                                id={index}
                                                key={index}
                                                value={index}
                                            >
                                                {option.itemTitle}
                                            </MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                                <InfoTooltip
                                    placement="bottom-end"
                                    title="Select one of your reports and an enterprise sector group belonging to it.  Then compare to another enterprise report with the same sector or an industry benchmark">
                                    <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginTop: '32px', }} fontSize='small' />
                                </InfoTooltip>

                            </Grid>
                            <Grid item lg={12} md={6} sm={12} xs={12} sx={{ textAlign: 'right' }} >
                                <CreatePDFButton
                                    referrer={'enterprise'}
                                    options={{ ...option, ...compareTo }}
                                />
                            </Grid>
                        </Grid>
                    </Grid >
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <Typography paragraph fontWeight="bold" mb={1}>
                                    {'Enterprise Type: '}
                                    <Typography paragraph display="inline" ml={1} mb={0}>
                                        {results.enterpriseType}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <Typography paragraph fontWeight="bold" align='left' mb={1}>
                                    {'System: '}
                                    <Typography paragraph display="inline" ml={1} mb={0}>
                                        {results.system}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <Typography paragraph fontWeight="bold" align='left' mb={1}>
                                    {'Organic: '}
                                    <Typography paragraph display="inline" ml={1} mb={0}>
                                        {results.isOrganic ? 'Yes' : 'No'}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ minHeight: 300 }}>
                        {ENTERPRISE_DETAILED_TABLES.map((table, index) => (
                            <DataTable
                                key={index}
                                metaTable={table}
                                tableData={results[table.key]}
                                emissionUnit={results?.emissionUnit}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormTitleTypography variant="h6" component="div">
                                Footnotes
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                *Your carbon footprint is expressed in units of CO<sub>2 </sub>
                                equivalents (CO<sub>2</sub>e) per unit of output e.g. kg CO<sub>2</sub>e per kg dwt of cold carcase.
                                This allows the efficiency of the enterprise to be compared.
                                The main greenhouse gases emitted by agriculture are
                                CH<sub>4</sub> = Methane (Predominantly from animal digestion);
                                N<sub>2</sub>O = Nitrous oxide (Predominantly from manure and fertiliser);
                                CO<sub>2</sub> = Carbon dioxide (Predominantly from burning of fossil fuels).
                            </Typography>
                            <Typography variant="body2" paragraph>
                                {'** Other includes crop residues, lime, transport and waste.'}
                            </Typography>
                            <Typography variant="body2" paragraph>
                                {'*** Total emission may differ due to rounding.  Emissions may be skewed on a year to year basis due to timing of sales therefore results are best monitored over a three year (minimum) period.'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormTitleTypography variant="h6" component="div">
                                Improve efficiency and environmental credentials
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph fontWeight="bold" mb={0}>
                                {'What does a carbon footprint actually tell you?'}
                            </Typography>
                            <Typography variant="body2" paragraph>
                                {'There is a strong correlation between efficiency, profitability and low carbon emissions.  The lower your carbon footprint, the more effective inputs have been at generating saleable product i.e. increased utilisation of costly inputs.  Each farm and system have natural limitations but, within this context, the process can identify carbon ‘hotspots’  on farm and is therefore a steer to improve efficiency and reduce greenhouse gas emissions.'}
                            </Typography>
                            <Typography variant="body2" paragraph fontWeight="bold" mb={0}>
                                {'How accurate does the information need to be?'}
                            </Typography>
                            <Typography variant="body2" paragraph>
                                {'The more accurate the information entered, the more meaningful the output.  Where possible on-farm records should be used to provide accurate farm-level data.'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormTitleTypography variant="h6" component="div">
                                Agrecalc report guide
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph fontWeight="bold" mb={1}>
                                {'A: Quick glace enterprise emissions'}
                                <Typography variant="body2" paragraph display="inline" ml={1} mb={0}>
                                    {'The ‘opportunity level‘ (high, medium or low) is the likelihood for improvement gauged against other farms in that sector.'}
                                </Typography>
                            </Typography>
                            <Typography variant="body2" paragraph fontWeight="bold" mb={1}>
                                {'B: Physical performance of enterprise'}
                                <Typography variant="body2" paragraph display="inline" ml={1} mb={0}>
                                    {'It is much easier to relate to performance indicators, actual sales, feeds and other inputs used. This becomes particularly useful when comparing years and for group comparisons.'}
                                </Typography>
                            </Typography>
                            <Typography variant="body2" paragraph fontWeight="bold" mb={1}>
                                {'C: Potential actions to reduce emissions'}
                                <Typography variant="body2" paragraph display="inline" ml={1} mb={0}>
                                    {'Examples of practical measures that could reduce emissions are shown below.  Technical advice should be sought before making any business changes.'}
                                </Typography>
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Grid container direction="column">
                                <Grid item >
                                    <Grid container direction="row">
                                        <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                            <Typography variant="body2" paragraph fontWeight="bold" mb={0}>
                                                {'Mitigation area'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} sx={{ display: 'inline-flex', }}>
                                            <Typography variant="body2" paragraph fontWeight="bold" mb={0}>
                                                {'Actions'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item >
                                    <Grid container direction="row" mb={2}>
                                        <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                            <Typography variant="body2" mb={0}>
                                                {'Energy and fuels'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} sx={{}}>
                                            <Typography variant="body2" mb={0}>
                                                {'Install smart meter to monitor electricity use – assess efficiency of equipment and activities.'}
                                            </Typography>
                                            <Typography variant="body2" mb={0}>
                                                {'Use thermostats, time clocks, motion sensors and low energy bulbs, increase lagging on hot water pipes, reduce number of hot washes in dairy and renew milk pump or other equipment.'}
                                            </Typography>
                                            <Typography variant="body2" mb={0}>
                                                {'Record fuel use per tractor and activity – assess efficiency of vehicles and operations.'}
                                            </Typography>
                                            <Typography variant="body2" mb={0}>
                                                {'Undertake regular machinery checks and maintenance, use correct tyre pressure, improve journey planning.'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" mb={2}>
                                        <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                            <Typography variant="body2" mb={0}>
                                                {'Renewable energy'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} sx={{}}>
                                            <Typography variant="body2" mb={0}>
                                                {'Undertake a renewable energy feasibility study.'}
                                            </Typography>
                                            <Typography variant="body2" mb={0}>
                                                {'Consider installing a wind turbine, an anaerobic digester, developing farm-scale micro hydro electricity, using a combined heat and power plant, growing trees as biomass fuel, using solar panels, ground source heat pumps or woodchip burners.'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" mb={2}>
                                        <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                            <Typography variant="body2" mb={0}>
                                                {'Fertiliser and manure'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} sx={{}}>
                                            <Typography variant="body2" mb={0}>
                                                {'Analyse soil and organic manure – ensure efficient use of organic and inorganic fertiliser.'}
                                            </Typography>
                                            <Typography variant="body2" mb={0}>
                                                {'Apply nitrogen at optimum rate and timing for crops, maintain clover content of swards, consider covering slurry stores and injecting slurry.'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" mb={2}>
                                        <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                            <Typography variant="body2" mb={0}>
                                                {'Livestock management'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} sx={{}}>
                                            <Typography variant="body2" mb={0}>
                                                {'Increase livestock productivity.'}
                                            </Typography>
                                            <Typography variant="body2" mb={0}>
                                                {'Improve feed conversion efficiency, increase calving or lambing percentage, reduce mortalities, increase weaning percentage, reduce age of calving, regularly review animal health plans, analyse silage or other homegrown forage.'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" mb={2}>
                                        <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                            <Typography variant="body2" mb={0}>
                                                {'Locking carbon into the soil'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} sx={{}}>
                                            <Typography variant="body2" mb={0}>
                                                {'Create carbon sinks.'}
                                            </Typography>
                                            <Typography variant="body2" mb={0}>
                                                {'Protect peatland and moorland from damage by avoiding over grazing, consider reduced tillage and ploughing in stubble and other crop residues, control soil erosion, create wildlife corridors along water margins, field margins and headlands, retain and conserve semi-natural grasslands, manage existing woodlands on farm and create new ones.'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormHeaderPaper>
                    </Grid>
                </Grid >
            }
            </div>
        </>
    )
}

export default EnterpriseTables