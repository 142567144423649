import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  metaData: {},
  totalArea: {},
  initialFormData: {},
  currentFormData: {},
  initialWholeFarmData: {},
  currentWholeFarmData: {}
};

const renewableHeatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RENEWABLE_HEAT_METADATA_SUCCESS: return setMetaDataSuccess(state, action);
    case actionTypes.GET_RENEWABLE_HEAT_METADATA_FAIL: return setMetaDataFail(state, action);
    case actionTypes.RESET_RENEWABLE_HEAT_METADATA: return resetMetaData(state, action);
    case actionTypes.GET_RENEWABLE_HEAT_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_RENEWABLE_HEAT_FORM_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_RENEWABLE_HEAT_FORM_DATA: return setFormData(state, action);
    case actionTypes.SET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS: return setWholeFarmData(state, action);
    case actionTypes.RESET_RENEWABLE_HEAT_ALL_DATA: return resetFormData(state, action);
    case actionTypes.GET_ALLOCATE_RENEWABLE_HEAT_SUCCESS: return setAllocateRenewableHeatSuccess(state, action);
    case actionTypes.GET_ALLOCATE_RENEWABLE_HEAT_FAIL: return setAllocateRenewableHeatFail(state, action);
    case actionTypes.GET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS: return setWholeFarmDataSuccess(state, action);
    default:
      return state;
  }
}

const setMetaDataSuccess = (state, action) => {
  const updatedState = {
    metaData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMetaDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setWholeFarmDataSuccess = (state, action) => {
  const updatedState = {
    initialWholeFarmData: action.payload,
    currentWholeFarmData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedState = {
    currentFormData: {
      ...state.currentFormData,
      [sectorId]: {
        ...formData
      }
    },
  }
  return updateObject(state, updatedState);
}

const setWholeFarmData = (state, action) => {
  const { wholeFarmData } = action.payload;
  const updatedState = {
    currentWholeFarmData: {
      ...wholeFarmData
    },
  }
  return updateObject(state, updatedState);
}

const resetFormData = (state, action) => {
  const updatedState = {
    currentFormData: state.initialFormData,
  }
  return updateObject(state, updatedState);
}

const setAllocateRenewableHeatSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAllocateRenewableHeatFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

export default renewableHeatReducer;
