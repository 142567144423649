export const GET_MY_FARM_DATA_SUCCESS = 'GET_MY_FARM_DATA_SUCCESS'
export const GET_MY_FARM_DATA_FAIL = 'GET_MY_FARM_DATA_FAIL'

export const GET_MY_ACCOUNT_DATA_SUCCESS = 'GET_MY_ACCOUNT_DATA_SUCCESS'
export const RESET_MY_ACCOUNT_DATA = 'RESET_MY_ACCOUNT_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'


