import * as React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import BackButton from './BackButton';
import CancelModal from '../Modal/CancelModal';

const DataEntryButtonStack = ({
    modalOpen, setModalOpen, handleModalResponse,
    handelSaveClick, handleNextClick
}) => {
    return (
        <Grid container direction="row" >
            <Grid item xs={2}>
                <BackButton />
            </Grid>
            <Grid item xs={10} sx={{ display:'flex', alignItems:'center' }}>
                <Stack spacing={2} sx={{ marginLeft: 'auto', float: 'right' }} direction="row">
                    <Button
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => setModalOpen(true)}>
                        Cancel
                    </Button>
                    <Button variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={handelSaveClick}>
                        Save
                    </Button>
                    <Button variant="contained"
                        sx={{ textTransform: 'none' }}
                        className="activated"
                        onClick={handleNextClick}>
                        Next
                    </Button>
                </Stack>
            </Grid>
            <CancelModal isOpen={modalOpen} handleResponse={handleModalResponse} />
        </Grid>
    );
};

export default DataEntryButtonStack;

