import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import _ from 'lodash';

const TopMenuList = ({ index, headerMenu,
  subMenuList = {}, isCurrentMenuIndex, setCurrentMenuIndex,
  includeSoilCarbon, landAndCropsOnly, livestockOnly }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  let filteredSubmenuList = !includeSoilCarbon ?
    _.filter(subMenuList, ((o) => { return !_.has(o, 'soilCarbonOnly'); })) : subMenuList;

  filteredSubmenuList = landAndCropsOnly ?
    _.filter(filteredSubmenuList, ((o) => { return !_.has(o, 'livestockOnly'); })) : filteredSubmenuList;

  // filteredSubmenuList = livestockOnly && headerMenu.landAndCropsOnly ?
  //   _.filter(filteredSubmenuList, ((o) => { return _.has(o, 'livestockOnly'); })) : filteredSubmenuList;

  const handleToggle = () => {
    if (filteredSubmenuList.length > 1)
      setOpen((prevOpen) => !prevOpen);
    else
      filteredSubmenuList.length > 0 && navigate(filteredSubmenuList[0].path);
  };

  const handleClose = (event, path) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if (path) {
      setCurrentMenuIndex(index);
      navigate(path);
    }
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (

    <div>
      <Button
        disabled={
          (landAndCropsOnly && headerMenu.livestockOnly)
          || (livestockOnly && headerMenu.landAndCropsOnly)
        }
        color='inherit'
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        sx={{
          textTransform: 'none',
          marginLeft: '.75rem',
          padding: '.75rem 1rem',
          borderRadius: 0,
          color: isCurrentMenuIndex &&
            '#253764',
          fontWeight: isCurrentMenuIndex &&
            'bold',
          fontSize: '1.15rem',
          ":hover": {
            color: '#253764',
            backgroundColor: 'transparent',
          }
        }}
        onClick={handleToggle}
      >
        {headerMenu.title}
      </Button>
      {filteredSubmenuList?.length > 0 && <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}

                >
                  {filteredSubmenuList.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={(e) => handleClose(e, item.path)}>{item.title}</MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>}
    </div>

  );
}

export default TopMenuList;
