import { Grid, Paper } from '@mui/material'
import React, { } from 'react'
import { HeaderFooterTypographyPDF, PageHeaderPaper } from '../../../../components/CustomStyles/StyledComponents';
import agreCalcLogo from '../../../../assets/images/agrecalc-logo-green.svg'
import dayjs from 'dayjs';

//This component is used to generate Enterprise report and whole farm report
const Header = ({ farmId, farmName, reportId, reportCreated, modelNumber, yearEnd, reportName }) => {
    return (
        <header style={{ width: 'inherit' }}>
            <PageHeaderPaper
                sx={{
                    px: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container direction="row" >
                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <HeaderFooterTypographyPDF
                            paragraph
                            variant="body2"
                            component="div"
                            sx={{ textAlign: 'left', mb: 0 }}
                        >
                            <span className='logo-lg'>
                                <img src={agreCalcLogo} alt='' height='45' />
                            </span>
                        </HeaderFooterTypographyPDF>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={1} mb={1}>
                            <Grid item> <HeaderFooterTypographyPDF variant="body1" color="text.secondary"> {` ${farmName} (ID ${farmId})`} </HeaderFooterTypographyPDF></Grid>
                        </Grid>
                        <Grid container spacing={1} mb={1}>
                            
                            <Grid item> <HeaderFooterTypographyPDF variant="body1" color="text.secondary"> {`${reportName} (ID ${reportId})`} </HeaderFooterTypographyPDF></Grid>
                        </Grid>
                        <Grid container mb={1}>
                            <Grid item xs={3} container  spacing={1}>
                                <Grid item> <HeaderFooterTypographyPDF  variant="body1" color='#ababab'> {"Model : "} </HeaderFooterTypographyPDF></Grid>
                                <Grid item><HeaderFooterTypographyPDF  variant="body1" color='text.secondary' >  {'v.'}{modelNumber} </HeaderFooterTypographyPDF></Grid>
                            </Grid>
                            <Grid item xs={5} container  spacing={1}>
                                <Grid item><HeaderFooterTypographyPDF  variant="body1" color='#ababab'> {"Generated Date : "} </HeaderFooterTypographyPDF></Grid>
                                <Grid item><HeaderFooterTypographyPDF  variant="body1" color='text.secondary' >  {dayjs(reportCreated || new Date()).format('YYYY-MM-DD')} </HeaderFooterTypographyPDF></Grid>
                            </Grid>
                            <Grid item xs={4} container  spacing={1}>
                                <Grid item><HeaderFooterTypographyPDF  variant="body1" color='#ababab'> {"Year End : "} </HeaderFooterTypographyPDF></Grid>
                                <Grid item><HeaderFooterTypographyPDF  variant="body1" color='text.secondary' >{dayjs(yearEnd).format('MMM YYYY')}</HeaderFooterTypographyPDF></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PageHeaderPaper>
            <Paper sx={{ height: '5px' }}></Paper>
        </header >
    )
}

export default Header