import * as actionTypes from './types';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import store from '../..';
import queryString from 'query-string';

export const getAdminProjects = (params) => async (dispatch) => {
    const response = await api.get(endpoints.admin.project.projects.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
        return dispatch(getProjectsSuccess(response.data));
    }
    else {
        return dispatch(getProjectsFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
}

const getProjectsSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_PROJECT_LIST_SUCCESS,
        payload: response
    };
};

const getProjectsFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
}

export const getAdminProject = (projectId) => async (dispatch) => {
    const response = await api.get(endpoints.admin.project.project.concat(projectId));
    if (response && response.status === 200) {
        return dispatch(getAdminProjectSuccess(response.data));
    }
    else {
        return dispatch(getAdminProjectFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const getAdminProjectSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_PROJECT_DATA_SUCCESS,
        payload: response
    };
};

const getAdminProjectFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const setAdminProjectId = (project) => {
    return {
        type: actionTypes.SET_CURRENT_ADMIN_PROJECT_ID,
        payload: project
    };
};

export const getProjectAdminData = (accountId) => async (dispatch) => {
    const response = await api.get(endpoints.admin.user.account.concat(accountId));
    if (response && response.status === 200) {
        return dispatch(getProjectAdminDataSuccess(response.data));
    }
    else {
        return dispatch(getProjectAdminDataFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const getProjectAdminDataSuccess = (response) => {
    return {
        type: actionTypes.GET_PROJECT_ADMIN_DATA_SUCCESS,
        payload: response
    };
};

const getProjectAdminDataFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const createProject = (data) => async (dispatch) => {
    try {
        const { projectName, description, users } = data;
        const response = await api.post(endpoints.admin.project.project,
            {
                name: projectName,
                description: description,
                users: [...users]
            }
        );
        if (response && response.status === 200) {
            dispatch(setAdminProjectId(response.data));
            return dispatch(createProjectSuccess("Project has been created successfully."));
        }
        else {
            return dispatch(createProjectFail(response?.data || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(createProjectFail(e || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const createProjectSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const createProjectFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const updateProject = (data) => async (dispatch) => {
    try {
        const { projectName, description, users } = data;
        const response = await api.put(endpoints.admin.project.project.concat(data.id),
            {
                name: projectName,
                description: description,
                users: [...users]
            }
        );
        if (response && response.status === 200) {
            return dispatch(updateProjectSuccess("Project has been updated successfully."));
        }
        else {
            return dispatch(updateProjectFail(response?.data || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateProjectFail(e || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const updateProjectSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const updateProjectFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};


export const deleteProject = () => async (dispatch) => {
    try {
        const { id } = store.getState().adminProject.projectDetails;
        const response = await api.del(endpoints.admin.project.project.concat('/', id));
        if (response && (response.status === 200 || response.status === 204)) {
            return dispatch(deleteProjectSuccess(response.data || "Successfully deleted the project"));
        }
        else {
            return dispatch(deleteProjectFail(response?.data || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateProjectFail(e || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const deleteProjectSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const deleteProjectFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response

    };
};

export const validateInviteeEmail = (email) => async () => {
    try {
        const response = await api.get(endpoints.admin.project.validate.email.concat('?email=', encodeURIComponent(email)));
        if (response && response.status === 200) {
            return { validated: response?.data };
        }
        else {
            return { error: "The email address entered is not associated with an Agrecalc account.  Only email addresses that are associated with an Agrecalc account can be invited to be part of a project." };
        }
    } catch (e) {
        return { error: "The email address entered is not associated with an Agrecalc account.  Only email addresses that are associated with an Agrecalc account can be invited to be part of a project." };
    }
};

