export const GET_PESTICIDES_METADATA = 'GET_PESTICIDES_METADATA'
export const GET_PESTICIDES_METADATA_SUCCESS = 'GET_PESTICIDES_METADATA_SUCCESS'
export const GET_PESTICIDES_METADATA_FAIL = 'GET_PESTICIDES_METADATA_FAIL'
export const RESET_PESTICIDES_METADATA = 'RESET_PESTICIDES_METADATA'

export const GET_PESTICIDES_FORM_DATA_SUCCESS = 'GET_PESTICIDES_FORM_DATA_SUCCESS'
export const GET_PESTICIDES_FORM_DATA_FAIL = 'GET_PESTICIDES_FORM_DATA_FAIL'

export const SET_PESTICIDES_FORM_DATA = 'SET_PESTICIDES_FORM_DATA'
export const RESET_PESTICIDES_ALL_DATA = 'RESET_PESTICIDES_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'