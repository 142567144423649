import queryString from 'query-string';
import * as actionTypes from './types';
import * as api from '../../api/base';
import { endpoints } from '../../api/endpoints';

export const getAllReports = (params) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.farm.reports.concat(params.farmId ? '?' + queryString.stringify(params) : '/all'));
    if (response && response.status === 200) {
      return dispatch(getAllReportsSuccess(response?.data));
    }
    else
      return dispatch(getAllReportsFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (error) {
    return dispatch(getAllReportsFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};

export const clearAllReportsData = (response) => {
  return {
    type: actionTypes.CLEAR_ALL_REPORTS_DATA,
    payload: response
  };
};

const getAllReportsSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_REPORTS_SUCCESS,
    payload: response
  };
};
const getAllReportsFail = (response) => {
  return {
    type: actionTypes.GET_ALL_REPORTS_FAIL,
    payload: response
  };
};

export const copyReport = ({ reportId, title }) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.farm.report.concat(`/${reportId}/copy?name=${title}`));
    if (response && (response.status === 200 || response.status === 204)) {
      return dispatch(copyReportSuccess(response.data || "The Report has been successfully cloned"));
    }
    else {
      return dispatch(copyReportFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
  } catch (error) {
    return dispatch(copyReportFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};
const copyReportSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};
const copyReportFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response

  };
};

export const deleteReport = (reportId) => async (dispatch) => {
  try {
    const response = await api.del(endpoints.farm.report.concat('?reportId=', reportId));
    if (response && (response.status === 200 || response.status === 204)) {
      return dispatch(deleteReportSuccess(response.data || "The Report has been successfully deleted"));
    }
    else {
      return dispatch(deleteReportFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
  } catch (error) {
    return dispatch(deleteReportFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};
const deleteReportSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};
const deleteReportFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response

  };
};

export const submitReviewReport = (reportId) => async (dispatch) => {
  const response = await api.get(endpoints.farm.reportReview.concat('?reportId=', reportId));
  if (response && (response.status === 200 || response.status === 204)) {
    return dispatch(submitReviewReportSuccess(response.data || "Your report has been marked for review."));
  }
  else {
    return dispatch(submitReviewReportFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};
const submitReviewReportSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};
const submitReviewReportFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response

  };
};

export const submitStatusReport = ({ reportId, statusId }) => async (dispatch) => {
  const response = await api.get(endpoints.farm.reportStatus.replace('REPORT_ID', reportId).replace('STATUS_ID', statusId));
  if (response && (response.status === 200 || response.status === 204)) {
    return dispatch(submitStatusReportSuccess(response.data || "The report has been mark as reviewed."));
  }
  else {
    return dispatch(submitStatusReportFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};
const submitStatusReportSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};
const submitStatusReportFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response

  };
};
