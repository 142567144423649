import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';//or session
import thunk from 'redux-thunk';

import reducers from './appReducer';
import logger from './middleware/logger';
import loaderHandler from './middleware/loaderHandler';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'common', 'profile', 'farm', 'farmReport', 'adminFarm'],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, logger, loaderHandler))
);

export const persistor = persistStore(store);

export default store;
