import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getBiocharMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.biochar.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getBiocharMetadataSuccess(response?.data));
  } else
    return dispatch(getBiocharMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getBiocharMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_BIOCHAR_METADATA_SUCCESS,
    payload: response
  };
};

const getBiocharMetadataFail = () => {
  return {
    type: actionTypes.GET_BIOCHAR_METADATA_FAIL,
  };
};

export const resetBiocharMetaData = () => {
  return {
    type: actionTypes.RESET_BIOCHAR_METADATA,
  };
};

export const getBiocharFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.biochar.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getBiocharFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getBiocharFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getBiocharFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_BIOCHAR_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getBiocharFormDataFail = () => {
  return {
    type: actionTypes.GET_BIOCHAR_FORM_DATA_FAIL,
  };
};

export const saveBiocharData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().biochar
  const response = await api.post(endpoints.landCrops.biochar.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveBiocharDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveBiocharDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveBiocharDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveBiocharDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setBiocharFormData = (response) => {
  return {
    type: actionTypes.SET_BIOCHAR_FORM_DATA,
    payload: response
  };
};

export const resetBiocharFormData = () => {
  return {
    type: actionTypes.RESET_BIOCHAR_ALL_DATA,
  };
};

export const getFeedstockTypeOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.biochar.lookup.feedstock);
    if (response && response.status === 200) {
      return dispatch(getFeedstockTypeOptionsSuccess(response?.data));
    }
    else
      return dispatch(getFeedstockTypeOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getFeedstockTypeOptionsFail(e));
  }
};
const getFeedstockTypeOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_FEEDSTOCK_TYPE_OPTIONS_SUCCESS,
    payload: response
  };
};
const getFeedstockTypeOptionsFail = (response) => {
  return {
    type: actionTypes.GET_FEEDSTOCK_TYPE_OPTIONS_FAIL,
    payload: response
  };
};

export const getTemperatureTypeOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.biochar.lookup.temperature);
    if (response && response.status === 200) {
      return dispatch(getTemperatureTypeOptionsSuccess(response?.data));
    }
    else
      return dispatch(getTemperatureTypeOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getTemperatureTypeOptionsFail(e));
  }
};
const getTemperatureTypeOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_TEMPERATURE_TYPE_OPTIONS_SUCCESS,
    payload: response
  };
};
const getTemperatureTypeOptionsFail = (response) => {
  return {
    type: actionTypes.GET_TEMPERATURE_TYPE_OPTIONS_FAIL,
    payload: response
  };
};