import React from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";
import { Paper, Typography } from "@mui/material";
import Color from "color";
import _ from "lodash";
import { ChartTitleTypography } from "../CustomStyles/StyledComponents";

// const data = [
//     { title: "Beef Group A", value: 40 },
//     { title: "Sheep Group B", value: 4 },
//     { title: "Sheep Group C", value: 30 },
//     { title: "Beef Group D", value: 20 },
//     { title: "Malting Spring Barley", value: 6 }
// ];

const COLOR_CODES = ['#f1b435', '#199F9F', '#253764', '#a13430', '#595959', '#004E37', '#800020', '#3b579f', '#88dc8e', '#b1bfe2']

const getColor = (index, initNum) => {
    return index <= COLOR_CODES.length ? COLOR_CODES[index] :
        Color.rgb(0 + (index * initNum),
            32 + (index * (initNum * 10)),
            96 + (index * (5 * initNum))).hex();
}

const RADIAN = Math.PI / 180;
const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
    index
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        value < 5 ?
            null :
            < text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${value?.toFixed(1)}%`}
            </text >
    );
};

const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 2 }} variant='outlined'>
                <Typography variant="caption" fontWeight="bold">
                    {`${payload[0].name} : ${payload[0].value?.toFixed(1)}%`}
                </Typography>
            </Paper>
        );
    }
    return null;
};

const renderColorfulLegendText = (value, entry, data) => {
    const { color } = entry;

    return <span style={{ color }}>{value?.concat(' (', data?.value?.toFixed(1), ' %)')}</span>;
};

export default function ColoredPieChart({
    title, dataset, key,
    height = 300, width = "100%",
    cx = 120, cy = 120, outerRadius = 115,
    isAnimationActive = true,
    legendWidth = "calc(100% - 260px)"

}) {

    const validData = _.filter(dataset, d => d.value > 0);
    return (
        <>
            <ChartTitleTypography gutterBottom align="center">
                {title}
            </ChartTitleTypography>
            <ResponsiveContainer
                height={height}
                width={width}
            >
                <PieChart >
                    <Pie
                        isAnimationActive={isAnimationActive}
                        key={key}
                        nameKey="title"
                        fontSize={10}
                        data={validData}
                        cx={cx}
                        cy={cy}
                        labelLine={false}
                        label={renderCustomLabel}
                        outerRadius={outerRadius}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {validData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(index, validData?.length || 1)} />
                        ))}
                    </Pie>
                    <Tooltip content={renderCustomTooltip} />
                    <Legend
                        layout='vertical'
                        align='right' wrapperStyle={{ width: legendWidth, whiteSpace: "break-spaces" }}
                        formatter={(value, entry, index) => renderColorfulLegendText(value, entry, validData[index])}
                    />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}
