import { Card, CardActionArea, CardContent, Grid, Paper, Typography } from '@mui/material'
import React, { } from 'react'
import { FormDescriptionTypographyPDF, FormTitleTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';
import CircularPieChart from '../../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../../components/Charts/ColoredPieChart';
import VerticalBarChart from '../../../../components/Charts/VerticalBarChart';
import { addCommas } from '../../../../helpers/stringFormatHelper';

const Charts = ({ results = {} }) => {
    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                //minHeight: '500px',
                pageBreakAfter: 'always',
                marginTop: '70px',
            }}
                p={2}
            >
                <Grid item xs={12} md={12} lg={12} mb={2}>
                    <Paper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormTitleTypographyPDF fontSize={20} variant="h6" component="div">
                            Whole Farm Emissions
                        </FormTitleTypographyPDF>
                        <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={0}>
                            {'This overview of your farm-level emissions provides a breakdown of your total greenhouse gas emissions by enterprise, source, and gas. This provides a quick overview of the total climate impact of your farm, and highlights major sources of emissions in your system.'}
                        </FormDescriptionTypographyPDF>
                    </Paper>
                </Grid>
                <Grid item xs={12} mb={2} px={0}>
                    <Grid container direction='row'>
                        <Grid item xs={3} p={1} >
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                            Total Emissions:
                                        </Typography>
                                        {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                            {addCommas(results.totalEmissions)}
                                        </Typography>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                            Highest Enterprise:
                                        </Typography>
                                        {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                            {results.highestCarbonEnterprise}
                                        </Typography>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={3} p={1} >
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                            Largest Source:
                                        </Typography>
                                        {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                            {results.largestSource}
                                        </Typography>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                            Highest Gas Emission:
                                        </Typography>
                                        {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                            {results.highestGasEmission}
                                        </Typography>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} px={0}>
                    <Grid container direction='row' spacing={0}>
                        <Grid item xs={6} pl={0}>
                            <Paper sx={{ pl: 0 }}>
                                <ColoredPieChart
                                    isAnimationActive={false}
                                    key={1}
                                    title='Emissions by Enterprise'
                                    dataset={results.emissionByEnterprise}
                                    height={250}
                                    width={380}
                                    cx={100}
                                    outerRadius={100}
                                    legendWidth='170px'
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={6} pl={0}>
                            <Paper>
                                <CircularPieChart
                                    isAnimationActive={false}
                                    key={2}
                                    title='Emissions by Gas'
                                    dataset={results.emissionByGas}
                                    height={250}
                                    width={355}
                                    cx={105}
                                    outerRadius={100}
                                    labelFontSize='1em'
                                    legendWidth='140px'
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' spacing={0}>
                        <Grid item xs={12} pl={0}>
                            <Paper sx={{ width: '600px', margin: '0 auto', mt: 4 }}>
                                <VerticalBarChart
                                    isAnimationActive={false}
                                    key={3}
                                    title='Emissions by Source'
                                    dataset={results.emissionBySource}
                                    height={240}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Charts