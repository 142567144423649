import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import { Grid, Paper, List, ListSubheader, Button } from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormTableRow, FormTableCell,
  SectorTitleTypography, DataEntryTextField, FormHeaderPaper,
  FormDescriptionTypography,
  InfoTooltip,
  CustomizedDivider
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger, toastrCustomOptionsNoFarmSelectPopUp } from "../../../constants/toastrOptions";
import { addCommas, removeCommas } from "../../../helpers/stringFormatHelper";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import {
  getNumbersWeightsFormData, getNumbersWeightsMetadata, resetNumbersWeightsMetadata,
  saveNumbersWeightsData, setNumbersWeightsFormData,
  setNumbersWeightsOtherSalesData,
  resetResponseState,
  unsetLoading,
  setLoading
} from "../../../store/appAction";
import _ from "lodash";
import { isEqual } from "lodash";
import { numericRegex2X, numericRegex4X } from "../../../constants/regex";
import { MAPPING_PROPS_NUMERIC_4X } from "../../../constants/arrays";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import store from '../../../store';

const CELL_WIDTH = {
  OpeningWeight: "16%",
  ClosingWeight: "16%",
  AvgNumLivestock: "16%",
};

const DataEntryTable = ({ sector, initialFormData, initialOtherSalesData, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);
  const [otherSalesDataInt, setOtherSalesDataInt] = useState(undefined);
  const [otherSalesDataExt, setOtherSalesDataExt] = useState(undefined);
  const TOTAL_COLUMN_COUNT = sector.columns.length;

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
    setOtherSalesDataInt(initialOtherSalesData);
    setOtherSalesDataExt(initialOtherSalesData);
  }, [initialFormData, initialOtherSalesData]);

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setNumbersWeightsFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  useEffect(() => {
    if (otherSalesDataExt && !_.isEmpty(otherSalesDataExt)) {
      dispatch(setNumbersWeightsOtherSalesData({
        sectorId: sector.id,
        otherSalesData: otherSalesDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherSalesDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = e.target.value;
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value)) {
        toastr.warning(
          `This input only accepts values between ${rule.min} and ${rule.max}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = useCallback((
    e,
    groupId,
    rowId,
    mappingProperty,
    isOtherSales,
    rule,
    readOnly
  ) => {
    let value = removeCommas(e.target.value);
    if(mappingProperty === 'AvgNumberOver12Months' && readOnly === true){
      toastr.warning(
        `Please use the Livestock Monthly Stock Totals tool to enter the average number over a 12-month period.`,
        undefined,
        toastrCustomOptionsNoFarmSelectPopUp
      );
      return;
    }
    if (e.target.inputMode === "numeric") {
      let numericRegex = MAPPING_PROPS_NUMERIC_4X.includes(mappingProperty) ?
        numericRegex4X : numericRegex2X;
      if (numericRegex.test(value)) {
        if (rule.max < parseFloat(value || 0)) {
          toastr.warning(
            `This input only accepts values between ${addCommas(
              rule.min
            )} and ${addCommas(rule.max)}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          if (isOtherSales) {
            setOtherSalesDataInt(produce((draft) => {
              draft[groupId][rowId][mappingProperty] = value;
            }));
            setOtherSalesDataExt(produce((draft) => {
              draft[groupId][rowId][mappingProperty] = parseFloat(value);
            }));
          } else {
            setSectorDataInt(produce((draft) => {
              draft[groupId][rowId][mappingProperty] = value;
            }));
            setSectorDataExt(produce((draft) => {
              draft[groupId][rowId][mappingProperty] = parseFloat(value);
            }));
          }
        }
      }
    } else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
    }
  }, []);

  const getCellContent = ({ groupId, rowId, cell, rowData, isOtherSales, firstVisibleCellDetails, locked }) => {
    switch (cell.dataType) {
      case "TextField":
        return (
          <DataEntryTextField
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width: isOtherSales ? 125 : 100, textAlign: "center" }}
            value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            onChange={(e) =>
              handleInputChange(
                e,
                groupId,
                rowId,
                cell.mappingProperty,
                isOtherSales,
                cell.validations,
                cell.readOnly
              )
            }
            onBlur={(e) => handleOnBlur(e, cell.validations)}
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
          />
        );
      default:
        return <FormTableCell sx={{ width: "12%" }}></FormTableCell>;
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        {(sector.sectorGroups.groups || []).map((group) => (
          <Fragment>
            <SectorTitleTypography sx={{ mb: 2 }}>
              {sector.title} - {group.title}
            </SectorTitleTypography>
            <Table
              sx={{ minWidth: 700 }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <FormTableRow>
                  <FormTableCell></FormTableCell>
                  {sector.columns.map((column) => (
                    <FormTableCell sx={{ width: '14%' }}>
                      {column.title}
                    </FormTableCell>
                  ))}
                </FormTableRow>
              </TableHead>
              <TableBody>
                {group.rows.map((row) => (
                  <FormTableRow>
                    <FormTableCell sx={{ width: '14%' }}>{row.title}</FormTableCell>
                    {row.cells.map((cell) => (
                      <FormTableCell>
                        {sectorDataInt &&
                          cell.visible &&
                          getCellContent({
                            groupId: group.id,
                            rowId: row.id,
                            cell: cell,
                            rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                            isOtherSales: false,
                            firstVisibleCellDetails,
                            locked
                          })}
                      </FormTableCell>
                    ))}
                  </FormTableRow>
                ))}
                {sector.sectorGroups.sectorGroupSummary && (
                  <>
                    <FormTableRow>
                      <FormTableCell></FormTableCell>
                      {sector.sectorGroups.sectorGroupSummary.map(
                        (sectorGroup) => (
                          <FormTableCell
                            colSpan={
                              TOTAL_COLUMN_COUNT /
                              sector.sectorGroups.sectorGroupSummary.length
                            }
                          >
                            {sectorGroup.title}
                            <InfoTooltip
                              placement="right"
                              title="This is required to estimate the GWP* metric for the warming potential of methane. A rough estimate is better than nothing - if you leave this field blank we will assume that the size of your farm has not changed over the past 20 years.">
                              <InfoOutlinedIcon color='primary' sx={{ marginLeft: '5px', verticalAlign: 'bottom' }} fontSize='small' />
                            </InfoTooltip>
                          </FormTableCell>
                        )
                      )}
                    </FormTableRow>
                  </>
                )}
                {group.groupSummary.map((groupSum) => (
                  <FormTableRow>
                    {groupSum.rows.map((row) => (
                      <>
                        <FormTableCell>{row.Title}</FormTableCell>
                        {row.cells.map((cell) => (
                          <FormTableCell
                            colSpan={
                              TOTAL_COLUMN_COUNT /
                              sector.sectorGroups.sectorGroupSummary.length
                            }
                          >
                            {otherSalesDataInt && cell.visible &&
                              getCellContent({
                                groupId: group.id,
                                rowId: row.id,
                                cell: cell,
                                rowData: otherSalesDataInt[group.id] && otherSalesDataInt[group.id][row.id],
                                isOtherSales: true,
                                firstVisibleCellDetails
                              })}
                          </FormTableCell>
                        ))}
                      </>
                    ))}
                  </FormTableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        ))}
      </TableContainer>
    </>
  );
};

const NumbersWeights = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.numberWeights.metaData);
  const initialFormData = useSelector(state => state.numberWeights.initialFormData);
  const initialOtherSalesData = useSelector(state => state.numberWeights.initialOtherSalesData);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);

  const [formData, setFormData] = useState({})
  const [otherSalesData, setOtherSalesData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getNumbersWeightsMetadata({ reportId }));
    dispatch(getNumbersWeightsFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetNumbersWeightsMetadata());
    }
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    setOtherSalesData({ ...initialOtherSalesData });
  }, [initialOtherSalesData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/livestock/purchases-sales-deaths');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch(getNumbersWeightsMetadata({ reportId }));
      dispatch(getNumbersWeightsFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const numberWeightsData = store.getState().numberWeights;
    const isUpdated = !isEqual(numberWeightsData?.initialFormData, numberWeightsData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const monthlyStockTool = params.get('monthlyStockTool');
    if(monthlyStockTool){
      dispatch(setLoading());
      dispatch(saveNumbersWeightsData({ reportId }));
      dispatch(setLoading());
      setTimeout(() => {
        dispatch(getNumbersWeightsFormData({ reportId }));
        navigate('/data-entry/livestock/numbers-weights');
    }, 500);
    }else{
      if(!isFormUpdated()){
        return;
      }else{
        dispatch(setLoading());
        dispatch(saveNumbersWeightsData({ reportId }));
        dispatch(setLoading());
        setTimeout(() => {
          dispatch(getNumbersWeightsFormData({ reportId }));
          navigate('/data-entry/livestock/numbers-weights');
      }, 500);
      }
    }
  }

  const handleNextClick = (e) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const monthlyStockTool = params.get('monthlyStockTool');

    if(monthlyStockTool){
      setNextClicked(true);
      dispatch(setLoading());
      dispatch(saveNumbersWeightsData({ reportId }));
    }else{
      setNextClicked(true);
      if (props.locked || !isFormUpdated())
        navigate('/data-entry/livestock/purchases-sales-deaths');
      else {
        dispatch(setLoading());
        dispatch(saveNumbersWeightsData({ reportId }));
        navigate('/data-entry/livestock/purchases-sales-deaths');
      };
    }
  }

  return (
    <ScrollSync>
    <Grid container spacing={2} className={props.locked && 'deactivated'}>
      <Grid item xs={12} md={12} lg={12}>
        <FormHeaderPaper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
          divider={false}
        >
          <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
          <FormTitleTypography variant="h5" component="div">
            Livestock Numbers {"&"} Weights
          </FormTitleTypography>
          <FormDescriptionTypography variant="body2" paragraph>
            Enter the average numbers and weights of livestock classes present on the farm.
          </FormDescriptionTypography>
        </FormHeaderPaper>
      </Grid>

      <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
          <List >
            <ListSubheader sx={{ bgcolor: "background.paper" }}>
              <ScrollSyncPane>
                <>
                  <Button
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => { navigate('/data-entry/livestock/monthly-stock-tool'); }}>
                        Open livestock monthly stock totals tool
                    </Button>
                </>
              </ScrollSyncPane>
            </ListSubheader>
          </List>
        </Paper>
      </Grid>

      <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
          <List sx={{ mb: 2 }}>
            <ListSubheader sx={{ bgcolor: "background.paper" }}>
              <ScrollSyncPane>
                <>
                <DataEntryButtonStack
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  handleModalResponse={handleModalResponse}
                  handelSaveClick={handelSaveClick}
                  handleNextClick={handleNextClick}
                />
                <CustomizedDivider/>
                </>
              </ScrollSyncPane>
            </ListSubheader>
            {formData && sectors.map((sector, index) => (
              <>
                <React.Fragment key={index}>
                  <DataEntryTable
                    key={sector.id}
                    sector={sector}
                    initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                    initialOtherSalesData={otherSalesData[sector.id] ? otherSalesData[sector.id] : {}}
                    firstVisibleCellDetails={firstVisibleCellDetails}
                    locked={props.locked}
                  />
                </React.Fragment>
              </>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid >
    </ScrollSync>
  );
};
export default NumbersWeights;
