export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL'

export const GET_ALL_SECTORS = 'GET_ALL_SECTORS'
export const GET_ALL_SECTORS_SUCCESS = 'GET_ALL_SECTORS_SUCCESS'
export const GET_ALL_SECTORS_FAIL = 'GET_ALL_SECTORS_FAIL'

export const GET_TYPES_BY_SECTOR = 'GET_TYPES_BY_SECTOR'
export const GET_TYPES_BY_SECTOR_SUCCESS = 'GET_TYPES_BY_SECTOR_SUCCESS'
export const GET_TYPES_BY_SECTOR_FAIL = 'GET_TYPES_BY_SECTOR_FAIL'

export const GET_SYSTEMS_BY_SECTOR = 'GET_SYSTEMS_BY_SECTOR'
export const GET_SYSTEMS_BY_SECTOR_SUCCESS = 'GET_SYSTEMS_BY_SECTOR_SUCCESS'
export const GET_SYSTEMS_BY_SECTOR_FAIL = 'GET_SYSTEMS_BY_SECTOR_FAIL'

export const GET_SECTOR_ITEMS_BY_SECTOR = 'GET_SECTOR_ITEMS_BY_SECTOR'
export const GET_SECTOR_ITEMS_BY_SECTOR_SUCCESS = 'GET_SECTOR_ITEMS_BY_SECTOR_SUCCESS'
export const GET_SECTOR_ITEMS_BY_SECTOR_FAIL = 'GET_SECTOR_ITEMS_BY_SECTOR_FAIL'

export const CREATE_FARM_REPORT_CONFIG = 'CREATE_FARM_REPORT_CONFIG'

export const CREATE_TEMP_FARM_REPORT_SECTORS = 'CREATE_TEMP_FARM_REPORT_SECTORS'
export const ADD_NEW_FARM_REPORT_SECTOR = 'ADD_NEW_FARM_REPORT_SECTOR'
export const ADD_REMOVE_FARM_REPORT_SECTOR = 'ADD_REMOVE_FARM_REPORT_SECTOR'

export const ADD_FARM_REPORT_GROUP = 'ADD_FARM_REPORT_GROUP'
export const REMOVE_FARM_REPORT_GROUP = 'REMOVE_FARM_REPORT_GROUP'
export const EDIT_FARM_REPORT_GROUP = 'EDIT_FARM_REPORT_GROUP'

export const ADD_FARM_REPORT_SECTOR_ITEM = 'ADD_FARM_REPORT_SECTOR_ITEM'
export const REMOVE_FARM_REPORT_SECTOR_ITEM = 'REMOVE_FARM_REPORT_SECTOR_ITEM'

export const GET_FARM_REPORT_DETAILS_SUCCESS = 'GET_FARM_REPORT_DETAILS_SUCCESS'
export const GET_FARM_REPORT_DETAILS_FAIL = 'GET_FARM_REPORT_DETAILS_FAIL'

export const GET_FARM_REPORT_CONFIG_SUCCESS = 'GET_FARM_REPORT_CONFIG_SUCCESS'
export const GET_FARM_REPORT_CONFIG_FAIL = 'GET_FARM_REPORT_CONFIG_FAIL'

export const SUBMIT_FARM_REPORT_SUCCESS = 'SUBMIT_FARM_REPORT_SUCCESS'
export const SUBMIT_FARM_REPORT_FAIL = 'SUBMIT_FARM_REPORT_FAIL'

export const UPDATE_FARM_REPORT_SUCCESS = 'UPDATE_FARM_REPORT_SUCCESS'
export const UPDATE_FARM_REPORT_FAIL = 'UPDATE_FARM_REPORT_FAIL'

export const EDIT_FARM_REPORT_DETAILS = 'EDIT_FARM_REPORT_DETAILS'
export const RESET_FARM_REPORT_DETAILS = 'RESET_FARM_REPORT_DETAILS'

export const RESET_RESPONSE_STATUS = 'RESET_RESPONSE_STATUS'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

