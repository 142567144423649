import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getFeedRationMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.feedRation.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getFeedRationMetadataSuccess(response?.data));
  }
  else
    return dispatch(getFeedRationMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getFeedRationMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_FEED_RATION_METADATA_SUCCESS,
    payload: response
  };
};

const getFeedRationMetadataFail = () => {
  return {
    type: actionTypes.GET_FEED_RATION_METADATA_FAIL,
  };
};

export const resetFeedRationMetadata = () => {
  return {
    type: actionTypes.RESET_FEED_RATION_METADATA,
  };
};

export const getFeedRationFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.feedRation.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getFeedRationFormDataSuccess(response?.data?.data));
  }
  else
    return dispatch(getFeedRationFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getFeedRationFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_FEED_RATION_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getFeedRationFormDataFail = () => {
  return {
    type: actionTypes.GET_FEED_RATION_FORM_DATA_FAIL,
  };
};

export const setFeedRationFormData = (response) => {
  return {
    type: actionTypes.SET_FEED_RATION_FORM_DATA,
    payload: response
  };
};

export const resetFeedRationFormData = () => {
  return {
    type: actionTypes.RESET_FEED_RATION_ALL_DATA,
  };
};

export const saveFeedRationData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().feedRation
  const response = await api.post(endpoints.livestock.feedRation.saveData,
    {
      reportId: reportId,
      data: currentFormData,
    });
  if (response && response.status === 200) {
    return dispatch(saveFeedRationDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveFeedRationDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveFeedRationDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveFeedRationDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};


