import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getBeddingOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.livestock.bedding.lookup.options);
    if (response && response.status === 200) {
      return dispatch(getBeddingOptionsSuccess(response?.data));
    }
    else
      return dispatch(getBeddingOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getBeddingOptionsFail(e));
  }
};
const getBeddingOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_BEDDING_OPTIONS_SUCCESS,
    payload: response
  };
};
const getBeddingOptionsFail = (response) => {
  return {
    type: actionTypes.GET_BEDDING_OPTIONS_FAIL,
    payload: response
  };
};

export const getBeddingMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.bedding.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getBeddingMetadataSuccess(response?.data));
  }
  else
    return dispatch(getBeddingMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getBeddingMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_BEDDING_METADATA_SUCCESS,
    payload: response
  };
};

const getBeddingMetadataFail = () => {
  return {
    type: actionTypes.GET_BEDDING_METADATA_FAIL,
  };
};

export const resetBeddingMetadata = () => {
  return {
    type: actionTypes.RESET_BEDDING_METADATA,
  };
};

export const getBeddingFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.bedding.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getBeddingFormDataSuccess(response?.data?.data));
  }
  else
    return dispatch(getBeddingFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getBeddingFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_BEDDING_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getBeddingFormDataFail = () => {
  return {
    type: actionTypes.GET_BEDDING_FORM_DATA_FAIL,
  };
};

export const setBeddingFormData = (response) => {
  return {
    type: actionTypes.SET_BEDDING_FORM_DATA,
    payload: response
  };
};

export const saveBeddingData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().bedding
  const response = await api.post(endpoints.livestock.bedding.saveData,
    {
      reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveBeddingDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveBeddingDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveBeddingDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveBeddingDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};