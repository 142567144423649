import {
  Backdrop,
  Button, CircularProgress, Grid, List, ListSubheader,
  Paper, Skeleton, Stack, Table, TableBody, TableContainer, TableHead,
  Typography
} from '@mui/material';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import _ from 'lodash';
import { isEqual } from "lodash";
import {
  FormHeaderPaper, FormTitleTypography, FormTableCell,
  FormTableRow, SectorTitleTypography, TableListItem, FormRowGroupTableRow, DataEntryTextField, FormDescriptionTypography, CustomizedDivider
} from '../../../components/CustomStyles/StyledComponents';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import { useNavigate } from 'react-router-dom';
import produce from 'immer';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  resetResponseState,
  getCropProductionFormData,
  resetCropProductionMetaData,
  getCropProductionMetaData,
  setCropProductionFormData,
  saveCropProductionData,
  setLoading,
  unsetLoading
} from '../../../store/appAction';
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "16%",
  OpeningStock: "8%",
  PercentageCropRemoved: "8%",
  HarvestedDryMatter: "8%",
  HarvestedForageYield: "8%",
  TotalCropProduction: "8%",
  CropUseSold: "8%",
  HomeSavedForSeed: "8%",
  FedOrUsedForBedding: "8%",
  CropUseRemainingStock: "8%",
};

const OPENING_STOCK_KEY = 'OpeningStock';
const CROP_REMOVED_KEY = 'PercentageCropRemoved';
const HARVESTED_DRY_MATTER_KEY = 'HarvestedDryMatter';
const HARVESTED_FORAGE_YIELD_KEY = 'HarvestedOrForageYield';
const TOTAL_CROP_PRODUCTION_KEY = 'TotalCropProduction';
const CROP_USE_SOLD_KEY = 'CropUseSold';
const HOME_SAVED_SEED_KEY = 'HomeSavedForSeed';
const FED_OR_BEDDING_KEY = 'FedOrUsedForBedding';
const REMAINING_STOCK_KEY = 'CropUseRemainingStock';
const LAND_AREA_TOTAL_KEY = 'TotalLandArea';

const DataEntryTable = ({ sector, initialFormData, firstVisibleCellDetails, locked }) => {
  const MAX_CROP_USE = 'maxCropUse';
  toastr.options = toastrCustomOptions;

  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setCropProductionFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleInputChange = useCallback((e, groupId, rowId, rowData, mappingProperty, rule, landAreaTotal) => {
    let value = removeCommas(e.target.value);

    if (e.target.inputMode === 'numeric') {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        //const rule = _.find(RANGE_RULES, (rule) => { if (rule.columnIdx.includes(parseInt(colIdx))) return rule; });
        const maxCropUse =
          parseFloat(rowData[OPENING_STOCK_KEY] || 0) + parseFloat(rowData[TOTAL_CROP_PRODUCTION_KEY] || 0);
        //const max = rule.maxYield ? maxYield : rule.maxCropUse ? maxCropUse : rule.max
        let max = rule?.maxValidation === MAX_CROP_USE ? maxCropUse : rule.max ? rule.max : 100


        switch (mappingProperty) {
          case CROP_USE_SOLD_KEY:
          case HOME_SAVED_SEED_KEY:
          case FED_OR_BEDDING_KEY:
            max = parseFloat(rowData[OPENING_STOCK_KEY] || 0) + parseFloat(rowData[TOTAL_CROP_PRODUCTION_KEY] || 0)
            break
          default:
            break;
        }

        if ((rule.min > numericValue || max < numericValue)) {
          toastr.warning(`This input only accepts values between ${addCommas(rule.min)} and ${addCommas(max)}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        }
        else {
          const openingStock = OPENING_STOCK_KEY === mappingProperty ? numericValue : parseFloat(rowData[OPENING_STOCK_KEY] || 0);
          const harvestedOrForageYield = HARVESTED_FORAGE_YIELD_KEY === mappingProperty ? numericValue : parseFloat(rowData[HARVESTED_FORAGE_YIELD_KEY] || 0);
          const sold = CROP_USE_SOLD_KEY === mappingProperty ? numericValue : parseFloat(rowData[CROP_USE_SOLD_KEY] || 0);
          const homeSavedSeed = HOME_SAVED_SEED_KEY === mappingProperty ? numericValue : parseFloat(rowData[HOME_SAVED_SEED_KEY] || 0);
          const fedOrBedding = FED_OR_BEDDING_KEY === mappingProperty ? numericValue : parseFloat(rowData[FED_OR_BEDDING_KEY] || 0);

          const totalCropProduction = parseFloat(harvestedOrForageYield) * (landAreaTotal || 0);
          const remainingStock = parseFloat(openingStock) + (parseFloat(totalCropProduction) - (parseFloat(sold) + parseFloat(fedOrBedding) + parseFloat(homeSavedSeed)));

          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = value;
            draft[groupId][rowId][TOTAL_CROP_PRODUCTION_KEY] = totalCropProduction ? totalCropProduction.toFixed(2) : '';
            draft[groupId][rowId][REMAINING_STOCK_KEY] = remainingStock ? remainingStock.toFixed(2) : '';
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = parseFloat(value);
            draft[groupId][rowId][TOTAL_CROP_PRODUCTION_KEY] = totalCropProduction ? totalCropProduction : null;
            draft[groupId][rowId][REMAINING_STOCK_KEY] = remainingStock ? remainingStock : null;
          }));
        }
      }
    }
    else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRowTitle = (title, totalArea, byProduct) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    //if (byProduct) return title;
    return title.concat(
      ' (', addCommas(totalArea || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }

  const getCellContent = ({ groupId, rowId, cell, rowData, landAreaTotal, firstVisibleCellDetails, locked }) => {
    switch (cell.dataType) {
      case 'TextField':
        return (
          <DataEntryTextField
            key={rowId + cell.mappingProperty}
            size="small"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            style={{ width: 70, textAlign: 'center' }}
            disabled={[TOTAL_CROP_PRODUCTION_KEY, REMAINING_STOCK_KEY].includes(cell.mappingProperty)}
            value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            onChange={(e) => handleInputChange(
              e,
              groupId,
              rowId,
              rowData,
              cell.mappingProperty,
              cell.validations,
              landAreaTotal)}
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
          />
        );
      case 'Empty':
      default:
        return (<FormTableCell sx={{ width: '8%' }}>{''}</FormTableCell>);
    }
  }

  return (
    <>
      {sector ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
              <TableBody>
                {(sector?.sectorGroups?.groups || []).map((group) => (
                  <Fragment>
                    {group.rows.map((row) => (
                      <FormTableRow>
                        <FormTableCell sx={{ width: CELL_WIDTH["RowTitle"] }}>
                          {sectorDataInt &&
                            fetchRowTitle(row.title, sectorDataInt[group.id] &&
                              sectorDataInt[group.id][row.id][LAND_AREA_TOTAL_KEY],
                              row.byProduct)}
                        </FormTableCell>
                        {row.cells.map((cell) => (
                          <FormTableCell sx={{ width: /*CELL_WIDTH[cell.mappingProperty]*/'8%' }}>
                            {sectorDataInt && cell.visible &&
                              getCellContent({
                                groupId: group.id,
                                rowId: row.id,
                                cell: cell,
                                rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                                landAreaTotal: sectorDataInt[group.id] && sectorDataInt[group.id][row.id][LAND_AREA_TOTAL_KEY],
                                firstVisibleCellDetails: firstVisibleCellDetails,
                                locked: locked
                              })}
                          </FormTableCell>
                        ))}
                      </FormTableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />
      }
    </>
  );
}

const CropProduction = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.cropProduction.metaData);
  const initialFormData = useSelector(state => state.cropProduction.initialFormData);
  const
    { reportConfig: { landAndCropsOnly }
    } = useSelector(state => state.farmReport);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);

  const [formData, setFormData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const [colGroups, setColGroups] = useState([]);

  const sectors = metaData?.form?.sectors || [];
  const columns = metaData?.form?.columns || [];

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getCropProductionMetaData({ reportId }));
    dispatch(getCropProductionFormData({ reportId }));
    dispatch(resetResponseState());
    //eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetCropProductionMetaData());
    }
  }, []);

  useEffect(() => {
    let groupedList = _(columns ? columns : [])
      .groupBy("group")
      .map((items, Group) => ({
        title: Group,
        length: items.length,
      }))
      .value();
    setColGroups(groupedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData]);

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      dispatch(unsetLoading());
      setFormData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        if (landAndCropsOnly)
          navigate('/data-entry/land-crops/biochar');
        else
          navigate('/data-entry/land-crops/crop-use-livestock');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getCropProductionMetaData({ reportId }));
      dispatch(getCropProductionFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const cropProductionData = store.getState().cropProduction;
    const isUpdated = !isEqual(cropProductionData?.initialFormData, cropProductionData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveCropProductionData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      if (landAndCropsOnly)
        navigate('/data-entry/land-crops/biochar');
      else
        navigate('/data-entry/land-crops/crop-use-livestock');
    } else {
      dispatch(setLoading());
      dispatch(saveCropProductionData({ reportId }));
    };
  }

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <FormTitleTypography variant="h5" component="div">
              Crop production & use
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              To calculate product level emissions, enter data for crop stocks, yield, harvest, and sales.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              {colGroups ?
                <ListSubheader sx={{ bgcolor: "background.paper" }}>
                  <ScrollSyncPane>
                    <>
                    <DataEntryButtonStack
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      handleModalResponse={handleModalResponse}
                      handelSaveClick={handelSaveClick}
                      handleNextClick={handleNextClick}
                    />
                    <CustomizedDivider/>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                        <TableHead>
                          {/* <FormRowGroupTableRow>
                        <FormTableCell></FormTableCell>
                        {colGroups && colGroups.map((colGroup) =>
                          <FormTableCell
                            align="center"
                            colSpan={colGroup.length}
                            style={{ fontSize: '1rem' }}
                          >
                            {!colGroup.title.includes("Empty") && colGroup.title}
                          </FormTableCell>
                        )}
                      </FormRowGroupTableRow> */}
                          <FormRowGroupTableRow>
                            <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}></FormTableCell>
                            {
                              columns.map((column) =>
                                <FormTableCell align="center" sx={{ width:/*CELL_WIDTH[column.cssClass]*/ '8%' }}>
                                  {column.title}
                                </FormTableCell>)
                            }
                          </FormRowGroupTableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                    </>
                  </ScrollSyncPane>
                </ListSubheader>
                : <Skeleton variant="rectangular" colSpan={columns.length} width={'100%'} height={115} />
              }
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <DataEntryTable
                      key={sector.id}
                      sector={sector}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}
                    />
                  </TableListItem>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid >
    </ScrollSync>
  );
}

export default CropProduction;
