import queryString from 'query-string';
import * as actionTypes from './types';
import * as api from '../../api/base';
import { endpoints } from '../../api/endpoints';

export const getEnterpriseSectorList = (params) => async (dispatch) => {
  const response = await api.get(endpoints.enterprise.sectors.concat('?', queryString.stringify(params)));
  if (response && response.status === 200) {
    return dispatch(getEnterpriseSectorListSuccess(response?.data));
  } else
    return dispatch(getEnterpriseSectorListFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getEnterpriseSectorListSuccess = (response) => {
  return {
    type: actionTypes.GET_ENTERPRISE_SECTOR_LIST_SUCCESS,
    payload: response
  };
};

const getEnterpriseSectorListFail = () => {
  return {
    type: actionTypes.GET_ENTERPRISE_SECTOR_LIST_FAIL,
  };
};

export const getEnterpriseCompareToList = (params) => async (dispatch) => {
  const response = await api.get(endpoints.enterprise.compare.concat('?', queryString.stringify(params)));
  if (response && response.status === 200) {
    return dispatch(getEnterpriseCompareToListSuccess(response?.data));
  } else
    return dispatch(getEnterpriseCompareToListFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getEnterpriseCompareToListSuccess = (response) => {
  return {
    type: actionTypes.GET_ENTERPRISE_COMPARE_TO_LIST_SUCCESS,
    payload: response
  };
};

const getEnterpriseCompareToListFail = () => {
  return {
    type: actionTypes.GET_ENTERPRISE_COMPARE_TO_LIST_FAIL,
  };
};


export const getEnterpriseTable = (params) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.enterprise.tables.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
      return dispatch(getEnterpriseTableSuccess(response?.data));
    } else
      return dispatch(getEnterpriseTableFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (error) {
    return dispatch(getEnterpriseTableFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getEnterpriseTableSuccess = (response) => {
  return {
    type: actionTypes.GET_ENTERPRISE_TABLE_DATA_SUCCESS,
    payload: response
  };
};

const getEnterpriseTableFail = () => {
  return {
    type: actionTypes.GET_ENTERPRISE_TABLE_DATA_FAIL,
  };
};

export const getEnterpriseChart = (params) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.enterprise.charts.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
      return dispatch(getEnterpriseChartSuccess(response?.data));
    } else
      return dispatch(getEnterpriseChartFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (error) {
    return dispatch(getEnterpriseChartFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getEnterpriseChartSuccess = (response) => {
  return {
    type: actionTypes.GET_ENTERPRISE_CHART_DATA_SUCCESS,
    payload: response
  };
};

const getEnterpriseChartFail = () => {
  return {
    type: actionTypes.GET_ENTERPRISE_CHART_DATA_FAIL,
  };
};

export const resetEnterpriseDropDownValues = () => {
  return {
    type: actionTypes.RESET_ENTERPRISE_DROP_DOWN_DATA,
  };
};
