import {
    Grid, Paper, Table, TableBody, TableContainer,
    TableFooter, TableHead, TableRow,Typography
} from '@mui/material'
import React, { } from 'react'
import {
    ChartTitleTypography, FormDescriptionTypographyPDF,
    FormTitleTypographyPDF, ResultsTableCellPDF, ResultsTableRow,FormHeaderPaper,FormTitleTypography
} from '../../../../components/CustomStyles/StyledComponents';
import { addCommas } from '../../../../helpers/stringFormatHelper';
import { WHOLE_FARM_DETAILED_TABLES } from '../../../../constants/shellTables';

const Tables = ({ results = {} }) => {
    const DataTable = ({ tableId, tableData, rowData }) => {
        return <>
            {tableData &&
                <TableContainer component={Paper} sx={{
                    marginTop: '60px',
                }}>
                    <ChartTitleTypography sx={{ mb: 0 }}>{tableData.tableTitle}</ChartTitleTypography>
                    <Table id={tableId}>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCellPDF></ResultsTableCellPDF>
                                {tableData.columns.map((column) => (
                                    <ResultsTableCellPDF>
                                        {<div dangerouslySetInnerHTML={{__html: column}}/>}
                                    </ResultsTableCellPDF>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowData && Object.entries(rowData)
                                .slice(0, Object.entries(rowData).length - (tableData.noFooter ? 0 : 1))
                                .map(([rowId, row]) => (
                                    <ResultsTableRow>
                                        {Object.entries(row).map(([cellId, cell]) => (
                                            <ResultsTableCellPDF>
                                                {cell ? addCommas(cell) : '--'}
                                            </ResultsTableCellPDF>
                                        ))}
                                    </ResultsTableRow>
                                ))}
                        </TableBody>
                        {!tableData.noFooter &&
                            <TableFooter sx={{ mb: 1 }}>
                                {rowData && Object.entries(rowData)
                                    .slice(Object.entries(rowData).length - 1)
                                    .map(([rowId, row]) => (
                                        <ResultsTableRow>
                                            {Object.entries(row).map(([cellId, cell]) => (
                                                <ResultsTableCellPDF>
                                                    {cell ? addCommas(cell) : '--'}
                                                </ResultsTableCellPDF>
                                            ))}
                                        </ResultsTableRow>
                                    ))}
                            </TableFooter>}
                    </Table>
                </TableContainer>
            }
            {/* The following code snippet has been commented out due to AGC-1761 */}
            {/* {
                tableId === 'carbonSequestrationResults' &&
                <Grid item xs={12} style={{marginTop:'20px'}}>
                    <Typography variant="body2" paragraph>
                        *Soil carbon modelling has higher uncertainty than other carbon estimates in Agrecalc. 
                        Our soil carbon results now aim to reflect this uncertainty. 'Likely' statements indicate a high degree 
                        of confidence your soil is gaining/losing carbon. 'Potential' statements indicate greater uncertainty, 
                        and any loss/gain is likely to be smaller.
                    </Typography>
                </Grid>
            } */}
            {
                tableId === 'methaneResults' &&
                <Grid item xs={12} style={{marginTop:'20px'}}>
                    <Typography variant="body2" paragraph>
                        <sup>†</sup> <em>GWP</em> is an alternative way of reporting methane emissions. Methane breaks
                        down much faster than other greenhouse gasses but has a large upfront
                        warming impact. GWP* aims to capture this effect better than the standard
                        GWP100 metric. When measuring using GWP*, increased methane emissions
                        have a large impact, but steady or declining emissions have a much smaller
                        impact..
                    </Typography>
                </Grid>
            }
        </>
    }
    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                pageBreakAfter: 'always',
                marginTop: '70px',
                borderRadius: 0,
            }}
                p={2}
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '80px',
                            borderRadius: 0,
                        }}
                    >
                        <FormTitleTypographyPDF fontSize={20} variant="h6" component="div">
                            Whole Farm Emissions
                        </FormTitleTypographyPDF>
                        <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={1}>
                            {`A summary of emissions from carbon dioxide, methane and nitrous oxide for the whole 
                            farm and per enterprise is presented below. Total emissions are also expressed per unit of output, 
                            per hectare and per livestock unit equivalent to allow comparisons to be made. Per unit of output 
                            is the most common way to express emissions associated with the production of food products.`}
                        </FormDescriptionTypographyPDF>
                        <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={0}>
                            {`All emissions are expressed as kg CO₂-eq, unless otherwise indicated.`}
                        </FormDescriptionTypographyPDF>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction='column'>
                        {Object.entries(WHOLE_FARM_DETAILED_TABLES).map(([tableKey, tableData]) => (
                            <Grid item xs={12} sx={{
                                pageBreakInside: 'avoid'
                            }}>
                                <DataTable
                                    tableId={tableKey}
                                    tableData={tableData}
                                    rowData={results[tableKey]} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Tables