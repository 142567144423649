import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getFeedCategoryOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.livestock.feed.lookup.category);
    if (response && response.status === 200) {
      return dispatch(getFeedCategoryOptionsSuccess(response?.data));
    }
    else
      return dispatch(getFeedCategoryOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getFeedCategoryOptionsFail(e));
  }
};
const getFeedCategoryOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_FEED_CATEGORY_OPTIONS_SUCCESS,
    payload: response
  };
};
const getFeedCategoryOptionsFail = (response) => {
  return {
    type: actionTypes.GET_FEED_CATEGORY_OPTIONS_FAIL,
    payload: response
  };
};

export const getFeedOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.livestock.feed.lookup.options);
    if (response && response.status === 200) {
      return dispatch(getFeedOptionsSuccess(response?.data));
    }
    else
      return dispatch(getFeedOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getFeedOptionsFail(e));
  }
};
const getFeedOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_FEED_OPTIONS_SUCCESS,
    payload: response
  };
};
const getFeedOptionsFail = (response) => {
  return {
    type: actionTypes.GET_FEED_OPTIONS_FAIL,
    payload: response
  };
};

export const getFeedMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.feed.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getFeedMetadataSuccess(response?.data));
  }
  else
    return dispatch(getFeedMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getFeedMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_FEED_METADATA_SUCCESS,
    payload: response
  };
};

const getFeedMetadataFail = () => {
  return {
    type: actionTypes.GET_FEED_METADATA_FAIL,
  };
};

export const resetFeedMetadata = () => {
  return {
    type: actionTypes.RESET_FEED_METADATA,
  };
};

export const getFeedFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.feed.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getFeedFormDataSuccess(response?.data?.data));
  }
  else
    return dispatch(getFeedFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getFeedFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_FEED_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getFeedFormDataFail = () => {
  return {
    type: actionTypes.GET_FEED_FORM_DATA_FAIL,
  };
};

export const setFeedFormData = (response) => {
  return {
    type: actionTypes.SET_FEED_FORM_DATA,
    payload: response
  };
};

export const resetFeedFormData = () => {
  return {
    type: actionTypes.RESET_FEED_ALL_DATA,
  };
};

export const saveFeedData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().feed
  const response = await api.post(endpoints.livestock.feed.saveData,
    {
      reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveFeedDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveFeedDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveFeedDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveFeedDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};


