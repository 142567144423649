import {
    Container,
    Grid,
    Paper,
    ThemeProvider,
    Typography,
} from '@mui/material'
import React from 'react'
import { SectorTitleTypography, FormHeaderPaper, FormTitleTypography, PrimaryListItemTypography, SecondaryListItemTypography } from '../../components/CustomStyles/StyledComponents';
import Footer from '../../layout/BottomNavigation';
import { mainTheme } from '../../theme';

const ReleaseNotes = () => {

    return (
        <ThemeProvider theme={mainTheme}>
            <Container maxWidth={'lg'} sx={{ margin: '0 auto', p: '50px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormTitleTypography variant="h5" component="div">
                                Release Notes
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                {'We are constantly updating the Agrecalc platform for our customers.  Please see our new releases and features below.'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <SectorTitleTypography sx={{ mb: 2 }}>
                                Agrecalc Cloud (June 2023)
                            </SectorTitleTypography>
                            <PrimaryListItemTypography variant="body2" sx={{}} mb={2} ml={1}>
                                {'Based on modern new cloud technologies, Agrecalc Cloud is our platform based on the latest  scientific research.'}
                            </PrimaryListItemTypography>
                            <Paper sx={{ pl: 3, mb: 2 }}>
                                <PrimaryListItemTypography variant="body2" sx={{ display: 'list-item', }} fontWeight="500" mb={1} ml={0}>
                                    {'New features;'}
                                </PrimaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'An improved user interface – simpler and easier to use based on experience and feedback gained from over 10,000 users to date'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'The ability to run more than one livestock herd or flock per farm'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'The ability to apply multiple different organic materials and manures to each crop'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'The addition of Anaerobic Digestion as a manure management option'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'A much wider list of crops, fertilisers and feeds from which to select'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'A number of new GHG mitigation options including;'}
                                </SecondaryListItemTypography>
                                <PrimaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={2}>
                                    {'Nitrification / urease inhibitors and protected fertilisers'}
                                </PrimaryListItemTypography>
                                <PrimaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={2}>
                                    {'Methane inhibitors (3NOP “Bovaer”)'}
                                </PrimaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'An integrated system for transferring manures from livestock to specific crops saving time'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'Enhanced pig input screens – facilitating the effective handling of multiple batches of animals per year saving time'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'Tool-tips and additional Help Text'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'Support Portal'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'API to integrate to third party applications'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'Greater auto population of fields , increasing accuracy and saving time'}
                                </SecondaryListItemTypography>
                                <PrimaryListItemTypography variant="body2" sx={{ display: 'list-item', }} fontWeight="500" mb={1} mt={3} ml={0}>
                                    {'Technology Platform'}
                                </PrimaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'Built on Microsoft Azure'}
                                </SecondaryListItemTypography>
                                <SecondaryListItemTypography variant="body2" sx={{ display: 'list-item', }} mb={1} ml={1}>
                                    {'Preferred browser is Google Chrome'}
                                </SecondaryListItemTypography>
                            </Paper>
                        </FormHeaderPaper>
                    </Grid>
                </Grid >
            </Container>
            <Footer fixed={true} />
        </ThemeProvider >
    )
}

export default ReleaseNotes