export const GET_BEDDING_OPTIONS_SUCCESS = 'GET_BEDDING_OPTIONS_SUCCESS'
export const GET_BEDDING_OPTIONS_FAIL = 'GET_BEDDING_OPTIONS_FAIL'

export const GET_BEDDING_METADATA = 'GET_BEDDING_METADATA'
export const GET_BEDDING_METADATA_SUCCESS = 'GET_BEDDING_METADATA_SUCCESS'
export const GET_BEDDING_METADATA_FAIL = 'GET_BEDDING_METADATA_FAIL'
export const RESET_BEDDING_METADATA= 'RESET_BEDDING_METADATA'

export const GET_BEDDING_FORM_DATA = 'GET_BEDDING_FORM_DATA'
export const GET_BEDDING_FORM_DATA_SUCCESS = 'GET_BEDDING_FORM_DATA_SUCCESS'
export const GET_BEDDING_FORM_DATA_FAIL = 'GET_BEDDING_FORM_DATA_FAIL'

export const SET_BEDDING_FORM_DATA = 'SET_BEDDING_FORM_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'