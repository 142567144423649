export const GET_ENTERPRISE_SECTOR_LIST_SUCCESS = 'GET_ENTERPRISE_SECTOR_LIST_SUCCESS'
export const GET_ENTERPRISE_SECTOR_LIST_FAIL = 'GET_ENTERPRISE_SECTOR_LIST_FAIL'

export const GET_ENTERPRISE_COMPARE_TO_LIST_SUCCESS = 'GET_ENTERPRISE_COMPARE_TO_LIST_SUCCESS'
export const GET_ENTERPRISE_COMPARE_TO_LIST_FAIL = 'GET_ENTERPRISE_COMPARE_TO_LIST_FAIL'

export const GET_ENTERPRISE_TABLE_DATA_SUCCESS = 'GET_ENTERPRISE_TABLE_DATA_SUCCESS'
export const GET_ENTERPRISE_TABLE_DATA_FAIL = 'GET_ENTERPRISE_TABLE_DATA_FAIL'

export const GET_ENTERPRISE_CHART_DATA_SUCCESS = 'GET_ENTERPRISE_CHART_DATA_SUCCESS'
export const GET_ENTERPRISE_CHART_DATA_FAIL = 'GET_ENTERPRISE_CHART_DATA_FAIL'

export const RESET_ENTERPRISE_DATA = 'RESET_ENTERPRISE_DATA'
export const RESET_ENTERPRISE_DROP_DOWN_DATA = 'RESET_ENTERPRISE_DROP_DOWN_DATA'




