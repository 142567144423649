import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const setLandAreaCropsColumnTotal = (total) => {
  return {
    type: actionTypes.LAND_AREA_CROPS_SET_COL_TOTAL,
    payload: total
  };
};

export const setLandAreaCropsTotalArea = (tableId, rowId, areaValue) => {
  return {
    type: actionTypes.LAND_AREA_CROPS_SET_TOTAL_AREA,
    payload: { tableId, rowId, areaValue }
  };
};

export const resetLandAreaCropsColumnTotal = () => {
  return {
    type: actionTypes.LAND_AREA_CROPS_RESET_COL_TOTAL,
  };
};

export const getLandAreaCropsColumnTotal = () => {
  const columnTotal = store.getState().landAreaCrops.columnTotal
  return columnTotal;
}

export const getLandAreaCropsOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.landAreaCrops.lookup.options);
    if (response && response.status === 200) {
      return dispatch(getLandAreaCropsOptionsSuccess(response?.data));
    }
    else
      return dispatch(getLandAreaCropsOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getLandAreaCropsOptionsFail(e));
  }
};
const getLandAreaCropsOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_LAND_AREA_CROPS_OPTIONS_SUCCESS,
    payload: response
  };
};
const getLandAreaCropsOptionsFail = (response) => {
  return {
    type: actionTypes.GET_LAND_AREA_CROPS_OPTIONS_FAIL,
    payload: response
  };
};

export const getLandAreaCropsMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.landAreaCrops.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getLandAreaCropsMetadataSuccess(response?.data));
  }
  else
    return dispatch(getLandAreaCropsMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getLandAreaCropsMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_LAND_AREA_CROPS_METADATA_SUCCESS,
    payload: response
  };
};

const getLandAreaCropsMetadataFail = () => {
  return {
    type: actionTypes.GET_LAND_AREA_CROPS_METADATA_FAIL,
  };
};

export const resetLandAreaCropsMetaData = () => {
  return {
    type: actionTypes.RESET_LAND_AREA_CROPS_METADATA,
  }
}

export const getLandAreaCropsFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.landAreaCrops.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    await dispatch(getLandAreaCropsFormDataSuccess(response?.data?.data));
    await dispatch(getLandAreaCropsMiscDataSuccess(response?.data?.reportData));
    return dispatch(setLandAreaCropsColumnTotal(response?.data?.wholeFarmData));
  }
  else
    return dispatch(getLandAreaCropsFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getLandAreaCropsFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_LAND_AREA_CROPS_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getLandAreaCropsMiscDataSuccess = (response) => {
  return {
    type: actionTypes.GET_LAND_AREA_CROPS_MISC_DATA_SUCCESS,
    payload: response
  };
};

const getLandAreaCropsFormDataFail = () => {
  return {
    type: actionTypes.GET_LAND_AREA_CROPS_FORM_DATA_FAIL,
  };
};

export const setLandAreaCropsFormData = (response) => {
  return {
    type: actionTypes.SET_LAND_AREA_CROPS_FORM_DATA,
    payload: response
  };
};

export const setLandAreaCropsMiscData = (response) => {
  return {
    type: actionTypes.SET_LAND_AREA_CROPS_MISC_DATA,
    payload: response
  };
};

export const saveLandAreaCropsData = ({ reportId }) => async (dispatch) => {
  const data = store.getState().landAreaCrops;
  const { currentFormData, columnTotal, currentReportData } = store.getState().landAreaCrops
  const response = await api.post(endpoints.landCrops.landAreaCrops.saveData,
    {
      reportId,
      data: currentFormData,
      wholeFarmData: columnTotal,
      reportData: currentReportData
    });
  if (response && response.status === 200) {
    return dispatch(saveLandAreaCropsDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveLandAreaCropsDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveLandAreaCropsDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveLandAreaCropsDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};


