import queryString from 'query-string';
import * as actionTypes from './types';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import store from '../..';

export const getAllAdminAccounts = (params) => async (dispatch) => {
    try {
        const response = await api.get(endpoints.admin.user.accounts.concat('?', queryString.stringify(params)));
        if (response && response.status === 200) {
            return dispatch(getAllAdminAccountSuccess(response?.data));
        }
        else
            return dispatch(getAllAdminAccountFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    } catch (e) {
        return dispatch(getAllAdminAccountFail(e.message));
    }
};

const getAllAdminAccountSuccess = (response) => {
    return {
        type: actionTypes.GET_ALL_ADMIN_ACCOUNT_DATA_SUCCESS,
        payload: response
    };
};

const getAllAdminAccountFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const getAdminAccountData = (accountId) => async (dispatch) => {
    const response = await api.get(endpoints.admin.user.account.concat(accountId));
    if (response && response.status === 200) {
        return dispatch(getAdminAccountDataSuccess(response.data));
    }
    else {
        return dispatch(getAdminAccountDataFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const getAdminAccountDataSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_ACCOUNT_DATA_SUCCESS,
        payload: response
    };
};

const getAdminAccountDataFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const setSelectedUserId = (userId) => {
    return {
        type: actionTypes.SET_ADMIN_ACCOUNT_USER_ID,
        payload: userId
    };
};

export const createAdminAccount = (data) => async (dispatch) => {
    const {
        id,
        type,
        email,
        firstName,
        lastName,
        organisationId,
        officeId,
        license,
        active,
        newsletter,
        agreement,
    } = data;
    try {
        const response = await api.post(endpoints.admin.user.create,
            {
                // name: firstName,
                // surname: lastName,
                email: email,
                isActive: active,
                lockoutEnabled: true,
                firstName: firstName,
                lastName: lastName,
                roleNames: [type],
                agreedToTandS: agreement,
                licenseId: license,
                officeId: officeId || null,
                optInNewsletter: newsletter,
                organisationId: organisationId || null
            });
        if (response && response.status === 200) {
            return dispatch(createAdminAccountSuccess("User has been created successfully"));
        }
        else {
            return dispatch(createAdminAccountFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(createAdminAccountFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const createAdminAccountSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const createAdminAccountFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const updateAdminAccount = (data) => async (dispatch) => {
    const {
        id,
        type,
        email,
        firstName,
        lastName,
        organisationId,
        officeId,
        license,
        active,
        newsletter,
        agreement,
    } = data;
    try {
        const response = await api.put(endpoints.admin.user.account.concat(id),
            {
                // name: firstName,
                // surname: lastName,
                email: email,
                isActive: active,
                lockoutEnabled: true,
                firstName: firstName,
                lastName: lastName,
                roleNames: [type],
                agreedToTandS: agreement,
                licenseId: license,
                officeId: officeId || null,
                optInNewsletter: newsletter,
                organisationId: organisationId || null,

            });
        if (response && response.status === 200) {
            return dispatch(updateAdminAccountSuccess("User has been updated successfully"));
        }
        else {
            return dispatch(updateAdminAccountFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateAdminAccountFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const updateAdminAccountSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const updateAdminAccountFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};


export const deleteAdminAccount = (data) => async (dispatch) => {
    const { selectedUserId } = store.getState().adminAccount;
    const response = await api.del(endpoints.admin.user.account.concat(selectedUserId));
    if (response && (response.status === 200 || response.status === 204)) {
        return dispatch(deleteAdminAccountSuccess(response.data || "Successfully deleted the user"));
    }
    else {
        return dispatch(deleteAdminAccountFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const deleteAdminAccountSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const deleteAdminAccountFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response

    };
};