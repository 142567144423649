import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import { v4 as uuidv4 } from 'uuid';
import {
  Button, FormControl,
  Grid, MenuItem, Paper,
  List, ListSubheader
} from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormRowGroupTableRow, FormTableRow,
  FormTableCell, SectorTitleTypography, DataEntryTextField,
  FormHeaderPaper, DataEntrySelect, FormDescriptionTypography,
  CustomizedDivider
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from "lodash";
import { isEqual } from "lodash";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import {
  getBeddingFormData, getBeddingMetadata, resetBeddingMetadata, getBeddingOptions,
  resetResponseState, saveBeddingData, setBeddingFormData, setLoading, unsetLoading
} from "../../../store/appAction";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import store from '../../../store';

const CELL_WIDTH = {
  Bedding: '30%',
  TotalTonnes: '12%'
}

const DataEntryTable = ({ sector, initialFormData, beddingOptions, isMounted, firstVisibleCellDetails,locked }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const sectorColumns = sector.columns;
  const [sectorDataInt, setSectorDataInt] = useState({});
  const [sectorDataExt, setSectorDataExt] = useState({});

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setBeddingFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleInputChange = useCallback((e, groupId, subRowIdx, mappingProperty, cell) => {
    let value = e.target.value;

    if (e.target.inputMode === "numeric") {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        const rule = cell.validations;
        if (rule.min > numericValue || rule.max < numericValue) {
          toastr.warning(
            `This input only accepts values between ${rule.min} and ${rule.max}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            draft[groupId][subRowIdx].value[mappingProperty] = value || null;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][subRowIdx].value[mappingProperty] = parseFloat(value || 0);
          }));
        }
      }
    } else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][subRowIdx].value[mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][subRowIdx].value[mappingProperty] = value || null;
      }));
    }
  }, []);

  const addNewRow = (groupId, subRowValues) => {
    let newId = uuidv4();
    let map = Object.keys(subRowValues).reduce((accumulator, mappingProperty) => {
      return { ...accumulator, [mappingProperty]: null };
    }, {});
    setSectorDataInt(produce((draft) => {
      draft[groupId].push({
        added: true,
        recordId: newId,
        value: map
      });
    }));
    setSectorDataExt(produce((draft) => {
      draft[groupId].push({
        added: true,
        recordId: newId,
        value: map
      });
    }));
  }

  const deleteNewRow = (groupId, subRowIdx) => {
    setSectorDataInt(produce((draft) => {
      let rowObj = draft[groupId][subRowIdx];
      if (rowObj.added)
        draft[groupId].splice(subRowIdx, 1);
      else
        rowObj.deleted = true;
    }));
    setSectorDataExt(produce((draft) => {
      let rowObj = draft[groupId][subRowIdx];
      if (rowObj.added)
        draft[groupId].splice(subRowIdx, 1);
      else
        rowObj.deleted = true;
    }));
  }

  const fetchRowTitle = (groupId) => {
    const groups = sector?.sectorGroups?.groups;
    const group = groups ? _.find(groups, { id: groupId }) : {};
    return group.title;
  }

  const fetchRowCells = (groupId) => {
    const groups = sector.sectorGroups.groups;
    const group = _.find(groups, { id: groupId });
    const cells = group?.rows[0]?.cells || [];
    return cells;
  }

  const getCellContent = (groupId, rowId, subRowIdx, cell, cellValue, locked) => {
    switch (cell.dataType) {
      case "Select":
        return (
          <FormTableCell sx={{
            width: CELL_WIDTH[cell.variant]
          }}>
            <FormControl sx={{ width: '90%', margin: 0 }}>
              <DataEntrySelect
                value={cellValue || ''}
                displayEmpty
                onChange={(e) => handleInputChange(e, groupId, subRowIdx, cell.mappingProperty, cell)}
                autoFocus={ locked === false && groupId === firstVisibleCellDetails.groupId &&  rowId === firstVisibleCellDetails.rowId && subRowIdx === 0 }
              >
                <MenuItem value="">
                  <em>Please Select</em>
                </MenuItem>
                {beddingOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </DataEntrySelect>
            </FormControl>
          </FormTableCell>
        );
      case "TextField":
        return (
          <FormTableCell sx={{
            width: '8%'
          }}>
            <DataEntryTextField
              size="small"
              variant="outlined"
              inputProps={{ inputMode: "numeric" }}
              style={{ width: 75, textAlign: "center" }}
              value={cellValue || ''}
              onChange={(e) => handleInputChange(e, groupId, subRowIdx, cell.mappingProperty, cell)}
              autoFocus={ locked === false && groupId === firstVisibleCellDetails.groupId &&  rowId === firstVisibleCellDetails.rowId && subRowIdx === 0 }
            />
          </FormTableCell>
        );
      default:
        break;
    }
  };

  return (
    <>
      <SectorTitleTypography sx={{ mb: 2 }}>
        {sector.title}
      </SectorTitleTypography>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table
          sx={{ minWidth: 700 }}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <FormTableRow>
              <FormTableCell></FormTableCell>
              {sectorColumns && sectorColumns.map((column) => (
                <FormTableCell sx={{ width: CELL_WIDTH[column.cssClass] }}>
                  {column.title}
                </FormTableCell>
              ))}
              <FormTableCell sx={{ width: "8%" }}>Add</FormTableCell>
              <FormTableCell sx={{ width: "8%" }}>Delete</FormTableCell>
            </FormTableRow>
          </TableHead>
          <TableBody>
            {Object.entries(sectorDataInt || {}).map(([groupId, allSubRows]) => {
              const subRows = allSubRows.filter((subRow) => !subRow.deleted);
              let lastElementOfActiveRowData = subRows[subRows.length - 1];
              return (
                <Fragment>
                  <FormRowGroupTableRow>
                    <FormTableCell sx={{ width: "20%" }} rowSpan={subRows.length + 1} >
                      {fetchRowTitle(groupId)}
                    </FormTableCell>
                  </FormRowGroupTableRow>
                  {allSubRows.map((subRow, subRowIdx) => {
                    let subRowValues = subRow.value;
                    var metaCells = fetchRowCells(groupId);
                    return (
                      !subRow.deleted &&
                      <FormRowGroupTableRow>
                        {(metaCells || []).map((cell) => (
                          getCellContent(groupId, cell.id, subRowIdx, cell, subRowValues[cell.mappingProperty], locked)
                        ))}
                        <FormTableCell sx={{ width: '8%' }}>
                          {isMounted && lastElementOfActiveRowData.recordId === subRow.recordId &&
                            !Object.values(subRowValues).includes(null) &&
                            <Button
                              style={{ padding: '3px 8px' }}
                              onClick={() => addNewRow(groupId, subRowValues)}>
                              <AddIcon />
                            </Button>
                          }
                        </FormTableCell>
                        <FormTableCell sx={{ width: '8%' }}>
                          {(isMounted && subRows.length > 1) &&
                            <Button
                              style={{ padding: '3px 8px' }}
                              onClick={() => deleteNewRow(groupId, subRowIdx)}>
                              <DeleteIcon />
                            </Button>
                          }
                        </FormTableCell>
                      </FormRowGroupTableRow>
                    );
                  })}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const Bedding = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const beddingOptions = useSelector(state => state.bedding.beddingOptions);
  const metaData = useSelector(state => state.bedding.metaData);
  const initialFormData = useSelector(state => state.bedding.initialFormData);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const [isMounted, setIsMounted] = useState(false);

  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
    window.pageYOffset = 0;
    dispatch(setLoading());
    dispatch(getBeddingOptions());
    dispatch(getBeddingMetadata({ reportId }));
    dispatch(getBeddingFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetBeddingMetadata());
    }
  }, []);

  useEffect(() => {
    setTimeout(function() { setIsMounted(true); dispatch(unsetLoading());}, 3000);
    return () => setIsMounted(false)
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(getBeddingFormData({ reportId }));
      setTimeout(() => {
        dispatch(unsetLoading());
    }, 2000);
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/livestock/feed-ration');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getBeddingMetadata({ reportId }));
      dispatch(getBeddingFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const beddingData = store.getState().bedding;
    const isUpdated = !isEqual(beddingData?.initialFormData, beddingData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveBeddingData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated())
      navigate('/data-entry/livestock/feed-ration');
    else {
      dispatch(setLoading());
      dispatch(saveBeddingData({ reportId }));
    };
  }

  return (
    <ScrollSync>
    <Grid container spacing={2} className={props.locked && 'deactivated'}>
      <Grid item xs={12} md={12} lg={12}>
        <FormHeaderPaper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
          divider={false}
        >
          <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
          <FormTitleTypography variant="h5" component="div">
            Bedding
          </FormTitleTypography>
          <FormDescriptionTypography variant="body2" paragraph>
            To calculate embedded emissions, enter the quantities and types of all imported bedding.
          </FormDescriptionTypography>
        </FormHeaderPaper>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
          <List sx={{ mb: 2 }}>
            <ListSubheader sx={{ bgcolor: "background.paper" }}>
              <ScrollSyncPane>
                <>
                <DataEntryButtonStack
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  handleModalResponse={handleModalResponse}
                  handelSaveClick={handelSaveClick}
                  handleNextClick={handleNextClick}
                />
                <CustomizedDivider/>
                </>
              </ScrollSyncPane>
            </ListSubheader>
            {Object.values(sectors).map((sector, index) => (
              <React.Fragment key={index}>
                <DataEntryTable
                  key={sector.id}
                  beddingOptions={beddingOptions}
                  sector={sector}
                  initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                  isMounted={isMounted}
                  firstVisibleCellDetails={firstVisibleCellDetails}
                  locked={props.locked}
                />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
    </ScrollSync>
  );
};

export default Bedding;
