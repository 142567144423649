import { updateObject } from '../../utility';
import * as actionTypes from './types';

const initialState = {
  offices: undefined,
  selectedOffice: {},
};

const officesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_ADMIN_OFFICE: return setOffice(state, action);
    case actionTypes.GET_ADMIN_OFFICE_LIST_SUCCESS: return setOfficesSuccess(state, action);
    case actionTypes.GET_ADMIN_OFFICE_LIST_FAIL: return setOfficesFail(state, action);
    default:
      return state;
  }
};

const setOffice = (state, action) => {
  const updatedState = {
    selectedOffice: action.payload,
  }
  return updateObject(state, updatedState);
}

const setOfficesSuccess = (state, action) => {
  const updatedState = {
    offices: action.payload,
  }
  return updateObject(state, updatedState);
}

const setOfficesFail = (state, action) => {
  const updatedState = {
    offices: [],
  }
  return updateObject(state, updatedState);
}

export default officesReducer;
