import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine
} from "recharts";
import { styled } from '@mui/material/styles';
import { Typography } from "@mui/material";

const data = [
    {
        name: "Page A",
        uv: 0,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Page B",
        uv: 300,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Page C",
        uv: 70,
        pv: 7800,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 60,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Page F",
        uv: 390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Page G",
        uv: 60,
        pv: 3004,
        amt: 2100
    }
];
export default function StackedVerticalBarChart() {

    const StyledTypography = styled(Typography)(({ theme }) => ({
        color: theme.palette.primary.dark
    }));
    return (
        <>
            <StyledTypography variant="h5" gutterBottom component="div">
                Stacked Vertical Bar Chart
            </StyledTypography>
            <ResponsiveContainer
                width="95%"
                height={200}>
                <BarChart
                    data={data}
                    stackOffset="sign"
                    layout="vertical"
                    margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5
                    }}
                    barSize={10}
                >
                    {/* <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} /> */}
                    <XAxis type="number" />
                    <YAxis dataKey="name" type='category' width={80} />
                    <Tooltip />
                    <Legend />
                    <ReferenceLine x={0} stroke="#000" />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="pv" fill="#8884d8" />
                    <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}
