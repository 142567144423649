import { Button, Card, CardActionArea, CardContent, FormControl, Grid, MenuItem, Paper,  Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import toastr from "toastr";
import { FarmDetailsSelect, FormDescriptionTypography, FormHeaderPaper, FormTitleTypography, InfoTooltip } from '../../../components/CustomStyles/StyledComponents';
import DataChecksErrors from '../../../components/Errors/DataChecksErrors';
import CircularPieChart from '../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../components/Charts/ColoredPieChart';
import VerticalBarChart from '../../../components/Charts/VerticalBarChart';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { getEnterpriseCompareToList, getWholeFarmChart, setLoading, setReportId, unsetLoading,getAllReports } from '../../../store/appAction';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import CreatePDFButton from '../../../components/Buttons/CreatePDFButton';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import { toastrCustomOptionsNoFarmSelectPopUp } from "../../../constants/toastrOptions";
import { ADMIN, FARMER,CONSULTANT } from '../../../constants/userRoles';
import { useLocation } from 'react-router-dom';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';

const WholeFarmCharts = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    let navigate = useNavigate();

    const chartData = useSelector(state => state.wholeFarm.chartData);
    const reportId = useSelector(state => state.common.reportId);
    const reportList = useSelector(state => state.farm.reports);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);
    const user = useSelector(state => state.auth.user);
    const selectedReportId = useSelector(state => state.common.reportId);
    const selectedFarmId = useSelector(state => state.adminFarm.farmDetails.id);
    const [results, setResults] = useState({});
    const [selectedFarmName, setSelectedFarmName] = useState(null);

    useEffect(() => {
        dispatch(getAllReports({ farmId }));
    }, []);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getWholeFarmChart({ reportId }));
        dispatch(getEnterpriseCompareToList({ reportId }));
        if(user.role === FARMER){
            getFarmNameForFarmer(farmId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        setResults(chartData || {});
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    const getFarmNameForFarmer = async (farmId) => {
        if(farmId){
          const response = await api.get(endpoints.admin.farm.farm.concat('?farmId=', farmId));
          if (response && response.status === 200) {
            setSelectedFarmName(response.data.farmName);
          }
        }
    }

    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }

    return (
        <>
            {results?.status === 1 ?
                <DataChecksErrors errorList={results.dataChecks} /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >

                            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName || selectedFarmName}/>
                            <FormTitleTypography variant="h5" component="div">
                                Resource use & Emissions Results
                            </FormTitleTypography>
                            <FormDescriptionTypography variant="body2" paragraph>
                                {'Emissions by enterprise, source and gas for the whole farm are presented below. To see a more detailed tabular view select Whole Farm - Tables in the menu above.'}
                            </FormDescriptionTypography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Grid container direction="row">
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '350px' }}
                                >
                                    <b style={{ fontSize: '1.15rem' }}>Report</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={reportId || ''}
                                        onChange={handleReportSelect}
                                        autoFocus={true}
                                    >
                                        <MenuItem value=""><em>Change Report...</em></MenuItem>
                                        {reportList.map((option) => (
                                            <MenuItem
                                                id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                                <InfoTooltip
                                    placement="right"
                                    title="Tip: If you would like to view a different report please select from this drop-down">
                                    <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginBottom: '5px', verticalAlign: 'bottom' }} fontSize='small' />
                                </InfoTooltip>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12} sx={{ textAlign: 'right' }} >
                                <CreatePDFButton referrer={'whole-farm'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={12} sm={6} md={3} >
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Total Emissions:
                                            </Typography>
                                            {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {addCommas(results.totalEmissions)}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} >
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Highest Enterprise:
                                            </Typography>
                                            {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {results.highestCarbonEnterprise}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} >
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Largest Source:
                                            </Typography>
                                            {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {results.largestSource}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} >
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Highest Gas Emission:
                                            </Typography>
                                            {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {results.highestGasEmission}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mb={5}>
                        {results && <Grid container direction='row' spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <Paper variant='outlined'>
                                    <ColoredPieChart
                                        key={1}
                                        title='Emissions by Enterprise'
                                        dataset={results.emissionByEnterprise}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <Paper variant='outlined'>
                                    <VerticalBarChart
                                        key={2}
                                        title='Emissions by Source'
                                        dataset={results.emissionBySource}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <Paper variant='outlined'>
                                    <CircularPieChart
                                        key={3}
                                        title='Emissions by Gas'
                                        dataset={results.emissionByGas}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid >
            }
        </>
    )
}

export default WholeFarmCharts