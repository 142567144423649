import { Backdrop, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import toastr from "toastr";
import _ from 'lodash';
import { ThemeProvider } from '@emotion/react';
import { FormHeaderPaper, FormTitleTypography } from '../../components/CustomStyles/StyledComponents';
import {
    resetResponseState, setLoading, unsetLoading, getMyAccount, updateMyAccount, getOrganisationData, getUserRoles, resetMyAccount
} from '../../store/appAction';
import AccountForm from '../../components/Forms/AccountForm';
import { mainTheme } from '../../theme';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { SELF_EDIT } from '../../constants/modes';

const MyAccount = () => {
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const accountDetails = useSelector(state => state.profile.account);
    const accountId = useSelector(state => state.auth.user.accountId);
    const { successMsg, errorMsg } = useSelector(state => state.common);

    const [intAccountDetails, setIntAccountDetails] = useState(undefined);
    const [formData, setFormData] = useState(undefined);

    const validationSchema = Yup.object({
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        firstName: Yup.string("Enter first name").required("First name is required"),
        lastName: Yup.string("Enter last name").required("Last name is required"),
        // office: Yup.string("").required("Office is required"),
        // organisation: Yup.string("").required("Organisation is required"),
    });

    useEffect(() => {
        dispatch(getUserRoles());
        dispatch(getMyAccount(accountId));
        // dispatch(getOrganisationData());
        return () => {
            dispatch(resetMyAccount());
          }
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                dispatch(getMyAccount(accountId));
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);


    useEffect(() => {

        setFormData({
            id: intAccountDetails?.id,
            type: intAccountDetails?.roleNames?.[0] || '',
            email: intAccountDetails?.email || '',
            firstName: intAccountDetails?.firstName || '',
            lastName: intAccountDetails?.lastName || '',
            organisation: intAccountDetails?.organisation || '',
            organisationId: intAccountDetails?.organisationId || '',
            office: intAccountDetails?.office || '',
            officeId: intAccountDetails?.officeId || '',
            license: intAccountDetails?.licenseId || '',
            active: intAccountDetails?.isActive || false,
            newsletter: intAccountDetails?.optInNewsletter || false,
            agreement: intAccountDetails?.agreedToTandS || false,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intAccountDetails]);

    useEffect(() => {
        setIntAccountDetails(accountDetails);
    }, [accountDetails])

    const handleSubmit = data => {
        dispatch(setLoading());
        if(data.officeId === ""){
            data.officeId = null
        }
        if(data.organisationId === ""){
            data.organisationId = null
        }
        dispatch(updateMyAccount(data));
    };

    return (
        <>
            <ThemeProvider theme={mainTheme}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={9}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <FormTitleTypography variant="h5" component="div">
                                Account Details
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                {' Modify your account details.'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item md={12} >
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {formData &&
                                <Formik
                                    enableReinitialize
                                    render={props => <AccountForm {...props} role={user?.role} mode={SELF_EDIT} />}
                                    initialValues={formData}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                />}
                        </Paper>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}

export default MyAccount