export const getFirstVisibleCellDetailsInDataEntry =(data) => {
    for (const obj of data) {
      for (const group of obj?.sectorGroups?.groups || []) {
        for (const row of group?.rows || []) {
          const visibleCell = row.cells.find(cell => cell?.visible);
          if (visibleCell) {
            const { id: parentId, title: parentTitle } = obj || {};
            const { id: groupId, title: groupTitle } = group || {};
            const { id: rowId, title: rowTitle } = row || {};
            const { id: cellId, variant, dataType, mappingProperty } = visibleCell || {};
            return {
              parentId, parentTitle, groupId, groupTitle, rowId, rowTitle, cellId, variant, dataType, mappingProperty 
            };
          }
        }
      }
    }
    return null;
}