export const SET_CURRENT_ADMIN_PROJECT_ID = 'SET_CURRENT_ADMIN_PROJECT_ID' 

export const GET_ADMIN_PROJECT_LIST_SUCCESS = 'GET_ADMIN_PROJECT_LIST_SUCCESS'
export const GET_ADMIN_PROJECT_LIST_FAIL = 'GET_ADMIN_PROJECT_LIST_FAIL'

export const GET_ADMIN_PROJECT_DATA_SUCCESS = 'GET_ADMIN_PROJECT_DATA_SUCCESS'

export const GET_PROJECT_ADMIN_DATA_SUCCESS = 'GET_PROJECT_ADMIN_DATA_SUCCESS'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL' 