export const GET_FEED_RATION_METADATA = 'GET_FEED_RATION_METADATA'
export const GET_FEED_RATION_METADATA_SUCCESS = 'GET_FEED_RATION_METADATA_SUCCESS'
export const GET_FEED_RATION_METADATA_FAIL = 'GET_FEED_RATION_METADATA_FAIL'
export const RESET_FEED_RATION_METADATA = 'RESET_FEED_RATION_METADATA'

export const GET_FEED_RATION_FORM_DATA = 'GET_FEED_RATION_FORM_DATA'
export const GET_FEED_RATION_FORM_DATA_SUCCESS = 'GET_FEED_RATION_FORM_DATA_SUCCESS'
export const GET_FEED_RATION_FORM_DATA_FAIL = 'GET_FEED_RATION_FORM_DATA_FAIL'

export const SET_FEED_RATION_FORM_DATA = 'SET_FEED_RATION_FORM_DATA'
export const RESET_FEED_RATION_ALL_DATA = 'RESET_FEED_RATION_ALL_DATA'

export const SAVE_FEED_RATION_ALL_DATA = 'SAVE_FEED_RATION_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'
export const RESET_RESPONSE_STATE = 'RESET_RESPONSE_STATE'