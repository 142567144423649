import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  farmId: '',
  farms: undefined,
  farmDetails: {},
};

const adminFarmReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADMIN_FARM_ID: return setFarmId(state, action);
    case actionTypes.GET_ALL_FARMS_SUCCESS: return setAllFarmsSuccess(state, action);
    case actionTypes.GET_ALL_FARMS_FAIL: return setAllFarmsFail(state, action);
    case actionTypes.GET_ADMIN_FARM_DATA_SUCCESS: return setAdminFarmDataSuccess(state, action);
    case actionTypes.GET_ADMIN_FARM_DATA_FAIL: return setAminFarmDataFail(state, action);
    case actionTypes.GET_ADMIN_FARM_OWNER_DATA_SUCCESS: return setAdminFarmOwnerDataSuccess(state, action);
    default:
      return state;
  }
};

const setFarmId = (state, action) => {
  const updatedState = {
    farmId: action.payload,
    farmDetails: {},
  }
  return updateObject(state, updatedState);
}

const setAllFarmsSuccess = (state, action) => {
  const updatedState = {
    farms: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAllFarmsFail = (state, action) => {
  const updatedState = {
    farms: [],
  }
  return updateObject(state, updatedState);
}

const setAdminFarmDataSuccess = (state, action) => {
  const updatedState = {
    farmDetails: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAdminFarmOwnerDataSuccess = (state, action) => {
  const updatedState = {
    farmOwnerDetails: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAminFarmDataFail = (state, action) => {
  const updatedState = {
    farmDetails: {},
  }
  return updateObject(state, updatedState);
}

export default adminFarmReducer;
