export const GET_MANURE_MANAGEMENT_METADATA = 'GET_MANURE_MANAGEMENT_METADATA'
export const GET_MANURE_MANAGEMENT_METADATA_SUCCESS = 'GET_MANURE_MANAGEMENT_METADATA_SUCCESS'
export const GET_MANURE_MANAGEMENT_METADATA_FAIL = 'GET_MANURE_MANAGEMENT_METADATA_FAIL'
export const RESET_MANURE_MANAGEMENT_METADATA = 'RESET_MANURE_MANAGEMENT_METADATA'

export const GET_MANURE_MANAGEMENT_FORM_DATA = 'GET_MANURE_MANAGEMENT_FORM_DATA'
export const GET_MANURE_MANAGEMENT_FORM_DATA_SUCCESS = 'GET_MANURE_MANAGEMENT_FORM_DATA_SUCCESS'
export const GET_MANURE_MANAGEMENT_FORM_DATA_FAIL = 'GET_MANURE_MANAGEMENT_FORM_DATA_FAIL'

export const SET_MANURE_MANAGEMENT_FORM_DATA = 'SET_MANURE_MANAGEMENT_FORM_DATA'
export const RESET_MANURE_MANAGEMENT_ALL_DATA = 'RESET_MANURE_MANAGEMENT_ALL_DATA'

export const SAVE_MANURE_MANAGEMENT_ALL_DATA = 'SAVE_MANURE_MANAGEMENT_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'
export const RESET_RESPONSE_STATE = 'RESET_RESPONSE_STATE'