import { updateObject } from '../../utility';
import * as actionTypes from './types';

const initialState = {
  selectedUserId: '',
  accounts: undefined,
  accountDetails: {},
};

const adminAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADMIN_ACCOUNT_USER_ID: return setUserId(state, action);
    case actionTypes.GET_ADMIN_ACCOUNT_DATA_SUCCESS: return setAdminAccountDataSuccess(state, action);
    case actionTypes.GET_ADMIN_ACCOUNT_DATA_FAIL: return setAdminAccountDataFail(state, action);
    case actionTypes.GET_ALL_ADMIN_ACCOUNT_DATA_SUCCESS: return setAllAdminAccountDataSuccess(state, action);
    case actionTypes.GET_ALL_ADMIN_ACCOUNT_DATA_FAIL: return setAllAdminAccountDataFail(state, action);
    default:
      return state;
  }
};

const setUserId = (state, action) => {
  const updatedState = {
    selectedUserId: action.payload,
    accountDetails: {},
  }
  return updateObject(state, updatedState);
}

const setAllAdminAccountDataSuccess = (state, action) => {
  const updatedState = {
    accounts: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAllAdminAccountDataFail = (state, action) => {
  const updatedState = {
    accounts: {},
  }
  return updateObject(state, updatedState);
}

const setAdminAccountDataSuccess = (state, action) => {
  const updatedState = {
    accountDetails: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAdminAccountDataFail = (state, action) => {
  const updatedState = {
    accountDetails: {},
  }
  return updateObject(state, updatedState);
}

export default adminAccountReducer;
