import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getPesticidesMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.pesticides.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getPesticidesMetadataSuccess(response?.data));
  } else
    return dispatch(getPesticidesMetadataFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getPesticidesMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_PESTICIDES_METADATA_SUCCESS,
    payload: response
  };
};

const getPesticidesMetadataFail = () => {
  return {
    type: actionTypes.GET_PESTICIDES_METADATA_FAIL,
  };
};

export const resetPesticidesMetaData = () => {
  return {
    type: actionTypes.RESET_PESTICIDES_METADATA,
  };
};

export const getPesticidesFormData = ({ reportId }) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.pesticides.formData.concat('?reportId=', reportId));
    if (response && response.status === 200) {
      return dispatch(getPesticidesFormDataSuccess(response?.data?.data));
    } else
      return dispatch(getPesticidesFormDataFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (error) {
    return dispatch(getPesticidesFormDataFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getPesticidesFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_PESTICIDES_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getPesticidesFormDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const savePesticidesData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().pesticides
  const response = await api.post(endpoints.landCrops.pesticides.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(savePesticidesDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(savePesticidesDataFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const savePesticidesDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const savePesticidesDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setPesticidesFormData = (response) => {
  return {
    type: actionTypes.SET_PESTICIDES_FORM_DATA,
    payload: response
  };
};

export const resetPesticidesFormData = () => {
  return {
    type: actionTypes.RESET_PESTICIDES_ALL_DATA,
  };
};

