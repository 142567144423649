import { Button, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getEnterpriseChart, getEnterpriseTable, getFarmReport, setLoading, unsetLoading } from '../../../../store/appAction';
import { useReactToPrint } from "react-to-print";
import { Container } from '@mui/system';
import Contents from './Contents';
import Charts from './Charts';
import Tables from './Tables';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import '../../../../assets/fonts/Montserrat-VariableFont_wght-normal.js'
import { FARMER } from '../../../../constants/userRoles';
import { useLocation, } from 'react-router-dom';
import queryString from 'query-string';
import { getPageStyles } from '../../../../helpers/pageStyleHelper';

const Preview = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const farm = useSelector(state => ([FARMER].includes(user.role) ?
        state.profile.farm : state.adminFarm.farmDetails));
    const reportId = useSelector(state => state.common.reportId);
    const report = useSelector(state => state.farmReport.reportDetails);
    const charts = useSelector(state => state.enterprise.chartData);
    const tables = useSelector(state => state.enterprise.tableData);
    const [reportData, setReportData] = useState({});
    const [chartData, setChartData] = useState({});
    const [tableData, setTableData] = useState({});
    const printRef = useRef();

    useEffect(() => {
        dispatch(setLoading());

        const { reportEnterpriseSectorId, reportEnterpriseSectorGroupId,
            reportEnterpriseSectorItemId, reportComparisonType, referenceId,
        } = queryString.parse(location.search);

        dispatch(getFarmReport({ reportId }));
        dispatch(getEnterpriseChart({
            ReportId: reportId,
            ReportEnterpriseSectorId: reportEnterpriseSectorId,
            ReportEnterpriseSectorGroupId: reportEnterpriseSectorGroupId,
            ReportEnterpriseSectorItemId: reportEnterpriseSectorItemId,
            ReportComparisonType: reportComparisonType,
            ComparisonReferenceId: referenceId,
        }));
        dispatch(getEnterpriseTable({
            ReportId: reportId,
            ReportEnterpriseSectorId: reportEnterpriseSectorId,
            ReportEnterpriseSectorGroupId: reportEnterpriseSectorGroupId,
            ReportEnterpriseSectorItemId: reportEnterpriseSectorItemId,
            ReportComparisonType: reportComparisonType,
            ComparisonReferenceId: referenceId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        setReportData(report || {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report]);

    useEffect(() => {
        setTableData(tables || {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tables]);

    useEffect(() => {
        setChartData(charts || {});
        setTimeout(() => {
            dispatch(unsetLoading());
            //handlePrint();
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charts]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        copyStyles: true,
        pageStyle: getPageStyles(),
        removeAfterPrint: true,
        documentTitle: `Agrecalc report - [${reportData?.friendlyReportId}].pdf`,
        // print: async (printIframe) => {
        //     // Do whatever you want here, including asynchronous work
        //     //    saveAs(new Blob(printIframe.contentWindow.document),'test.pdf')
        //     var x = window.open();
        //     x.document.open();
        //     x.document.write(printIframe.contentDocument.body.innerHTML);
        //     x.document.close();
        // },
    });

    const ReportPreview = () => (
        <Container sx={{ pointerEvents: 'none' }}>
            <Grid ref={printRef} className='to-pdf' container px={2} my={2}>
                <Header
                    farmId={farm?.friendlyFarmId}
                    farmName={farm?.farmName}
                    reportId={reportData?.friendlyReportId}
                    modelNumber={chartData?.calculatorReportVersion}
                    reportName={reportData?.reportName}
                />
                <Charts results={chartData} configs={tableData} />
                <Tables results={tableData} firstChild={true} />
                <Contents firstChild={true} />
                <Footer />
            </Grid >
        </Container >
    );

    return (
        <Container
            sx={{
                width: '950px',
            }} p={0}>
            <Grid container direction='row' spacing={0}>
                <Grid item xs={12} mb={3}>
                    <Button
                        variant="outlined"
                        sx={{ textTransform: 'none', marginTop: '23px', float: 'right' }}
                        onClick={handlePrint}
                    >
                        Print
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Container
                        sx={{
                            py: 4,
                            height: '1280px',
                            width: '900px',
                            overflow: 'scroll',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                        }}>
                        <ReportPreview />
                    </Container>
                </Grid>
            </Grid>
        </Container >
    )
}

export default Preview