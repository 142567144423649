export const toastrCustomOptions = {
    preventDuplicates: true,
    positionClass: 'toast-top-center',
    showDuration: 100,
    hideDuration: 200,
    timeOut: 800,
    closeButton: true
}

export const toastrCustomOptionsLonger = {
    preventDuplicates: true,
    positionClass: 'toast-top-center',
    showDuration: 10000,
    hideDuration: 200,
    timeOut: 10000,
    closeButton: true
}

export const toastrCustomOptionsNoFarmSelectPopUp = {
    preventDuplicates: true,
    positionClass: 'toast-top-center',
    showDuration: 3000,
    hideDuration: 0,
    timeOut: 3000,
    closeButton: true,
    progressBar:false
}