import { Grid, AppBar, Tab, FormControl, MenuItem, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { FormDescriptionTypography, FormHeaderPaper, FormTitleTypography, ResultTablesTabs, FarmDetailsSelect, InfoTooltip } from '../../components/CustomStyles/StyledComponents';
import DataChecksErrors from '../../components/Errors/DataChecksErrors';
import { getEnterpriseSectorList, setLoading, setReportId, unsetLoading, getAllReports } from '../../store/appAction';
import SelectedFarm from '../../components/SelectedFarm/SelectedFarm';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    ResultsTableCell, ResultsTableRow,
    ResultsTableTitleTypography,ResultsTableCellWithoutMargin, ResultsTableCellWithMargin
} from '../../components/CustomStyles/StyledComponents';
import { addCommas } from '../../helpers/stringFormatHelper';


const SummeryTable = ({ tableData, selectedEnterpriseName }) => {
    return <>
        {selectedEnterpriseName &&
            <TableContainer sx={{ mb: 3 }}>
                <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Emissions Summary Table"}</ResultsTableTitleTypography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                    return (
                                        <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                    );
                                })
                            }
                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ResultsTableRow>
                            <ResultsTableCell>{'Direct CO₂'}</ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCell> {report?.emissionSummary?.directCo2 ? addCommas(report?.emissionSummary?.directCo2) : '--'} </ResultsTableCell>);
                                })
                            }
                            <ResultsTableCell>{tableData?.activeReport?.emissionSummary?.directCo2 ? addCommas(tableData?.activeReport?.emissionSummary?.directCo2) : '--'}</ResultsTableCell>
                        </ResultsTableRow>
                        <ResultsTableRow>
                            <ResultsTableCell>{'Indirect CO₂'}</ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCell> {report?.emissionSummary?.indirectCo2 ? addCommas(report?.emissionSummary?.indirectCo2) : '--'} </ResultsTableCell>);
                                })
                            }
                            <ResultsTableCell>{tableData?.activeReport?.emissionSummary?.indirectCo2 ? addCommas(tableData?.activeReport?.emissionSummary?.indirectCo2) : '--'}</ResultsTableCell>
                        </ResultsTableRow>
                        <ResultsTableRow>
                            <ResultsTableCell>{'Methane'}</ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCell> {report?.emissionSummary?.methane ? addCommas(report?.emissionSummary?.methane) : '--'} </ResultsTableCell>);
                                })
                            }
                            <ResultsTableCell>{tableData?.activeReport?.emissionSummary?.methane ? addCommas(tableData?.activeReport?.emissionSummary?.methane) : '--'}</ResultsTableCell>
                        </ResultsTableRow>
                        <ResultsTableRow>
                            <ResultsTableCell>{'Nitrous Oxide'}</ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCell> {report?.emissionSummary?.nitrousOxide ? addCommas(report?.emissionSummary?.nitrousOxide) : '--'} </ResultsTableCell>);
                                })
                            }
                            <ResultsTableCell>{tableData?.activeReport?.emissionSummary?.nitrousOxide ? addCommas(tableData?.activeReport?.emissionSummary?.nitrousOxide) : '--'}</ResultsTableCell>
                        </ResultsTableRow>
                    </TableBody>

                    <TableFooter>
                        <ResultsTableRow>
                            <ResultsTableCell>{'Total Emissions'}</ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCell> {report?.emissionSummary?.totalEmission ? addCommas(report?.emissionSummary?.totalEmission) : '--'} </ResultsTableCell>);
                                })
                            }
                            <ResultsTableCell>{tableData?.activeReport?.emissionSummary?.totalEmission ? addCommas(tableData?.activeReport?.emissionSummary?.totalEmission) : '--'}</ResultsTableCell>

                        </ResultsTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        }
    </>
}

const DetailedTable = ({ tableData, selectedEnterpriseName, option }) => {
    const activeReportEnterpriseEmission = tableData?.activeReport?.enterpriseEmission?.enterpriseProducts || null;
    const isPreviousReportContainsEnterpriseEmissionData = [false, false];
    tableData?.comparisonReportResultDtos?.map((report,index) => {
        if(report){
            isPreviousReportContainsEnterpriseEmissionData[index]=true;
        }
    });
    return <>
        {selectedEnterpriseName &&
            <>
                <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Direct Carbon Dioxide "}</ResultsTableTitleTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                        return (
                                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                        );
                                    })
                                }
                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Diesel'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.diesel ? addCommas(report?.emissionDetailed?.diesel) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.diesel ? addCommas(tableData?.activeReport?.emissionDetailed?.diesel) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Electricity'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.electricity ? addCommas(report?.emissionDetailed?.electricity) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.electricity ? addCommas(tableData?.activeReport?.emissionDetailed?.electricity) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Other fuels'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.otherFuels ? addCommas(report?.emissionDetailed?.otherFuels) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.otherFuels ? addCommas(tableData?.activeReport?.emissionDetailed?.otherFuels) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Renewable heat and electricity'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.renewableHeatAndElectricity ? addCommas(report?.emissionDetailed?.renewableHeatAndElectricity) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.renewableHeatAndElectricity ? addCommas(tableData?.activeReport?.emissionDetailed?.renewableHeatAndElectricity) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                        </TableBody>
                        <TableFooter>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Total direct carbon dioxide '}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.totalDirectCo2 ? addCommas(report?.emissionDetailed?.totalDirectCo2) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.totalDirectCo2 ? addCommas(tableData?.activeReport?.emissionDetailed?.totalDirectCo2) : '--'}</ResultsTableCell>

                            </ResultsTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Indirect Carbon Dioxide (Farm inputs) "}</ResultsTableTitleTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                        return (
                                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                        );
                                    })
                                }
                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Fertiliser'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.fertiliser ? addCommas(report?.emissionDetailed?.fertiliser) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.fertiliser ? addCommas(tableData?.activeReport?.emissionDetailed?.fertiliser) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Lime'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.lime ? addCommas(report?.emissionDetailed?.lime) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.lime ? addCommas(tableData?.activeReport?.emissionDetailed?.lime) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Pesticides'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.pesticides ? addCommas(report?.emissionDetailed?.pesticides) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.pesticides ? addCommas(tableData?.activeReport?.emissionDetailed?.pesticides) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Bedding'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.bedding ? addCommas(report?.emissionDetailed?.bedding) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.bedding ? addCommas(tableData?.activeReport?.emissionDetailed?.bedding) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Feed'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.feed ? addCommas(report?.emissionDetailed?.feed) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.feed ? addCommas(tableData?.activeReport?.emissionDetailed?.feed) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Energy'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.energy ? addCommas(report?.emissionDetailed?.energy) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.energy ? addCommas(tableData?.activeReport?.emissionDetailed?.energy) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Plastic use / disposal'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.plasticUse ? addCommas(report?.emissionDetailed?.plasticUse) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.plasticUse ? addCommas(tableData?.activeReport?.emissionDetailed?.plasticUse) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Carcass disposal'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.carcassDisposal ? addCommas(report?.emissionDetailed?.carcassDisposal) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.carcassDisposal ? addCommas(tableData?.activeReport?.emissionDetailed?.carcassDisposal) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Transport'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.transport ? addCommas(report?.emissionDetailed?.transport) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.transport ? addCommas(tableData?.activeReport?.emissionDetailed?.transport) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Biochar'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.bioChar ? addCommas(report?.emissionDetailed?.bioChar) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.bioChar ? addCommas(tableData?.activeReport?.emissionDetailed?.bioChar) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                        </TableBody>

                        <TableFooter>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Total indirect carbon dioxide (other) '}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.totalIndirectCo2 ? addCommas(report?.emissionDetailed?.totalIndirectCo2) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.totalIndirectCo2 ? addCommas(tableData?.activeReport?.emissionDetailed?.totalIndirectCo2) : '--'}</ResultsTableCell>

                            </ResultsTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Methane"}</ResultsTableTitleTypography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                    return (
                                        <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                    );
                                })
                            }
                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ResultsTableRow>
                            <ResultsTableCell>{'Feed digestion (GWP100)'}</ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCell> {report?.emissionDetailed?.feedDigestion ? addCommas(report?.emissionDetailed?.feedDigestion) : '--'} </ResultsTableCell>);
                                })
                            }
                            <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.feedDigestion ? addCommas(tableData?.activeReport?.emissionDetailed?.feedDigestion) : '--'}</ResultsTableCell>
                        </ResultsTableRow>

                        <ResultsTableRow>
                            <ResultsTableCell>{'Manure management (GWP100)'}</ResultsTableCell>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCell> {report?.emissionDetailed?.manureManagement ? addCommas(report?.emissionDetailed?.manureManagement) : '--'} </ResultsTableCell>);
                                })
                            }
                            <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.manureManagement ? addCommas(tableData?.activeReport?.emissionDetailed?.manureManagement) : '--'}</ResultsTableCell>
                        </ResultsTableRow>

                        <ResultsTableRow>
                            <ResultsTableCellWithMargin>{'Total methane (GWP100)'}</ResultsTableCellWithMargin>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCellWithMargin> {report?.emissionDetailed?.totalMethane ? addCommas(report?.emissionDetailed?.totalMethane) : '--'} </ResultsTableCellWithMargin>);
                                })
                            }
                            <ResultsTableCellWithMargin>{tableData?.activeReport?.emissionDetailed?.totalMethane ? addCommas(tableData?.activeReport?.emissionDetailed?.totalMethane) : '--'}</ResultsTableCellWithMargin>
                        </ResultsTableRow>

                    </TableBody>

                    <TableFooter>
                        <ResultsTableRow id='jee'>

                            <ResultsTableCellWithoutMargin>{'Total methane (GWP*)'}</ResultsTableCellWithoutMargin>
                            {
                                tableData?.comparisonReportResultDtos?.map((report) => {
                                    return (<ResultsTableCellWithoutMargin> {report?.emissionDetailed?.totalMethaneFromGwp ? addCommas(report?.emissionDetailed?.totalMethaneFromGwp) : '--'} </ResultsTableCellWithoutMargin>);
                                })
                            }
                            <ResultsTableCellWithoutMargin>{tableData?.activeReport?.emissionDetailed?.totalMethaneFromGwp ? addCommas(tableData?.activeReport?.emissionDetailed?.totalMethaneFromGwp) : '--'}</ResultsTableCellWithoutMargin>
                        </ResultsTableRow>
                    </TableFooter>

                </Table>

                </TableContainer>

                <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Nitrous Oxide"}</ResultsTableTitleTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                        return (
                                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                        );
                                    })
                                }
                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Imported fertilisers and manures'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.importedFertilisersAndManure ? addCommas(report?.emissionDetailed?.importedFertilisersAndManure) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.importedFertilisersAndManure ? addCommas(tableData?.activeReport?.emissionDetailed?.importedFertilisersAndManure) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Grazing, manure spreading, and manure management'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.grazingManSpreadingAndManManagement ? addCommas(report?.emissionDetailed?.grazingManSpreadingAndManManagement) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.grazingManSpreadingAndManManagement ? addCommas(tableData?.activeReport?.emissionDetailed?.grazingManSpreadingAndManManagement) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Crop residues'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.cropResidues ? addCommas(report?.emissionDetailed?.cropResidues) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.cropResidues ? addCommas(tableData?.activeReport?.emissionDetailed?.cropResidues) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                        </TableBody>

                        <TableFooter>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Total nitrous oxide'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.totalNitrousOxide ? addCommas(report?.emissionDetailed?.totalNitrousOxide) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.totalNitrousOxide ? addCommas(tableData?.activeReport?.emissionDetailed?.totalNitrousOxide) : '--'}</ResultsTableCell>

                            </ResultsTableRow>
                        </TableFooter>
                    </Table>

                </TableContainer>

                <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Carbon Sequestration"}</ResultsTableTitleTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                        return (
                                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                        );
                                    })
                                }
                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Biochar'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.bioCharSequestration ? addCommas(report?.emissionDetailed?.bioCharSequestration) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.bioCharSequestration ? addCommas(tableData?.activeReport?.emissionDetailed?.bioCharSequestration) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Hedges'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.hedgeSequestration ? addCommas(report?.emissionDetailed?.hedgeSequestration) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.hedgeSequestration ? addCommas(tableData?.activeReport?.emissionDetailed?.hedgeSequestration) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Forestry'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.forestrySequestration ? addCommas(report?.emissionDetailed?.forestrySequestration) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.forestrySequestration ? addCommas(tableData?.activeReport?.emissionDetailed?.forestrySequestration) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    option.title === 'Whole Farm' && 
                    <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Total Emissions"}</ResultsTableTitleTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                        return (
                                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                        );
                                    })
                                }
                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Gross emissions from farming'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.grossEmissionFromFarming ? addCommas(report?.emissionDetailed?.grossEmissionFromFarming) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.grossEmissionFromFarming ? addCommas(tableData?.activeReport?.emissionDetailed?.grossEmissionFromFarming) : '--'}</ResultsTableCell>
                            </ResultsTableRow>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Farm Area'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.farmArea ? addCommas(report?.emissionDetailed?.farmArea) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.farmArea ? addCommas(tableData?.activeReport?.emissionDetailed?.farmArea) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Whole farm CO₂ emissions per kg of farm output'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.wholeFarmCo2EmissionPerFarmOutput ? addCommas(report?.emissionDetailed?.wholeFarmCo2EmissionPerFarmOutput) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.wholeFarmCo2EmissionPerFarmOutput ? addCommas(tableData?.activeReport?.emissionDetailed?.wholeFarmCo2EmissionPerFarmOutput) : '--'}</ResultsTableCell>
                            </ResultsTableRow>


                            <ResultsTableRow>
                                <ResultsTableCell>{'Emissions per hectare'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.emissionPerHectare ? addCommas(report?.emissionDetailed?.emissionPerHectare) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.emissionPerHectare ? addCommas(tableData?.activeReport?.emissionDetailed?.emissionPerHectare) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                            <ResultsTableRow>
                                <ResultsTableCell>{'Farm and enterprise output'}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.emissionDetailed?.farmAndEnterpriseOutput ? addCommas(report?.emissionDetailed?.farmAndEnterpriseOutput) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.emissionDetailed?.farmAndEnterpriseOutput ? addCommas(tableData?.activeReport?.emissionDetailed?.farmAndEnterpriseOutput) : '--'}</ResultsTableCell>

                            </ResultsTableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
                }
                
                {/* <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }}>{"Enterprise Emissions"}</ResultsTableTitleTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                        return (
                                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                        );
                                    })
                                }
                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ResultsTableRow>
                                <ResultsTableCell>{'Gross emissions for enterprise '}</ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        return (<ResultsTableCell> {report?.enterpriseEmission?.grossEmissionPerEnterprise ? addCommas(report?.emissionDetailed?.grossEmissionPerEnterprise) : '--'} </ResultsTableCell>);
                                    })
                                }
                                <ResultsTableCell>{tableData?.activeReport?.enterpriseEmission?.grossEmissionPerEnterprise ? addCommas(tableData?.activeReport?.enterpriseEmission?.grossEmissionPerEnterprise) : '--'}</ResultsTableCell>
                            </ResultsTableRow>

                        </TableBody>
                    </Table>
                </TableContainer> */}
                {activeReportEnterpriseEmission !== null &&
                    <>
                        <ResultsTableTitleTypography sx={{ mb: 2 }} style={{ paddingBottom: '5px', marginBottom: '5px' }}>{"Product Emissions"}</ResultsTableTitleTypography>

                        {
                            activeReportEnterpriseEmission?.map((product, index) => {
                                return (
                                    <>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <ResultsTableCell sx={{ width: '25%' }} style={{ textAlign: 'left', alignItems: 'left' }}>{`${product?.productName} + per kg CO₂-eq`}</ResultsTableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                                    {
                                                        tableData?.comparisonReportResultDtos?.map((report) => {
                                                            const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                                            return (
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                                            );
                                                        })
                                                    }
                                                    <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                                                </TableRow>
                                            </TableHead>
                                            {
                                                index === 0 &&
                                                <TableBody>
                                                    <ResultsTableRow>
                                                        <ResultsTableCell sx={{ width: '25%' }}>{"Total Emissions for that product"}</ResultsTableCell>
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[0] !== false &&
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[0]?.emissionPerKgOfProduct ? tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[0]?.emissionPerKgOfProduct :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[1] !== false &&
                                                            <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[0]?.emissionPerKgOfProduct ? tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[0]?.emissionPerKgOfProduct :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[0]?.emissionPerKgOfProduct ? tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[0]?.emissionPerKgOfProduct :'--'}`} </ResultsTableCell>
                                                        }
                                                    </ResultsTableRow>

                                                    <ResultsTableRow>
                                                        <ResultsTableCell sx={{ width: '25%' }}>{"Emissions per kg of product output"}</ResultsTableCell>
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[0] !== false &&
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions ? tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[1] !== false &&
                                                            <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions ? tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions ? tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions :'--'}`} </ResultsTableCell>
                                                        }
                                                    </ResultsTableRow>

                                                </TableBody>
                                            }

                                            {
                                                index === 1 &&
                                                <TableBody>
                                                    <ResultsTableRow>
                                                        <ResultsTableCell sx={{ width: '25%' }}>{"Total Emissions for that product"}</ResultsTableCell>
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[0] !== false &&
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions ? tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[1] !== false &&
                                                            <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions ? tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions ? tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[0]?.totalEmissions :'--'}`} </ResultsTableCell>
                                                        }
                                                    </ResultsTableRow>

                                                    <ResultsTableRow>
                                                        <ResultsTableCell sx={{ width: '25%' }}>{"Emissions per kg of product output"}</ResultsTableCell>
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[0] !== false &&
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[1]?.emissionPerKgOfProduct ? tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseProducts?.[1]?.emissionPerKgOfProduct :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                            isPreviousReportContainsEnterpriseEmissionData[1] !== false &&
                                                            <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[1]?.emissionPerKgOfProduct ? tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseProducts?.[1]?.emissionPerKgOfProduct :"--"} `} </ResultsTableCell>
                                                        }
                                                        {
                                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[1]?.emissionPerKgOfProduct ? tableData?.activeReport?.enterpriseEmission?.enterpriseProducts?.[1]?.emissionPerKgOfProduct :'--'}`} </ResultsTableCell>
                                                        }
                                                    </ResultsTableRow>
                                                </TableBody>
                                            }
                                        </Table>
                                    </>
                                );
                            })

                        }
                        {
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                        {
                                            tableData?.comparisonReportResultDtos?.map((report) => {
                                                const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                                return (
                                                    <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear} (kg CO₂-eq)`} </ResultsTableCell>
                                                );
                                            })
                                        }
                                        <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`} </ResultsTableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <ResultsTableRow>
                                        <ResultsTableCell sx={{ width: '25%' }}>{"Emissions per hectare"}</ResultsTableCell>
                                        {
                                            isPreviousReportContainsEnterpriseEmissionData[0] !== false && 
                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.emissionPerHectare ? tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.emissionPerHectare : "--"} `} </ResultsTableCell>
                                        }
                                        {
                                            isPreviousReportContainsEnterpriseEmissionData[1] !== false && 
                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.emissionPerHectare ? tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.emissionPerHectare : "--"} `} </ResultsTableCell>
                                        }
                                        {
                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.activeReport?.enterpriseEmission?.emissionPerHectare ? tableData?.activeReport?.enterpriseEmission?.emissionPerHectare : "--"}`} </ResultsTableCell>
                                        }
                                    </ResultsTableRow>

                                    <ResultsTableRow>
                                        <ResultsTableCell sx={{ width: '25%' }}>{"Enterprise output"}</ResultsTableCell>
                                        {
                                            isPreviousReportContainsEnterpriseEmissionData[0] !== false && 
                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseOutput ? tableData?.comparisonReportResultDtos?.[0]?.enterpriseEmission?.enterpriseOutput : "--"} `} </ResultsTableCell>
                                        }
                                        {
                                            isPreviousReportContainsEnterpriseEmissionData[1] !== false && 
                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseOutput ? tableData?.comparisonReportResultDtos?.[1]?.enterpriseEmission?.enterpriseOutput : "--"} `} </ResultsTableCell>
                                        }
                                        {
                                                <ResultsTableCell sx={{ width: '25%' }}> {`${tableData?.activeReport?.enterpriseEmission?.enterpriseOutput ? tableData?.activeReport?.enterpriseEmission?.enterpriseOutput : "--"}`} </ResultsTableCell>
                                        }
                                    </ResultsTableRow>
                                </TableBody>
                            </Table>
                        }
                    </>
                }
            </>
        }
    </>
}

const YearOnYear = () => {
    const dispatch = useDispatch();
    const reportId = useSelector(state => state.common.reportId);
    const reportList = useSelector(state => state.farm.reports);
    const enterpriseList = useSelector(state => state.enterprise.enterpriseList);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);


    const [results, setResults] = useState(undefined);
    const [currentTab, setCurrentTab] = useState(0);
    const [enterpriseOptIdx, setEnterpriseOptIdx] = useState(undefined);
    const [option, setOption] = useState({});

    useEffect(() => {
        dispatch(getAllReports({ farmId }));
    }, []);

    const getData = async (option) => {
        dispatch(setLoading());
        const params = {};
        if (option.title === 'Whole Farm') {
            params.reportId = reportId;
            const response = await api.get(endpoints.yearOnYear.yearOnYearData.concat('?', queryString.stringify(params)));
            if (response && response.status === 200) {
                setResults(response?.data || {});
                dispatch(unsetLoading());
            }

        } else {
            if (option.reportEnterpriseSectorItemId !== null) {
                //livestock == false
                params.reportId = reportId;
                params.cropEnterpriseId = option?.reportEnterpriseSectorItemId;

            } else {
                //livestock = true
                params.reportId = reportId;
                params.livestockEnterpriseId = option?.reportEnterpriseSectorGroupId
            }
            const response = await api.get(endpoints.yearOnYear.yearOnYearData.concat('?', queryString.stringify(params)));
            if (response && response.status === 200) {
                setResults(response?.data || {});
                dispatch(unsetLoading());
            }
        }
    }

    useEffect(() => {
        (enterpriseList.length > 0) && handleEnterpriseSelect({ target: { value: '' } });
    }, [enterpriseList]);


    useEffect(() => {
        dispatch(getEnterpriseSectorList({ reportId }));
        setOption({});
        setEnterpriseOptIdx(undefined);
    }, [reportId]);

    useEffect(() => {
        console.log("option", "pass");
        getData(option);
    }, [option]);

    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }

    const handleEnterpriseSelect = async e => {
        if (e.target.value === '') {
            const enterprise = {
                calculatorReportVersion: null,
                title: "Whole Farm",
                reportEnterpriseSectorId: null,
                reportEnterpriseSectorGroupId: null,
                reportEnterpriseSectorItemId: null
            }
            await setOption(enterprise);

        }
        setEnterpriseOptIdx(e.target.value);
        const enterprise = enterpriseList[e.target.value];
        if (enterprise) {
            await setOption(enterprise);
        }
    };

    const handleTabChange = (event, tab) => {
        setCurrentTab(tab);
    };

    return (
        <>
            {results?.status === 1 ?
                <DataChecksErrors errorList={results.dataChecks} /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >

                            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName} />
                            <FormTitleTypography variant="h5" component="div">
                                Year on Year Results
                            </FormTitleTypography>
                            <FormDescriptionTypography variant="body2" paragraph>
                                {'Detailed and summary results for the whole farm and per enterprise will be compared to results from the previous two years. If you wish to compare year on year results from another scenario, select the report from the Report options below.'}
                            </FormDescriptionTypography>
                        </FormHeaderPaper>
                    </Grid>

                    <Grid item xs={12}>
                        <AppBar position="static">
                            <ResultTablesTabs
                                value={currentTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                variant="standard"
                                aria-label="full width tabs example"
                                autoFocus={true}
                            >
                                <Tab sx={{ textTransform: 'none' }} label="Summary" autoFocus={true} />
                                <Tab sx={{ textTransform: 'none' }} label="Detailed" />
                            </ResultTablesTabs>
                        </AppBar>
                    </Grid>

                    <Grid item xs={12} mb={2}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '99%', float: 'left' }}
                                >
                                    <b style={{ fontSize: '1.15rem' }}>Report</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={reportId || ''}
                                        onChange={handleReportSelect}
                                        autoFocus={true}
                                    >
                                        {reportList.map((option) => (
                                            <MenuItem
                                                id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>

                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '91%', float: 'left' }}
                                >
                                    <b style={{ fontSize: '1.15rem' }}>Enterprise</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={enterpriseOptIdx !== undefined ? enterpriseOptIdx : ''}
                                        onChange={handleEnterpriseSelect}
                                    >
                                        <MenuItem value=""><em>Whole Farm</em></MenuItem>
                                        {enterpriseList.map((option, index) => (
                                            <MenuItem
                                                id={index}
                                                key={index}
                                                value={index}
                                            >
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                                <InfoTooltip
                                    placement="bottom-end"
                                    title="Select one of your reports and then whether you want to see the figures for the whole farm breakdown or just for an enterprise">
                                    <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginTop: '32px', }} fontSize='small' />
                                </InfoTooltip>
                            </Grid>
                        </Grid>
                    </Grid >

                    <Grid item xs={12} md={12} lg={12} sx={{ minHeight: 300 }}>
                        {
                            results && currentTab === 0 ?
                                <SummeryTable
                                    tableData={results}
                                    selectedEnterpriseName={option?.title}

                                /> :
                                <DetailedTable
                                    tableData={results}
                                    selectedEnterpriseName={option?.title}
                                    option ={option}
                                />
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default YearOnYear