export const GET_PERFORMANCE_METADATA = 'GET_PERFORMANCE_METADATA'
export const GET_PERFORMANCE_METADATA_SUCCESS = 'GET_PERFORMANCE_METADATA_SUCCESS'
export const GET_PERFORMANCE_METADATA_FAIL = 'GET_PERFORMANCE_METADATA_FAIL'
export const RESET_PERFORMANCE_METADATA = 'RESET_PERFORMANCE_METADATA'

export const GET_PERFORMANCE_FORM_DATA = 'GET_PERFORMANCE_FORM_DATA'
export const GET_PERFORMANCE_FORM_DATA_SUCCESS = 'GET_PERFORMANCE_FORM_DATA_SUCCESS'
export const GET_PERFORMANCE_FORM_DATA_FAIL = 'GET_PERFORMANCE_FORM_DATA_FAIL'

export const SET_PERFORMANCE_FORM_DATA = 'SET_PERFORMANCE_FORM_DATA'
export const RESET_PERFORMANCE_ALL_DATA = 'RESET_PERFORMANCE_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'