import {
    AppBar, FormControl, Grid, MenuItem, Paper, Tab, Table,
    TableBody, TableContainer, TableFooter, TableHead, TableRow,Typography
} from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    ResultsTableCell, ResultTablesTabs, FormTitleTypography,
    FormHeaderPaper, ResultsTableRow, FarmDetailsSelect,
    ResultsTableTitleTypography, FormDescriptionTypography, InfoTooltip, InfoTooltipForGWP
} from '../../../components/CustomStyles/StyledComponents'
import DataChecksErrors from '../../../components/Errors/DataChecksErrors';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { getEnterpriseCompareToList, getWholeFarmDetailedTable, setLoading, setReportId, unsetLoading } from '../../../store/appAction';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { WHOLE_FARM_DETAILED_TABLES, SUMMARY_TABLE } from '../../../constants/shellTables';
import { useNavigate } from 'react-router-dom';
import CreatePDFButton from '../../../components/Buttons/CreatePDFButton';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';

const DataTable = ({ tableData, rowData }) => {
    const renderCustomTooltip = () => {
        const text = `<sup>†</sup> <em>GWP</em> is an alternative way of reporting methane emissions. Methane breaks
        down much faster than other greenhouse gasses but has a large upfront
        warming impact. GWP* aims to capture this effect better than the standard
        GWP100 metric. When measuring using GWP*, increased methane emissions
        have a large impact, but steady or declining emissions have a much smaller
        impact..`;
        return <div dangerouslySetInnerHTML={{__html: text}}/>
    };

    return <>
        {tableData &&
            <TableContainer sx={{ mb: 3 }}>
                <ResultsTableTitleTypography sx={{ mb: 2 }}>{tableData.tableTitle}</ResultsTableTitleTypography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <ResultsTableCell></ResultsTableCell>
                            {tableData.columns.map((column) => (
                                column === WHOLE_FARM_DETAILED_TABLES.methaneResults.columns[3] ?
                                <ResultsTableCell>
                                    {   
                                        <InfoTooltipForGWP title ={renderCustomTooltip()}>
                                            <div dangerouslySetInnerHTML={{__html: column}}/>
                                        </InfoTooltipForGWP>
                                    }
                                </ResultsTableCell>:
                                <ResultsTableCell>
                                {<div dangerouslySetInnerHTML={{__html: column}}/>}
                            </ResultsTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData && Object.entries(rowData)
                            .slice(0, Object.entries(rowData).length - (tableData.noFooter ? 0 : 1))
                            .map(([rowId, row]) => (
                                <ResultsTableRow>
                                    {Object.entries(row).map(([cellId, cell]) => (
                                        <ResultsTableCell>
                                            {cell ? addCommas(cell) : '--'}
                                        </ResultsTableCell>
                                    ))}
                                </ResultsTableRow>
                            ))}
                    </TableBody>
                    {!tableData.noFooter &&
                        <TableFooter>
                            {rowData && Object.entries(rowData)
                                .slice(Object.entries(rowData).length - 1)
                                .map(([rowId, row]) => (
                                    <ResultsTableRow>
                                        {Object.entries(row).map(([cellId, cell]) => (
                                            <ResultsTableCell>
                                                {cell ? addCommas(cell) : '--'}
                                            </ResultsTableCell>
                                        ))}
                                    </ResultsTableRow>
                                ))}
                        </TableFooter>}
                </Table>
            </TableContainer>
        }
    </>
}

const WholeFarmTables = () => {
    const dispatch = useDispatch();
    const detailedResults = useSelector(state => state.wholeFarm.tableDetailedResults);
    const reportId = useSelector(state => state.common.reportId);
    const reportList = useSelector(state => state.farm.reports);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);

    const [currentTab, setCurrentTab] = useState(0);
    const [shellTables, setShellTables] = useState([]);

    // useEffect(() => {
    //     dispatch(getWholeFarmDetailedTable({ reportId }));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getWholeFarmDetailedTable({ reportId }));
        dispatch(getEnterpriseCompareToList({ reportId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        setShellTables(currentTab === 0 ? SUMMARY_TABLE : WHOLE_FARM_DETAILED_TABLES);
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);


    const handleTabChange = (event, tab) => {
        setCurrentTab(tab);
        setShellTables(tab === 0 ? SUMMARY_TABLE : WHOLE_FARM_DETAILED_TABLES);
    };

    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }

    return (
        <>
            {detailedResults?.status === 1 ?
                <DataChecksErrors errorList={detailedResults.dataChecks} /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                            <FormTitleTypography variant="h5" component="div">
                                Resource use & Emissions Results
                            </FormTitleTypography>
                            <FormDescriptionTypography variant="body2" paragraph>
                                {`A ${currentTab === 0 ? 'summary' : 'detailed view'} of emissions from carbon dioxide, methane and nitrous oxide for the whole farm and per enterprise is presented below.`}
                                {/* {`Total emissions are also expressed per unit of output, per hectare and per livestock unit equivalent to allow comparisons to be made. Per unit of output is the most common way to express emissions associated with the production of food products.`} */}
                            </FormDescriptionTypography>
                            {/* <Typography variant="body2" paragraph>
                                {'All emissions are expressed as kg CO₂-eq, unless otherwise indicated.'}
                            </Typography> */}
                            <FormDescriptionTypography variant="body2" paragraph>
                                {currentTab === 0 ?
                                    'To view where the emissions came from select the Detailed tab below.' :
                                    'For a higher level view of emissions please select the Summary tab below.'}
                                {' To view this information at a higher level please select Whole Farm - Charts in the menu above.'}
                            </FormDescriptionTypography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <AppBar position="static">
                            <ResultTablesTabs
                                value={currentTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                variant="standard"
                                aria-label="full width tabs example"
                                autoFocus={true}
                            >
                                <Tab sx={{ textTransform: 'none' }} label="Summary" autoFocus={true}    />
                                <Tab sx={{ textTransform: 'none' }} label="Detailed" />
                            </ResultTablesTabs>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12} mb={0} mt={2}>
                        <Grid container direction="row">
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{
                                        width: '350px',
                                        // px: 2,
                                    }}
                                >
                                    <b>Report</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={reportId || ''}
                                        onChange={handleReportSelect}
                                    >
                                        <MenuItem value=""><em>Change Report...</em></MenuItem>
                                        {reportList.map((option) => (
                                            <MenuItem
                                                id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                                <InfoTooltip
                                    placement="right"
                                    title="Tip: If you would like to view a different report please select from this drop-down">
                                    <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginBottom: '5px', verticalAlign: 'bottom' }} fontSize='small' />
                                </InfoTooltip>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12} sx={{ textAlign: 'right' }} >
                                <CreatePDFButton referrer={'whole-farm'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ minHeight: 300 }}>
                        {Object.entries(shellTables).map(([tableKey, tableData]) => (
                            <>
                            <DataTable
                                tableId={tableKey}
                                tableData={tableData}
                                rowData={detailedResults[tableKey]} 
                            />
                            {/* The following code snippet has been commented out due to AGC-1761 */}
                            {/* {
                                tableKey === 'carbonSequestrationResults' &&
                                <Grid item xs={12}>
                                        <Typography variant="body2" paragraph>
                                            *Soil carbon modelling has higher uncertainty than other carbon estimates in Agrecalc. 
                                            Our soil carbon results now aim to reflect this uncertainty. 'Likely' statements indicate a high degree 
                                            of confidence your soil is gaining/losing carbon. 'Potential' statements indicate greater uncertainty, 
                                            and any loss/gain is likely to be smaller.
                                        </Typography>
                                </Grid>
                            } */}
                            </>
                        ))}
                    </Grid>
                </Grid >
            }
        </>
    )
}

export default WholeFarmTables