import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DeleteReportModal = ({ isOpen = false, handleResponse }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(isOpen);
  const handleClose = () => {
    // setOpen(false);
    handleResponse(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="body1" component="h2" sx={{ marginBottom: 2 }}>
          Are you sure you want to delete this report?
        </Typography>
        <Stack spacing={2} sx={{ marginLeft: 'auto' }} direction="row">
          <Button
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={() => handleResponse(true)}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={() => handleResponse(false)}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default DeleteReportModal;
