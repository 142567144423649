import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getPerformanceMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.performance.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getPerformanceMetadataSuccess(response?.data));
  }
  else
    return dispatch(getPerformanceMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getPerformanceMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_PERFORMANCE_METADATA_SUCCESS,
    payload: response
  };
};

const getPerformanceMetadataFail = () => {
  return {
    type: actionTypes.GET_PERFORMANCE_METADATA_FAIL,
  };
};

export const resetPerformanceMetadata = () => {
  return {
    type: actionTypes.RESET_PERFORMANCE_METADATA,
  };
};

export const getPerformanceFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.performance.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getPerformanceFormDataSuccess(response?.data?.data));
  }
  else
    return dispatch(getPerformanceFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getPerformanceFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_PERFORMANCE_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getPerformanceFormDataFail = () => {
  return {
    type: actionTypes.GET_PERFORMANCE_FORM_DATA_FAIL,
  };
};

export const setPerformanceFormData = (response) => {
  return {
    type: actionTypes.SET_PERFORMANCE_FORM_DATA,
    payload: response
  };
};

export const resetPerformanceFormData = () => {
  return {
    type: actionTypes.RESET_PERFORMANCE_ALL_DATA,
  };
};

export const savePerformanceData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().performance
  const response = await api.post(
    endpoints.livestock.performance.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(savePerformanceDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(savePerformanceDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const savePerformanceDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const savePerformanceDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};


