export const TRANSFERRED_MANURE_SET_COL_TOTAL = 'TRANSFERRED_MANURE_SET_COL_TOTAL'
export const TRANSFERRED_MANURE_RESET_COL_TOTAL = 'TRANSFERRED_MANURE_RESET_COL_TOTAL'

export const GET_TRANSFERRED_MANURE_METADATA = 'GET_TRANSFERRED_MANURE_METADATA'
export const GET_TRANSFERRED_MANURE_METADATA_SUCCESS = 'GET_TRANSFERRED_MANURE_METADATA_SUCCESS'
export const GET_TRANSFERRED_MANURE_METADATA_FAIL = 'GET_TRANSFERRED_MANURE_METADATA_FAIL'
export const RESET_TRANSFERRED_MANURE_METADATA = 'RESET_TRANSFERRED_MANURE_METADATA'

export const GET_TRANSFERRED_MANURE_FORM_DATA_SUCCESS = 'GET_TRANSFERRED_MANURE_FORM_DATA_SUCCESS'
export const GET_TRANSFERRED_MANURE_FORM_DATA_FAIL = 'GET_TRANSFERRED_MANURE_FORM_DATA_FAIL'

export const SET_TRANSFERRED_MANURE_FORM_DATA = 'SET_TRANSFERRED_MANURE_FORM_DATA'
export const RESET_TRANSFERRED_MANURE_ALL_DATA = 'RESET_TRANSFERRED_MANURE_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'
