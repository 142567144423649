export const GET_ORGANIC_MATERIAL_MANURES_METADATA = 'GET_ORGANIC_MATERIAL_MANURES_METADATA'
export const GET_ORGANIC_MATERIAL_MANURES_METADATA_SUCCESS = 'GET_ORGANIC_MATERIAL_MANURES_METADATA_SUCCESS'
export const GET_ORGANIC_MATERIAL_MANURES_METADATA_FAIL = 'GET_ORGANIC_MATERIAL_MANURES_METADATA_FAIL'
export const RESET_ORGANIC_MATERIAL_MANURES_METADATA = 'RESET_ORGANIC_MATERIAL_MANURES_METADATA'

export const GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_SUCCESS = 'GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_SUCCESS'
export const GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_FAIL = 'GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_FAIL'

export const SET_ORGANIC_MATERIAL_MANURES_FORM_DATA = 'SET_ORGANIC_MATERIAL_MANURES_FORM_DATA'
export const RESET_ORGANIC_MATERIAL_MANURES_ALL_DATA = 'RESET_ORGANIC_MATERIAL_MANURES_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS'
export const GET_OPTIONS_FAIL = 'GET_OPTIONS_FAIL' 