import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  metaData: {},
  totalArea: {},
  initialFormData: {},
  currentFormData: {},
};

const soilCarbonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SOIL_CARBON_METADATA_SUCCESS: return setMetaDataSuccess(state, action);
    case actionTypes.GET_SOIL_CARBON_METADATA_FAIL: return setMetaDataFail(state, action);
    case actionTypes.RESET_SOIL_CARBON_METADATA: return resetMetaData(state, action);
    case actionTypes.GET_SOIL_CARBON_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_SOIL_CARBON_FORM_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_SOIL_CARBON_FORM_DATA: return setFormData(state, action);
    case actionTypes.RESET_SOIL_CARBON_ALL_DATA: return resetFormData(state, action);
    case actionTypes.GET_CROP_TYPE_OPTIONS_SUCCESS: return setCropTypeOptionsSuccess(state, action);
    case actionTypes.GET_CROP_TYPE_OPTIONS_FAIL: return setCropTypeOptionsFail(state, action);
    case actionTypes.GET_GRAZING_SYSTEM_OPTIONS_SUCCESS: return setGrazingSystemOptionsSuccess(state, action);
    case actionTypes.GET_GRAZING_SYSTEM_OPTIONS_FAIL: return setGrazingSystemOptionsFail(state, action);
    case actionTypes.GET_TILLAGE_PRACTICE_OPTIONS_SUCCESS: return setTillagePracticeOptionsSuccess(state, action);
    case actionTypes.GET_TILLAGE_PRACTICE_OPTIONS_FAIL: return setTillagePracticeOptionsFail(state, action);
    default:
      return state;
  }
}

const setMetaDataSuccess = (state, action) => {
  const updatedState = {
    metaData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMetaDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedState = {
    currentFormData: {
      ...state.currentFormData,
      [sectorId]: {
        ...formData
      }
    },
  }
  return updateObject(state, updatedState);
}

const resetFormData = (state, action) => {
  const updatedState = {
    currentFormData: state.initialFormData,
  }
  return updateObject(state, updatedState);
}

const setCropTypeOptionsSuccess = (state, action) => {
  const updatedState = {
    fallowCoverCropTypeOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setCropTypeOptionsFail = (state, action) => {
  const updatedState = {
    fallowCoverCropTypeOptions: [],
  }
  return updateObject(state, updatedState);
}

const setGrazingSystemOptionsSuccess = (state, action) => {
  const updatedState = {
    grazingSystemOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setGrazingSystemOptionsFail = (state, action) => {
  const updatedState = {
    grazingSystemOptions: [],
  }
  return updateObject(state, updatedState);
}

const setTillagePracticeOptionsSuccess = (state, action) => {
  const updatedState = {
    tillagePracticeOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setTillagePracticeOptionsFail = (state, action) => {
  const updatedState = {
    tillagePracticeOptions: [],
  }
  return updateObject(state, updatedState);
}

export default soilCarbonReducer;