import { combineReducers } from 'redux';
import authReducer from './authentication/reducer';
import commonReducer from './common/reducer';

import farmReducer from './farm/reducer';
import farmReportReducer from './farm-report/reducer';

import landAreaCropsReducer from './land-crops/land-area-crops/reducer';
import fertiliserReducer from './land-crops/fertiliser/reducer';
import organicMaterialManuresReducer from './land-crops/organic-material-manures/reducer'
import transferredManureReducer from './land-crops/transferred-manure/reducer';
import pesticidesReducer from './land-crops/pesticides/reducer';
import cropProductionReducer from './land-crops/crop-production/reducer'
import cropUseReducer from './land-crops/crop-use/reducer'
import biocharReducer from './land-crops/biochar/reducer'
import soilCarbonReducer from './land-crops/soil-carbon/reducer'

import numbersWeightsReducer from './livestock/numbers-weights/reducer';
import purchasesSalesDeathsReducer from './livestock/purchase-sales-deaths/reducer';
import performanceReducer from './livestock/performance/reducer';
import manureManagementReducer from './livestock/manure-management/reducer';
import feedReducer from './livestock/feed/reducer';
import beddingReducer from './livestock/bedding/reducer';
import feedRationReducer from './livestock/feed-ration/reducer';
import monthlyStockReducer from './livestock/monthly-stock-tool/reducer';

import electricityFuelReducer from './energy-waste/electricity-fuel/reducer'
import electricityFuelContReducer from './energy-waste/electricity-fuel-cont/reducer'
import renewableElectricityReducer from './energy-waste/renewable-electricity/reducer'
import renewableHeatReducer from './energy-waste/renewable-heat/reducer'
import wasteTransportWaterReducer from './energy-waste/waste-transport-water/reducer'

import wholeFarmReducer from './whole-farm/reducer'
import enterpriseReducer from './enterprise/reducer';
import profileReducer from './profile/reducer';
import adminAccountReducer from './admin/account/reducer';
import adminFarmReducer from './admin/farm/reducer';
import farmsReducer from './farm/reducer'
import adminReportsReducer from './admin/report/reducer';
import organisationsReducer from './admin/organisation/reducer';
import officesReducer from './admin/office/reducer';
import projectReducer from './admin/project/reducer';

const appReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  farm: farmReducer,
  farmReport: farmReportReducer,
  landAreaCrops: landAreaCropsReducer,
  fertiliser: fertiliserReducer,
  organicMaterialManures: organicMaterialManuresReducer,
  transferredManure: transferredManureReducer,
  pesticides: pesticidesReducer,
  cropProduction: cropProductionReducer,
  cropUse: cropUseReducer,
  biochar: biocharReducer,
  soilCarbon: soilCarbonReducer,
  numberWeights: numbersWeightsReducer,
  purchasesSales: purchasesSalesDeathsReducer,
  performance: performanceReducer,
  manureManagement: manureManagementReducer,
  feed: feedReducer,
  bedding: beddingReducer,
  feedRation: feedRationReducer,
  electricityFuel: electricityFuelReducer,
  electricityFuelCont: electricityFuelContReducer,
  renewableElectricity: renewableElectricityReducer,
  renewableHeat: renewableHeatReducer,
  wasteTransportWater: wasteTransportWaterReducer,
  wholeFarm: wholeFarmReducer,
  enterprise: enterpriseReducer,
  profile: profileReducer,
  adminAccount: adminAccountReducer,
  adminFarm: adminFarmReducer,
  farms: farmsReducer,
  adminReports: adminReportsReducer,
  adminOrganisation: organisationsReducer,
  adminOffice: officesReducer,
  adminProject: projectReducer,
  monthlyStock: monthlyStockReducer
});

export default appReducer;
