import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getElectricityFuelMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.electricityFuel.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getElectricityFuelMetadataSuccess(response?.data));
  } else
    return dispatch(getElectricityFuelMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getElectricityFuelMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_METADATA_SUCCESS,
    payload: response
  };
};

const getElectricityFuelMetadataFail = () => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_METADATA_FAIL,
  };
};

export const resetElectricityFuelMetaData = () => {
  return {
    type: actionTypes.RESET_ELECTRICITY_FUEL_METADATA,
  };
};

export const getElectricityFuelFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.electricityFuel.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    const data = response?.data?.data
    dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getElectricityFuelFormDataSuccess(data));
  } else
    return dispatch(getElectricityFuelFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getElectricityFuelFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getElectricityFuelFormDataFail = () => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_FORM_DATA_FAIL,
  };
};

const getWholeFarmData = (response) => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
}

export const saveElectricityFuelData = ({ reportId }) => async (dispatch) => {
  const { currentFormData, currentWholeFarmData } = store.getState().electricityFuel
  const response = await api.post(endpoints.energyWaste.electricityFuel.saveData,
    {
      reportId: reportId,
      data: currentFormData,
      wholeFarmData: currentWholeFarmData
    });
  if (response && response.status === 200) {
    return dispatch(saveElectricityFuelDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveElectricityFuelDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveElectricityFuelDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveElectricityFuelDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setElectricityFuelFormData = (response) => {
  return {
    type: actionTypes.SET_ELECTRICITY_FUEL_FORM_DATA,
    payload: response
  };
};

export const setWholeFarmData = (response) => {
  return {
    type: actionTypes.SET_ELECTRICITY_FUEL_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
};

export const resetElectricityFuelFormData = () => {
  return {
    type: actionTypes.RESET_ELECTRICITY_FUEL_ALL_DATA,
  };
};

export const getAllocateElectricityFuel = ({ reportId, currentWholeFarmData }) => async (dispatch) => {
  const response = await api.post(endpoints.energyWaste.electricityFuel.defaults
    .concat('?ReportId=', reportId)
    .concat('&Electricity=', currentWholeFarmData.Electricity)
    .concat('&RedDieselContractorsUse=', currentWholeFarmData.RedDieselContractorsUse)
    .concat('&RedDieselGrainDrying=', currentWholeFarmData.RedDieselGrainDrying)
    .concat('&WhiteDiesel=', currentWholeFarmData.WhiteDiesel)
    .concat('&Petrol=', currentWholeFarmData.Petrol)
  );
  if (response && response.status === 200) {
    const data = response?.data
    //dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getElectricityFuelFormDataSuccess(data));
  } else
    return dispatch(getAllocateElectricityFuelFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getAllocateElectricityFuelFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};