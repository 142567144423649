import { Grid, Paper } from '@mui/material'
import React, { } from 'react'
import { FormHeaderPaper, ParagraphTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';

const Contents = ({ results = {}, firstChild = false }) => {
    return (
        <Paper sx={{
            maxWidth: '860px',
            // minHeight: '500px',
            pageBreakAfter: 'always',
            pageBreakInside: 'avoid',
            marginTop: firstChild ? '160px' : '70px',
        }}
            p={2}
        >
            {/* <Grid item xs={12}>
                    <FormHeaderPaper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <ParagraphTypographyPDF variant="body2" paragraph>
                            {`There is a strong correlation between efficiency, profitability and low carbon emissions.  The lower your carbon footprint, 
                            the more effective inputs have been at generating saleable product i.e. increased utilisation of costly inputs.  
                            Each farm and system have natural limitations but, within this context, the process can identify carbon ‘hotspots’  
                            on farm and is therefore a steer to improve efficiency and reduce greenhouse gas emissions.`}
                        </ParagraphTypographyPDF>

                        <ParagraphTypographyPDF variant="body2" paragraph>
                            {`The more accurate the information entered, the more meaningful the output.  
                            Where possible on-farm records should be used to provide accurate farm-level data.`}
                        </ParagraphTypographyPDF>
                    </FormHeaderPaper>
                </Grid> */}
            <Grid item xs={12}>
                <FormHeaderPaper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    divider={false}
                >
                    <ParagraphTypographyPDF variant="body2" paragraph>
                        *Your carbon footprint is expressed in units of CO<sub>2 </sub>
                        equivalents (CO<sub>2</sub>e) per unit of output e.g. kg CO<sub>2</sub>e per kg dwt of cold carcase.
                        This allows the efficiency of the enterprise to be compared.
                        The main greenhouse gases emitted by agriculture are
                        CH<sub>4</sub> = Methane (Predominantly from animal digestion);
                        N<sub>2</sub>O = Nitrous oxide (Predominantly from manure and fertiliser);
                        CO<sub>2</sub> = Carbon dioxide (Predominantly from burning of fossil fuels).
                    </ParagraphTypographyPDF>
                    <ParagraphTypographyPDF variant="body2" paragraph>
                        {`** Other includes crop residues, lime, transport and waste.`}
                    </ParagraphTypographyPDF>
                    <ParagraphTypographyPDF variant="body2" paragraph>
                        {`*** Total emission may differ due to rounding.  
                        Emissions may be skewed on a year to year basis due to timing of sales therefore results are best monitored over a three year (minimum) period.`}
                    </ParagraphTypographyPDF>
                </FormHeaderPaper>
            </Grid>
        </Paper>
    )
}

export default Contents;