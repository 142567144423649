export const GET_RENEWABLE_HEAT_METADATA = 'GET_RENEWABLE_HEAT_METADATA'
export const GET_RENEWABLE_HEAT_METADATA_SUCCESS = 'GET_RENEWABLE_HEAT_METADATA_SUCCESS'
export const GET_RENEWABLE_HEAT_METADATA_FAIL = 'GET_RENEWABLE_HEAT_METADATA_FAIL'
export const RESET_RENEWABLE_HEAT_METADATA = 'RESET_RENEWABLE_HEAT_METADATA'

export const GET_RENEWABLE_HEAT_FORM_DATA_SUCCESS = 'GET_RENEWABLE_HEAT_FORM_DATA_SUCCESS'
export const GET_RENEWABLE_HEAT_FORM_DATA_FAIL = 'GET_RENEWABLE_HEAT_FORM_DATA_FAIL'

export const SET_RENEWABLE_HEAT_FORM_DATA = 'SET_RENEWABLE_HEAT_FORM_DATA'
export const RESET_RENEWABLE_HEAT_ALL_DATA = 'RESET_RENEWABLE_HEAT_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_ALLOCATE_RENEWABLE_HEAT_SUCCESS = 'GET_ALLOCATE_RENEWABLE_HEAT_SUCCESS'
export const GET_ALLOCATE_RENEWABLE_HEAT_FAIL = 'GET_ALLOCATE_RENEWABLE_HEAT_FAIL'

export const GET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS = 'GET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS'
export const SET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS = 'SET_RENEWABLE_HEAT_WHOLE_FARM_DATA_SUCCESS'