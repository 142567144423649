export const SET_ADMIN_ACCOUNT_USER_ID = 'SET_ADMIN_ACCOUNT_USER_ID'

export const GET_ADMIN_ACCOUNT_DATA_SUCCESS = 'GET_ADMIN_ACCOUNT_DATA_SUCCESS'
export const GET_ADMIN_ACCOUNT_DATA_FAIL = 'GET_ADMIN_ACCOUNT_DATA_FAIL'

export const GET_ALL_ADMIN_ACCOUNT_DATA_SUCCESS = 'GET_ALL_ADMIN_ACCOUNT_DATA_SUCCESS'
export const GET_ALL_ADMIN_ACCOUNT_DATA_FAIL = 'GET_ALL_ADMIN_ACCOUNT_DATA_FAIL'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const SET_LOADING = 'SET_LOADING'
export const UNSET_LOADING = 'UNSET_LOADING'