import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  columnTotal: {
    OwnedAndTenantedLandTotal: 0,
    SeasonalLandTotal: 0
  },
  totalArea: {
    1: {
      1: 25.33,
      2: 45.33,
      3: 10.43,
      4: 6.44,
      5: 45.45,

    },
    2: {
      1: 2.33,
      2: 5.66,
      3: 3.4
    },
    3: {
      1: 2.33,
      2: 5.66,
      3: 3.43,
      4: 3.44,
      5: 3.45,
      6: 3.46,
      7: 3.47,
      8: 3.48
    }
  },
  landUseOptions: [],
  metaData: {},
  initialFormData: {},
  currentFormData: {},
  initialReportData: {},
  currentReportData: {},
};

const landAreaCropsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LAND_AREA_CROPS_SET_COL_TOTAL: return setColumnTotal(state, action);
    case actionTypes.LAND_AREA_CROPS_RESET_COL_TOTAL: return resetColumnTotal(state, action);
    case actionTypes.LAND_AREA_CROPS_SET_TOTAL_AREA: return setTotalArea(state, action);
    case actionTypes.GET_LAND_AREA_CROPS_METADATA_SUCCESS: return setMetadataSuccess(state, action);
    case actionTypes.GET_LAND_AREA_CROPS_METADATA_FAIL: return setMetadataFail(state, action);
    case actionTypes.RESET_LAND_AREA_CROPS_METADATA: return resetMetaData(state, action);
    case actionTypes.GET_LAND_AREA_CROPS_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_LAND_AREA_CROPS_MISC_DATA_SUCCESS: return setMiscDataSuccess(state, action);
    case actionTypes.SET_LAND_AREA_CROPS_MISC_DATA: return setMiscData(state, action);
    case actionTypes.GET_LAND_AREA_CROPS_FORM_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_LAND_AREA_CROPS_FORM_DATA: return setFormData(state, action);
    case actionTypes.GET_LAND_AREA_CROPS_OPTIONS_SUCCESS: return setLandUseOptionsSuccess(state, action);
    case actionTypes.GET_LAND_AREA_CROPS_OPTIONS_FAIL: return setLandUseOptionsFail(state, action);
    default:
      return state;
  }
};

const setColumnTotal = (state, action) => {
  const updatedState = {
    columnTotal: action.payload,
  }
  return updateObject(state, updatedState);
}

const resetColumnTotal = (state, action) => {
  const updatedState = {
    columnTotal: initialState.columnTotal,
  }
  return updateObject(state, updatedState);
}

const setTotalArea = (state, action) => {
  const { tableId, rowId, areaValue } = action.payload;
  const updatedState = {
    totalArea: {
      ...state.totalArea,
      [tableId]: {
        ...state.totalArea[tableId],
        [rowId]: areaValue
      }
    },
  }
  return updateObject(state, updatedState);
}

const setLandUseOptionsSuccess = (state, action) => {
  const updatedState = {
    landUseOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setLandUseOptionsFail = (state, action) => {
  const updatedState = {
    landUseOptions: [],
  }
  return updateObject(state, updatedState);
}

const setMetadataSuccess = (state, action) => {
  const updatedState = {
    metaData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMetadataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
  const updatedState = {
    columnTotal: {
      OwnedAndTenantedLandTotal: 0,
      SeasonalLandTotal: 0
    },
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMiscDataSuccess = (state, action) => {
  const updatedState = {
    initialReportData: action.payload,
    currentReportData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedState = {
    currentFormData: {
      ...state.currentFormData,
      [sectorId]: {
        ...formData
      }
    },
  }
  return updateObject(state, updatedState);
}

const setMiscData = (state, action) => {
  const updatedState = {
    currentReportData: {
      ...state.currentReportData,
      ...action.payload
    }
  }
  return updateObject(state, updatedState);
}

export default landAreaCropsReducer;
