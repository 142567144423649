import * as actionTypes from './types';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import queryString from 'query-string';

export const getAllAdminReports = (params) => async (dispatch) => {
    try {
        const response = await api.get(endpoints.admin.report.concat('?', queryString.stringify(params))) /*response1*/;
        if (response && response.status === 200) {
            return dispatch(getAllAdminReportsSuccess(response?.data));
        }
        else
            return dispatch(getAllAdminReportsFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    } catch (e) {
        return dispatch(getAllAdminReportsFail(e.message));
    }
};

const getAllAdminReportsSuccess = (response) => {
    return {
        type: actionTypes.GET_ALL_ADMIN_REPORTS_DATA_SUCCESS,
        payload: response
    };
};

export const clearAllAdminReportsData = (response) => {
    return {
        type: actionTypes.CLEAR_ALL_ADMIN_REPORTS_DATA,
        payload: response
    };
};

const getAllAdminReportsFail = (response) => {
    return {
        type: actionTypes.GET_ALL_ADMIN_REPORTS_DATA_FAIL,
        payload: response
    };
};

export const deleteAdminReport = (reportId) => async (dispatch) => {
    try {
        const response = await api.del(endpoints.admin.report.concat('?reportId=', reportId));
        if (response && (response.status === 200 || response.status === 204)) {
            return dispatch(deleteAdminReportSuccess(response.data || "The Farm has been successfully deleted"));
        }
        else {
            return dispatch(deleteAdminReportFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (error) {
        if(error && error?.response?.status === 403){
            return dispatch(deleteAdminReportFail("You are not authorized to delete the report."));
        }
    }
};

const deleteAdminReportSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const deleteAdminReportFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response

    };
};
