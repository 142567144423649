export const GET_WHOLE_FARM_DETAILED_TABLE_DATA_SUCCESS = 'GET_WHOLE_FARM_DETAILED_TABLE_DATA_SUCCESS'
export const GET_WHOLE_FARM_DETAILED_TABLE_DATA_FAIL = 'GET_WHOLE_FARM_DETAILED_TABLE_DATA_FAIL'

export const GET_WHOLE_FARM_SUMMARY_TABLE_DATA_SUCCESS = 'GET_WHOLE_FARM_SUMMARY_TABLE_DATA_SUCCESS'
export const GET_WHOLE_FARM_SUMMARY_TABLE_DATA_FAIL = 'GET_WHOLE_FARM_SUMMARY_TABLE_DATA_FAIL'

export const GET_WHOLE_FARM_CHART_DATA_SUCCESS = 'GET_WHOLE_FARM_CHART_DATA_SUCCESS'
export const GET_WHOLE_FARM_CHART_DATA_FAIL = 'GET_WHOLE_FARM_CHART_DATA_FAIL'

export const GET_WHOLE_FARM_REPORT_DATA_SUCCESS = 'GET_WHOLE_FARM_REPORT_DATA_SUCCESS'
export const GET_WHOLE_FARM_REPORT_DATA_FAIL = 'GET_WHOLE_FARM_REPORT_DATA_FAIL'

export const RESET_WHOLE_FARM_DATA = 'RESET_WHOLE_FARM_DATA'