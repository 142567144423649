import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { getOrganisationData, resetResponseState, unsetLoading , setLoading, setCurrentAdminOffice, deleteOffice} from '../../store/appAction';
import CancelModal from '../Modal/CancelModal';
import { EDIT } from '../../constants/modes';
import { ADMIN, CONSULTANT, FARMER, SUPPER_ADMIN } from '../../constants/userRoles';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';
import DeleteModal from '../../components/Modal/DeleteModal';
import CommonModal from '../../components/Modal/CommonModal';


const OfficeForm = props => {
    const {
        values: {
            id,
            name,
            organisationId
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleReset,
        resetForm,
        mode,
        role
    } = props;
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMsg, errorMsg } = useSelector(state => state.common);
    const [modalOpen, setModalOpen] = useState(false);
    const organisationOptions = useSelector(state => state.common.organisations);
    const selectedOffice = useSelector(state => state.adminOffice.selectedOffice);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [commonModalOpen, setCommonModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getOrganisationData());
        return () => {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                (mode !== EDIT) && resetForm();
                toastr.success(successMsg);
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    const handleCancelModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            handleReset();
        }
    }

    const handelBackResponse = () => {
        navigate('/admin/offices/list');
    }

    const handleDelete = async (e, office) => {
        dispatch(setLoading());
        const response = await api.get(
          endpoints.admin.office.officeUsers.replace("OFFICE_ID", selectedOffice.id)
        );
        if (response && response.status === 200) {
          if (response.data?.hasAnyUsers) {
            setCommonModalOpen(true);
          } else {
            setDeleteModalOpen(true);
          }
        } else{
          dispatch(unsetLoading());
          toastr.error(
            response?.data?.error?.message ||
              "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.",
            undefined,
            toastrCustomOptionsLonger
          );
        }
    };

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            dispatch(setLoading());
            dispatch(deleteOffice());
            navigate('/admin/offices/list');
        }
        dispatch(unsetLoading());
    }

    const handleCommonModalResponse = (positiveResponse) => {
        setCommonModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            dispatch(setLoading());
            dispatch(deleteOffice());
            navigate('/admin/offices/list');
        }
        dispatch(unsetLoading());
    }
    

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 1, ml: 20, pr: 30 }}>
                <Grid
                    spacing={1}
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            autoComplete="name"
                            label="Office Name"
                            helperText={touched.name ? errors.name : ""}
                            error={Boolean(errors.name)}
                            value={name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        < FormControl fullWidth error={Boolean(errors.organisationId)}>
                            <InputLabel id="organisationId-select">Organisation Name</InputLabel>
                            <Select
                                fullWidth
                                name="organisationId"
                                label="Organisation Name"
                                id="organisationId"
                                labelId="organisationId-select"
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                value={organisationId}
                                helperText={touched.organisationId ? errors.organisationId : ""}
                            >
                                {(organisationOptions || []).map((option) => (
                                    <MenuItem
                                        id={option.id}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.organizationName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Stack spacing={2} sx={{ marginLeft: 'auto', marginTop: 3 }} direction="row" >
                        <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                            onClick={handelBackResponse}>
                            Back
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalOpen(true);
                            }}>
                            Cancel
                        </Button>
                        {(mode === EDIT) && [ADMIN, SUPPER_ADMIN].includes(role) &&
                            <Button variant="outlined" sx={{ textTransform: 'none' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete();
                                }}>
                                Delete
                            </Button>
                        }
                        <Button type="submit" variant="contained" sx={{ textTransform: 'none' }}>
                            Save
                        </Button>
                    </Stack>
                </Grid>
                <CancelModal isOpen={modalOpen} handleResponse={handleCancelModalResponse} />
                <DeleteModal
                    isOpen={deleteModalOpen}
                    handleResponse={handleDeleteModalResponse}
                    value={'Are you sure you want to delete this Office?'}
                />
                <CommonModal
                    isOpen={commonModalOpen}
                    value='If you remove this office then all users within this office will have access to all of the organisations farms. Do you wish to continue?'
                    handleResponse={(value)=>{
                        handleCommonModalResponse(value);
                    }} 
                />
            </Box>

        </>
    )
}

export default OfficeForm