import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Link, ListSubheader, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import _ from 'lodash';

const SCOTLAND = 'Scotland';

const RegisterForm = props => {
  const {
    values: {
      email,
      firstName,
      lastName,
      country,
      postCode,
      farmName,
      holdingNumber,
      refNumber,
      advisoryService,
      // reasonForService,
      allowExtraEmails,
      termsCondAgreed
    },
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    handleReset,
  } = props;
  const countries = useSelector(state => state.common.countries);
  const { successMsg } = useSelector(state => state.common);
  const [labelForBRN, setLabelForBRN] = useState('Business Reference Number (BRN)');

  const scotland = _.find(countries, ['name', SCOTLAND]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg]);

  useEffect(() => {
    const selectedCountry = countries?.find( ({ id }) => id === country);
    switch(selectedCountry?.name){
        case 'England' : setLabelForBRN('Single Business Identifier (SBI)'); break;
        case 'Wales' : setLabelForBRN('Customer Reference Number (CRN)'); break;
        case 'N Ireland' : setLabelForBRN('Business IDentifier (BID)'); break;
        default : setLabelForBRN('Business Reference Number (BRN)'); break;
    }
}, [country]);

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            id="email"
            name="email"
            autoComplete="email"
            label="Email Address"
            helperText={touched.email ? errors.email : ""}
            error={Boolean(touched.email ? errors.email : false)}
            value={email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            autoComplete="given-name"
            label="First Name"
            helperText={touched.firstName ? errors.firstName : ""}
            error={Boolean(touched.firstName ? errors.firstName : false)}
            value={firstName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            autoComplete="family-name"
            label="Last Name"
            helperText={touched.lastName ? errors.lastName : ""}
            error={Boolean(touched.lastName ? errors.lastName : false)}
            value={lastName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl
            fullWidth
            error={Boolean(touched.country ? errors.country : false)}
          >
            <InputLabel id="country-select">Country</InputLabel>
            <Select
              fullWidth
              name="country"
              label="Country"
              id="country"
              labelId="country-select"
              autoComplete="country"
              value={country}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <ListSubheader disableSticky={true} sx={{ pl: 2 }}>UK</ListSubheader>
              {(countries ? countries : []).map((option) => (
                (option.countryCategory === 1) && <MenuItem
                  id={option.id}
                  key={option.id}
                  value={option.id}
                  sx={{ pl: 3 }}
                >
                  - {option.name}
                </MenuItem>
              ))}
              <ListSubheader disableSticky={true} sx={{ pl: 2 }}>----</ListSubheader>
              {(countries ? countries : []).map((option) => (
                (option.countryCategory !== 1) && <MenuItem
                  id={option.id}
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {touched.country && <FormHelperText>{errors.country}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            name="postCode"
            id="postCode"
            autoComplete="postcode"
            label="Zip or Postcode"
            helperText={touched.postCode ? errors.postCode : ""}
            error={Boolean(touched.postCode ? errors.postCode : false)}
            value={postCode}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="farmName"
            id="farmName"
            autoComplete="farm-name"
            label="Farm Name"
            helperText={touched.farmName ? errors.farmName : ""}
            error={Boolean(touched.farmName ? errors.farmName : false)}
            value={farmName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            name="holdingNumber"
            id="holdingNumber"
            autoComplete="holding-number"
            label="Holding Number"
            helperText={touched.holdingNumber ? errors.holdingNumber : ""}
            error={Boolean(touched.holdingNumber ? errors.holdingNumber : false)}
            value={holdingNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            fullWidth
            name="refNumber"
            id="refNumber"
            autoComplete="reference-number"
            label={labelForBRN}
            helperText={touched.refNumber ? `${labelForBRN} is required` : ""}
            error={Boolean(touched.refNumber ? errors.refNumber : false)}
            value={refNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} >
          {scotland && (scotland.id === country) && (
            <FormControlLabel
              control={
                <Checkbox
                  name="advisoryService"
                  color="secondary"
                  helperText={touched.advisoryService ? errors.advisoryService : ""}
                  error={Boolean(touched.advisoryService ? errors.advisoryService : false)}
                  value={advisoryService}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              }
              label="Farm Advisory Service"
            />
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            name="reasonForService"
            id="reasonForService"
            autoComplete="reason-for-service"
            label="Reason for using this service?"
            helperText={touched.reasonForService ? errors.reasonForService : ""}
            error={Boolean(touched.reasonForService ? errors.reasonForService : false)}
            value={reasonForService}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid> */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="allowExtraEmails"
                color="secondary"
                helperText={touched.allowExtraEmails ? errors.allowExtraEmails : ""}
                error={Boolean(touched.allowExtraEmails ? errors.allowExtraEmails : false)}
                value={allowExtraEmails}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            }
            label="I would like to receive the Agrecalc Newsletter"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color={Boolean(touched.termsCondAgreed ? errors.termsCondAgreed : false) ?
              "error" : "text.primary"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="termsCondAgreed"
                  color={Boolean(errors.termsCondAgreed) ? "error" : "secondary"}
                  helperText={touched.termsCondAgreed ? errors.termsCondAgreed : ""}
                  checked={termsCondAgreed}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              }
              label="I agree to the"
              error={Boolean(touched.termsCondAgreed ? errors.termsCondAgreed : false)}
              sx={{ mr: 1 }}
            ></FormControlLabel>
            <Link
              color={Boolean(touched.termsCondAgreed ? errors.termsCondAgreed : false) ?
                "error" : "primary"}
              underline="hover"
              target="_blank"
              variant='body1'
              sx={{
                marginTop: '9px',
                display: 'inline-flex',
                position: 'absolute',
              }}
              href="https://www.agrecalc.com/terms/">
              Terms of Service
            </Link>
          </Typography>
          {touched.termsCondAgreed &&
            <FormHelperText
              error={Boolean(touched.termsCondAgreed ? errors.termsCondAgreed : false)}
            >
              {errors.termsCondAgreed}
            </FormHelperText>}
        </Grid>
      </Grid>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        size="large"
        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
      >
        Register
      </Button>
    </Box>
  )
}

export default RegisterForm