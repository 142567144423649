import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  metaData: {},
  totalArea: {},
  initialFormData: {},
  currentFormData: {},
};

const biocharReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BIOCHAR_METADATA_SUCCESS: return setMetaDataSuccess(state, action);
    case actionTypes.GET_BIOCHAR_METADATA_FAIL: return setMetaDataFail(state, action);
    case actionTypes.RESET_BIOCHAR_METADATA: return resetMetaData(state, action);
    case actionTypes.GET_BIOCHAR_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_BIOCHAR_FORM_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_BIOCHAR_FORM_DATA: return setFormData(state, action);
    case actionTypes.RESET_BIOCHAR_ALL_DATA: return resetFormData(state, action);
    case actionTypes.GET_TEMPERATURE_TYPE_OPTIONS_SUCCESS: return setTemperatureTypeOptionsSuccess(state, action);
    case actionTypes.GET_TEMPERATURE_TYPE_OPTIONS_FAIL: return setTemperatureTypeOptionsFail(state, action);
    case actionTypes.GET_FEEDSTOCK_TYPE_OPTIONS_SUCCESS: return setFeedstockTypeOptionsSuccess(state, action);
    case actionTypes.GET_FEEDSTOCK_TYPE_OPTIONS_FAIL: return setFeedstockTypeOptionsFail(state, action);
    default:
      return state;
  }
}

const setMetaDataSuccess = (state, action) => {
  const updatedState = {
    metaData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMetaDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedState = {
    currentFormData: {
      ...state.currentFormData,
      [sectorId]: {
        ...formData
      }
    },
  }
  return updateObject(state, updatedState);
}

const resetFormData = (state, action) => {
  const updatedState = {
    currentFormData: state.initialFormData,
  }
  return updateObject(state, updatedState);
}

const setTemperatureTypeOptionsSuccess = (state, action) => {
  const updatedState = {
    temperatureTypesOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setTemperatureTypeOptionsFail = (state, action) => {
  const updatedState = {
    temperatureTypesOptions: [],
  }
  return updateObject(state, updatedState);
}

const setFeedstockTypeOptionsSuccess = (state, action) => {
  const updatedState = {
    feedstockTypesOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFeedstockTypeOptionsFail = (state, action) => {
  const updatedState = {
    feedstockTypesOptions: [],
  }
  return updateObject(state, updatedState);
}

export default biocharReducer;
