import { Backdrop, CircularProgress, Grid, ThemeProvider } from '@mui/material'
import { Formik } from 'formik';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormHeaderPaper, FormTitleTypography, MyFarmPaper } from '../../components/CustomStyles/StyledComponents'
import { mainTheme } from '../../theme';
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import {
  getAllCountries, getFarmOwnersData, getMyFarm,
  getOrganisationData, resetResponseState, setLoading, unsetLoading, updateMyFarm
} from '../../store/appAction';
import * as Yup from "yup";
import _ from 'lodash';
import FarmForm from '../../components/Forms/FarmForm';
import { ukPostCodeAlt } from '../../constants/regex';
import { EDIT } from '../../constants/modes';

const MyFarm = () => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();

  const farm = useSelector(state => state.profile.farm);
  const farmId = useSelector(state => state.common.farmId);
  const user = useSelector(state => state.auth.user);
  const { countries, successMsg, errorMsg } = useSelector(state => state.common);

  const [intFarm, setIntFarm] = useState(undefined);
  const [formData, setFormData] = useState(undefined);

  const validationSchema = Yup.object({
    farmName: Yup.string("Enter first name").trim().required("First name is required"),
    holdingNumber: Yup.string("").trim().required("Holding Number is required"),
    addressLine1: Yup.string("").trim().required("Address 1 is required"),
    addressLine2: Yup.string("").trim().required("Address 2 is required"),
    countryId: Yup.string("").trim().required("Country is required"),
    townCity: Yup.string("").trim().required("Town/City is required"),
    zipPostCode: Yup.string("").trim().required("Postcode is required")
      .when('countryId', {
        is: (countryId) => (_.find(countries, { id: countryId })?.countryCategory || -1) === 1,
        then: Yup.string().matches(ukPostCodeAlt, 'Enter a valid postcode'),
      }),
    businessReferenceNumber: Yup.string("").trim().required("Business Reference Number is required")
  });

  useEffect(() => {
    dispatch(getMyFarm(farmId));
    dispatch(getAllCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (intFarm && !_.isEmpty(intFarm)) {
      setFormData({
        id: intFarm?.id || '',
        farmName: intFarm?.farmName || '',
        farmOwnerUserId: intFarm?.farmOwnerUserId || '',
        holdingNumber: intFarm?.holdingNumber || '',
        addressLine1: intFarm?.addressLine1 || '',
        addressLine2: intFarm?.addressLine2 || '',
        townCity: intFarm?.townCity || '',
        countryId: intFarm?.countryId || '',
        zipPostCode: intFarm?.zipPostCode || '',
        businessReferenceNumber: intFarm?.businessReferenceNumber || '',
        farmAdvisoryService: intFarm?.farmAdvisoryService || false,
        // license: 'Paid'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intFarm]);

  useEffect(() => {
    setIntFarm(farm);
  }, [farm])


  const handleSubmit = (data) => {
    dispatch(setLoading());
    dispatch(updateMyFarm(data));
    setIntFarm(data);
  }

  return (
    <>
      <ThemeProvider theme={mainTheme}>
        <MyFarmPaper>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FormHeaderPaper sx={{ marginTop: 1.5, padding: 2 }}>
                <FormTitleTypography variant="h5" gutterBottom component="div">
                  Farm Details
                </FormTitleTypography>
              </FormHeaderPaper>
            </Grid>
            <Grid textAlign={'right'} item xs={4}>
              <FormTitleTypography sx={{ margin: 1.5, padding: 2 }} variant="h5" gutterBottom component="div">
                Farm ID : {intFarm?.farmOwnerUserId}
              </FormTitleTypography>
            </Grid>
          </Grid>
          {
            formData &&
            <Formik
              render={props => <FarmForm {...props} role={user?.role} mode={EDIT} />}
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            />
          }
        </MyFarmPaper>
      </ThemeProvider>
    </>
  )
}

export default MyFarm


