import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const setColumnTotal = (total) => {
  return {
    type: actionTypes.TRANSFERRED_MANURE_SET_COL_TOTAL,
    payload: total
  };
};
export const resetColumnTotal = (total) => {
  return {
    type: actionTypes.TRANSFERRED_MANURE_RESET_COL_TOTAL,
  };
};

export const getColumnTotal = () => {
  const columnTotal = store.getState().transferredManure.columnTotal
  return columnTotal;
}

export const getTransferredManureMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.transferredManure.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getTransferredManureMetadataSuccess(response?.data));
  } else
    return dispatch(getTransferredManureMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getTransferredManureMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_TRANSFERRED_MANURE_METADATA_SUCCESS,
    payload: response
  };
};

const getTransferredManureMetadataFail = () => {
  return {
    type: actionTypes.GET_TRANSFERRED_MANURE_METADATA_FAIL,
  };
};

export const resetTransferredManureMetaData = () => {
  return {
    type: actionTypes.RESET_TRANSFERRED_MANURE_METADATA,
  };
};

export const getTransferredManureFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.transferredManure.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getTransferredManureFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getTransferredManureFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getTransferredManureFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_TRANSFERRED_MANURE_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getTransferredManureFormDataFail = () => {
  return {
    type: actionTypes.GET_TRANSFERRED_MANURE_FORM_DATA_FAIL,
  };
};

export const saveTransferredManureData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().transferredManure
  const response = await api.post(endpoints.landCrops.transferredManure.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveTransferredManureDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveTransferredManureDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveTransferredManureDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveTransferredManureDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setTransferredManureFormData = (response) => {
  return {
    type: actionTypes.SET_TRANSFERRED_MANURE_FORM_DATA,
    payload: response
  };
};

export const resetTransferredManureFormData = () => {
  return {
    type: actionTypes.RESET_TRANSFERRED_MANURE_ALL_DATA,
  };
};




