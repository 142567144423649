import { Paper, Typography } from '@mui/material'
import React, { } from 'react'
import { DocumentTitleTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';
import { REPORT_STATUS } from '../../../../constants/arrays';
import dayjs from 'dayjs';

const MainPage = ({ farmId, farmName, reportId, reportName, reportingPeriod, modelNumber,yearEnd }) => {
    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                //minHeight: '500px',
                pageBreakAfter: 'always',
                marginTop: '70px',
            }}
                p={2}
            >
                <DocumentTitleTypographyPDF variant="h3" component="div" fontWeight="500" my={5}>
                    Carbon Footprint Report
                </DocumentTitleTypographyPDF>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Farm:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${farmName} (ID ${farmId})`}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Report:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${reportName} (ID ${reportId})`}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Reporting Period:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${dayjs(reportingPeriod?.start).format('M/YYYY')} - ${dayjs(reportingPeriod?.end).format('M/YYYY')}`}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Year End:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {dayjs(yearEnd).format('MMM YYYY')}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Model Version:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${modelNumber}`}
                    </Typography>
                </Typography>
            </Paper>
        </>
    )
}

export default MainPage