import { updateObject } from '../../utility';
import * as actionTypes from './types';

const initialState = {
  reports: undefined,
};

const adminReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_ADMIN_REPORTS_DATA_SUCCESS: return setReportsDataSuccess(state, action);
    case actionTypes.GET_ALL_ADMIN_REPORTS_DATA_FAIL: return setReportsDataFail(state, action);
    case actionTypes.CLEAR_ALL_ADMIN_REPORTS_DATA: return clearAllAdminReportsData(state, action);
    default:
      return state;
  }
};

const setReportsDataSuccess = (state, action) => {
  const updatedState = {
    reports: action.payload,
  }
  return updateObject(state, updatedState);
}

const setReportsDataFail = (state, action) => {
  const updatedState = {
    reports: [],
  }
  return updateObject(state, updatedState);
}

const clearAllAdminReportsData = (state, action) => {
  const updatedState = {
    reports: [],
  }
  return updateObject(state, updatedState);
}

export default adminReportsReducer;
