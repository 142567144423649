import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { getSession } from '../../helpers/authHelper';
import MainLayout from './MainLayout';
import { useSelector } from 'react-redux';
const Layout = ({
  component: Component,
  isAuthProtected,
  adminPages,
  roles,
  ...rest
}) => {
  const location = useLocation();
  let user = JSON.parse(getSession());
  const { reportDetails } = useSelector(state => state.farmReport);

  if (isAuthProtected && !user) {
    return (
      <Navigate
        state={{ from: rest.path }}
        to={`/auth/login?returnUrl=${location.pathname !== '/' ? location.pathname : ''}`}
      />
    )
  }
  return (adminPages ? (roles && roles.includes(user.role)) || !roles ?
    <MainLayout>
      <Component {...rest} />
    </MainLayout> :
    <Navigate state={{ from: rest.path }} to="/" /> :
    <MainLayout>
      <Component {...rest} locked={reportDetails.locked} />
    </MainLayout>
  )
}

MainLayout.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
}

export default Layout;
