import * as actionTypes from './types';
import { updateObject } from '../utility';

const initialState = {
  initialReportDetails:{},
  reportDetails: {
    reportId: "",
    farmId: "",
    reportName: "",
    averageAnnualTemperature: 10,
    yearEnd: "",
    includeSoilCarbon: false,
    includeFarmAdvisoryService: false,
    locked: false,
    projects: []
  },
  reportConfig: {
    landAndCropsOnly: false,
    livestockOnly: false
  },
  initialReportConfig:{},
  projects: [],
  sectors: [],
  types: [],
  systems: [],
  sectorItems: [],
  errorMsg: '',
  successMsg: ''
};

const farmReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROJECTS_SUCCESS: return setProjectsSuccess(state, action);
    case actionTypes.GET_PROJECTS_FAIL: return setProjectsFail(state, action);
    case actionTypes.GET_ALL_SECTORS_SUCCESS: return setAllSectorsSuccess(state, action);
    case actionTypes.GET_ALL_SECTORS_FAIL: return setAllSectorsFail(state, action);
    case actionTypes.GET_TYPES_BY_SECTOR_SUCCESS: return getTypesBySectorSuccess(state, action);
    case actionTypes.GET_TYPES_BY_SECTOR_FAIL: return getTypesBySectorFail(state, action);
    case actionTypes.GET_SYSTEMS_BY_SECTOR_SUCCESS: return getSystemsBySectorSuccess(state, action);
    case actionTypes.GET_SYSTEMS_BY_SECTOR_FAIL: return getSystemsBySectorFail(state, action);
    case actionTypes.GET_SECTOR_ITEMS_BY_SECTOR_SUCCESS: return getSectorItemsBySectorSuccess(state, action);
    case actionTypes.GET_SECTOR_ITEMS_BY_SECTOR_FAIL: return getSectorItemsBySectorFail(state, action);
    case actionTypes.CREATE_FARM_REPORT_CONFIG: return setFarmReportConfig(state, action);
    case actionTypes.CREATE_TEMP_FARM_REPORT_SECTORS: return setFarmReportSectors(state, action);
    case actionTypes.ADD_NEW_FARM_REPORT_SECTOR: return setNewFarmReportSector(state, action);
    case actionTypes.ADD_REMOVE_FARM_REPORT_SECTOR: return setUnsetFarmReportSector(state, action);
    case actionTypes.ADD_FARM_REPORT_GROUP: return setFarmReportGroup(state, action);
    case actionTypes.REMOVE_FARM_REPORT_GROUP: return unsetFarmReportGroup(state, action);
    case actionTypes.EDIT_FARM_REPORT_GROUP: return editFarmReportGroup(state, action);
    case actionTypes.ADD_FARM_REPORT_SECTOR_ITEM: return setFarmReportSectorItem(state, action);
    case actionTypes.REMOVE_FARM_REPORT_SECTOR_ITEM: return unsetFarmReportSectorItem(state, action);
    case actionTypes.GET_FARM_REPORT_DETAILS_SUCCESS: return setFarmReportDetails(state, action);
    case actionTypes.GET_FARM_REPORT_DETAILS_FAIL: return setFarmReportDetailsFail(state, action);
    case actionTypes.GET_FARM_REPORT_CONFIG_SUCCESS: return setFarmReportConfig(state, action);
    case actionTypes.GET_FARM_REPORT_CONFIG_FAIL: return setFarmReportConfigFail(state, action);
    case actionTypes.SUBMIT_FARM_REPORT_SUCCESS: return farmReportResponseSuccess(state, action);
    case actionTypes.SUBMIT_FARM_REPORT_FAIL: return farmReportResponseFail(state, action);
    case actionTypes.UPDATE_FARM_REPORT_SUCCESS: return farmReportResponseSuccess(state, action);
    case actionTypes.UPDATE_FARM_REPORT_FAIL: return farmReportResponseFail(state, action);
    case actionTypes.EDIT_FARM_REPORT_DETAILS: return editFarmReportDetails(state, action);
    case actionTypes.RESET_FARM_REPORT_DETAILS: return resetFarmReportDetails(state, action);
    case actionTypes.RESET_RESPONSE_STATUS: return resetResponseState(state, action);
    default:
      return state;
  }
};

const setProjectsSuccess = (state, action) => {
  const updatedState = {
    projects: action.payload,
  }
  return updateObject(state, updatedState);
}

const setProjectsFail = (state, action) => {
  const updatedState = {
    projects: [],
  }
  return updateObject(state, updatedState);
}

const setAllSectorsSuccess = (state, action) => {
  const updatedState = {
    sectors: action.payload,
  }
  return updateObject(state, updatedState);
}

const setAllSectorsFail = (state, action) => {
  const updatedState = {
    sectors: [],
  }
  return updateObject(state, updatedState);
}

const getTypesBySectorSuccess = (state, action) => {
  const updatedState = {
    types: action.payload,
  }
  return updateObject(state, updatedState);
}

const getTypesBySectorFail = (state, action) => {
  const updatedState = {
    types: [],
  }
  return updateObject(state, updatedState);
}

const getSystemsBySectorSuccess = (state, action) => {
  const updatedState = {
    systems: action.payload,
  }
  return updateObject(state, updatedState);
}

const getSystemsBySectorFail = (state, action) => {
  const updatedState = {
    systems: [],
  }
  return updateObject(state, updatedState);
}

const getSectorItemsBySectorSuccess = (state, action) => {
  const updatedState = {
    sectorItems: action.payload,
  }
  return updateObject(state, updatedState);
}

const getSectorItemsBySectorFail = (state, action) => {
  const updatedState = {
    sectorItems: [],
  }
  return updateObject(state, updatedState);
}

const setFarmReportDetails = (state, action) => {
  const updatedState = {
    reportDetails: action.payload,
    initialReportDetails: action.payload
  }
  return updateObject(state, updatedState);
}

const setFarmReportDetailsFail = (state, action) => {
  const updatedState = {
    errorMsg: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFarmReportConfig = (state, action) => {
  const updatedState = {
    reportConfig: action.payload,
    initialReportConfig: action.payload
  }
  return updateObject(state, updatedState);
}

const setFarmReportConfigFail = (state, action) => {
  const updatedState = {
    reportConfig: {},
  }
  return updateObject(state, updatedState);
}

const farmReportResponseSuccess = (state, action) => {
  const updatedState = {
    successMsg: action.payload
  }
  return updateObject(state, updatedState);
}

const farmReportResponseFail = (state, action) => {
  const updatedState = {
    errorMsg: action.payload
  }
  return updateObject(state, updatedState);
}

const editFarmReportDetails = (state, action) => {
  const { editObject } = action.payload;
  const [key, value] = Object.entries(editObject)[0];
  const updatedState = {
    reportDetails: {
      ...state.reportDetails,
      [key]: value
    }
  }
  return updateObject(state, updatedState);
}

const resetFarmReportDetails = (state, action) => {
  const updatedState = {
    reportDetails: {
      ...initialState.reportDetails
    },
    reportConfig: {},
  }
  return updateObject(state, updatedState);
}

const setFarmReportSectors = (state, action) => {
  const updatedState = {
    reportConfig: {
      sectorConfigurations: action.payload
    }
  }
  return updateObject(state, updatedState);
}

const setNewFarmReportSector = (state, action) => {
  const { newSector } = action.payload;
  const updatedState = {
    reportConfig: {
      ...state.reportConfig,
      sectorConfigurations: [
        ...state.reportConfig.sectorConfigurations,
        newSector
      ]
    }
  }
  return updateObject(state, updatedState);
}

const setUnsetFarmReportSector = (state, action) => {
  const { isDeleted, sectorIdx } = action.payload;
  const updatedState = {
    reportConfig: {
      ...state.reportConfig,
      sectorConfigurations: [
        ...state.reportConfig.sectorConfigurations.slice(0, sectorIdx),
        {
          ...state.reportConfig.sectorConfigurations[sectorIdx],
          isDeleted: isDeleted
        },
        ...state.reportConfig.sectorConfigurations.slice(sectorIdx + 1),
      ]
    }
  }
  return updateObject(state, updatedState);
}

const setFarmReportGroup = (state, action) => {
  const { newGroup, sectorIdx } = action.payload;
  const updatedState = {
    reportConfig: {
      ...state.reportConfig,
      sectorConfigurations: [
        ...state.reportConfig.sectorConfigurations.slice(0, sectorIdx),
        {
          ...state.reportConfig.sectorConfigurations[sectorIdx],
          enterpriseGroupConfigurations: [
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations,
            newGroup
          ]
        },
        ...state.reportConfig.sectorConfigurations.slice(sectorIdx + 1),
      ]
    }
  }
  return updateObject(state, updatedState);
}

const unsetFarmReportGroup = (state, action) => {
  const { groupIdx, sectorIdx } = action.payload;
  const updatedState = {
    reportConfig: {
      ...state.reportConfig,
      sectorConfigurations: [
        ...state.reportConfig.sectorConfigurations.slice(0, sectorIdx),
        {
          ...state.reportConfig.sectorConfigurations[sectorIdx],
          enterpriseGroupConfigurations: [
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(0, groupIdx),
            {
              ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx],
              isDeleted: true
            },
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(groupIdx + 1),
          ]
        },
        ...state.reportConfig.sectorConfigurations.slice(sectorIdx + 1),
      ]
    }
  }
  return updateObject(state, updatedState);
}

const editFarmReportGroup = (state, action) => {
  const { groupIdx, sectorIdx, editObject } = action.payload;
  const [key, value] = Object.entries(editObject)[0];
  const updatedState = {
    reportConfig: {
      ...state.reportConfig,
      sectorConfigurations: [
        ...state.reportConfig.sectorConfigurations.slice(0, sectorIdx),
        {
          ...state.reportConfig.sectorConfigurations[sectorIdx],
          enterpriseGroupConfigurations: [
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(0, groupIdx),
            {
              ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx],
              [key]: value
            },
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(groupIdx + 1),
          ]
        },
        ...state.reportConfig.sectorConfigurations.slice(sectorIdx + 1),
      ]
    }
  }
  return updateObject(state, updatedState);
}

const setFarmReportSectorItem = (state, action) => {
  const { sectorIdx, groupIdx, newItem } = action.payload;
  const updatedState = {
    reportConfig: {
      ...state.reportConfig,
      sectorConfigurations: [
        ...state.reportConfig.sectorConfigurations.slice(0, sectorIdx),
        {
          ...state.reportConfig.sectorConfigurations[sectorIdx],
          enterpriseGroupConfigurations: [
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(0, groupIdx),
            {
              ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx],
              sectorItemConfigurations: [
                ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx].sectorItemConfigurations,
                newItem
              ]
            },
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(groupIdx + 1),
          ]
        },
        ...state.reportConfig.sectorConfigurations.slice(sectorIdx + 1),
      ]
    }
  }
  return updateObject(state, updatedState);
}

const unsetFarmReportSectorItem = (state, action) => {
  const { sectorIdx, groupIdx, sectorItemIdx } = action.payload;
  const updatedState = {
    reportConfig: {
      ...state.reportConfig,
      sectorConfigurations: [
        ...state.reportConfig.sectorConfigurations.slice(0, sectorIdx),
        {
          ...state.reportConfig.sectorConfigurations[sectorIdx],
          enterpriseGroupConfigurations: [
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(0, groupIdx),
            {
              ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx],
              sectorItemConfigurations: [
                ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx].sectorItemConfigurations.slice(0, sectorItemIdx),
                {
                  ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx].sectorItemConfigurations[sectorItemIdx],
                  isDeleted: true
                },
                ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations[groupIdx].sectorItemConfigurations.slice(sectorItemIdx + 1),
              ]
            },
            ...state.reportConfig.sectorConfigurations[sectorIdx].enterpriseGroupConfigurations.slice(groupIdx + 1),
          ]
        },
        ...state.reportConfig.sectorConfigurations.slice(sectorIdx + 1),
      ]
    }
  }
  return updateObject(state, updatedState);
}

const resetResponseState = (state, action) => {
  const updatedState = {
    errorMsg: '',
    successMsg: ''
  }
  return updateObject(state, updatedState);
}

export default farmReportReducer;
