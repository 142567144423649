export const GET_ELECTRICITY_FUEL_METADATA = 'GET_ELECTRICITY_FUEL_METADATA'
export const GET_ELECTRICITY_FUEL_METADATA_SUCCESS = 'GET_ELECTRICITY_FUEL_METADATA_SUCCESS'
export const GET_ELECTRICITY_FUEL_METADATA_FAIL = 'GET_ELECTRICITY_FUEL_METADATA_FAIL'
export const RESET_ELECTRICITY_FUEL_METADATA = 'RESET_ELECTRICITY_FUEL_METADATA'

export const GET_ELECTRICITY_FUEL_FORM_DATA_SUCCESS = 'GET_ELECTRICITY_FUEL_FORM_DATA_SUCCESS'
export const GET_ELECTRICITY_FUEL_FORM_DATA_FAIL = 'GET_ELECTRICITY_FUEL_FORM_DATA_FAIL'

export const SET_ELECTRICITY_FUEL_FORM_DATA = 'SET_ELECTRICITY_FUEL_FORM_DATA'
export const RESET_ELECTRICITY_FUEL_ALL_DATA = 'RESET_ELECTRICITY_FUEL_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_ALLOCATE_ELECTRICITY_FUEL_SUCCESS = 'GET_ALLOCATE_ELECTRICITY_FUEL_SUCCESS'
export const GET_ALLOCATE_ELECTRICITY_FUEL_FAIL = 'GET_ALLOCATE_ELECTRICITY_FUEL_FAIL'

export const GET_ELECTRICITY_FUEL_WHOLE_FARM_DATA_SUCCESS = 'GET_ELECTRICITY_FUEL_WHOLE_FARM_DATA_SUCCESS'
export const SET_ELECTRICITY_FUEL_WHOLE_FARM_DATA_SUCCESS = 'SET_ELECTRICITY_FUEL_WHOLE_FARM_DATA_SUCCESS'