
import {
    Container,
    Grid,
    Paper,
    ThemeProvider,
    Typography,
} from '@mui/material'
import React from 'react'
import { FormHeaderPaper, FormTitleTypography, PrimaryListItemTypography, AltSectorTitleTypography, SectorTitleTypography } from '../../components/CustomStyles/StyledComponents';
import Footer from '../../layout/BottomNavigation';
import { mainTheme } from '../../theme';

const SuggestIdeas = () => {

    return (
        <ThemeProvider theme={mainTheme}>
            <Container maxWidth={'lg'} sx={{ margin: '0 auto', p: '50px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormTitleTypography variant="h5" component="div">
                                Suggest Ideas
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                {'This page allows you to submit your ideas to the product roadmap'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <SectorTitleTypography sx={{ mb: 2 }}>
                                COMING SOON
                            </SectorTitleTypography>
                            {/* <Paper sx={{ pl: 3, mb: 2 }}>
                                <PrimaryListItemTypography variant="body2" sx={{ width: '100%' }} fontWeight="500" mb={1} ml={0}>
                                    {'If you would like to discuss an issue with Agrecalc or simply request more information then please email us at info@agrecalc.com'}
                                </PrimaryListItemTypography>
                            </Paper>
                            <SectorTitleTypography sx={{ mb: 2 }}>
                                Raise a support ticket
                            </SectorTitleTypography>
                            <Paper sx={{ pl: 3, mb: 2 }}>
                                <PrimaryListItemTypography variant="body2" sx={{ width: '100%'}} fontWeight="500" mb={1} ml={0}>
                                    {'If you would like to log a ticket regarding the Agrecalc platform then please create an account at the Agrecalc Support Portal: agrecalc-support@ideas.aha.io'}
                                </PrimaryListItemTypography>
                                <PrimaryListItemTypography variant="body2" sx={{ width: '100%'}} fontWeight="500" mb={1} ml={0}>
                                    {'For other technical enquiries please contact support@agrecalc.com'}
                                </PrimaryListItemTypography>                                
                            </Paper> */}
                        </FormHeaderPaper>
                    </Grid>
                </Grid >
            </Container>
            <Footer fixed={true} />
        </ThemeProvider >
    )
}

export default SuggestIdeas