/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button, Checkbox, FormControl, Grid, MenuItem,
  Paper, Stack, Switch, TextField, Typography, List, ListSubheader
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Divider from '@mui/material/Divider';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import toastr from "toastr";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormDescriptionTypography, FormHeaderPaper,
  FormTitleTypography,
  InfoTooltip, ReportEnterpriseGroupTypography, ReportFormTitleTypography, DataEntryDatePicker, DataEntrySelect, DataEntryTextField, DataEntryAutocomplete, CustomizedDivider
} from '../../../components/CustomStyles/StyledComponents';
import {
  addFarmReportGroup, addFarmReportSectorItem, addNewFarmReportSector, addRemoveFarmReportSector,
  createFarmReportConfig, editFarmReportDetails,
  editFarmReportGroup, getAllSectors, getFarmReport, getMyAccount, getMyFarm, getProjects, getSectorItemsBySector, getSystemsBySector,
  getTypesBySector, removeFarmReportGroup, removeFarmReportSectorItem, resetFarmReportDetails,
  resetResponseState, setLoading, submitFarmReport, unsetLoading, updateFarmReport,getAdminFarm,deleteReport,setReportId
} from '../../../store/appAction';
import _ from "lodash";
import { isEqual } from "lodash";
import CancelModal from "../../../components/Modal/CancelModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toastrCustomOptions, toastrCustomOptionsLonger,toastrCustomOptionsNoFarmSelectPopUp } from "../../../constants/toastrOptions";
import BackButton from "../../../components/Buttons/BackButton";
import { ADMIN, FARMER,CONSULTANT } from '../../../constants/userRoles';
import { EDIT } from "../../../constants/modes";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import DeleteReportModal from "../../../components/Modal/DeleteReportModal";
import store from '../../../store';

const BASE_REPORT_CONFIG_ENTRY = {
  "sectorConfigurations": [],
  "isDeleted": false
}

const BASE_SECTOR_ENTRY = {
  "enterpriseSectorId": undefined,
  "enterpriseGroupConfigurations": [],
  "isDeleted": true
}

const BASE_GROUP_ENTRY = {
  "enterpriseGroupName": 'Default Group',
  "isOrganic": false,
  "type": null,
  "system": null,
  "sectorItemConfigurations": [],
  "isDeleted": false
}

const BASE_SECTOR_ITEM_ENTRY = {
  "enterpriseSectorItemId": undefined,
  "isDeleted": false
}

const FarmReport = (props) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  let { mode } = useParams();

  const reportId = useSelector(state => state.common.reportId);
  const selectedReportId = useSelector(state => state.common.reportId);
  const projectList = useSelector(state => state.farmReport.projects);
  const sectorList = useSelector(state => state.farmReport.sectors);
  const typesList = useSelector(state => state.farmReport.types);
  const systemsList = useSelector(state => state.farmReport.systems);
  const sectorItemsList = useSelector(state => state.farmReport.sectorItems);
  const adminFarm = useSelector(state => state.adminFarm);

  const user = useSelector(state => state.auth.user);
  const farmId = useSelector(state => ([FARMER].includes(user.role) ?
    state.common.farmId : state.adminFarm.farmId));
  const selectedFarmId = useSelector(state => ([FARMER].includes(user.role) ?
    state.common.farmId : state.adminFarm.farmId));

  const { reportConfig, reportDetails } = useSelector(state => state.farmReport);
  const { successMsg, errorMsg } = useSelector(state => state.common);

  const [addProjectToggle, setAddProjectToggle] = useState(false);

  const [currentSector, setCurrentSector] = useState(undefined);
  const [currentSectorIndex, setCurrentSectorIndex] = useState(undefined);
  const [isCurrentSectorPicked, setIsCurrentSectorPicked] = useState(false);

  const [currentSectorGroupList, setCurrentSectorGroupList] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(undefined);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(undefined);
  const [addGroupToggle, setAddGroupToggle] = useState(false);

  const [currentSectorItemList, setCurrentSectorItemList] = useState([]);
  const [currentSectorItemIdList, setCurrentSectorItemIdList] = useState([]);
  const [addSectorItemToggle, setAddSectorItemToggle] = useState(false);
  const [psfAndFasData, setPsfAndFasData] = useState(null);
  const [selectedFarmName, setSelectedFarmName] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);

  const projectOptionsRef = useRef(null);
  const focusRef = React.useRef();


  useEffect(() => {
    // if (mode === EDIT && reportId) {
    //   dispatch(setLoading());
    //   dispatch(getFarmReport({ reportId }));
    // } else {
    //   handleResetForm();
    //   dispatch(createFarmReportConfig(BASE_REPORT_CONFIG_ENTRY));
    // }
    dispatch(getAllSectors());
    dispatch(getProjects());
    dispatch(resetResponseState());
    if ([FARMER].includes(user.role)) {
      dispatch(getMyFarm(farmId));
      dispatch(getMyAccount(farmId));
    }
    getPsfAndFasData(farmId);
    if(user.role === FARMER){
      getFarmNameForFarmer(farmId);
    }
    getFarmReportDetails(reportId);
    return () => {
      handleResetForm();
      dispatch(resetResponseState());
      dispatch(resetFarmReportDetails());
    }
  }, []);

  useEffect(() => {
    if (mode === EDIT && reportId) {
      dispatch(setLoading());
      dispatch(getFarmReport({ reportId }));
    } else {
      handleResetForm();
      dispatch(createFarmReportConfig(BASE_REPORT_CONFIG_ENTRY));
    }
  }, [location]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      if (successMsg) {
        toastr.success(successMsg);
        reportId && dispatch(getFarmReport({ reportId }));
        (mode !== EDIT) && navigate('/data-entry/report/edit');
      }
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
    }
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (nextClicked) {
      setNextClicked(false);
      if (reportConfig?.livestockOnly)
        navigate('/data-entry/livestock/numbers-weights');
      else
        navigate('/data-entry/land-crops/land-area-crops');
    }
    // setLoading(false);
    if (currentSector) {
      let sectorConfigList = reportConfig.sectorConfigurations;
      const currentSectorConfig = _.find(
        sectorConfigList,
        { enterpriseSectorId: currentSector.id }
      )
      if (currentSectorConfig) {
        let sectorIdx = _.findIndex(
          sectorConfigList,
          (sector) => {
            return sector.enterpriseSectorId === currentSector.id;
          }
        );
        setCurrentSectorIndex(sectorIdx);
        setIsCurrentSectorPicked(!currentSectorConfig.isDeleted);
        if (currentGroupIndex !== undefined)
          setCurrentGroup(currentSectorConfig.enterpriseGroupConfigurations[currentGroupIndex]);
        setCurrentSectorGroupList(currentSectorConfig.enterpriseGroupConfigurations);
      } else {
        setIsCurrentSectorPicked(false);
        setCurrentGroupIndex(undefined);
        setCurrentSectorGroupList([]);
        setCurrentSectorItemList([]);
        setCurrentSectorItemIdList([]);
      }
    }
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 800);
  }, [reportConfig]);

  useEffect(() => {
    if (currentSector) {
      dispatch(getTypesBySector({ sectorId: currentSector.id }));
      dispatch(getSystemsBySector({ sectorId: currentSector.id }));
      dispatch(getSectorItemsBySector({ sectorId: currentSector.id }));
      let sectorConfigList = reportConfig.sectorConfigurations;
      const currentSectorConfig = _.find(
        sectorConfigList,
        { enterpriseSectorId: currentSector.id }
      );
      if (currentSectorConfig) {
        let sectorIdx = _.findIndex(
          sectorConfigList,
          (sector) => {
            return sector.enterpriseSectorId === currentSector.id;
          }
        );
        setCurrentSectorIndex(sectorIdx);
        setIsCurrentSectorPicked(!currentSectorConfig.isDeleted);
        setCurrentGroupIndex(currentSector.type === "LandAndCrops" ?
          0 : undefined);
        setCurrentGroup(currentSector.type === "LandAndCrops" ?
          currentSectorConfig.enterpriseGroupConfigurations[0] : undefined);
        setCurrentSectorGroupList(currentSectorConfig.enterpriseGroupConfigurations);
      } else {
        setIsCurrentSectorPicked(false);
        setCurrentGroupIndex(currentSector.type === "LandAndCrops" ? 0 : undefined);
        setCurrentGroup(undefined);
        setCurrentSectorGroupList([]);
        setCurrentSectorItemList([]);
        setCurrentSectorItemIdList([]);
      }
    }
  }, [currentSector]);

  useEffect(() => {
    if (currentGroup) {
      setCurrentSectorItemList(currentGroup.sectorItemConfigurations);
      setCurrentSectorItemIdList(
        currentGroup.sectorItemConfigurations
          .map(item => !item.isDeleted && item.enterpriseSectorItemId)
      );
    } else {
      setCurrentSectorItemList([]);
      setCurrentSectorItemIdList([]);
    }
  }, [currentGroup]);

  useEffect(() => {
    if (currentGroup) {
      setCurrentSectorItemIdList(
        currentGroup.sectorItemConfigurations
          .map(item => !item.isDeleted && item.enterpriseSectorItemId)
      );
    }
  }, [currentSectorItemList]);

  useEffect(() => {
    if (addProjectToggle) {
      projectOptionsRef?.current?.focus();
    }
  }, [addProjectToggle]);

  useEffect(() => {
    if(reportDetails.locked === false){
      focusRef.current.focus(); 
    }
  }, [reportDetails]);

  const getFarmNameForFarmer = async (farmId) => {
    if(farmId){
      const response = await api.get(endpoints.admin.farm.farm.concat('?farmId=', farmId));
      if (response && response.status === 200) {
        setSelectedFarmName(response.data.farmName);
      }
    }
  }

  const getFarmReportDetails = async (reportId) => {
    if(reportId){
      const response = await api.get(endpoints.farmReport.details.concat('?reportId=', reportId));
      if (response && response.status === 200) {
        if(response?.data?.isUpgraded === false){
          toastr.warning("This farm report cannot be updated as it belongs to a user that has not been approved for Agrecalc cloud.", undefined, toastrCustomOptionsNoFarmSelectPopUp);
        }
      }
    }
  }

  const fetchSectorState = (sectorId) => {
    if (reportConfig?.sectorConfigurations?.length > 0) {
      let sectorConfigList = reportConfig.sectorConfigurations;
      let sectorIdx = _.findIndex(
        sectorConfigList,
        (sector) => {
          return sector.enterpriseSectorId === sectorId;
        }
      );
      return sectorIdx === -1 ? false : (!reportConfig?.sectorConfigurations[sectorIdx]?.isDeleted || false);
    }
    return false;
  }

  const getPsfAndFasData = async (farmId) =>{
    if(farmId){
      const response = await api.get(endpoints.admin.farm.pdfAndFasData.concat('?farmId=', farmId));
      if (response && response.status === 200) {
        setPsfAndFasData(response.data); 
      }
    }
  }

  const fetchSectorItemTitle = (sectorItemId) => {
    return sectorItemsList.length > 0 && _.find(sectorItemsList, { id: sectorItemId })?.itemName;
  }

  const handleDateTimePicker = (value) => {
    handleEditDetails({ target: { name: 'yearEnd', value: value.toISOString() } })
  }

  const handleAddProject = (name, option) => {
    const tempProjects = [...(reportDetails?.projects || [])];

    tempProjects.push({
      added: true,
      projectId: option.id,
      projectName: option.name
    });
    setAddProjectToggle(false);
    handleEditDetails({ target: { name, value: tempProjects } });
  }

  const handleRemoveProject = (projectIdx) => {
    const tempProjects = [...(reportDetails?.projects || [])];

    if (tempProjects[projectIdx].added)
      tempProjects.splice(projectIdx, 1);
    else
      tempProjects[projectIdx].deleted = true;

    setAddProjectToggle(false);
    handleEditDetails({ target: { name: 'projects', value: tempProjects } });
  }

  const handleEditDetails = (e) => {
    let { name, value, checked, type, inputMode } = e.target;
    if (inputMode === "numeric") {
      let numericRegex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        if (value <= 40) {
          dispatch(editFarmReportDetails({
            editObject: { [name]: value }
          }));
        }
      }
    } else
      dispatch(editFarmReportDetails({
        editObject: { [name]: type === "checkbox" ? checked : value }
      }));
  }

  const handleSelectSector = (e, sector, sectorId) => {
    let sectorConfigList = reportConfig.sectorConfigurations;
    let sectorIdx = _.findIndex(
      sectorConfigList,
      (sector) => {
        return sector.enterpriseSectorId === sectorId;
      }
    );
    if (sectorIdx === -1) {
      let tempSector = { ...BASE_SECTOR_ENTRY };
      let tempGroup = { ...BASE_GROUP_ENTRY }
      tempSector.isDeleted = false;
      tempSector.enterpriseSectorId = sector.id
      tempSector.enterpriseGroupConfigurations =
        sector.type === 'LandAndCrops' ? [tempGroup] : [];
      dispatch(addNewFarmReportSector({
        newSector: tempSector
      }));
    } else {
      dispatch(addRemoveFarmReportSector({
        isDeleted: !e.target.checked,
        sectorIdx
      }));
    }
  }

  const handleAddGroup = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let groupTitle = e?.target?.value || '';
      let tempGroup = { ...BASE_GROUP_ENTRY }
      const isDuplicateGroupName = currentSectorGroupList?.some(obj =>
        obj?.enterpriseGroupName?.includes(groupTitle)
      );
      if(isDuplicateGroupName){
        toastr.error("The group name you entered already exists. Please enter a different, unique name for your group and try again.",undefined, toastrCustomOptionsNoFarmSelectPopUp);
        return;
      }
      tempGroup.enterpriseGroupName = groupTitle;
      let sectorConfigList = reportConfig.sectorConfigurations;
      let sectorIdx = _.findIndex(
        sectorConfigList,
        (sector) => {
          return sector.enterpriseSectorId === currentSector.id;
        }
      );
      setCurrentGroupIndex(currentSectorGroupList.length);
      dispatch(addFarmReportGroup({
        sectorIdx,
        newGroup: tempGroup
      }))
      setAddGroupToggle(false);
    }
  }

  const handleRemoveGroup = (e, groupIdx) => {
    dispatch(removeFarmReportGroup({ groupIdx, sectorIdx: currentSectorIndex }));

    if (groupIdx === currentGroupIndex) {
      setCurrentGroupIndex(currentGroupIndex - 1);
      // setCurrentGroup(undefined);
    }
    e.stopPropagation();
  }

  const handleEditGroup = (e) => {
    let { name, value, checked, } = e.target;
    e.preventDefault();
    dispatch(editFarmReportGroup({
      sectorIdx: currentSectorIndex,
      groupIdx: currentGroupIndex,
      editObject: { [name]: name === "isOrganic" ? checked : value }
    }));
  }

  const handleAddSectorItem = (e) => {
    const { id } = e?.target?.value;
    let tempSectorItem = { ...BASE_SECTOR_ITEM_ENTRY };
    tempSectorItem.enterpriseSectorItemId = id;

    dispatch(addFarmReportSectorItem({
      sectorIdx: currentSectorIndex,
      groupIdx: currentGroupIndex,
      newItem: tempSectorItem
    }));
    setAddSectorItemToggle(false);
  }

  const handleRemoveSectorItem = (e, sectorItemIdx) => {
    e.preventDefault();

    dispatch(removeFarmReportSectorItem({
      sectorIdx: currentSectorIndex,
      groupIdx: currentGroupIndex,
      sectorItemIdx
    }));
  }

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      handleResetForm();
    }
  }

  const handleResetForm = () => {
    setCurrentSector(undefined);
    setCurrentGroup(undefined);
    setCurrentSectorGroupList([]);
    setCurrentSectorItemList([]);
    setCurrentSectorItemIdList([]);

    dispatch(resetResponseState());
    dispatch(resetFarmReportDetails());
    if (mode === EDIT && reportId) {
      dispatch(getFarmReport({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const { initialReportDetails, reportDetails , initialReportConfig , reportConfig, }  = store.getState().farmReport;
    const isUpdatedReportDetails = !isEqual(initialReportDetails, reportDetails);
    const isUpdatedReportConfigurations = !isEqual(initialReportConfig, reportConfig);
    if(isUpdatedReportDetails || isUpdatedReportConfigurations){
      return true;
    }
    return false;
}

  const handleSaveFarmReport = (nextClicked) => {
    if(!isFormUpdated()){
      return;
    }
    if (handleCustomValidation()) {
      dispatch(setLoading());
      if (mode === EDIT && reportId)
        dispatch(updateFarmReport());
      else
        dispatch(submitFarmReport(nextClicked));
    } else {
      setNextClicked(false);
    }
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      if (reportConfig?.livestockOnly)
        navigate('/data-entry/livestock/numbers-weights');
      else
        navigate('/data-entry/land-crops/land-area-crops');
    }
    else{
      if (handleCustomValidation()) {
        dispatch(setLoading());
        if (mode === EDIT && reportId)
          dispatch(updateFarmReport());
        else
          dispatch(submitFarmReport(true));
      } else {
        setNextClicked(false);
      }
    }
  }

  const handleCustomValidation = () => {
    var BreakException = {};
    if (!reportDetails.reportName) {
      toastr.error("You need to enter the Report Name before you can continue.",
        undefined, toastrCustomOptionsLonger);
      return false;
    }
    if (!reportDetails.averageAnnualTemperature) {
      toastr.error("You need to enter the Average Annual Temperature before you can continue.",
        undefined, toastrCustomOptionsLonger);
      return false;
    }
    if (!reportDetails.yearEnd) {
      toastr.error("You need to enter the Year End before you can continue.",
        undefined, toastrCustomOptionsLonger);
      return false;
    }
    const sectorsForLivestockSystem = sectorList
      .filter((sector) => (['Beef', 'Sheep'].includes(sector.sectorName)))
      .map(s => s.id);
    const sectorsForLivestockType = sectorList
      .filter((sector) => (sector.type === 'LiveStock'))
      .map(s => s.id);
    const activeSectors = _.filter(reportConfig.sectorConfigurations, { isDeleted: false });
    if (activeSectors.length === 0) {
      toastr.error("You must enter an enterprise sector.", undefined, toastrCustomOptionsLonger);
      return false;
    } else {
      try {
        activeSectors.forEach(sector => {
          const currentSector = _.find(sectorList, { id: sector.enterpriseSectorId });
          const isLivestock = sectorsForLivestockType.includes(sector.enterpriseSectorId);
          const activeGroups = _.filter(sector.enterpriseGroupConfigurations, { isDeleted: false });
          if (activeGroups.length === 0) {
            toastr
              .error("You must add at least one group"/* for each livestock sector."*/,
                `Sector: ${currentSector.sectorName}`,
                toastrCustomOptionsLonger);
            throw BreakException;
          } else {
            activeGroups.forEach(group => {
              const activeSectorItems = _.filter(group.sectorItemConfigurations, { isDeleted: false });
              if (isLivestock && [null, ''].includes(group.type)) {
                toastr
                  .error("You must select an enterprise type"/* for all your enterprise groups."*/,
                    `Sector: ${currentSector.sectorName} | Group: ${group.enterpriseGroupName}`,
                    toastrCustomOptionsLonger);
                throw BreakException;
              } else if (sectorsForLivestockSystem.includes(sector.enterpriseSectorId) && [null, ''].includes(group.system)) {
                toastr
                  .error("You must select a system"/* for all your enterprise groups."*/,
                    `Sector: ${currentSector.sectorName} | Group: ${group.enterpriseGroupName}`,
                    toastrCustomOptionsLonger);
                throw BreakException;
              } else if (activeSectorItems.length === 0) {
                toastr
                  .error(isLivestock ?
                    "You must add at least one class"/* for each group."*/ :
                    "You must enter an enterprise type"/* for all your enterprise sectors."*/,
                    isLivestock ?
                      `Sector: ${currentSector.sectorName} | Group: ${group.enterpriseGroupName}` :
                      `Sector: ${currentSector.sectorName}`,
                    toastrCustomOptionsLonger);
                throw BreakException;
              }
            });
          }
        });
      } catch (e) { return false; }
    }
    return true;
  }

  const handleDeleteModalResponse = (positiveResponse) => {
    setDeleteModalOpen(false);
    if (positiveResponse) {
      dispatch(deleteReport(reportId));
      dispatch(setReportId(null));
      navigate(-1);
    }
  }

  return (
    <>
    <ScrollSync>
      <Grid container spacing={2} >
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
            divider={false}
          >
            <SelectedFarm farmId={selectedFarmId} farmName={adminFarm.farmDetails.farmName || selectedFarmName}/>
            <FormTitleTypography variant="h5" component="div">
              Farm Report
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              {'Create, modify or view your Farm Report and select the enterprise sector(s) which it relates to.'}
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ paddingTop:'0 !important' }}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              paddingTop:'0'
            }}
          >
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                  <>
                    <Grid container direction="row" >
                    <Grid item xs={2}>
                      <BackButton />
                    </Grid>
                    <Grid item xs={10} sx={{ display:'flex', alignItems:'center' }}>
                      <Stack spacing={2} sx={{ marginLeft: 'auto', float: 'right' }} direction="row">
                        <Button
                          variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={() => setModalOpen(true)}>
                          Cancel
                        </Button>
                        <Button 
                          disabled={!reportDetails.isUpgraded}
                          variant="outlined" sx={{ textTransform: 'none' }}
                          onClick={(e) => { setDeleteModalOpen(true);}}>
                          Delete
                        </Button>
                        <Button variant="outlined"
                          sx={{ textTransform: 'none' }}
                          className="activated"
                          onClick={handleSaveFarmReport}>
                          Save
                        </Button>
                        <Button variant="contained"
                          sx={{ textTransform: 'none' }}
                          className="activated"
                          onClick={handleNextClick}>
                          Next
                        </Button>
                      </Stack>
                    </Grid>
                    </Grid>
                    <CustomizedDivider/>
                  </>
                </ScrollSyncPane>
              </ListSubheader>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={1} className={props.locked && 'deactivated'} sx={{ marginTop:'1rem' }}>
              <Grid item xs={3}>
                Report Name
              </Grid>
              <Grid item xs={6}>
                <DataEntryTextField
                  name="reportName"
                  size="small"
                  variant="outlined"
                  required
                  style={{ width: 350, textAlign: "center" }}
                  value={reportDetails.reportName || ''}
                  onChange={handleEditDetails}
                  inputRef={focusRef}
                />
              </Grid>
              <Grid item xs={3}>
                {/* <FormControl
                  variant="outlined"
                  sx={{ width: '100%', margin: 0 }}
                >
                  <FarmDetailsSelect
                    displayEmpty
                    value={''}
                  >
                    <MenuItem value=""><em>Coefficients</em></MenuItem>
                  </FarmDetailsSelect>
                </FormControl> */}
              </Grid>
              {/*-------------------------------*/}
              <Grid item xs={3}>
                Average Annual Temperature (°C)
              </Grid>
              <Grid item xs={6}>
                <DataEntryTextField
                  name="averageAnnualTemperature"
                  size="small"
                  variant="outlined"
                  inputProps={{ inputMode: "numeric" }}
                  style={{ width: 50, textAlign: "center" }}
                  value={reportDetails.averageAnnualTemperature || ''}
                  onChange={handleEditDetails}
                />
              </Grid>
              <Grid item xs={3}>
                {/* <FormControl
                  variant="outlined"
                  sx={{ width: '100%', margin: 0 }}
                >
                  <FarmDetailsSelect
                    displayEmpty
                    value={''}
                  >
                    <MenuItem value=""><em>Projects...</em></MenuItem>
                  </FarmDetailsSelect>
                </FormControl> */}
              </Grid>
              {/*-------------------------------*/}
              <Grid item xs={3}>
                Year End
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DataEntryDatePicker
                    views={['month', 'year']}
                    value={reportDetails.yearEnd || null}
                    onChange={handleDateTimePicker}
                    renderInput={(params) => <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "month year"
                      }}
                      onChange={(e) => e.preventDefault()} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
              </Grid>
              {/*-------------------------------*/}
              <Grid item xs={3}>
                Include Soil Carbon
                <Switch
                  color="primary"
                  type="checkbox"
                  name="includeSoilCarbon"
                  checked={reportDetails.includeSoilCarbon}
                  value={reportDetails.includeSoilCarbon}
                  onChange={handleEditDetails}
                />
              </Grid>
              {
                psfAndFasData?.isFasVisible &&
                <Grid item xs={4}>
                  Farm Advisory Service
                  <Switch
                    disabled={!psfAndFasData?.canUpdateFarmAdvisoryService}
                    color="primary"
                    type="checkbox"
                    name="includeFarmAdvisoryService"
                    checked={reportDetails.includeFarmAdvisoryService}
                    value={reportDetails.includeFarmAdvisoryService}
                    onChange={handleEditDetails}
                  />
                  <InfoTooltip
                      placement="right"
                      title="Select this if your farm is part of the Farm Advisory Service scheme.">
                      <InfoOutlinedIcon color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' }} fontSize='small' />
                  </InfoTooltip>
                </Grid>
              }  
              {
                psfAndFasData?.isPsfVisible && 
                <Grid item xs={5}>
                  Preparing for sustainable farming
                  <Switch
                    color="primary"
                    type="checkbox"
                    name="isPsfEnabled"
                    checked={reportDetails.isPsfEnabled}
                    value={reportDetails.isPsfEnabled}
                    onChange={handleEditDetails}
                  />
                  <InfoTooltip
                      placement="right"
                      title="Select this if your farm is part of the Preparing for sustainable farming scheme.">
                      <InfoOutlinedIcon color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' }} fontSize='small' />
                  </InfoTooltip>
                </Grid>
              }
              <Grid item xs={3}>
              </Grid>
              {/*-------------------------------*/}
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={3}>
                Project(s)
              </Grid>
              <Grid className="activated" item xs={9}>
                <Paper>
                  {addProjectToggle ?
                    <FormControl
                      variant="outlined"
                      sx={{ width: 350, m: 0, mb: 1 }}
                    >
                      <DataEntryAutocomplete
                        id="projects-autocomplete"
                        ref={projectOptionsRef}
                        open={true}
                        disablePortal
                        options={projectList
                          ?.filter((project) => (
                            !(reportDetails?.projects
                              ?.filter(p => !p.deleted)
                              ?.includes(project.id))
                          ))}
                        getOptionLabel={(owner) => `${owner?.name}`}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderOption={(props, option) => {
                          if (!(reportDetails?.projects?.map(p => !p.deleted && p.projectId)?.includes(option.id)))
                            return (
                              <MenuItem
                                {...props} key={option.id} value={option}>
                                {option.name}
                              </MenuItem>
                            );
                        }}
                        onChange={(e, value) => {
                          handleAddProject("projects", value);
                        }}
                        renderInput={(params) => (
                          <DataEntryTextField
                            {...params}
                            fullWidth
                            focused
                            autoFocus={props.locked === false ? true : false}
                            name="projects"
                            variant="outlined"
                            onBlur={() => setAddProjectToggle(false)}
                          />
                        )}
                      />
                    </FormControl> :
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', mb: 1, px: 1, py: 0 }}
                      onClick={() => setAddProjectToggle(true)}
                    >+ Add Project</Button>
                  }
                  <Paper>
                    {reportDetails?.projects?.map((project, projectIdx) => (
                      !project.deleted &&
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ textTransform: 'none', mr: 1, mb: 1, px: 1 }}
                        size="small"
                        endIcon={
                          <CancelIcon
                            name="projects"
                            sx={{ padding: '2px' }}
                            onClick={() => handleRemoveProject(projectIdx)}
                          />
                        }
                      >
                        {project.projectName}
                      </Button>
                    ))}
                  </Paper>
                </Paper>
              </Grid>
              {/* <Grid item xs={3}>
              </Grid> */}
              {/*-------------------------------*/}
            </Grid>
            </List>
          </Paper>
          <Divider orientation='horizontal' sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} className={props.locked && 'deactivated'}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <ReportFormTitleTypography sx={{ fontSize: '1.15rem' }} component="div">{`Enterprise Sectors`}</ReportFormTitleTypography>
            <Typography variant="body2" paragraph>
              {"Tick all the enterprises on the farm that generate saleable output.  Then select the sector(s) to create enterprise groups "}
              <InfoTooltip
                placement="right"
                title="Tip: as an example, if you are a dairy farm who also sells finished cattle please tick 'Dairy' and 'Beef'.">
                <InfoOutlinedIcon color='primary' sx={{ verticalAlign: 'bottom' }} fontSize='small' />
              </InfoTooltip >
            </Typography>
            <Stack
              spacing={1}
              direction="row"
              sx={{ marginBottom: '10px' }}
            >
              {sectorList.map((sector, sectorIdx) => (
                sector.type !== 'LandAndCrops' &&
                <Button
                  color={currentSector && currentSector.id == sector.id ? 'primary' : 'secondary'}
                  variant={currentSector && currentSector.id == sector.id ? 'contained' : 'outlined'}
                  sx={{
                    textTransform: 'none', marginLeft: '2px',
                    padding: '3px 8px', lineHeight: 'normal',
                    width: '8%'
                  }}
                  size="small"
                  endIcon={
                    <Checkbox size='small'
                      sx={{ padding: '2px' }}
                      color={currentSector && currentSector.id == sector.id ? 'primary' : 'secondary'}
                      checked={fetchSectorState(sector.id)}
                      onClick={(e) => handleSelectSector(e, sector, sector.id)}
                    />}
                  onClick={() => {
                    setCurrentSector(sector);
                  }}
                >
                  {sector.sectorName.trim()}
                </Button>
              ))}
            </Stack>
            <Stack
              spacing={1}
              direction="row"
            >
              {sectorList.map((sector, sectorIdx) => (
                sector.type === 'LandAndCrops' &&
                <Button
                  color={currentSector && currentSector.id == sector.id ? 'primary' : 'secondary'}
                  variant={currentSector && currentSector.id == sector.id ? 'contained' : 'outlined'}
                  sx={{ textTransform: 'none', marginLeft: '2px', padding: '3px 8px', lineHeight: 'normal' }}
                  size="small"
                  endIcon={
                    <Checkbox size='small'
                      sx={{ padding: '2px' }}
                      color={currentSector && currentSector.id == sector.id ? 'primary' : 'secondary'}
                      checked={fetchSectorState(sector.id)}
                      onClick={(e) => handleSelectSector(e, sector, sector.id)}
                    />}
                  onClick={() => {
                    setCurrentSector(sector);
                  }}
                >
                  {sector.sectorName.trim()}
                </Button>
              ))}
            </Stack>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                ...(!isCurrentSectorPicked && { pointerEvents: "none", opacity: '.25' })
              }}
            >
              {currentSector && currentSector.type === "LiveStock" &&
                <>
                  <ReportEnterpriseGroupTypography>
                    {`${currentSector ?
                      currentSector?.sectorName.concat(' ')
                      : ''}Enterprise Group(s)`}
                  </ReportEnterpriseGroupTypography>
                  <Typography variant="body2" paragraph>
                    {'Add an enterprise group for each collective group within the selected sector. Then highlight the group(s) to create classes or enterprise types '}
                    <InfoTooltip
                      placement="bottom"
                      title="Tip: Provide each group with a distinctive name, for cows this would be one group for each herd.">
                      <InfoOutlinedIcon color='primary' sx={{ verticalAlign: 'bottom' }} fontSize='small' />
                    </InfoTooltip>
                  </Typography>
                  <Stack spacing={1} direction="row">
                    {currentSectorGroupList.map((group, groupIdx) => (
                      !group.isDeleted &&
                      <Button
                        key={groupIdx}
                        variant={currentGroupIndex === groupIdx ? 'contained' : 'outlined'}
                        color={currentGroupIndex === groupIdx ? 'primary' : 'secondary'}
                        sx={{ textTransform: 'none', marginLeft: '5px', padding: '3px' }}
                        size="small"
                        endIcon={
                          <CancelIcon sx={{ padding: '2px' }} onClick={(e) => { handleRemoveGroup(e, groupIdx) }} />
                        }
                        onClick={() => {
                          setCurrentGroupIndex(groupIdx);
                          setCurrentGroup(group);
                        }}
                      >
                        {group.enterpriseGroupName}
                      </Button>
                    ))}
                    {addGroupToggle ?
                      <>
                        <Typography paddingTop={'2px'}>Group Name: </Typography>
                        <DataEntryTextField
                          size="small"
                          variant="outlined"
                          placeholder="Please type your group name and press [ENTER]"
                          inputProps={{ inputMode: "numeric" }}
                          style={{ width: 350, margin: 'auto 5px', textAlign: "center" }}
                          onKeyDown={handleAddGroup}
                          onBlur={() => setAddGroupToggle(false)}
                          autoFocus={props.locked === false ? true : false}
                        />
                      </>
                      :
                      <Button
                        variant="text"
                        sx={{ textTransform: 'none', marginLeft: '2px', padding: '3px' }}
                        onClick={() => setAddGroupToggle(true)}
                      >+ Add Group</Button>
                    }
                  </Stack>
                </>
              }
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  ...(!currentGroup &&
                    { pointerEvents: "none", opacity: '.25' })
                }}
              >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item alignItems="center" xs={2}>
                    Organic
                    <Switch
                      name="isOrganic"
                      type="checkbox"
                      color="primary"
                      size="small"
                      checked={currentGroup?.isOrganic || false}
                      onClick={handleEditGroup}
                    />
                  </Grid>
                  <Grid item alignItems="center" sx={{ paddingTop: '15px' }} xs={5}>
                    {typesList && typesList.length > 0 &&
                      <>
                        <span style={{ marginTop: 2, display: 'inline-block' }} >Type</span>
                        <FormControl
                          variant="outlined"
                          sx={{ width: '85%', marginLeft: '5px' }}
                        >
                          <DataEntrySelect
                            displayEmpty
                            name="type"
                            value={currentGroup?.type || ''}
                            onChange={handleEditGroup}
                          >
                            <MenuItem value=""><em>Please Select...</em></MenuItem>
                            {typesList.map((type) => (
                              <MenuItem key={type.id} value={type.id}>{type.typeName}</MenuItem>
                            ))}
                          </DataEntrySelect>
                        </FormControl>
                      </>
                    }
                  </Grid>
                  <Grid item alignItems="center" xs={5}>
                    {systemsList && systemsList.length > 0 &&
                      <>
                        <span style={{ marginTop: 2, display: 'inline-block' }} >System</span>
                        <FormControl
                          variant="outlined"
                          sx={{ width: '85%', marginLeft: '5px' }}
                        >
                          <DataEntrySelect
                            displayEmpty
                            name="system"
                            value={currentGroup?.system || ''}
                            onChange={handleEditGroup}
                          >
                            <MenuItem value=""><em>Please Select...</em></MenuItem>
                            {systemsList.map((system) => (
                              <MenuItem key={system.id} value={system.id}>{system.systemName}</MenuItem>
                            ))}
                          </DataEntrySelect>
                        </FormControl>
                      </>}
                  </Grid>
                  {/* <Grid item alignItems="center" xs={2}>
                  </Grid> */}
                </Grid>
              </Paper>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  ...(!currentGroup && { pointerEvents: "none", opacity: '.25' })
                }}
              >
                <ReportEnterpriseGroupTypography>
                  {`${currentSector?.type === "LiveStock" && currentGroup ?
                    currentGroup?.enterpriseGroupName.concat(' ') :
                    ''}Classes or Enterprise Types`}
                </ReportEnterpriseGroupTypography>
                <Typography variant="body2" paragraph>
                  Add all classes or enterprise types relevant to this sector or group
                </Typography>
                <Paper>
                  {currentSectorItemList.map((sectorItem, sectorItemIdx) => (
                    !sectorItem.isDeleted &&
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ textTransform: 'none', ml: 1, mb: 1, padding: '3px' }}
                      size="small"
                      endIcon={
                        <CancelIcon
                          sx={{ padding: '2px' }}
                          onClick={(e) => handleRemoveSectorItem(e, sectorItemIdx)}
                        />
                      }
                    >
                      {fetchSectorItemTitle(sectorItem.enterpriseSectorItemId)}
                    </Button>
                  ))}
                  {addSectorItemToggle ?
                    <FormControl
                      variant="outlined"
                      sx={{ width: '15%', ml: 1 }}
                    >
                      <DataEntrySelect
                        displayEmpty
                        defaultOpen
                        value={''}
                        onChange={handleAddSectorItem}
                        onBlur={() => setAddSectorItemToggle(false)}
                        autoFocus={props.locked === false ? true : false}
                      >
                        <MenuItem value=""><em>Please Select...</em></MenuItem>
                        {sectorItemsList.map((item) => (
                          !currentSectorItemIdList.includes(item.id) &&
                          <MenuItem key={item.id} value={item}>{item.itemName}</MenuItem>
                        ))}
                      </DataEntrySelect>
                    </FormControl> :
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', ml: 1, mb: 1, px: 1 }}
                      onClick={() => setAddSectorItemToggle(true)}
                    >+ Add Type</Button>
                  }
                </Paper>
              </Paper>
            </Paper>
            <CancelModal isOpen={modalOpen} handleResponse={handleModalResponse} />
            <DeleteReportModal
            isOpen={deleteModalOpen}
            handleResponse={handleDeleteModalResponse} />
          </Paper>
        </Grid>
      </Grid >
    </ScrollSync>
    </>
  );
};

export default FarmReport;

