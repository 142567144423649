import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getOrganicMaterialManuresMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.organicMaterialManures.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getOrganicMaterialManuresMetadataSuccess(response?.data));
  } else
    return dispatch(getOrganicMaterialManuresMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getOrganicMaterialManuresMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_ORGANIC_MATERIAL_MANURES_METADATA_SUCCESS,
    payload: response
  };
};

const getOrganicMaterialManuresMetadataFail = () => {
  return {
    type: actionTypes.GET_ORGANIC_MATERIAL_MANURES_METADATA_FAIL,
  };
};

export const resetOrganicMaterialManuresMetaData = () => {
  return {
    type: actionTypes.RESET_ORGANIC_MATERIAL_MANURES_METADATA,
  }
}

export const getOrganicMaterialManuresFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.organicMaterialManures.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getOrganicMaterialManuresFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getOrganicMaterialManuresFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getOrganicMaterialManuresFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getOrganicMaterialManuresFormDataFail = () => {
  return {
    type: actionTypes.GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_FAIL,
  };
};

export const saveOrganicMaterialManuresData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().organicMaterialManures
  const response = await api.post(endpoints.landCrops.organicMaterialManures.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveOrganicMaterialManuresDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveOrganicMaterialManuresDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveOrganicMaterialManuresDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveOrganicMaterialManuresDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setOrganicMaterialManuresFormData = (response) => {
  return {
    type: actionTypes.SET_ORGANIC_MATERIAL_MANURES_FORM_DATA,
    payload: response
  };
};

export const resetOrganicMaterialManuresFormData = () => {
  return {
    type: actionTypes.RESET_ORGANIC_MATERIAL_MANURES_ALL_DATA,
  };
};

export const getOrganicMaterialManuresOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.organicMaterialManures.lookup.options);
    if (response && response.status === 200) {
      return dispatch(getOrganicMaterialManuresOptionsSuccess(response?.data));
    }
    else
      return dispatch(getOrganicMaterialManuresOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getOrganicMaterialManuresOptionsFail(e));
  }
};
const getOrganicMaterialManuresOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_OPTIONS_SUCCESS,
    payload: response
  };
};
const getOrganicMaterialManuresOptionsFail = (response) => {
  return {
    type: actionTypes.GET_OPTIONS_FAIL,
    payload: response
  };
};