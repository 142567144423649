import React, { useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import Layout from './layout/Main';
import { publicRoutes, privateRoutes, adminRoutes } from './routes/allRoutes';
import { useIdle } from "@uidotdev/usehooks";
import store from './store/';
import { signOut } from './store/appAction';
import { APP_IDLE_TIME } from './config'


const App = () => {
  const idle = useIdle(APP_IDLE_TIME);
  useEffect(() => {
    if(idle){
      store.dispatch(signOut());
      window.location.href = '/'
    }
  }, [idle]);

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={<route.component />} />
        ))}
        {privateRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element=
            {
              <Layout
                path={route.path}
                component={route.component}
                isAuthProtected={true}
                exact
              />
            }
          />
        ))}
        {adminRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element=
            {
              <Layout
                path={route.path}
                component={route.component}
                adminPages={true}
                roles={route.roles}
                isAuthProtected={true}
                exact
              />
            }
          />
        ))}
      </Routes>
    </React.Fragment>)
};


export default App;
