import * as actionTypes from './types';
import { updateObject } from '../utility';

const initialState = {
  tableDetailedResults: {},
  tableSummaryResults: {},
  chartData: {},
  detailed: {},
};

const wholeFarmReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WHOLE_FARM_DETAILED_TABLE_DATA_SUCCESS: return setDetailedDataSuccess(state, action);
    case actionTypes.GET_WHOLE_FARM_DETAILED_TABLE_DATA_FAIL: return setDetailedDataFail(state, action);
    case actionTypes.GET_WHOLE_FARM_SUMMARY_TABLE_DATA_SUCCESS: return setSummaryDataSuccess(state, action);
    case actionTypes.GET_WHOLE_FARM_SUMMARY_TABLE_DATA_FAIL: return setSummaryDataFail(state, action);
    case actionTypes.GET_WHOLE_FARM_CHART_DATA_SUCCESS: return setChartDataSuccess(state, action);
    case actionTypes.GET_WHOLE_FARM_CHART_DATA_FAIL: return setChartDataFail(state, action);
    case actionTypes.GET_WHOLE_FARM_REPORT_DATA_SUCCESS: return setReportDataSuccess(state, action);
    case actionTypes.GET_WHOLE_FARM_REPORT_DATA_FAIL: return setReportDataFail(state, action);
    default:
      return state;
  }
}

const setDetailedDataSuccess = (state, action) => {
  const updatedState = {
    tableDetailedResults: action.payload,
  }
  return updateObject(state, updatedState);
}

const setDetailedDataFail = (state, action) => {
  const updatedState = {
    tableDetailedResults: {},
  }
  return updateObject(state, updatedState);
}
const setSummaryDataSuccess = (state, action) => {
  const updatedState = {
    tableSummaryResults: action.payload,
  }
  return updateObject(state, updatedState);
}

const setSummaryDataFail = (state, action) => {
  const updatedState = {
    tableSummaryResults: {},
  }
  return updateObject(state, updatedState);
}

const setChartDataSuccess = (state, action) => {
  const updatedState = {
    chartData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setChartDataFail = (state, action) => {
  const updatedState = {
    chartData: {},
  }
  return updateObject(state, updatedState);
}

const setReportDataSuccess = (state, action) => {
  const updatedState = {
    detailed: action.payload,
  }
  return updateObject(state, updatedState);
}

const setReportDataFail = (state, action) => {
  const updatedState = {
    detailed: {},
  }
  return updateObject(state, updatedState);
}


export default wholeFarmReducer;
