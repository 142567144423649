import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import { Grid, Paper, List, ListSubheader } from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormTableRow, FormTableCell,
  DataEntryTextField, FormHeaderPaper, SectorTitleTypography,
  FormDescriptionTypography, CustomizedDivider
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import _ from "lodash";
import { isEqual } from "lodash";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { addCommas, removeCommas } from "../../../helpers/stringFormatHelper";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import {
  getFeedRationFormData, getFeedRationMetadata, resetFeedRationMetadata, saveFeedRationData,
  setFeedRationFormData, resetResponseState, setLoading, unsetLoading
} from "../../../store/appAction";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import store from '../../../store';

const CELL_WIDTH = {
  DigestibilityDiet: "20%",
  CrudeProteinDiet: "20%",
  ShareYearMethaneInhibitorFed: "20%",
};

const DataEntryTable = ({ sector, initialFormData, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState({});
  const [sectorDataExt, setSectorDataExt] = useState({});

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setFeedRationFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = removeCommas(e.target.value);
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value)) {
        toastr.warning(
          `This input only accepts values between ${addCommas(
            rule.min
          )} and ${addCommas(rule.max)}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = useCallback((
    e, groupId, rowId, colId, rule
  ) => {
    let value = removeCommas(e.target.value);

    if (e.target.inputMode === "numeric") {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        if (rule.max < parseFloat(value)) {
          toastr.warning(
            `This input only accepts values between ${addCommas(
              rule.min
            )} and ${addCommas(rule.max)}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][colId] = addCommas(value);
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][colId] = parseFloat(value);
          }));
        }
      }
    } else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][colId] = addCommas(value);
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][colId] = parseFloat(value);
      }));
    }
  }, []);

  const getCellContent = ({ groupId, rowId, cell, rowData, isOtherSales, firstVisibleCellDetails, locked }) => {
    switch (cell.dataType) {
      case "TextField":
        return (
          <DataEntryTextField
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width: 100, textAlign: "center" }}
            value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            onChange={(e) =>
              handleInputChange(e, groupId, rowId, cell.mappingProperty, cell.validations)
            }
            onBlur={(e) =>
              handleOnBlur(e, cell.validations)
            }
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
          />
        );
      default:
        return <FormTableCell sx={{ width: "10%" }}></FormTableCell>;
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        {(sector.sectorGroups.groups || []).map((group) => (
          <Fragment>
            <SectorTitleTypography sx={{ mb: 2 }}>
              {sector.title} - {group.title}
            </SectorTitleTypography>
            <Table
              sx={{ minWidth: 700 }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <FormTableRow>
                  <FormTableCell></FormTableCell>
                  {sector.columns.map((column) => (
                    <FormTableCell sx={{ width: '16%' }}>
                      {column.title}
                    </FormTableCell>
                  ))}
                </FormTableRow>
              </TableHead>
              <TableBody>
                {group.rows.map((row) => (
                  <FormTableRow>
                    <FormTableCell
                      sx={{ width: '14%' }}
                    >{row.title}</FormTableCell>
                    {row.cells.map((cell) => (
                      <FormTableCell>
                        {sectorDataInt &&
                          cell.visible &&
                          getCellContent({
                            groupId: group.id,
                            rowId: row.id,
                            cell: cell,
                            rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                            isOtherSales: false,
                            firstVisibleCellDetails: firstVisibleCellDetails,
                            locked: locked
                          })}
                      </FormTableCell>
                    ))}
                  </FormTableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        ))}
      </TableContainer>
    </>
  );
};

const FeedRation = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.feedRation.metaData);
  const initialFormData = useSelector(state => state.feedRation.initialFormData);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);

  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
    window.pageYOffset = 0;
    dispatch(setLoading());
    dispatch(getFeedRationMetadata({ reportId }));
    dispatch(getFeedRationFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetFeedRationMetadata());
    }
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 500);    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getFeedRationMetadata({ reportId }));
      dispatch(getFeedRationFormData({ reportId }));
    }
  }

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/energy-waste/electricity-fuel');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const isFormUpdated = () => {
    const feedRationData = store.getState().feedRation;
    const isUpdated = !isEqual(feedRationData?.initialFormData, feedRationData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveFeedRationData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated())
      navigate('/data-entry/energy-waste/electricity-fuel');
    else {
      dispatch(setLoading());
      dispatch(saveFeedRationData({ reportId }));
    };
  }

  return (
    <ScrollSync>
    <Grid container spacing={2} className={props.locked && 'deactivated'}>
      <Grid item xs={12} md={12} lg={12}>
        <FormHeaderPaper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
          divider={false}
        >
          <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
          <FormTitleTypography variant="h5" component="div">
            Feed Ration
          </FormTitleTypography>
          <FormDescriptionTypography variant="body2" paragraph>
            To estimate emissions from livestock, enter the average characteristics of livestock diets, or defaults can be used.
          </FormDescriptionTypography>
        </FormHeaderPaper>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
          <List sx={{ mb: 2 }}>
            <ListSubheader sx={{ bgcolor: "background.paper" }}>
              <ScrollSyncPane>
                <>
                <DataEntryButtonStack
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  handleModalResponse={handleModalResponse}
                  handelSaveClick={handelSaveClick}
                  handleNextClick={handleNextClick}
                />
                <CustomizedDivider/>
                </>
              </ScrollSyncPane>
            </ListSubheader>
            {firstVisibleCellDetails && sectors.map((sector, index) => (
              <>
                <React.Fragment key={index}>
                  <DataEntryTable
                    key={sector.id}
                    sector={sector}
                    initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                    firstVisibleCellDetails={firstVisibleCellDetails}
                    locked={props.locked}
                  />
                </React.Fragment>
              </>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
    </ScrollSync>
  );
};

export default FeedRation;
