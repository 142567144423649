import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, } from '@mui/material';
import React, { useEffect, useState } from 'react'
import toastr from "toastr";
import { InfoTooltip, ListTableCell, ListTableRow } from '../../../components/CustomStyles/StyledComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentAdminOrganisation, setCurrentAdminOffice, getAdminOffices, unsetLoading, resetResponseState, setLoading } from '../../../store/appAction';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import { deleteOffice, getAdminOfficesByOrg } from '../../../store/admin/office/action';
import DeleteModal from '../../../components/Modal/DeleteModal';
import CommonModal from '../../../components/Modal/CommonModal';
import EmptyTableBody from '../../../components/Tables/EmptyTableBody';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';

const Offices = () => {
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMsg, errorMsg } = useSelector(state => state.common);
    const officeList = useSelector(state => state.adminOffice.offices);
    const currentOrganisation = useSelector(state => state.adminOrganisation.selectedOrganisation);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [commonModalOpen, setCommonModalOpen] = useState(false);

    useEffect(() => {
        dispatch(setLoading());
        handleGetOffices();
        return () => {
            dispatch(setCurrentAdminOrganisation({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        officeList && setTimeout(() => {
            dispatch(unsetLoading());
        }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeList]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                handleGetOffices();
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);


    const handleGetOffices = () => {
        if (currentOrganisation && currentOrganisation?.id) {
            dispatch(getAdminOfficesByOrg(currentOrganisation.id));
        } else {
            dispatch(getAdminOffices());
        }
    }

    const handelOfficeCreate = () => {
        dispatch(setCurrentAdminOffice({}));
        navigate('/admin/offices/create')
    }

    const handleOfficeEditDetails = (e, officeId, organisationId) => {
        dispatch(setCurrentAdminOrganisation(organisationId));
        dispatch(setCurrentAdminOffice(officeId));
        navigate('/admin/offices/edit')
    }
    const handleDelete = async (e, office) => {
      dispatch(setLoading());
      const response = await api.get(
        endpoints.admin.office.officeUsers.replace("OFFICE_ID", office.id)
      );
      if (response && response.status === 200) {
        if (response.data?.hasAnyUsers) {
          dispatch(setCurrentAdminOffice(office));
          setCommonModalOpen(true);
        } else {
          dispatch(setCurrentAdminOffice(office));
          setDeleteModalOpen(true);
        }
      } else {
        dispatch(unsetLoading());
        toastr.error(
          response?.data?.error?.message ||
            "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.",
          undefined,
          toastrCustomOptionsLonger
        );
      }
    };

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            dispatch(setLoading());
            dispatch(deleteOffice());
        }
        dispatch(unsetLoading());
    }

    const handleCommonModalResponse = (positiveResponse) => {
        setCommonModalOpen(false);
        if (positiveResponse) {
            dispatch(setLoading());
            dispatch(deleteOffice());
        }
        dispatch(unsetLoading());
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                ></Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper
                    sx={{
                        p: 2,
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}>
                    <Button variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handelOfficeCreate}>
                        Create
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '85%' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '95%' }} size="small" aria-label="customized table">
                            <TableHead>
                                <ListTableCell sx={{ textAlign: 'left', width: '40%' }}>Office</ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '50%' }}>Organisation</ListTableCell>
                                <ListTableCell sx={{ textAlign: 'center', width: '10%' }}>Action</ListTableCell>
                            </TableHead>
                            {officeList?.length > 0 ?
                                <TableBody>
                                    {officeList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <ListTableRow key={index}>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleOfficeEditDetails(e, row)}>
                                                {row.officeName}
                                            </ListTableCell>
                                            <ListTableCell>{row.organizationName}</ListTableCell>
                                            <ListTableCell sx={{ textAlign: 'center' }}>
                                                <InfoTooltip title="Delete Office">
                                                    <DeleteIcon
                                                        id='delete'
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={(e) => handleDelete(e, row)}
                                                    />
                                                </InfoTooltip>
                                            </ListTableCell>
                                        </ListTableRow>
                                    ))}
                                </TableBody> :
                                <EmptyTableBody />
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={officeList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <DeleteModal
                        isOpen={deleteModalOpen}
                        handleResponse={handleDeleteModalResponse}
                        value={'Are you sure you want to delete this Office?'}
                    />
                    <CommonModal
                        isOpen={commonModalOpen}
                        value='If you remove this office then all users within this office will have access to all of the organisations farms. Do you wish to continue?'
                        handleResponse={(value)=>{
                            handleCommonModalResponse(value);
                        }} />
                </Paper>
            </Grid>
        </Grid >

    )
}

export default Offices