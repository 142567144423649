import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  feedCategoryOptions: [],
  feedOptions: [],
  metaData: {},
  initialFormData: {},
  currentFormData: {},
};

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FEED_METADATA_SUCCESS: return setMetadataSuccess(state, action);
    case actionTypes.GET_FEED_METADATA_FAIL: return setMetadataFail(state, action);
    case actionTypes.RESET_FEED_METADATA: return resetMetaData(state, action);
    case actionTypes.GET_FEED_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_FEED_FORM_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_FEED_FORM_DATA: return setFormData(state, action);
    case actionTypes.RESET_FEED_ALL_DATA: return resetFormData(state, action);
    case actionTypes.GET_FEED_CATEGORY_OPTIONS_SUCCESS: return setFeedCategoryOptionsSuccess(state, action);
    case actionTypes.GET_FEED_CATEGORY_OPTIONS_FAIL: return setFeedCategoryOptionsFail(state, action);
    case actionTypes.GET_FEED_OPTIONS_SUCCESS: return setFeedOptionsSuccess(state, action);
    case actionTypes.GET_FEED_OPTIONS_FAIL: return setFeedOptionsFail(state, action);
    default:
      return state;
  }
};

const setFeedCategoryOptionsSuccess = (state, action) => {
  const updatedState = {
    feedCategoryOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFeedCategoryOptionsFail = (state, action) => {
  const updatedState = {
    feedCategoryOptions: [],
  }
  return updateObject(state, updatedState);
}

const setFeedOptionsSuccess = (state, action) => {
  const updatedState = {
    feedOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFeedOptionsFail = (state, action) => {
  const updatedState = {
    feedOptions: [],
  }
  return updateObject(state, updatedState);
}

const setMetadataSuccess = (state, action) => {
  const updatedState = {
    metaData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMetadataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedState = {
    currentFormData: {
      ...state.currentFormData,
      [sectorId]: {
        ...formData
      }
    },
  }
  return updateObject(state, updatedState);
}

const resetFormData = (state, action) => {
  const updatedState = {
    currentFormData: state.initialFormData,
  }
  return updateObject(state, updatedState);
}

export default feedReducer;
