import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getFertiliserMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.fertiliser.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getFertiliserMetadataSuccess(response?.data));
  } else
    return dispatch(getFertiliserMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getFertiliserMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_FERTILISER_METADATA_SUCCESS,
    payload: response
  };
};

const getFertiliserMetadataFail = () => {
  return {
    type: actionTypes.GET_FERTILISER_METADATA_FAIL,
  };
};

export const resetFertiliserMetaData = () => {
  return {
    type: actionTypes.RESET_FERTILISER_METADATA,
  };
};

export const getFertiliserFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.fertiliser.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getFertiliserFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getFertiliserFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getFertiliserFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_FERTILISER_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getFertiliserFormDataFail = () => {
  return {
    type: actionTypes.GET_FERTILISER_FORM_DATA_FAIL,
  };
};

export const savFertiliserData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().fertiliser
  const response = await api.post(endpoints.landCrops.fertiliser.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveFertiliserDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveFertiliserDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveFertiliserDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveFertiliserDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const getFertiliserOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.fertiliser.lookup.options);
    if (response && response.status === 200) {
      return dispatch(getFertiliserOptionsSuccess(response?.data));
    }
    else
      return dispatch(getFertiliserOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getFertiliserOptionsFail(e));
  }
};
const getFertiliserOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_FERTILISER_OPTIONS_SUCCESS,
    payload: response
  };
};
const getFertiliserOptionsFail = (response) => {
  return {
    type: actionTypes.GET_FERTILISER_OPTIONS_FAIL,
    payload: response
  };
};

export const setFertiliserFormData = (response) => {
  return {
    type: actionTypes.SET_FERTILISER_FORM_DATA,
    payload: response
  };
};

export const resetFertiliserFormData = () => {
  return {
    type: actionTypes.RESET_FERTILISER_ALL_DATA,
  };
};

