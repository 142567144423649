import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    ReferenceLine
} from "recharts";
import { Paper, Typography } from "@mui/material";
import _ from "lodash";
import { ChartTitleTypography } from "../CustomStyles/StyledComponents";

const data = [
    // {
    //     title: "Manure Management",
    //     value: 70,
    //     comparison: 12,
    // },
    // {
    //     title: "Fertiliser",
    //     value: 23,
    //     comparison: 19,
    // },
    // {
    //     title: "Purchased Feed",
    //     value: 45,
    //     comparison: 8,
    // },
    // {
    //     title: "Purchased Bedding",
    //     value: 36,
    //     comparison: 30,
    // },
    // {
    //     title: "Fuel",
    //     value: 18,
    //     comparison: 8,
    // },
    // {
    //     title: "Electricity",
    //     value: 19,
    //     comparison: 3,
    // },
    // {
    //     title: "Other",
    //     value: 39,
    //     comparison: 14,
    // }
];
const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 2 }} variant='outlined'>
                <Typography variant="caption" mb={1}>
                    {label}
                </Typography>
                <br />
                <Typography variant="caption" fontWeight="bold">
                    {`${payload[0].name} : ${payload[0].value?.toFixed(2)}`}
                </Typography>
                <br />
                <Typography variant="caption" fontWeight="bold" color="#CC5500">
                    {`${payload[1].name} : ${payload[1].value?.toFixed(2)}`}
                </Typography>
            </Paper>
        );
    }
    return null;
};

export default function Vertical2XBarChart({
    title = "Emissions vs Comparison",
    emissionUnit = "Emissions (kg CO₂-eq/ kg dwt)",
    dataset, key,
    height = 300, width = "100%",
    axisInfo = {
        x1: 'value',
        x2: 'comparison',
        y: 'title'
    },
    color = {
        x1: '#253764',
        x2: '#CC5500'
    },
    isAnimationActive = true
}) {

    return (
        <>
            <ChartTitleTypography gutterBottom align="center">
                {title}
            </ChartTitleTypography>
            <ResponsiveContainer
                height={height}
                width={width}
            >
                <BarChart
                    isAnimationActive={isAnimationActive}
                    key={key}
                    data={_.filter(dataset, d => d.value >= 0)}
                    stackOffset="sign"
                    layout="vertical"
                    margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5
                    }}
                    barSize={8}
                >
                    {/* <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} /> */}
                    <XAxis name="Emissions" dataKey={axisInfo.x1} type='number' >
                        <Label value={emissionUnit} offset={0} fontSize="1.2em" position="insideBottom" />
                    </XAxis>
                    <YAxis dataKey={axisInfo.y} type='category' interval={0} width={75} height={40} />
                    <Tooltip content={renderCustomTooltip} />
                    <Legend />
                    <ReferenceLine x={0} stroke="#000" />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar name="Your farm" dataKey={axisInfo.x1} fill={color.x1} />
                    <Bar name="Comparison" dataKey={axisInfo.x2} fill={color.x2} />
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}
