import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  metaData: {},
  totalArea: {},
  initialFormData: {},
  currentFormData: {},
};

const organicMaterialManuresReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORGANIC_MATERIAL_MANURES_METADATA_SUCCESS: return setMetaDataSuccess(state, action);
    case actionTypes.GET_ORGANIC_MATERIAL_MANURES_METADATA_FAIL: return setMetaDataFail(state, action);
    case actionTypes.RESET_ORGANIC_MATERIAL_MANURES_METADATA: return resetMetaData(state, action);
    case actionTypes.GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_ORGANIC_MATERIAL_MANURES_FORM_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_ORGANIC_MATERIAL_MANURES_FORM_DATA: return setFormData(state, action);
    case actionTypes.RESET_ORGANIC_MATERIAL_MANURES_ALL_DATA: return resetFormData(state, action);
    case actionTypes.GET_OPTIONS_SUCCESS: return setOptionsSuccess(state, action);
    case actionTypes.GET_OPTIONS_FAIL: return setOptionsFail(state, action);

    default:
      return state;
  }
}

const setMetaDataSuccess = (state, action) => {
  const updatedState = {
    metaData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMetaDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedState = {
    currentFormData: {
      ...state.currentFormData,
      [sectorId]: {
        ...formData
      }
    },
  }
  return updateObject(state, updatedState);
}

const resetFormData = (state, action) => {
  const updatedState = {
    currentFormData: state.initialFormData,
  }
  return updateObject(state, updatedState);
}

const setOptionsSuccess = (state, action) => {
  const updatedState = {
    options: action.payload,
  }
  return updateObject(state, updatedState);
}

const setOptionsFail = (state, action) => {
  const updatedState = {
    options: [],
  }
  return updateObject(state, updatedState);
}

export default organicMaterialManuresReducer;