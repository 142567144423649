import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getElectricityFuelContMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.electricityFuelCont.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getElectricityFuelContMetadataSuccess(response?.data));
  } else
    return dispatch(getElectricityFuelContMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getElectricityFuelContMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_CONT_METADATA_SUCCESS,
    payload: response
  };
};

const getElectricityFuelContMetadataFail = () => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_CONT_METADATA_FAIL,
  };
};

export const resetElectricityFuelContMetaData = () => {
  return {
    type: actionTypes.RESET_ELECTRICITY_FUEL_CONT_METADATA,
  };
};

export const getElectricityFuelContFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.electricityFuelCont.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    const data = response?.data?.data
    dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getElectricityFuelContFormDataSuccess(data));
  } else
    return dispatch(getElectricityFuelContFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getElectricityFuelContFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_CONT_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getElectricityFuelContFormDataFail = () => {
  return {
    type: actionTypes.GET_ELECTRICITY_FUEL_CONT_FORM_DATA_FAIL,
  };
};

const getWholeFarmData = (response) => {
  return {
    type: actionTypes.GET_WHOLE_FARM_DATA_CONT_SUCCESS,
    payload: response
  };
}

export const saveElectricityFuelContData = ({ reportId }) => async (dispatch) => {
  const { currentFormData, currentWholeFarmData } = store.getState().electricityFuelCont
  const response = await api.post(endpoints.energyWaste.electricityFuelCont.saveData,
    {
      reportId: reportId,
      data: currentFormData,
      wholeFarmData: currentWholeFarmData
    });
  if (response && response.status === 200) {
    return dispatch(saveElectricityFuelContDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveElectricityFuelContDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveElectricityFuelContDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveElectricityFuelContDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setElectricityFuelContFormData = (response) => {
  return {
    type: actionTypes.SET_ELECTRICITY_FUEL_CONT_FORM_DATA,
    payload: response
  };
};

export const setWholeFarmDataCont = (response) => {
  return {
    type: actionTypes.SET_WHOLE_FARM_DATA_CONT_SUCCESS,
    payload: response
  };
};

export const resetElectricityFuelContFormData = () => {
  return {
    type: actionTypes.RESET_ELECTRICITY_FUEL_CONT_ALL_DATA,
  };
};

export const getAllocateElectricityFuelCont = ({ reportId, currentWholeFarmData }) => async (dispatch) => {
  const response = await api.post(endpoints.energyWaste.electricityFuelCont.defaults
    .concat('?ReportId=', reportId)
    .concat('&Kerosene=', currentWholeFarmData.Kerosene)
    .concat('&LPG=', currentWholeFarmData.LPG)
    .concat('&MainsGas=', currentWholeFarmData.MainsGas)
    .concat('&Coal=', currentWholeFarmData.Coal)
  );

  if (response && response.status === 200) {
    const data = response?.data
    //dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getElectricityFuelContFormDataSuccess(data));
  } else
    return dispatch(getAllocateElectricityFuelContFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getAllocateElectricityFuelContFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};