import React, { useEffect, useState } from 'react';
import { DataEntryDatePicker, } from '../CustomStyles/StyledComponents';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Paper, TextField } from '@mui/material';
import dayjs from 'dayjs';

const TableSearchDatePicker =
    ({
        inputFormat = 'DD-MM-YY',
        outputFormat = 'YYYY-MM-DD',
        views = undefined,
        searchKey, handleSearchQuery = () => { } }) => {
        const [searchDateFrom, setSearchDateFrom] = useState(undefined);
        const [searchDateTo, setSearchDateTo] = useState(undefined);

        useEffect(() => {
            handleSearchQuery(searchKey.concat('From'), searchDateFrom?.isValid() ? dayjs((searchDateFrom)?.toISOString()).format(outputFormat) : '');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchDateFrom]);

        useEffect(() => {
            handleSearchQuery(searchKey.concat('To'), searchDateTo?.isValid() ? dayjs(searchDateTo?.toISOString()).format(outputFormat) : '');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchDateTo]);

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Paper p={0} m={0} >
                    <DataEntryDatePicker
                        openTo="day"
                        inputFormat={inputFormat}
                        views={views}
                        value={searchDateFrom || null}
                        maxDate={searchDateTo || null}
                        onChange={setSearchDateFrom}
                        renderInput={(params) => <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "From",
                                type: 'search',
                                // readOnly: true
                            }}
                            onChange={(e) => e.preventDefault()} />}
                    />

                    <DataEntryDatePicker
                        openTo="day"
                        inputFormat={inputFormat}
                        views={views}
                        value={searchDateTo || null}
                        minDate={searchDateFrom || null}
                        onChange={setSearchDateTo}
                        renderInput={(params) => <TextField
                            {...params}
                            type='search'
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "To",
                                type: 'search',
                                // readOnly: true
                            }}
                            onChange={(e) => e.preventDefault()} />}
                    />
                </Paper>
            </LocalizationProvider >
        );
    };

export default TableSearchDatePicker;

