import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer, Tooltip, Label } from "recharts";
import Color from "color";
import { Paper, styled, Typography } from "@mui/material";
import _ from "lodash";
import { addCommas } from "../../helpers/stringFormatHelper";
import { ChartTitleTypography } from "../CustomStyles/StyledComponents";
import RectangleIcon from '@mui/icons-material/Rectangle';
// const getColor = (index, initNum) => {
//     return Color.rgb(90 + (index * (100 + ((index + initNum) * initNum))),
//         200 + (index + (initNum * initNum)),
//         100 + (index * 10)).hex();
// }

// const data = [
//     { title: "CO2- eq Emissions", value: 70600 },
//     { title: "CO2 -eq from Methane", value: 54000 },
//     { title: "CO2 -eq from Nitrous Oxide", value: 19300 },
// ];

const COLOR_CODES = ['#253764', '#f1b435', '#199F9F']

const getColor = (index, initNum) => {
    return index <= COLOR_CODES.length ? COLOR_CODES[index] :
        Color.rgb(0 + (index * initNum),
            32 + (index * (initNum * 10)),
            96 + (index * (5 * initNum))).hex();
}

const renderActiveShapeAlt = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.title}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
            >{`PV ${value}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        title,
        value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={20}
                textAnchor={"middle"}
                fontWeight="bold"
                fontSize="1.15em"
                fill="#595959">
                {`${addCommas(value.toFixed(2))} (${(percent * 100).toFixed(2)}%)`}
            </text>
            <text x={cx} y={cy + 15} dy={20}
                textAnchor={"middle"}
                fontWeight="bold"
                fontSize="1.15em"
                fill="#595959">
                {title}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            {/* <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            /> */}
            {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
            {/* <text
                x={ex + (cos >= 0 ? 1 : -1) * 8}
                y={ey + (cos >= 0 ? 1 : -1) * 12}
                dy={3}
                textAnchor={textAnchor}
                fill="#333"
            >
                <tspan
                    style={{
                        fontWeight: 700,
                        fill: "#253764",
                    }}
                >
                    {`${value.toFixed(2)} (${(percent * 100).toFixed(2)}%)`}
                    <br />
                    {title}
                </tspan>
            </text> */}
        </g >
    );
};

const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 2 }} variant='outlined'>
                <Typography variant="caption" fontWeight="bold">
                    {`${payload[0].name} : ${addCommas(payload[0].value?.toFixed(2))} kg CO₂-eq`}
                </Typography>
            </Paper>
        );
    }
    return null;
};

const renderColorfulLegendText = (value, entry, data) => {
    const { color } = entry;

    return <span style={{ color }}>{value?.concat(' (', data?.value?.toFixed(1), ' kg CO₂-eq)')}</span>;
};


const CustomLabel = ({ viewBox, total = 0 }) => {
    const { cx, cy } = viewBox;
    return (
        <React.Fragment>
            <text x={cx - 50} y={cy + 10}>
                <tspan
                    style={{
                        fontWeight: 700,
                        fontSize: "1.25em",
                        fill: "#253764",
                    }}
                >
                    {addCommas(total)}
                </tspan>
            </text>
            <text x={cx - 50} y={cy + 20}>
                <tspan
                    style={{
                        fontWeight: 700,
                        fontSize: "1.25em",
                        fill: "#253764",
                    }}
                >
                    kg CO&#8322;-eq
                </tspan>
            </text>

        </React.Fragment>
    );
};

export default function CircularPieChart({
    title, dataset, key,
    height = 300, width = "100%",
    cx = 130, cy = 120, outerRadius = 115,
    isAnimationActive = true,
    labelFontSize = "1.2em",
    legendWidth = "calc(100% - 260px)"
}) {

    const [activeIndex, setActiveIndex] = useState(-1);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    const totalValue = _.sumBy(dataset, (obj) => { return obj.value; });

    return (
        <>
            <ChartTitleTypography gutterBottom align="center">
                {title}
            </ChartTitleTypography>
            <ResponsiveContainer
                height={height}
                width={width}
            >
                <PieChart>
                    <Pie
                        isAnimationActive={isAnimationActive}
                        key={key}
                        nameKey="title"
                        fontSize={10}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={dataset}
                        cx={cx}
                        cy={cy}
                        // label={renderCustomLabel}
                        innerRadius={outerRadius - 30}
                        outerRadius={outerRadius}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                    >
                        {dataset?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(index, dataset?.length)} />
                        ))}
                        {/* <Label
                            content={<CustomLabel total={totalValue} />}
                            position="center"
                        /> */}
                        <Label value={`${addCommas(totalValue.toFixed(2))} kg CO₂-eq`}
                            position="center"
                            style={{
                                fontWeight: 700,
                                fontSize: labelFontSize,
                                fill: "#cc5500",
                            }} />
                    </Pie>
                    <Tooltip content={renderCustomTooltip} />
                    <Legend
                        layout='vertical'
                        align='right' wrapperStyle={{ width: legendWidth, whiteSpace: "break-spaces" }}
                        formatter={(value, entry, index) => renderColorfulLegendText(value, entry, dataset[index])}
                    />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}
