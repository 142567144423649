export const GET_NUMBERS_WEIGHTS_METADATA = 'GET_NUMBERS_WEIGHTS_METADATA'
export const GET_NUMBERS_WEIGHTS_METADATA_SUCCESS = 'GET_NUMBERS_WEIGHTS_METADATA_SUCCESS'
export const GET_NUMBERS_WEIGHTS_METADATA_FAIL = 'GET_NUMBERS_WEIGHTS_METADATA_FAIL'
export const RESET_NUMBERS_WEIGHTS_METADATA = 'RESET_NUMBERS_WEIGHTS_METADATA'

export const GET_NUMBERS_WEIGHTS_FORM_DATA = 'GET_NUMBERS_WEIGHTS_FORM_DATA'
export const GET_NUMBERS_WEIGHTS_FORM_DATA_SUCCESS = 'GET_NUMBERS_WEIGHTS_FORM_DATA_SUCCESS'
export const GET_NUMBERS_WEIGHTS_FORM_DATA_FAIL = 'GET_NUMBERS_WEIGHTS_FORM_DATA_FAIL'

export const GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA = 'GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA'
export const GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA_SUCCESS = 'GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA_SUCCESS'
export const GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA_FAIL = 'GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA_FAIL'

export const SET_NUMBERS_WEIGHTS_FORM_DATA = 'SET_NUMBERS_WEIGHTS_FORM_DATA'
export const SET_NUMBERS_WEIGHTS_OTHER_SALES_DATA = 'SET_NUMBERS_WEIGHTS_OTHER_SALES_DATA'
export const RESET_NUMBERS_WEIGHTS_ALL_DATA = 'RESET_NUMBERS_WEIGHTS_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'