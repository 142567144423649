export const GET_SOIL_CARBON_METADATA = 'GET_SOIL_CARBON_METADATA'
export const GET_SOIL_CARBON_METADATA_SUCCESS = 'GET_SOIL_CARBON_METADATA_SUCCESS'
export const GET_SOIL_CARBON_METADATA_FAIL = 'GET_SOIL_CARBON_METADATA_FAIL'
export const RESET_SOIL_CARBON_METADATA = 'RESET_SOIL_CARBON_METADATA'

export const GET_SOIL_CARBON_FORM_DATA_SUCCESS = 'GET_SOIL_CARBON_FORM_DATA_SUCCESS'
export const GET_SOIL_CARBON_FORM_DATA_FAIL = 'GET_SOIL_CARBON_FORM_DATA_FAIL'

export const SET_SOIL_CARBON_FORM_DATA = 'SET_SOIL_CARBON_FORM_DATA'
export const RESET_SOIL_CARBON_ALL_DATA = 'RESET_SOIL_CARBON_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_CROP_TYPE_OPTIONS_SUCCESS = 'GET_CROP_TYPE_OPTIONS_SUCCESS'
export const GET_CROP_TYPE_OPTIONS_FAIL = 'GET_CROP_TYPE_OPTIONS_FAIL'

export const GET_GRAZING_SYSTEM_OPTIONS_SUCCESS = 'GET_GRAZING_SYSTEM_OPTIONS_SUCCESS'
export const GET_GRAZING_SYSTEM_OPTIONS_FAIL = 'GET_GRAZING_SYSTEM_OPTIONS_FAIL'

export const GET_TILLAGE_PRACTICE_OPTIONS_SUCCESS = 'GET_TILLAGE_PRACTICE_OPTIONS_SUCCESS'
export const GET_TILLAGE_PRACTICE_OPTIONS_FAIL = 'GET_TILLAGE_PRACTICE_OPTIONS_FAIL'