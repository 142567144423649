import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  initialFormData: {},
  currentFormData: {},
  errorMsg: '',
  successMsg: ''
};

const monthlyStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GET_MONTHLY_STOCK_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_MONTHLY_STOCK_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_MONTHLY_STOCK_DATA: return setFormData(state, action);
    case actionTypes.RESET_MONTHLY_STOCK_DATA: return resetFormData(state, action);
    default:
      return state;
  }
};

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    initialFormData: {},
    currentFormData: {},
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedSectors = state.currentFormData.sectors.map(sector => {
    if (sector.sectorId === sectorId) {

      const updatedEsGroups = sector.esGroups.map(group => {
        const updatedGroup = formData.esGroups.find(
          grp => grp.groupId === group.groupId
        );
        if (updatedGroup) {
          return {
            ...group,
            esItems: group.esItems.map(item => {
              const updatedItem = updatedGroup.esItems.find(
                itm => itm.itemId === item.itemId
              );
              return updatedItem ? { ...item, ...updatedItem } : item;
            }),
            ...updatedGroup,
          };
        }
        return group;
      });

      return {
        ...sector,
        esGroups: updatedEsGroups,
        ...formData,
      };
    }
    return sector;
  });

  return {
    ...state,
    currentFormData: {
      ...state.currentFormData,
      sectors: updatedSectors,
    },
  };
};

const resetFormData = (state, action) => {
  const updatedState = {
    currentFormData: state.initialFormData,
    currentFormData: state.currentFormData,
  }
  return updateObject(state, updatedState);
}

export default monthlyStockReducer;
