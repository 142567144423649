import { updateObject } from '../../utility';
import * as actionTypes from './types';

const initialState = {
  projectId: '',
  projects: undefined,
  adminAccount: {},
  projectDetails: {},
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_ADMIN_PROJECT_ID: return setProjectId(state, action);
    case actionTypes.GET_ADMIN_PROJECT_LIST_SUCCESS: return setProjectsSuccess(state, action);
    case actionTypes.GET_ADMIN_PROJECT_LIST_FAIL: return setProjectsFail(state, action);
    case actionTypes.GET_ADMIN_PROJECT_DATA_SUCCESS: return setProject(state, action);
    case actionTypes.GET_PROJECT_ADMIN_DATA_SUCCESS: return setProjectAdmin(state, action);
    default:
      return state;
  }
};

const setProjectId = (state, action) => {
  const updatedState = {
    projectId: action.payload,
    projectDetails: {},
  }
  return updateObject(state, updatedState);
}

const setProject = (state, action) => {
  const updatedState = {
    projectDetails: action.payload,
  }
  return updateObject(state, updatedState);
}

const setProjectAdmin = (state, action) => {
  const updatedState = {
    adminAccount: action.payload,
  }
  return updateObject(state, updatedState);
}


const setProjectsSuccess = (state, action) => {
  const updatedState = {
    projects: action.payload,
  }
  return updateObject(state, updatedState);
}

const setProjectsFail = (state, action) => {
  const updatedState = {
    projects: [],
  }
  return updateObject(state, updatedState);
}


export default projectsReducer;
