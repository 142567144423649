import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getNumbersWeightsMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.numbersWeights.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getNumbersWeightsMetadataSuccess(response?.data));
  }
  else
    return dispatch(getNumbersWeightsMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getNumbersWeightsMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_NUMBERS_WEIGHTS_METADATA_SUCCESS,
    payload: response
  };
};

const getNumbersWeightsMetadataFail = () => {
  return {
    type: actionTypes.GET_NUMBERS_WEIGHTS_METADATA_FAIL,
  };
};

export const resetNumbersWeightsMetadata = () => {
  return {
    type: actionTypes.RESET_NUMBERS_WEIGHTS_METADATA,
  };
};

export const getNumbersWeightsFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.numbersWeights.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    const data = response?.data?.data;
    const summeryData = response?.data?.groupSummeryData;
    dispatch(getNumbersWeightsOtherSalesDataSuccess(summeryData));
    return dispatch(getNumbersWeightsFormDataSuccess(data));
  }
  else
    return dispatch(getNumbersWeightsFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getNumbersWeightsFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_NUMBERS_WEIGHTS_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getNumbersWeightsFormDataFail = () => {
  return {
    type: actionTypes.GET_NUMBERS_WEIGHTS_FORM_DATA_FAIL,
  };
};

export const getNumbersWeightsOtherSalesData = (/*{ reportId, formId }*/) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.numbersWeights.otherSalesData);
  if (response && response.status === 200) {
    return dispatch(getNumbersWeightsOtherSalesDataSuccess(response?.data));
  }
  else
    return dispatch(getNumbersWeightsOtherSalesDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getNumbersWeightsOtherSalesDataSuccess = (response) => {
  return {
    type: actionTypes.GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA_SUCCESS,
    payload: response
  };
};

const getNumbersWeightsOtherSalesDataFail = () => {
  return {
    type: actionTypes.GET_NUMBERS_WEIGHTS_OTHER_SALES_DATA_FAIL,
  };
};

export const setNumbersWeightsFormData = (response) => {
  return {
    type: actionTypes.SET_NUMBERS_WEIGHTS_FORM_DATA,
    payload: response
  };
};

export const setNumbersWeightsOtherSalesData = (response) => {
  return {
    type: actionTypes.SET_NUMBERS_WEIGHTS_OTHER_SALES_DATA,
    payload: response
  };
};

export const resetNumbersWeightsFormData = () => {
  return {
    type: actionTypes.RESET_NUMBERS_WEIGHTS_ALL_DATA,
  };
};

export const saveNumbersWeightsData = ({ reportId }) => async (dispatch) => {
  const { currentFormData, currentOtherSalesData } = store.getState().numberWeights
  const response = await api.post(endpoints.livestock.numbersWeights.saveData,
    {
      reportId: reportId,
      data: currentFormData,
      groupSummeryData: currentOtherSalesData
    });
  if (response && response.status === 200) {
    return dispatch(saveNumbersWeightsDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveNumbersWeightsDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveNumbersWeightsDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveNumbersWeightsDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};