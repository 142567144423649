import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getSoilCarbonMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.soilCarbon.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getSoilCarbonMetadataSuccess(response?.data));
  } else
    return dispatch(getSoilCarbonMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getSoilCarbonMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_SOIL_CARBON_METADATA_SUCCESS,
    payload: response
  };
};

const getSoilCarbonMetadataFail = () => {
  return {
    type: actionTypes.GET_SOIL_CARBON_METADATA_FAIL,
  };
};

export const resetSoilCarbonMetaData = () => {
  return {
    type: actionTypes.RESET_SOIL_CARBON_METADATA,
  };
};

export const getSoilCarbonFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.soilCarbon.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getSoilCarbonFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getSoilCarbonFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getSoilCarbonFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_SOIL_CARBON_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getSoilCarbonFormDataFail = () => {
  return {
    type: actionTypes.GET_SOIL_CARBON_FORM_DATA_FAIL,
  };
};

export const saveSoilCarbonData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().soilCarbon
  const response = await api.post(endpoints.landCrops.soilCarbon.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveSoilCarbonDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveSoilCarbonDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveSoilCarbonDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveSoilCarbonDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setSoilCarbonFormData = (response) => {
  return {
    type: actionTypes.SET_SOIL_CARBON_FORM_DATA,
    payload: response
  };
};

export const resetSoilCarbonFormData = () => {
  return {
    type: actionTypes.RESET_SOIL_CARBON_ALL_DATA,
  };
};

export const getCropTypeOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.soilCarbon.lookup.fallowCoverCropTypeOptions);
    if (response && response.status === 200) {
      return dispatch(getCropTypeOptionsSuccess(response?.data));
    }
    else
      return dispatch(getCropTypeOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getCropTypeOptionsFail(e));
  }
};

const getCropTypeOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_CROP_TYPE_OPTIONS_SUCCESS,
    payload: response
  };
};

const getCropTypeOptionsFail = (response) => {
  return {
    type: actionTypes.GET_CROP_TYPE_OPTIONS_FAIL,
    payload: response
  };
};

export const getGrazingSystemOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.soilCarbon.lookup.grazingSystemOptions);
    if (response && response.status === 200) {
      return dispatch(getGrazingSystemOptionsSuccess(response?.data));
    }
    else
      return dispatch(getGrazingSystemOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getGrazingSystemOptionsFail(e));
  }
};

const getGrazingSystemOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_GRAZING_SYSTEM_OPTIONS_SUCCESS,
    payload: response
  };
};

const getGrazingSystemOptionsFail = (response) => {
  return {
    type: actionTypes.GET_GRAZING_SYSTEM_OPTIONS_FAIL,
    payload: response
  };
};

export const getTillagePracticeOptionsOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.soilCarbon.lookup.tillagePracticeOptions);
    if (response && response.status === 200) {
      return dispatch(getTillagePracticeOptionsSuccess(response?.data));
    }
    else
      return dispatch(getTillagePracticeOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getTillagePracticeOptionsFail(e));
  }
};

const getTillagePracticeOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_TILLAGE_PRACTICE_OPTIONS_SUCCESS,
    payload: response
  };
};

const getTillagePracticeOptionsFail = (response) => {
  return {
    type: actionTypes.GET_TILLAGE_PRACTICE_OPTIONS_FAIL,
    payload: response
  };
};