import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TableBody, TableCell, TableFooter, TableRow } from '@mui/material';

const EmptyTableBody = ({ title }) => {
  return (
      <TableRow sx={{ height: '200px', verticalAlign: 'top' }}>
        <TableCell sx={{ textAlign: 'left' }} colSpan={6}>
          <Typography variant='body1' padding={5}>{`No ${title || 'records'} to display.`}</Typography>
        </TableCell>
      </TableRow>
  );
};

export default EmptyTableBody;