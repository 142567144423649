import queryString from 'query-string';
import * as actionTypes from './types';
import * as api from '../../api/base';
import { endpoints } from '../../api/endpoints';

export const getWholeFarmDetailedTable = (params) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.wholeFarm.tables.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
      return dispatch(getWholeFarmDetailedTableSuccess(response?.data));
    } else
      return dispatch(getWholeFarmDetailedTableFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));

  } catch (error) {
    return dispatch(getWholeFarmDetailedTableFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getWholeFarmDetailedTableSuccess = (response) => {
  return {
    type: actionTypes.GET_WHOLE_FARM_DETAILED_TABLE_DATA_SUCCESS,
    payload: response
  };
};

const getWholeFarmDetailedTableFail = () => {
  return {
    type: actionTypes.GET_WHOLE_FARM_DETAILED_TABLE_DATA_FAIL,
  };
};

export const getWholeFarmChart = (params) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.wholeFarm.charts.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
      return dispatch(getWholeFarmChartSuccess(response?.data));
    } else
      return dispatch(getWholeFarmChartFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (error) {
    return dispatch(getWholeFarmChartFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getWholeFarmChartSuccess = (response) => {
  return {
    type: actionTypes.GET_WHOLE_FARM_CHART_DATA_SUCCESS,
    payload: response
  };
};

const getWholeFarmChartFail = () => {
  return {
    type: actionTypes.GET_WHOLE_FARM_CHART_DATA_FAIL,
  };
};

export const getWholeFarmReport = (params) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.wholeFarm.report.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
      return dispatch(getWholeFarmReportSuccess(response?.data));
    } else
      return dispatch(getWholeFarmReportFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (error) {
    return dispatch(getWholeFarmReportFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getWholeFarmReportSuccess = (response) => {
  return {
    type: actionTypes.GET_WHOLE_FARM_REPORT_DATA_SUCCESS,
    payload: response
  };
};

const getWholeFarmReportFail = () => {
  return {
    type: actionTypes.GET_WHOLE_FARM_REPORT_DATA_FAIL,
  };
};

export const getFarmerFriendlyReport = (params) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.wholeFarm.farmerFriendlyReport.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
      return dispatch(getFarmerFriendlyReportSuccess(response?.data));
    } else
      return dispatch(getFarmerFriendlyReportFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (error) {
    return dispatch(getFarmerFriendlyReportFail(error?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
}

const getFarmerFriendlyReportSuccess = (response) => {
  return {
    type: actionTypes.GET_WHOLE_FARM_REPORT_DATA_SUCCESS,
    payload: response
  };
};

const getFarmerFriendlyReportFail = () => {
  return {
    type: actionTypes.GET_WHOLE_FARM_REPORT_DATA_FAIL,
  };
};



