import { Link, Paper, Typography } from '@mui/material';
import React from 'react'

const Footer = ({ fixed }) => {
  return (
    <Paper sx={{ position: fixed ? 'fixed' : 'inherit', bottom: 0, left: 0, right: 0, zIndex: 10, borderRadius: 0 }} elevation={4}>
      <Typography variant="body2" color="text.secondary" align="center" mt={2}>
        {'Copyright © 2014-'}
        {new Date().getFullYear()}
        {' '}
        <Link color="inherit" underline="hover" href="https://app.agrecalc.com/">
          Agrecalc
        </Link>
        {' | '}
        {'All rights reserved.'}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" mb={2}>
        <Link color="inherit" underline="hover" target="_blank" href="https://www.agrecalc.com/terms/">
          Terms & Conditions
        </Link>
      </Typography>
    </Paper>
  );
}

export default Footer
