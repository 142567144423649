import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getCropUseMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.cropUse.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getCropUseMetadataSuccess(response?.data));
  } else
    return dispatch(getCropUseMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getCropUseMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_CROP_USE_METADATA_SUCCESS,
    payload: response
  };
};

const getCropUseMetadataFail = () => {
  return {
    type: actionTypes.GET_CROP_USE_METADATA_FAIL,
  };
};

export const resetCropUseMetaData = () => {
  return {
    type: actionTypes.RESET_CROP_USE_METADATA,
  };
};

export const getCropUseFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.landCrops.cropUse.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getCropUseFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getCropUseFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getCropUseFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_CROP_USE_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getCropUseFormDataFail = () => {
  return {
    type: actionTypes.GET_CROP_USE_FORM_DATA_FAIL,
  };
};

export const saveCropUseData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().cropUse
  const response = await api.post(endpoints.landCrops.cropUse.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveCropUseDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveCropUseDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveCropUseDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveCropUseDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setCropUseFormData = (response) => {
  return {
    type: actionTypes.SET_CROP_USE_FORM_DATA,
    payload: response
  };
};

export const resetCropUseFormData = () => {
  return {
    type: actionTypes.RESET_CROP_USE_ALL_DATA,
  };
};