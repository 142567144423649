import {
  Button, FormControl, Grid, List,
  ListSubheader, MenuItem, Paper, Skeleton, Stack, Table,
  TableBody, TableContainer, TableHead,
  TableRow, Typography
} from '@mui/material';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import toastr from 'toastr';
import {
  FormHeaderPaper, FormTitleTypography, FormTableCell,
  FormTableRow, SectorTitleTypography, TableListItem, DataEntryTextField, DataEntrySelect, FormDescriptionTypography, CustomizedDivider
} from '../../../components/CustomStyles/StyledComponents';
import _ from 'lodash';
import { isEqual } from "lodash";
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import produce from 'immer';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  getCropTypeOptions,
  getGrazingSystemOptions,
  getSoilCarbonFormData,
  getSoilCarbonMetaData,
  resetSoilCarbonMetaData,
  getTillagePracticeOptionsOptions,
  resetResponseState,
  saveSoilCarbonData,
  setLoading,
  setSoilCarbonFormData,
  unsetLoading
} from '../../../store/appAction';
import CancelModal from '../../../components/Modal/CancelModal';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import BackButton from '../../../components/Buttons/BackButton';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "12%",
  TillageType: '16%',
  GrazingSystem: '18%',
  FractionOfRotationUnderFallow: '8%',
  FractionOfRotationUnderPerennials: '8%',
  FallowCoverCropType: '12%',
  CoverOrCompanionCropType: '12%',
  SoilCStock: '8%'
}

const LAND_AREA_TOTAL_KEY = 'TotalLandArea';
const TILLAGE_PRACTICE = 'TillageType';
const GRAZING_SYSTEM = 'GrazingSystem';
const FALLOW_COVER_CROP_TYPE = 'FallowCoverCropType';
const COVER_COMPANION_CROP_TYPE = 'CoverOrCompanionCropType';

const DataEntryTable = ({ sector, initialFormData, fallowCoverCropTypeOptions,
  grazingSystemOptions, tillagePracticeOptions, firstVisibleCellDetails, locked }) => {

  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setSoilCarbonFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleInputChange = useCallback((e, groupId, rowId, cell, totalArea) => {
    let value = e.target.value;

    if (e.target.inputMode === 'numeric') {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        const rule = cell.validations;
        const max = rule.max || parseFloat(rule.maxValidation * (totalArea || 0));
        if (max === 0) {
          toastr.warning(`Please enter Land Area information and try again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else if (rule.min > numericValue || max < numericValue) {
          toastr.warning(`This input only accepts values between ${addCommas(rule.min)} and ${addCommas(max)}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = value;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = parseFloat(value || 0);
          }));
        }
      }
    }
    else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value || null;
      }));
    }
  }, []);

  const getCellContent = (groupId, rowId, cell, rowData, totalArea, firstVisibleCellDetails, locked) => {
    switch (cell.dataType) {
      case 'Select':
        return (
          <FormControl sx={{ width: '98%', margin: 0 }}>
            <DataEntrySelect
              value={rowData && rowData[cell.mappingProperty] !== null ?
                rowData[cell.mappingProperty] : ''}
              displayEmpty
              onChange={(e) => handleInputChange(e, groupId, rowId, cell, totalArea)}
              autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
            >
              <MenuItem value=""> <em>Please Select</em> </MenuItem>
              {cell.mappingProperty === TILLAGE_PRACTICE && tillagePracticeOptions &&
                tillagePracticeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              {cell.mappingProperty === GRAZING_SYSTEM && grazingSystemOptions &&
                grazingSystemOptions.map((option) => (
                  <MenuItem style={{ whiteSpace: 'normal', width: 280 }}
                    key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              {(cell.mappingProperty === FALLOW_COVER_CROP_TYPE) &&
                fallowCoverCropTypeOptions && fallowCoverCropTypeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              {/*SHOULD FIX BELOW */}
              {(cell.mappingProperty === COVER_COMPANION_CROP_TYPE) &&
                fallowCoverCropTypeOptions && fallowCoverCropTypeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
            </DataEntrySelect>
          </FormControl>
        );
      case 'TextField':
        return (
          <DataEntryTextField
            key={rowId + cell.id}
            size="small"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            style={{ width: 75, textAlign: 'center' }}
            onChange={(e) => handleInputChange(e, groupId, rowId, cell, rowData)}
            value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
          />
        );
      case 'Empty':
      default:
        return (<></>)
    }
  }

  const fetchRowTitle = (title, totalArea) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    return title.concat(
      ' (', addCommas(totalArea || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }

  return (
    <>
      {sector ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
              <TableBody>
                {(sector.sectorGroups.groups || []).map((group) => (
                  <Fragment>
                    {group.rows.map((row) => (
                      <FormTableRow>
                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}>
                          {sectorDataInt &&
                            fetchRowTitle(row.title, row.totalArea)}
                        </FormTableCell>
                        {row.cells.map((cell) => (
                          <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
                            {sectorDataInt && cell && cell.visible &&
                              getCellContent(group.id, row.id, cell,
                                sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                                row.totalArea,
                                firstVisibleCellDetails,
                                locked
                              )}
                          </FormTableCell>
                        ))}
                      </FormTableRow>
                    ))}

                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />
      }
    </>
  );
}

const SoilCarbon = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.soilCarbon.metaData);
  const initialFormData = useSelector(state => state.soilCarbon.initialFormData);
  const fallowCoverCropTypeOptions = useSelector(state => state.soilCarbon.fallowCoverCropTypeOptions);
  const grazingSystemOptions = useSelector(state => state.soilCarbon.grazingSystemOptions);
  const tillagePracticeOptions = useSelector(state => state.soilCarbon.tillagePracticeOptions);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const
    { reportConfig: { landAndCropsOnly }
    } = useSelector(state => state.farmReport);
  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];
  const columns = metaData?.form?.columns || [];

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getCropTypeOptions());
    dispatch(getGrazingSystemOptions());
    dispatch(getTillagePracticeOptionsOptions());
    dispatch(getSoilCarbonMetaData({ reportId }));
    dispatch(getSoilCarbonFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetSoilCarbonMetaData());
    }
  }, []);

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      dispatch(unsetLoading());
      setFormData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        if (landAndCropsOnly)
          navigate('/data-entry/energy-waste/electricity-fuel');
        else
          navigate('/data-entry/livestock/numbers-weights');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getSoilCarbonMetaData({ reportId }));
      dispatch(getSoilCarbonFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const soilCarbonData = store.getState().soilCarbon;
    const isUpdated = !isEqual(soilCarbonData?.initialFormData, soilCarbonData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveSoilCarbonData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      if (landAndCropsOnly)
        navigate('/data-entry/energy-waste/electricity-fuel');
      else
        navigate('/data-entry/livestock/numbers-weights');
    } else {
      dispatch(setLoading());
      dispatch(saveSoilCarbonData({ reportId }));
    };
  }

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <FormTitleTypography variant="h5" component="div">
              Soil Carbon Sequestration
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              To estimate soil carbon sequestration, a few further details about soil and land management are required.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                  <>
                  <DataEntryButtonStack
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleModalResponse={handleModalResponse}
                    handelSaveClick={handelSaveClick}
                    handleNextClick={handleNextClick}
                  />
                  <CustomizedDivider/>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}></FormTableCell>
                          {columns.map((column) =>
                            <FormTableCell align="center" sx={{ width: CELL_WIDTH[column.cssClass] }}>
                              {column.title}
                            </FormTableCell>
                          )}
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  </>
                </ScrollSyncPane>
              </ListSubheader>
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <DataEntryTable
                      key={sector.id}
                      sectorId={sector.id}
                      sector={sector}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      fallowCoverCropTypeOptions={fallowCoverCropTypeOptions}
                      grazingSystemOptions={grazingSystemOptions}
                      tillagePracticeOptions={tillagePracticeOptions}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}
                    />
                  </TableListItem>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
}

export default SoilCarbon;
