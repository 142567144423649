import axios from 'axios'
import { authHeader, deleteSession, authHeaderForMultipartFormData } from '../helpers/authHelper';
import { BASE_URL, BASE_AUTH_URL } from '../config';
import store from '../store'
import qs from 'qs';
import { signOut, unsetLoading } from '../store/appAction';

export const API_PREFIX = 'api';
const CONTENT_TYPE_URL_ENCODED = "application/x-www-form-urlencoded";

const axiosApi = axios.create({
  baseURL: `${BASE_URL}/${API_PREFIX}`,
})

const axiosAuthApi = axios.create({
  baseURL: `${BASE_AUTH_URL}`
})

export async function postAuth(url, data) {
  return axiosAuthApi
    .post(url, qs.stringify(data), {
      headers: {
        "Content-Type": CONTENT_TYPE_URL_ENCODED,
        'Access-Control-Allow-Origin': "*"
      }
    })
    .then(response => response)
    .catch(error => error.response);
}

const whitelistedUrls = [
]

axiosApi.interceptors.request.use(async (config) => {
  return config
}, (error) => {
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return handleError(error)
  }
)

export const axiosInstance = axiosApi;

export async function get(url, config = {}) {
  return await axiosApi.get(url, { params: config, headers: authHeader() }).then(response => response)
}

export async function getFileAsBlob(url, param = {}) {
  return await axiosApi.get(url, {
    responseType: 'blob',
    headers: authHeader(),
    params:param
  })
  .then(response => response)
  .catch(error=> error.response);
}

export async function patch(url, data, config = {}) {
  return await axiosApi.patch(url, { ...data }, { ...config, headers: authHeader() })
    .then(response => response)
    .catch(error => error.response);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config, headers: authHeader() })
    .then(response => response)
    .catch(error => error.response);
}

export async function postMultipartFormData(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config, headers: authHeaderForMultipartFormData() })
    .then(response => response)
    .catch(error => error.response);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config, headers: authHeader() })
    .then(response => response)
}

export async function putMultipartFormData(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config, headers: authHeaderForMultipartFormData() })
    .then(response => response)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config, headers: authHeader() })
    .then(response => response)
}

const handleError = (error) => {
  if (error.response) {
    store.dispatch(unsetLoading())
    switch (error.response.status) {
      case 401:
        store.dispatch(signOut());
        window.location.href = '/'
        return Promise.reject(error)
      case 500:
      default:
        return Promise.reject(error)
    }
  } else {

    return Promise.reject(error)
  }
}
export function isSuccessResponse(status) {
  //validate success responses
  if (status >= 200 && status <= 299) {
    return true;
  }
  return false;
}

function isWhitelisted(url) {
  return whitelistedUrls.findIndex(whiteListedurl =>
    url.includes(whiteListedurl)) > -1;
}
