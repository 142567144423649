import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from "react-router-dom";
import { Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, styled, } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import toastr from "toastr";

import agreCalcLogo from '../../assets/images/agrecalc-logo-green.svg'
import { StyledListItemButton } from '../../components/CustomStyles/StyledComponents';
import { useSelector } from 'react-redux';
import { ADMIN, FARMER,CONSULTANT } from '../../constants/userRoles';
import { toastrCustomOptionsNoFarmSelectPopUp } from "../../constants/toastrOptions";

const SideBar = ({ selectedNavItem,
  drawerOpen,
  leftNavMenuList,
  setSelectedNavItem,
  toggleDrawer,
}) => {
  console.log("Debug log AGC-2148",leftNavMenuList);
  const drawerWidth = 240;
  let navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const farmId = useSelector(state => ([FARMER].includes(user.role) ?
    state.common.farmId : state.adminFarm.farmId));
  const selectedReportId = useSelector(state => state.common.reportId);  
  const selectedFarmId = useSelector(state => ([FARMER].includes(user.role) ?
    state.common.farmId : state.adminFarm.farmId));

  const handleLeftNavItemClick = (key, menu) => {
    let hasWarning = false;
    if(menu.title === 'Farm Reports'){
      if((user.role === CONSULTANT || user.role === ADMIN) && (!selectedFarmId)){
        toastr.warning("You must select a farm before accessing Farm Report", undefined, toastrCustomOptionsNoFarmSelectPopUp) 
        hasWarning = true;
      }
    }

    if(menu.title === 'Report Data Entry'){
      if(user.role === FARMER && (farmId && !selectedReportId)){
        toastr.warning("You must select or create a farm report before accessing Report Data Entry.", undefined, toastrCustomOptionsNoFarmSelectPopUp);
        hasWarning = true;
      }
      if((user.role === CONSULTANT || user.role === ADMIN) && (selectedFarmId && !selectedReportId )){
        toastr.warning("You must select or create a farm report before accessing Report Data Entry.", undefined, toastrCustomOptionsNoFarmSelectPopUp) 
        hasWarning = true;
      }
      if((user.role === CONSULTANT || user.role === ADMIN) && (!selectedFarmId)){
        toastr.warning("You must select a farm and then a farm report before accessing Report Data Entry.", undefined, toastrCustomOptionsNoFarmSelectPopUp)
        hasWarning = true;
      }
    }

    if(menu.title === 'Report Results'){
      if(user.role === FARMER && (!farmId )){
        toastr.warning("You must select or create a farm report before accessing Report Results.", undefined, toastrCustomOptionsNoFarmSelectPopUp)
        hasWarning = true;
      }
      if(user.role === FARMER && (farmId && !selectedReportId)){
        toastr.warning("You must select or create a farm report before accessing Report Results.", undefined, toastrCustomOptionsNoFarmSelectPopUp)
        hasWarning = true;
      }
      if((user.role === CONSULTANT || user.role === ADMIN) && (selectedFarmId && !selectedReportId )){
        toastr.warning("You must select or create a farm report before accessing Report Results.", undefined, toastrCustomOptionsNoFarmSelectPopUp) 
        hasWarning = true;
      }
      if((user.role === CONSULTANT || user.role === ADMIN) && (!selectedFarmId)){
        toastr.warning("You must select a farm and then a farm report before accessing Report Data Entry.", undefined, toastrCustomOptionsNoFarmSelectPopUp)
        hasWarning = true;
      }
    }

    if(menu.title === 'Year on Year'){
      if(user.role === FARMER && (!farmId )){
        toastr.warning("You must select or create a farm report before accessing Year on Year Results.", undefined, toastrCustomOptionsNoFarmSelectPopUp)
        hasWarning = true;
      }
      if(user.role === FARMER && (farmId && !selectedReportId)){
        toastr.warning("You must select or create a farm report before accessing Year on Year Results.", undefined, toastrCustomOptionsNoFarmSelectPopUp)
        hasWarning = true;
      }
      if((user.role === CONSULTANT || user.role === ADMIN) && (selectedFarmId && !selectedReportId )){
        toastr.warning("You must select or create a farm report before accessing Year on Year Results.", undefined, toastrCustomOptionsNoFarmSelectPopUp) 
        hasWarning = true;
      }
      if((user.role === CONSULTANT || user.role === ADMIN) && (!selectedFarmId)){
        toastr.warning("You must select a farm and then a farm report before accessing Year on Year Results.", undefined, toastrCustomOptionsNoFarmSelectPopUp)
        hasWarning = true;
      }
    }

    if(hasWarning){
      return;
    }
    setSelectedNavItem(key);
    navigate(menu.path);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  return (
    <React.Fragment>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <DrawerHeader>
          <span className='logo-lg' style={{ margin: '0 auto' }}>
            <img src={agreCalcLogo} alt='' height='45' />
          </span>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {Object.entries(leftNavMenuList).map(([key, menu]) => (
            ((menu.roles.length === 0 || menu.roles.includes(user.role))) &&
            <ListItem
              key={key}
              disablePadding
            >
              <StyledListItemButton
                id={key}
                selected={selectedNavItem === key}
                onClick={() => handleLeftNavItemClick(key, menu)}>
                <ListItemText
                  secondary={menu.title} />
              </StyledListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider />
                <List>
                    <Collapse in={navItemState[3]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton selected={selectedNavItem === 4} id={4} onClick={() => handleListItemClick(4)} sx={{ pl: 4 }}>
                                <ListItemText primary="Starred" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List> */}
      </Drawer>
    </React.Fragment>
  )
}

SideBar.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

export default SideBar
