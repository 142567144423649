import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import toastr from "toastr";
import CancelModal from '../../components/Modal/CancelModal';
import DeleteModal from '../../components/Modal/DeleteModal';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { ADMIN, SUPPER_ADMIN } from '../../constants/userRoles';
import { deleteAdminAccount, getOfficesData, resetResponseState, unsetLoading } from '../../store/appAction';
import { EDIT, SELF_EDIT } from '../../constants/modes';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';

const AccountForm = props => {
    const {
        values: {
            type,
            email,
            firstName,
            lastName,
            organisation: organisationName,
            organisationId,
            office: officeName,
            officeId,
            active,
            newsletter,
            agreement,
        },
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange,
        handleReset,
        resetForm,
        role,
        mode,
    } = props;
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [organisations, setOrganisations] = useState([]);
    const [offices, setOffices] = useState([]);

    const [organisation, setOrganisation] = useState(null);
    const [office, setOffice] = useState(null);

    const currentOrganisations = useSelector(state => state.common.organisations);
    const currentOffices = useSelector(state => state.common.offices);

    const userRoles = useSelector(state => state.common.userRoles);
    const { successMsg, errorMsg } = useSelector(state => state.common);

    const filterOptionsForOrganization = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'organizationName']});
    const filterOptionsForOffice = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'officeName']});

    useEffect(() => {
        return () => {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                if (mode !== EDIT) {
                    handleReset();
                    setOrganisation(null);
                    setOffice(null);
                }
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        setOrganisations(currentOrganisations);
        (mode === EDIT) && (currentOrganisations?.length > 0) &&
            organisationId && setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganisations]);

    useEffect(() => {
        setOffices(currentOffices);
        (mode === EDIT) && (currentOffices?.length > 0) &&
            officeId && setOffice(_.find(currentOffices, { id: officeId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOffices]);

    useEffect(() => {
        setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        if (organisationId)
            dispatch(getOfficesData({ organisationId }))
        else {
            setFieldValue("officeId", "");
            setOffices([]);
            setOffice(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId]);

    useEffect(() => {
        setOffice(_.find(currentOffices, { id: officeId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeId]);

    const handleCancelModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            handleReset();
            if (mode !== EDIT) {
                setOrganisation(null);
                setOffice(null);
            }
        }
    }

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            handleReset();
            setOrganisation(null);
            setOffice(null);
            dispatch(deleteAdminAccount());
            handelBackResponse();
        }
    }

    const handelBackResponse = () => {
        navigate('/admin/account/list');
    }

    const fetchOptions = (data, nameKey) => {
        if (mode === SELF_EDIT) {
            switch (nameKey) {
                case 'organizationName':
                    return [
                        <MenuItem
                            id={organisationId}
                            key={organisationId}
                            value={organisationId}
                        >
                            {organisationName}
                        </MenuItem>
                    ];
                case 'officeName':
                    return [
                        <MenuItem
                            id={officeId}
                            key={officeId}
                            value={officeId}
                        >
                            {officeName}
                        </MenuItem>
                    ];
                default:
                    return [];
            }
        }
        return (
            Object.entries(data || []).map(([optionId, optionData]) => (
                <MenuItem
                    id={optionData.id}
                    key={optionData.id}
                    value={optionData.id}
                >
                    {optionData[nameKey]}
                </MenuItem>
            )));
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 1, ml: 20, pr: 30 }}>
                <Grid
                    spacing={1}
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    {[ADMIN, SUPPER_ADMIN].includes(role) &&
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={Boolean(errors.type)}>
                                <InputLabel id="type-select">Type</InputLabel>
                                <Select
                                    disabled={mode === SELF_EDIT}
                                    autoComplete="type"
                                    fullWidth
                                    name="type"
                                    label="Type"
                                    id="type"
                                    labelId="type-select"
                                    onChange={handleChange}
                                    value={type}
                                    helperText={touched.type ? errors.type : ""}
                                >
                                    {userRoles?.map((userRole) => (
                                        <MenuItem
                                            id={userRole.name}
                                            key={userRole.name}
                                            value={userRole.name}
                                        >
                                            {userRole?.extraProperties?.DisplayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            label="Email Address"
                            helperText={touched.email ? errors.email : ""}
                            error={Boolean(touched.email ? errors.email : false)}
                            value={email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="firstName"
                            name="firstName"
                            autoComplete="given-name"
                            label="First Name"
                            helperText={touched.firstName ? errors.firstName : ""}
                            error={Boolean(touched.firstName ? errors.firstName : false)}
                            value={firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="lastName"
                            name="lastName"
                            autoComplete="given-name"
                            label="Last Name"
                            helperText={touched.lastName ? errors.lastName : ""}
                            error={Boolean(touched.lastName ? errors.lastName : false)}
                            value={lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {mode === SELF_EDIT ?
                            <FormControl fullWidth error={Boolean(errors.organisationId)}>
                                <InputLabel id="organisationId-select">Organisation</InputLabel>
                                <Select
                                    disabled={mode === SELF_EDIT}
                                    fullWidth
                                    name="organisationId"
                                    label="Organisation"
                                    id="organisationId"
                                    labelId="organisationId-select"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    value={organisationId}
                                    helperText={touched.organisationId ? errors.organisationId : ""}
                                >
                                    {fetchOptions(currentOrganisations, 'organizationName')}
                                </Select>
                            </FormControl> :
                            <Autocomplete
                                id="organisation-autocomplete"
                                disablePortal
                                value={organisation}
                                options={organisations}
                                getOptionLabel={(org) => `${org?.organizationName}`}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                filterOptions={filterOptionsForOrganization}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.organizationName}
                                        </li>
                                    );
                                }}
                                onChange={(e, value) => {
                                    setFieldValue("organisationId", value?.id || "");
                                    setFieldValue("officeId", "");
                                    setOffices([]);
                                    setOffice(null);
                                }}
                                onOpen={handleBlur}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        id="organisationId"
                                        name="organisationId"
                                        label="Organisation"
                                        error={Boolean(errors.organisationId)}
                                        value={organisationId}
                                        helperText={touched.organisationId ? errors.organisationId : ""}
                                    />
                                )}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {mode === SELF_EDIT ?
                            <FormControl fullWidth error={Boolean(errors.officeId)}>
                                <InputLabel id="officeId-select">Office</InputLabel>
                                <Select
                                    disabled={mode === SELF_EDIT}
                                    fullWidth
                                    name="officeId"
                                    label="Office"
                                    id="officeId"
                                    labelId="officeId-select"
                                    onChange={handleChange}
                                    value={officeId}
                                    helperText={touched.officeId ? errors.officeId : ""}
                                >
                                    {fetchOptions(offices, 'officeName', 'office')}
                                </Select>
                            </FormControl> :
                            <Autocomplete
                                id="office-autocomplete"
                                disablePortal
                                value={office}
                                options={offices}
                                getOptionLabel={(office) => `${office?.officeName}`}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                filterOptions={filterOptionsForOffice}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.officeName}
                                        </li>
                                    );
                                }}
                                onChange={(e, value) => {
                                    setFieldValue("officeId", value?.id || "");
                                }}
                                onOpen={handleBlur}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        id="officeId"
                                        name="officeId"
                                        label="Office"
                                        error={Boolean(errors.officeId)}
                                        value={officeId}
                                        helperText={touched.officeId ? errors.officeId : ""}
                                    />
                                )}
                            />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="active"
                                    color="secondary"
                                    helperText={touched.active ? errors.active : ""}
                                    error={Boolean(errors.active)}
                                    checked={active}
                                    onChange={handleChange}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="newsletter"
                                    color="secondary"
                                    helperText={touched.newsletter ? errors.newsletter : ""}
                                    error={Boolean(errors.newsletter)}
                                    checked={newsletter}
                                    onChange={handleChange}
                                />
                            }
                            label="I would like to receive the Agrecalc Newsletter"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="agreement"
                                    color="secondary"
                                    helperText={touched.agreement ? errors.agreement : ""}
                                    error={Boolean(errors.agreement)}
                                    checked={agreement}
                                    onChange={handleChange}
                                />
                            }
                            label="Do you agree to be contacted by SRUC about participation in relevant SRUC research"
                        />
                    </Grid>
                    <Stack spacing={2} sx={{ marginLeft: 'auto', marginTop: 3 }} direction="row" >
                        <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                            onClick={handelBackResponse}>
                            Back
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalOpen(true);
                            }}>
                            Cancel
                        </Button>
                        {mode === EDIT && <Button variant="outlined" sx={{ textTransform: 'none' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setDeleteModalOpen(true);
                            }}>
                            Delete
                        </Button>}
                        <Button type="submit" variant="contained" sx={{ textTransform: 'none' }}>
                            Save
                        </Button>
                    </Stack>
                </Grid>
                <CancelModal isOpen={modalOpen} handleResponse={handleCancelModalResponse} />
                <DeleteModal
                    isOpen={deleteModalOpen}
                    handleResponse={handleDeleteModalResponse}
                    value='Are you sure you want to delete this User?' />
            </Box>
        </>
    )
}

export default AccountForm