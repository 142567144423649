import React, { useEffect, useState } from 'react';
import { DataEntrySelect } from '../CustomStyles/StyledComponents';
import { FormControl, MenuItem } from '@mui/material';

const TableSearchSelect = ({ children, searchKey, handleSearchQuery = () => { } }) => {
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        handleSearchQuery(searchKey, searchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    return (
        <FormControl
            variant="outlined"
            sx={{ width: '100%', margin: 0 }}
        >
            <DataEntrySelect
                displayEmpty
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            >
                <MenuItem value=""><em>-None-</em></MenuItem>
                {children}
            </DataEntrySelect>
        </FormControl>
    );
};

export default TableSearchSelect;

