import { Backdrop, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import toastr from "toastr";
import _ from 'lodash';
import { FormHeaderPaper, FormTitleTypography } from '../../../components/CustomStyles/StyledComponents';
import { getAdminAccountData, getOfficesData, getUserRoles, getOrganisationData, setSelectedUserId, resetResponseState, updateAdminAccount, setLoading, unsetLoading, createAdminAccount } from '../../../store/appAction';
import { useParams } from 'react-router-dom';
import AccountForm from '../../../components/Forms/AccountForm';
import { ThemeProvider } from '@emotion/react';
import { mainTheme } from '../../../theme';
import { toastrCustomOptions } from '../../../constants/toastrOptions';
import { EDIT } from '../../../constants/modes';

const AdminAccount = () => {
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    let { mode } = useParams();

    const user = useSelector(state => state.auth.user);
    const accountDetails = useSelector(state => state.adminAccount.accountDetails);
    const accountId = useSelector(state => state.adminAccount.selectedUserId);
    const { loading } = useSelector(state => state.common);

    const [intAccountDetails, setIntAccountDetails] = useState(undefined);
    const [formData, setFormData] = useState(undefined);

    const validationSchema = Yup.object({
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        firstName: Yup.string("Enter first name").required("First name is required"),
        lastName: Yup.string("Enter last name").required("Last name is required"),
        // office: Yup.string("").required("Office is required"),
        // organisation: Yup.string("").required("Organisation is required"),
    });

    useEffect(() => {
        if (mode === EDIT && accountId != null) {
            dispatch(setLoading());
            dispatch(getAdminAccountData(accountId));
        }
        else {
            dispatch(setSelectedUserId(null));
            setFormData({
                id: '',
                type: '',
                email: '',
                firstName: '',
                lastName: '',
                organisationId: '',
                officeId: '',
                license: '',
                active: false,
                newsletter: false,
                agreement: false,

            });
        }
        dispatch(getOrganisationData());
        dispatch(getUserRoles());
        return () => {
            setIntAccountDetails(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (intAccountDetails && !_.isEmpty(intAccountDetails)) {
            setFormData({
                id: intAccountDetails?.id,
                type: intAccountDetails?.roleNames?.[0] || '',
                email: intAccountDetails?.email || '',
                firstName: intAccountDetails?.firstName || '',
                lastName: intAccountDetails?.lastName || '',
                organisationId: intAccountDetails?.organisationId || '',
                officeId: intAccountDetails?.officeId || '',
                license: intAccountDetails?.licenseId || '',
                active: intAccountDetails?.isActive || false,
                newsletter: intAccountDetails?.optInNewsletter || false,
                agreement: intAccountDetails?.agreedToTandS || false,
            });
            dispatch(unsetLoading());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intAccountDetails]);

    useEffect(() => {
        setIntAccountDetails(accountDetails);
    }, [accountDetails])

    const handleSubmit = data => {
        dispatch(setLoading());
        if (mode === EDIT && accountId != null) {
            dispatch(updateAdminAccount(data));
        } else {
            dispatch(createAdminAccount(data));
        }
    };

    return (
        <>
            <ThemeProvider theme={mainTheme}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={9}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <FormTitleTypography variant="h5" component="div">
                                {(mode === EDIT && accountId) ? 'Edit' : 'Create'} User
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                {' Create or modify your account details.'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item md={12} >
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {formData &&
                                <Formik
                                    enableReinitialize
                                    render={props => <AccountForm {...props} role={user?.role} mode={mode} />}
                                    initialValues={formData}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                />}
                        </Paper>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}

export default AdminAccount