import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';
import { replaceEmptyStringWithNull } from '../../../helpers/stringFormatHelper';

export const getManureManagementMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.manureManagement.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getManureManagementMetadataSuccess(response?.data));
  }
  else
    return dispatch(getManureManagementMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getManureManagementMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_MANURE_MANAGEMENT_METADATA_SUCCESS,
    payload: response
  };
};

const getManureManagementMetadataFail = () => {
  return {
    type: actionTypes.GET_MANURE_MANAGEMENT_METADATA_FAIL,
  };
};

export const resetManureManagementMetadata = () => {
  return {
    type: actionTypes.RESET_MANURE_MANAGEMENT_METADATA,
  };
};

export const getManureManagementFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.manureManagement.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getManureManagementFormDataSuccess(response?.data?.data));
  }
  else
    return dispatch(getManureManagementFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getManureManagementFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_MANURE_MANAGEMENT_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getManureManagementFormDataFail = () => {
  return {
    type: actionTypes.GET_MANURE_MANAGEMENT_FORM_DATA_FAIL,
  };
};

export const setManureManagementFormData = (response) => {
  return {
    type: actionTypes.SET_MANURE_MANAGEMENT_FORM_DATA,
    payload: response
  };
};

export const resetManureManagementFormData = () => {
  return {
    type: actionTypes.RESET_MANURE_MANAGEMENT_ALL_DATA,
  };
};

export const saveManureManagementData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().manureManagement;
  const formData = replaceEmptyStringWithNull(currentFormData);
  const response = await api.post(
    endpoints.livestock.manureManagement.saveData,
    {
      reportId: reportId,
      data: formData,
    });
  if (response && response.status === 200) {
    return dispatch(saveManureManagementDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveManureManagementDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveManureManagementDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveManureManagementDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};