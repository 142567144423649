import { Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { FormHeaderPaper } from '../CustomStyles/StyledComponents';
import { useNavigate } from 'react-router';

const DataChecksErrors = ({ errorList }) => {
    let navigate = useNavigate();

    const handelBackToReport = () => {
        navigate('/data-entry/report/edit')
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <>
                        {errorList.map((validations) => (
                            <>
                                <Typography variant="body2" paragraph fontWeight="bold" color="error" mb={1}>
                                    {validations.errorMessage}
                                </Typography>
                                {/* <Typography variant="body2" paragraph>
                                    {validations.memberNames.join(', ')}
                                </Typography> */}
                            </>
                        ))}
                    </>
                </Paper>
            </Grid>
            <Grid item xs={12} p={2}>
                <Button variant="contained"
                    sx={{ textTransform: 'none', ml: 2 }}
                    onClick={handelBackToReport}>
                    Back to Report
                </Button>
            </Grid>
        </Grid >
    );
}

export default DataChecksErrors;
