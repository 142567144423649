import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataEntryTextField } from '../CustomStyles/StyledComponents';

const CopyReportModal = ({ isOpen = false, handleResponse, onTitleChange }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(isOpen);
  const handleClose = () => {
    // setOpen(false);
    handleResponse(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={2} sx={{ marginLeft: 'auto', marginBottom: '15px' }} direction="column">
          <Typography id="modal-modal-title" variant="body1" component="h2" sx={{ marginBottom: 0 }}>
            Please enter the name for the copy of this report:
          </Typography>
          <DataEntryTextField
            name="averageAnnualTemperature"
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width: '100%', textAlign: "center" }}
            //value={''}
            onChange={(e) => onTitleChange(e.target.value)}
          />
        </Stack>
        <Stack spacing={2} sx={{ marginLeft: 'auto' }} direction="row">
          <Button
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={() => handleResponse(true)}
          >
            Ok
          </Button>
          <Button
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={() => handleResponse(false)}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default CopyReportModal;
