import * as actionTypes from './types';
import { updateObject } from '../utility';

const initialState = {
  account: {},
  farm: {},
  farmOwners: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MY_FARM_DATA_SUCCESS: return setMyFarmDataSuccess(state, action);
    case actionTypes.GET_MY_ACCOUNT_DATA_SUCCESS: return setMyAccountDataSuccess(state, action);
    case actionTypes.RESET_MY_ACCOUNT_DATA: return resetMyAccountData(state, action);
    default:
      return state;
  }
};

const setMyFarmDataSuccess = (state, action) => {
  const updatedState = {
    farm: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMyAccountDataSuccess = (state, action) => {
  const updatedState = {
    account: action.payload,
  }
  return updateObject(state, updatedState);
}

const resetMyAccountData = (state, action) => {
  const updatedState = {
    account: {},
  }
  return updateObject(state, updatedState);
}

export default profileReducer;
