export const SET_ADMIN_FARM_ID = 'SET_ADMIN_FARM_ID'

export const GET_ALL_FARMS_SUCCESS = 'GET_ALL_FARMS_SUCCESS'
export const GET_ALL_FARMS_FAIL = 'GET_ALL_FARMS_FAIL'

export const GET_ADMIN_FARM_DATA_SUCCESS = 'GET_ADMIN_FARM_DATA_SUCCESS'
export const GET_ADMIN_FARM_DATA_FAIL = 'GET_ADMIN_FARM_DATA_FAIL'

export const GET_ADMIN_FARM_OWNER_DATA_SUCCESS = 'GET_ADMIN_FARM_OWNER_DATA_SUCCESS'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'


