import queryString from 'query-string';
import _ from 'lodash';
import * as actionTypes from './types';
import * as api from '../../api/base';
import { endpoints } from '../../api/endpoints';
import store from '..';
import { setReportId } from '../appAction';

export const getAllSectors = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.farmReport.lookup.sectors);
    if (response && response.status === 200) {
      return dispatch(getAllSectorsSuccess(response?.data));
    }
    else
      return dispatch(getAllSectorsFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getAllSectorsFail(e.message));
  }
};
const getAllSectorsSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_SECTORS_SUCCESS,
    payload: response
  };
};
const getAllSectorsFail = (response) => {
  return {
    type: actionTypes.GET_ALL_SECTORS_FAIL,
    payload: response
  };
};

export const getProjects = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.farmReport.projects);
    if (response && response.status === 200) {
      return dispatch(getProjectsSuccess(response?.data));
    }
    else
      return dispatch(getProjectsFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getProjectsFail(e.message));
  }
};
const getProjectsSuccess = (response) => {
  return {
    type: actionTypes.GET_PROJECTS_SUCCESS,
    payload: response
  };
};
const getProjectsFail = (response) => {
  return {
    type: actionTypes.GET_PROJECTS_FAIL,
    payload: response
  };
};

export const getTypesBySector = ({ sectorId }) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.farmReport.lookup.types.concat('?sectorId=', sectorId));
    if (response && response.status === 200) {
      return dispatch(getTypesBySectorSuccess(response?.data));
    }
    else
      return dispatch(getTypesBySectorFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
  catch (e) {
    return dispatch(getTypesBySectorFail(e.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};
const getTypesBySectorSuccess = (response) => {
  return {
    type: actionTypes.GET_TYPES_BY_SECTOR_SUCCESS,
    payload: response
  };
};
const getTypesBySectorFail = (response) => {
  return {
    type: actionTypes.GET_TYPES_BY_SECTOR_FAIL,
    payload: response
  };
};

export const getSystemsBySector = ({ sectorId }) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.farmReport.lookup.systems.concat('?sectorId=', sectorId));
    if (response && response.status === 200) {
      return dispatch(getSystemsBySectorSuccess(response?.data));
    }
    else
      return dispatch(getSystemsBySectorFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));

  } catch (e) {
    return dispatch(getSystemsBySectorFail(e));
  }
};
const getSystemsBySectorSuccess = (response) => {
  return {
    type: actionTypes.GET_SYSTEMS_BY_SECTOR_SUCCESS,
    payload: response
  };
};
const getSystemsBySectorFail = (response) => {
  return {
    type: actionTypes.GET_SYSTEMS_BY_SECTOR_FAIL,
    payload: response
  };
};

export const getSectorItemsBySector = ({ sectorId }) => async (dispatch) => {
  try {
    const response = await api.get(endpoints.farmReport.lookup.sectorItems.concat('?sectorId=', sectorId));
    if (response && response.status === 200) {
      return dispatch(getSectorItemsBySectorSuccess(response?.data));
    }
    else
      return dispatch(getSectorItemsBySectorFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));

  } catch (e) {
    return dispatch(getSectorItemsBySectorFail(e.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};

const getSectorItemsBySectorSuccess = (response) => {
  return {
    type: actionTypes.GET_SECTOR_ITEMS_BY_SECTOR_SUCCESS,
    payload: response
  };
};
const getSectorItemsBySectorFail = (response) => {
  return {
    type: actionTypes.GET_SECTOR_ITEMS_BY_SECTOR_FAIL,
    payload: response
  };
};

export const createFarmReportConfig = (response) => {
  return {
    type: actionTypes.CREATE_FARM_REPORT_CONFIG,
    payload: response
  };
};

export const createTempFarmReportSectors = (response) => {
  return {
    type: actionTypes.CREATE_TEMP_FARM_REPORT_SECTORS,
    payload: response
  };
};

export const addNewFarmReportSector = (response) => {
  return {
    type: actionTypes.ADD_NEW_FARM_REPORT_SECTOR,
    payload: response
  };
};

export const addRemoveFarmReportSector = (response) => {
  return {
    type: actionTypes.ADD_REMOVE_FARM_REPORT_SECTOR,
    payload: response
  };
};

export const addFarmReportGroup = (response) => {
  return {
    type: actionTypes.ADD_FARM_REPORT_GROUP,
    payload: response
  };
};

export const removeFarmReportGroup = (response) => {
  return {
    type: actionTypes.REMOVE_FARM_REPORT_GROUP,
    payload: response
  };
};

export const editFarmReportGroup = (response) => {
  return {
    type: actionTypes.EDIT_FARM_REPORT_GROUP,
    payload: response
  };
};

export const addFarmReportSectorItem = (response) => {
  return {
    type: actionTypes.ADD_FARM_REPORT_SECTOR_ITEM,
    payload: response
  };
};

export const removeFarmReportSectorItem = (response) => {
  return {
    type: actionTypes.REMOVE_FARM_REPORT_SECTOR_ITEM,
    payload: response
  };
};

export const getFarmReport = ({ reportId }) => async (dispatch) => {
  try {
    const detailsResponse = await api.get(endpoints.farmReport.details.concat('?reportId=', reportId));

    if (detailsResponse && detailsResponse.status === 200 && detailsResponse?.data) {
      let reportDetailsRefined = {
        ...detailsResponse.data,
        locked: [5, 6, 7, 8].includes(detailsResponse?.data?.status) || [0, 1].includes(detailsResponse?.data?.accessLevel)
      };
      dispatch(getFarmReportDetailsSuccess(reportDetailsRefined));
      const configResponse = await api.get(endpoints.farmReport.config.concat('?reportId=', reportId));

      if (configResponse && configResponse.status === 200) {
        let responseData = configResponse?.data || {}
        let reportConfigRefined = {
          isDeleted: responseData.isDeleted,
          reportId: responseData.reportId,
          landAndCropsOnly: !responseData.isLivestockSectorsAdded && responseData.isLandAreaSectorsAdded,
          livestockOnly: responseData.isLivestockSectorsAdded && !responseData.isLandAreaSectorsAdded,
          sectorConfigurations: _.orderBy(responseData.sectorConfigurations, 'enterpriseSectorId', 'asc')
        }
        return dispatch(getFarmReportConfigSuccess(reportConfigRefined));
      } else
        return dispatch(getFarmReportConfigFail(configResponse?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
    else
      return dispatch(getFarmReportDetailsFail(detailsResponse?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    dispatch(getFarmReportDetailsFail(e.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."))
    return dispatch(getFarmReportConfigFail(e.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};

const getFarmReportDetailsSuccess = (response) => {
  return {
    type: actionTypes.GET_FARM_REPORT_DETAILS_SUCCESS,
    payload: response
  };
};

const getFarmReportDetailsFail = (response) => {
  return {
    type: actionTypes.GET_FARM_REPORT_DETAILS_FAIL,
    payload: response
  };
};

const getFarmReportConfigSuccess = (response) => {
  return {
    type: actionTypes.GET_FARM_REPORT_CONFIG_SUCCESS,
    payload: response
  };
};

const getFarmReportConfigFail = (response) => {
  return {
    type: actionTypes.GET_FARM_REPORT_CONFIG_FAIL,
    payload: response
  };
};

export const submitFarmReport = () => async (dispatch) => {
  try {
    const { reportDetails, reportConfig } = store.getState().farmReport
    const { farmId } = store.getState().common

    const detailsResponse = await api.post(
      endpoints.farmReport.details,
      { ...reportDetails, farmId: farmId }
    );
    if (detailsResponse
      && detailsResponse.status === 200
      && detailsResponse?.data?.reportId) {
      const reportId = detailsResponse.data.reportId;
      const configResponse = await api.post(
        endpoints.farmReport.config,
        { ...reportConfig, reportId: reportId }
      );
      if (configResponse && configResponse.status === 200) {
        dispatch(setReportId(reportId));
        return dispatch(submitFarmReportSuccess("Farm Report has been successfully created."));
      } else
        return dispatch(submitFarmReportFail(configResponse?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
    else
      return dispatch(submitFarmReportFail(detailsResponse?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(submitFarmReportFail(e.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};

const submitFarmReportSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const submitFarmReportFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const updateFarmReport = () => async (dispatch) => {
  try {
    const { reportDetails, reportConfig } = store.getState().farmReport

    const detailsResponse = await api.put(
      endpoints.farmReport.details,
      { ...reportDetails }
    );
    if (detailsResponse
      && detailsResponse.status === 200
      && detailsResponse?.data?.reportId) {
      const configResponse = await api.put(
        endpoints.farmReport.config,
        { ...reportConfig }
      );
      if (configResponse && configResponse.status === 200) {
        return dispatch(updateFarmReportSuccess("Farm Report has been successfully updated."));
      } else
        return dispatch(updateFarmReportFail(configResponse?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
    else
      return dispatch(updateFarmReportFail(detailsResponse?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(updateFarmReportFail(e.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  }
};

const updateFarmReportSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const updateFarmReportFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const editFarmReportDetails = (response) => {
  return {
    type: actionTypes.EDIT_FARM_REPORT_DETAILS,
    payload: response
  };
}

export const resetFarmReportDetails = () => {
  return {
    type: actionTypes.RESET_FARM_REPORT_DETAILS,
  };
}

export const resetFarmReportResponseState = () => {
  return {
    type: actionTypes.RESET_RESPONSE_STATUS,
  };
};