import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
    metaData: {},
    totalArea: {},
    initialFormData: {},
    currentFormData: {},
};

const cropProductionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CROP_PRODUCTION_METADATA_SUCCESS: return setMetaDataSuccess(state, action);
        case actionTypes.GET_CROP_PRODUCTION_METADATA_FAIL: return setMetaDataFail(state, action);
        case actionTypes.RESET_CROP_PRODUCTION_METADATA: return resetMetaData(state, action);
        case actionTypes.GET_CROP_PRODUCTION_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
        case actionTypes.GET_CROP_PRODUCTION_FORM_DATA_FAIL: return setFormDataFail(state, action);
        case actionTypes.SET_CROP_PRODUCTION_FORM_DATA: return setFormData(state, action);
        case actionTypes.RESET_CROP_PRODUCTION_ALL_DATA: return resetFormData(state, action);
        default:
            return state;
    }
}

const setMetaDataSuccess = (state, action) => {
    const updatedState = {
        metaData: action.payload,
    }
    return updateObject(state, updatedState);
}

const setMetaDataFail = (state, action) => {
    const updatedState = {
        metaData: {},
    }
    return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
    const updatedState = {
      metaData: {},
    }
    return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
    const updatedState = {
        initialFormData: action.payload,
        currentFormData: action.payload,
    }
    return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
    const updatedState = {
        metaData: {},
    }
    return updateObject(state, updatedState);
}

const resetFormData = (state, action) => {
    const updatedState = {
        currentFormData: state.initialFormData,
        currentOtherSalesData: state.initialOtherSalesData,
    }
    return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
    const { sectorId, formData } = action.payload;
    const updatedState = {
        currentFormData: {
            ...state.currentFormData,
            [sectorId]: {
                ...formData
            }
        },
    }
    return updateObject(state, updatedState);
}

export default cropProductionReducer;
