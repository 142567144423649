import * as actionTypes from './types';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';

export const getMyFarm = (farmId) => async (dispatch) => {
    try {
        const response = await api.get(endpoints.profile.farm.concat('?farmId=', farmId));
        if (response && response.status === 200) {
            return dispatch(getMyFarmSuccess(response.data));
        }
        else {
            return dispatch(getMyFarmFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateMyAccountFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const getMyFarmSuccess = (response) => {
    return {
        type: actionTypes.GET_MY_FARM_DATA_SUCCESS,
        payload: response
    };
};

const getMyFarmFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};


export const updateMyFarm = (data) => async (dispatch) => {
    try {
        const response = await api.put(endpoints.profile.farm, {
            id: data.id,
            farmName: data.farmName,
            holdingNumber: data.holdingNumber,
            businessReferenceNumber: data.businessReferenceNumber,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            townCity: data.townCity,
            countryId: data.countryId,
            zipPostCode: data.zipPostCode,
            isFarmAdvisoryService: data.isFarmAdvisoryService
        });

        if (response && response.status === 200) {
            return dispatch(updateMyFarmSuccess("My farm has been successfully updated"));
        }
        else {
            return dispatch(updateMyFarmFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateMyAccountFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
}

const updateMyFarmSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const updateMyFarmFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const getMyAccount = (accountId) => async (dispatch) => {
    try {
        const response = await api.get(endpoints.profile.account);
        if (response && response.status === 200) {
            return dispatch(getMyAccountSuccess(response.data));
        }
        else {
            return dispatch(getMyAccountFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateMyAccountFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const getMyAccountSuccess = (response) => {
    return {
        type: actionTypes.GET_MY_ACCOUNT_DATA_SUCCESS,
        payload: response
    };
};

const getMyAccountFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const updateMyAccount = (data) => async (dispatch) => {
    const {
        id,
        type,
        email,
        firstName,
        lastName,
        organisationId,
        officeId,
        license,
        active,
        newsletter,
        agreement,
    } = data;
    try {
        const response = await api.put(endpoints.profile.account,
            {
                email: email,
                isActive: active,
                lockoutEnabled: true,
                firstName: firstName,
                lastName: lastName,
                roleNames: [type],
                agreedToTandS: agreement,
                licenseId: license,
                officeId: officeId,
                optInNewsletter: newsletter,
                organisationId: organisationId

            });
        if (response && response.status === 200) {
            await dispatch(getMyAccount(id));
            return dispatch(updateMyAccountSuccess("Account details has been updated successfully"));
        }
        else {
            return dispatch(updateMyAccountFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateMyAccountFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const updateMyAccountSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const updateMyAccountFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const resetMyAccount = () => {
    return {
      type: actionTypes.RESET_MY_ACCOUNT_DATA,
    }
}

