import React, { useEffect, useState } from 'react';
import { DataEntryTextField } from '../CustomStyles/StyledComponents';

const TableSearchText = ({ searchKey, handleSearchQuery = () => { }, autoFocus=false }) => {
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        handleSearchQuery(searchKey, searchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    return (
        <DataEntryTextField
            size="small"
            variant="outlined"
            type='search'
            inputProps={{ inputMode: "text" }}
            style={{ width: "100%", textAlign: "center", margin: 0 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus={autoFocus}
        />
    );
};

export default TableSearchText;

