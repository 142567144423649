import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    ReferenceLine
} from "recharts";
import { styled } from '@mui/material/styles';
import { Paper, Typography } from "@mui/material";
import _ from "lodash";
import { ChartTitleTypography } from "../CustomStyles/StyledComponents";

const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 2 }} variant='outlined'>
                <Typography variant="caption" mb={1}>
                    {label}
                </Typography>
                <br />
                <Typography variant="caption" fontWeight="bold">
                    {`${payload[0].name} : ${payload[0].value?.toFixed(2)}%`}
                </Typography>
            </Paper>
        );
    }
    return null;
};

export default function VerticalBarChart({
    title,
    dataset, key,
    height = 300, width = "100%",
    axisInfo = {
        x: 'value',
        y: 'title'
    } },
    isAnimationActive = true
) {

    return (
        <>
            <ChartTitleTypography gutterBottom align="center">
                {title}
            </ChartTitleTypography>
            <ResponsiveContainer
                height={height}
                width={width}
            >
                <BarChart
                    isAnimationActive={isAnimationActive}
                    key={key}
                    data={_.filter(dataset, d => d.value >= 0)}
                    stackOffset="sign"
                    layout="vertical"
                    margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5
                    }}
                    barSize={8}
                >
                    {/* <XAxis dataKey="source" scale="point" padding={{ left: 10, right: 10 }} /> */}
                    <XAxis name="Emissions" dataKey={axisInfo.x} type='number' >
                        <Label value="CO₂-eq Emissions (%)" offset={0} fontSize="1.2em" position="insideBottom" />
                    </XAxis>
                    <YAxis dataKey={axisInfo.y} type='category' interval={0} width={75} />
                    {/* <Tooltip itemStyle={{ fontWeight: 700, fontSize: "1.15em" }} /> */}
                    <Tooltip content={renderCustomTooltip} />
                    {/* <Legend /> */}
                    <ReferenceLine x={0} stroke="#000" />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar name="Emissions" dataKey={axisInfo.x} fill="#253764" />
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}
