import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

const BackButton = ({ step = -1 }) => {
    let navigate = useNavigate();
    return (
        <Button
            className="activated"
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={() => navigate(step)}
        >
            Previous
        </Button>);
};

export default BackButton;

