import * as actionTypes from './types';
import store from '../..';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';

export const getWasteTransportWaterMetaData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.wasteTransportWater.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getWasteTransportWaterMetadataSuccess(response?.data));
  } else
    return dispatch(getWasteTransportWaterMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getWasteTransportWaterMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_WASTE_TRANSPORT_WATER_METADATA_SUCCESS,
    payload: response
  };
};

const getWasteTransportWaterMetadataFail = () => {
  return {
    type: actionTypes.GET_WASTE_TRANSPORT_WATER_METADATA_FAIL,
  };
};

export const resetWasteTransportWaterMetaData = () => {
  return {
    type: actionTypes.RESET_WASTE_TRANSPORT_WATER_METADATA,
  };
};

export const getWasteTransportWaterFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.energyWaste.wasteTransportWater.formData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    const data = response?.data?.data
    dispatch(getWholeFarmData(response?.data?.wholeFarmData));
    return dispatch(getWasteTransportWaterFormDataSuccess(data));
  } else
    return dispatch(getWasteTransportWaterFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getWasteTransportWaterFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_WASTE_TRANSPORT_WATER_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getWasteTransportWaterFormDataFail = () => {
  return {
    type: actionTypes.GET_WASTE_TRANSPORT_WATER_FORM_DATA_FAIL,
  };
};

const getWholeFarmData = (response) => {
  return {
    type: actionTypes.GET_WASTE_TRANSPORT_WATER_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
}

export const saveWasteTransportWaterData = ({ reportId }) => async (dispatch) => {
  const { currentFormData, currentWholeFarmData } = store.getState().wasteTransportWater
  const response = await api.post(endpoints.energyWaste.wasteTransportWater.saveData,
    {
      reportId: reportId,
      data: currentFormData,
      wholeFarmData: currentWholeFarmData
    });
  if (response && response.status === 200) {
    return dispatch(saveWasteTransportWaterDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveWasteTransportWaterDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveWasteTransportWaterDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveWasteTransportWaterDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setWasteTransportWaterFormData = (response) => {
  return {
    type: actionTypes.SET_WASTE_TRANSPORT_WATER_FORM_DATA,
    payload: response
  };
};

export const setWasteTransportWaterWholeFarmData = (response) => {
  return {
    type: actionTypes.SET_WASTE_TRANSPORT_WATER_WHOLE_FARM_DATA_SUCCESS,
    payload: response
  };
};

export const resetWasteTransportWaterFormData = () => {
  return {
    type: actionTypes.RESET_WASTE_TRANSPORT_WATER_ALL_DATA,
  };
};
