export const getPageStyles = () => {
    return `
    @media all {
        *CSS code*
    }

    @media print {
        table.print-friendly tr td, table.print-friendly tr th {
            page-break-inside: avoid;
        }
        /* Define the header and footer */
        header {
            position: fixed;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            height: 66px;
        }            
        header::before {
            counter-increment: page; /* Increment the value of section counter by 1 */
        }
        body {
            counter-reset: page 1;
          }
        footer {
            position: fixed;
            display: block;
            bottom: 0;
            left: 0;
            right: 0;
            height: 55px;
        }
        /*#pageFooter:after {
            content: counter(page);
        }*/
    }
    
    @page { 
        size: A4;
        /*margin: 30px;*/
    }`
};