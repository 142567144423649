import { Button, Chip, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { ListTableCell, ListTableRow, SearchTableCell } from '../../../components/CustomStyles/StyledComponents';
import {
    getAdminProjects, resetResponseState, setAdminProjectId, setLoading, unsetLoading
} from '../../../store/appAction';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import EmptyTableBody from '../../../components/Tables/EmptyTableBody';

import { debounce } from 'lodash';
import SortButton from '../../../components/Buttons/SortButton';
import TableSearchText from '../../../components/Inputs/TableSearchText';

const SORTING = 'friendlyProjectId';
const SORT_ORDER = 'asc';
const PAGE = 0;
const ROWS_PER_PAGE = 10;


const Projects = () => {
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMsg, errorMsg } = useSelector(state => state.common);
    const projectList = useSelector(state => state.adminProject.projects);

    const [searchQuery, setSearchQuery] = useState({
        sorting: SORTING,
        sortOrder: SORT_ORDER,
        skipCount: PAGE,
        maxResultCount: ROWS_PER_PAGE
    });
    const [pagination, setPagination] = useState({
        page: PAGE,
        rowsPerPage: ROWS_PER_PAGE
    });
    const [skip, setSkip] = useState(false);

    useEffect(() => {
        dispatch(setLoading());
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        projectList && setTimeout(() => {
            dispatch(unsetLoading());
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectList]);

    useEffect(() => {
        const { page, rowsPerPage } = pagination;

        !skip && projectList?.items && setSearchQuery(prevState => ({
            ...prevState,
            skipCount: page * rowsPerPage,
            maxResultCount: rowsPerPage
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
        dispatch(getAdminProjects(searchQuery));// TODO: Should enable in future
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                dispatch(getAdminProjects(searchQuery));
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    const handelProjectCreate = () => {
        navigate('/admin/project/create')
    }

    const handleProjectEditDetails = (e, project) => {
        dispatch(setAdminProjectId(project?.id));
        navigate('/admin/project/edit')
    }

    const handleChangePage = (event, newPage) => {
        setPagination(prevState => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination(prevState => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    };

    const setSorting = ({ sortOrder, sorting }) => {
        setSearchQuery(prevState => ({
            ...prevState,
            sortOrder, sorting
        }));
    }

    const buildSearchQuery = (key, value) => {
        setSkip(true);
        setPagination({
            page: 0,
            rowsPerPage: 10
        });
        setSearchQuery(prevState => ({
            ...prevState,
            [key]: value || undefined,
            skipCount: PAGE,
            maxResultCount: ROWS_PER_PAGE
        }));
        // dispatch(setLoading());
    };

    const debouncedBuildSearchQuery = useMemo(() => {
        return debounce(buildSearchQuery, 500);
    }, []);

    // useEffect(() => {
    //     return () => {
    //         debouncedBuildSearchQuery.cancel();
    //     };
    // });

    const handleUserSearchHighlight = (fullName) => {
        return fullName?.toLowerCase().includes(searchQuery?.users);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                ></Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper
                    sx={{
                        p: 2,
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}>
                    <Button variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handelProjectCreate}>
                        Create
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '98%' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '95%' }} size="small" aria-label="customized table">
                            <TableHead>
                                <ListTableCell sx={{ textAlign: 'left', width: '15%' }}>Id
                                    <SortButton
                                        sortKey={'friendlyProjectId'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '30%' }}>Name
                                    <SortButton
                                        sortKey={'name'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '55%' }}>Users</ListTableCell>
                            </TableHead>
                            <TableBody>
                                <ListTableRow>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="friendlyProjectId"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                            autoFocus={true}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="name"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="users"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                </ListTableRow>
                                {projectList?.items?.length > 0 ?
                                    (projectList?.items?.map((row, index) => (
                                        <ListTableRow key={index}>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleProjectEditDetails(e, row)}>
                                                {row.friendlyProjectId}
                                            </ListTableCell>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleProjectEditDetails(e, row)}>
                                                {row.name}
                                            </ListTableCell>
                                            <ListTableCell>
                                                {(row.users || []
                                                    /*faker.helpers.multiple(faker.person.fullName, {
                                                        count: Math.floor(Math.random() * 17),
                                                    })*/)
                                                    .map((user) =>
                                                        <Chip
                                                            label={user.fullName}
                                                            color="primary"
                                                            size='small'
                                                            variant={handleUserSearchHighlight(user.fullName) ? "filled" : "outlined"}
                                                            sx={{ margin: '2px' }}
                                                        />)}
                                            </ListTableCell>
                                        </ListTableRow>
                                    ))) :
                                    <EmptyTableBody />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={projectList?.totalCount || projectList?.length || 0}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid >

    )
}

export default Projects