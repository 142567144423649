import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';
import queryString from 'query-string';

export const getMonthlyStockData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.livestock.numbersWeights.monthlyStock.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getMonthlyStockDataSuccess(response?.data));
  }
  else
    return dispatch(getMonthlyStockDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getMonthlyStockDataSuccess = (response) => {
  return {
    type: actionTypes.GET_GET_MONTHLY_STOCK_DATA_SUCCESS,
    payload: response
  };
};

const getMonthlyStockDataFail = () => {
  return {
    type: actionTypes.GET_MONTHLY_STOCK_DATA_FAIL,
  };
};

export const setMonthlyStockData = (response) => {
  return {
    type: actionTypes.SET_MONTHLY_STOCK_DATA,
    payload: response
  };
};

export const resetMonthlyStockData = () => {
  return {
    type: actionTypes.RESET_MONTHLY_STOCK_DATA,
  };
};

export const saveMonthlyStockData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().monthlyStock
  const params = {};
  params.reportId = reportId;
  const response = await api.post(endpoints.livestock.numbersWeights.monthlyStock.concat('?', queryString.stringify(params)),currentFormData);
  if (response && response.status === 200) {
    return dispatch(saveMonthlyStockDataSuccess("Monthly Stock data has been successfully saved."));
  }
  else
    return dispatch(saveMonthlyStockDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};
  

const saveMonthlyStockDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveMonthlyStockDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};