export const GET_CROP_USE_METADATA = 'GET_CROP_USE_METADATA'
export const GET_CROP_USE_METADATA_SUCCESS = 'GET_CROP_USE_METADATA_SUCCESS'
export const GET_CROP_USE_METADATA_FAIL = 'GET_CROP_USE_METADATA_FAIL'
export const RESET_CROP_USE_METADATA = 'RESET_CROP_USE_METADATA'

export const GET_CROP_USE_FORM_DATA_SUCCESS = 'GET_CROP_USE_FORM_DATA_SUCCESS'
export const GET_CROP_USE_FORM_DATA_FAIL = 'GET_CROP_USE_FORM_DATA_FAIL'

export const SET_CROP_USE_FORM_DATA = 'SET_CROP_USE_FORM_DATA'
export const RESET_CROP_USE_ALL_DATA = 'RESET_CROP_USE_ALL_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'