import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, } from '@mui/material';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person';
import { signOut } from '../../store/appAction';
import { ADMIN, CONSULTANT, FARMER, SUPPER_ADMIN } from '../../constants/userRoles';
import { InfoTooltip } from '../../components/CustomStyles/StyledComponents';
import {setFarmId, setAdminFarmId, setReportId, resetEnterpriseDropDownValues} from '../../store/appAction';

const ProfileMenu = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector(state => state.auth.user);
  const adminFarm = useSelector(state => state.adminFarm);
  const farmId = useSelector(state => state.common.farmId);
  const isAdminRoute = location.pathname.includes('admin');
  const handleSubMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSubMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyFarm = () => {
    if ([ADMIN, SUPPER_ADMIN].includes(user?.role))
      navigate('/#');

    navigate('/my-farm');
  }

  const handleMyAccount = () => {
    navigate('/my-account');
  }

  const handleLogout = () => {
    dispatch(setFarmId(null));
    dispatch(setAdminFarmId(null));
    dispatch(setReportId(null));
    dispatch(resetEnterpriseDropDownValues())
    dispatch(signOut());
    navigate('/auth/login');
  }

  return (
    <>
      <InfoTooltip title="Account settings">
        <IconButton
          onClick={handleSubMenuClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>{user?.givenName?.toUpperCase()[0]}</Avatar>
          {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
        </IconButton>
      </InfoTooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleSubMenuClose}
        onClick={handleSubMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <ListItemIcon>
            <Person2Icon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem> */}

        <MenuItem onClick={handleMyAccount}>
          <ListItemIcon>
            <Person2Icon fontSize="small" />
          </ListItemIcon>
          My Account
        </MenuItem>
        {([FARMER].includes(user?.role)) &&
          <MenuItem onClick={handleMyFarm}>
            <ListItemIcon>
              <AgricultureIcon fontSize="small" />
            </ListItemIcon>
            My Farm
          </MenuItem>}
        <Divider />
        {/* <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default ProfileMenu;
